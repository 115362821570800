import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SurveyListType } from "./SurveysController";

// Customizable Area End

export const configJSON = require("./config");

type UserResponseType = Array<{
  id: string,
  type: string,
  attributes: {
      survey_id: 20,
      survey_name: string,
      account_id: string,
      response: { [key: string]: string | string[] },
      created_at: string
  }
}>

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  surveyId: string | null;
  accountId: string | null;
  survey: SurveyListType | null;
  responseUser: UserResponseType;
  deleteId?: string | null;
  accordionActive: number | null;
  isShare: boolean,
  linkShare:string
  messageHeading:string
  page: number,
  hasNext: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  responsesUserId: string = "";
  surveysDataId:  string = "";
  changeActiveStateSurveyId: string = "";
  deleteSurveyId : string  = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      surveyId: window.location.pathname.split("/")[2],
      accountId: null,
      survey: null,
      responseUser: [],
      deleteId: null,
      accordionActive: null,
      isShare: false,
      linkShare:'',
      messageHeading:'',
      page: 1,
      hasNext : true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
    if(apiRequestCallId && responseJson !== null){
      switch(apiRequestCallId){
        case this.surveysDataId: 
          return this.setState({ survey : responseJson.data });
        case this.changeActiveStateSurveyId:
          return this.getSurvey()          
        case this.responsesUserId:
          if(!responseJson.data.message){
          return  this.setState({ responseUser: this.SurveyListCondition(responseJson), page : this.state.page + 1 })
          } 
          return this.setState({ hasNext: true })
        case this.deleteSurveyId:
          return  this.props.navigation.navigate("SurveysScreen")
      }
    } 
    }
    // Customizable Area End
  }
  // web events

  // Customizable Area Start

  SurveyListCondition = (responseJson : any) => this.state.page > 1 ? this.state.responseUser?.concat(responseJson.data) : responseJson.data

  async componentDidMount() {
    this.getSurvey();
    this.responsesUser();
  }

  responsesUser = () => {
    this.responsesUserId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      `${configJSON.SURVEYS_RESPONSE}${this.state.surveyId}&per_page=10&page=${this.state.page}`,
    )
  }

  changeSurveysActionState = (id?: string, active?: boolean) => {
    if(id){
      this.changeActiveStateSurveyId = this.apiCall(
        configJSON.PUT_METHOD_TYPE,
        configJSON.changeSurveysState(id),
        JSON.stringify({ active })
      );
    }
  }

  getSurvey = () => {
    this.surveysDataId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      `${configJSON.CREATE_SURVEY}/${this.state.surveyId}`
    )
  }

  toggleDelete = (deleteId?: string | null ) => this.setState({ deleteId });
  
  deleteSurveyAPI = () => {
    this.deleteSurveyId = this.apiCall(
      configJSON.DELETE_METHOD_TYPE,
      `${configJSON.SURVEYS_LIST}/${this.state.deleteId}`
    );
  }

  handleShareLink =(serveyObj?:any) => { 
  const shareLink = configJSON.surveyDetailsShareLink + serveyObj?.type +'/'+ serveyObj?.attributes?.survey_id +'/'+ serveyObj?.attributes?.user_account_id
    const heading = `Survey Response : `+serveyObj?.attributes?.survey_name
    this.setState({
      isShare : !this.state.isShare,
      linkShare:shareLink,
      messageHeading:heading
    })   
  }


  apiCall = (method: string, endpoint: string, body?: string) => {
    const header = {
      token: localStorage.getItem("tokenn"),
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
