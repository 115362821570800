import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { VillageIDStatus, depthOfPumpFarmerStatus, distIDStatus, distanceInFarmerStatus, educationFarmerStatus, farmSurveyNumberFarmerStatus, farmnameFarmerStatus, horizontalDistanceFarmerStatus, motorInHpFarmerStatus, ownershipFarmerStatus, soilTextureFarmerStatus, sourceOfIrrigationFarmerStatus, stateIDStatus, talukaIDStatus, totalFarmAreaFarmerStatus, typeOfIrrigationFarmerStatus, unitOfMeasureFarmerStatus } from "../../../components/src/helper";
import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";
const userLocation = localStorage.getItem('userLocationFarmMapping');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  TokenValue: null | string;
  loggedInUserRole: string | null;
  isLoggedUserAdmin: boolean;
  totalFarmDiary: any;
  farmDiaryData: any[];
  userSurveyData: Array<any>;
  currentFarmerData: any;
  currentFarmerMeta: any;
  currentFarmerLoading: boolean;
  disableStateFarmer: boolean;
  farmerInitialValues: any;
  getDistrictData: any[];
  getTalukaData: any[];
  getVillageData: any[];
  getSoilTextureFarmerData: any[];
  getLandTypeFarmerData: any[];
  DistanceInData: any[];
  farmImageFile: any;
  farmImageURL: any;
  openEditUserModal: boolean;
  FElistData: any[];
  selectedFEformData: any;
  seletedUserTypeAddUser: string;
  getOwnerListFarmerData: any[];
  unitFarmAreaData: any[];
  getEducationFarmerData: any;
  getStateData: any[];
  getSourceOfIrrigationData: any[];
  getTypeIrrigationListingData: any[];
  userLandTypeFarmer: any;
  userModal: boolean;
  openDeleteModal: boolean;
  deleteUserData: any;
  farmerRowData: any;
  openDeleteFarmerModal: boolean;
  errorModalOpen: boolean;
  errorModalMsg: string;
  successAddFarmer: {};
  successUpdateFarmer: {};
  selectedLocationArr: any[];
  adminInitialValues: any;
  feInitialValues: any;
  deletedVillagesList: any[];
  defaultAssignedVillages: any[];
  showEyeIconPassword: boolean;
  showEyeIconConfirmPassword: boolean;
  getMobileDeviceModelFEData: any;
  getLocationsListFEData: any;
  anchorElVillageFilter: any;
  openTableFilter: boolean;
  successAddFE: {};
  getFilterStateData: any[];
  getFilterDistrictData: any[];
  getFilterTalukaData: any[];
  getFilterVillageData: any[];
  getFilterFEData: any[],
  getUnitOfMeasureFarmerData: any[];
  successAddSuperAdmin: any;
  successAddAdmin: {};
  surveyExpanded: boolean | string;
  isShare: boolean,
  linkShare:string
  messageHeading:string
  initialStatesVF: any[];
  initalDistrictsVF: any[];
  initialTalukasVF: any[];
  initialVillagesVF: any[];
  stateInputValueVF: string;
  districtInputValueVF: string;
  talukaInputValueVF: string;
  villageInputValueVF: string;
  selectedStateVF: any;
  selectedDistrictVF: any;
  selectedTalukaVF: any;
  selectedVillageVF: any;
  filteredStatesVF: any[];
  filteredDistrictsVF: any[];
  filteredTalukasVF: any[];
  filteredVillagesVF: any[];
  isAccordianErrorVF: any;
  isDisabledVF: any;
  openVillageFilter: boolean;
  expandedVF: any;
  // SUCCESS MODAL INTERFACE
  successModalOpen: boolean;
  successModalMsg: string;
  // LOADING BTN INTERFACE
  addFarmerLoading: boolean;
  addFELoading: boolean;
  addAdminLoading: boolean;
  addSALoading: boolean;
  updateFarmerLoading: boolean;
  deleteFarmerLoading: boolean;
  cropImageModal: boolean;
  croppedImage: any;
  farmerProfileImage: { icon: string }
  // CROP-MAPPING
  OpenFarmMapping: boolean
  latlngHash: any
  farmMappingURL: any;
  coordinates: any[];
  markerDefault: any;
  farmMappingFile: any;
  tempCoordinates:any[];
  userLocation: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleFarmerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFarmerByIdApiCallId: string = "";
  getFarmDiaryByIdApiCallId: string = "";
  addFarmerApiCallId: string = "";
  updateFarmerApiCallId: string = "";
  getTalukaApiCallId: string = "";
  getVillageApiCallId: string = "";
  getDistrictApiCallId: string = "";
  addFEApiCallId: string = "";
  addAdminApiCallId: string = "";
  addSuperAdminApiCallId: string = "";
  deleteUserFarmerApiCallId: string = "";
  getFElistApiCallId: string = "";
  getStateApiCallId: string = "";
  getFilterVillageApiCallId: string = "";
  getFilterFeApiCallId: string = "";
  getFilterDistrictApiCallId: string = "";
  getFilterTalukaApiCallId: string = "";
  getSourceOfIrrigationFarmerApiCallId: string = "";
  getOwnerListFarmerApiCallId: string = "";
  getUnitOfMeasureFarmerApiCallId: string = "";
  getSoilTextureFarmerApiCallId: string = "";
  getTypeIrrigationListingApiCallId: string = "";
  getEducationFarmerApiCallId: string = "";
  getUnitFarmAreaApiCallId: string = "";
  getDistanceInApiCallId: string = "";
  getMobileDeviceModelFEApiCallId: string = "";
  getLandTypeFarmerApiCallId: string = "";
  getLocationsListFEApiCallId: string = "";
  getUserSurveyByIdApiCallId: string = "";
  // ASSIGN VILLAGE FILTER STARTS
  getVillageFilterTalukaData: string = "";
  getVillageFilterStateData: string = "";
  getVillageFilterDistrictData: string = "";
  getVillageFilterVillageData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loggedInUserRole: localStorage.getItem("user_role"),
      TokenValue: localStorage.getItem("tokenn"),
      isLoggedUserAdmin: false,
      totalFarmDiary: "",
      farmDiaryData: [],
      userSurveyData: [],
      currentFarmerData: {
        first_name: "",
        last_name: "",
        dob: null,
      },
      currentFarmerMeta: {},
      disableStateFarmer: false,
      currentFarmerLoading: false,
      farmerInitialValues: {
        middlenameFarmer: "",
        firstnameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        totalFamilyMemberFarmer: "",
        dateOfBirthFarmer: null,
        educationFarmer: "",
        stateFarmer: "",
        genderFarmer: "",
        villageFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        farmnameFarmer: "",
        ownershipFarmer: "",
        totalFarmAreaFarmer: "",
        farmSurveyNumberFarmer: "",
        unitOfMeasureFarmer: "",
        horizontalDistanceFarmer: "",
        motorInHpFarmer: "",
        distanceInFarmer: "",
        soilTextureFarmer: "",
        depthOfPumpFarmer: "",
        landTypeFarmer: "",
        typeOfIrrigationFarmer: "",
        sourceOfIrrigationFarmer: "",
      },
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
      getSoilTextureFarmerData: [],
      getLandTypeFarmerData: [],
      DistanceInData: [],
      farmImageFile: null,
      farmImageURL: null,
      openEditUserModal: false,
      FElistData: [],
      selectedFEformData: null,
      seletedUserTypeAddUser: "Farmers",
      getOwnerListFarmerData: [],
      unitFarmAreaData: [],
      getEducationFarmerData: [],
      getStateData: [],
      getSourceOfIrrigationData: [],
      getTypeIrrigationListingData: [],
      userLandTypeFarmer: null,
      userModal: false,
      openDeleteModal: false,
      deleteUserData: null,
      farmerRowData: {},
      openDeleteFarmerModal: false,
      errorModalOpen: false,
      errorModalMsg: "",
      successAddFarmer: {},
      successUpdateFarmer: {},
      selectedLocationArr: [],
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      getMobileDeviceModelFEData: [],
      getLocationsListFEData: [],
      anchorElVillageFilter: null,
      openTableFilter: false,
      successAddFE: {},
      getFilterStateData: [],
      successAddSuperAdmin: null,
      getFilterDistrictData: [],
      getFilterTalukaData: [],
      getFilterVillageData: [],
      successAddAdmin: {},
      getUnitOfMeasureFarmerData: [],
      getFilterFEData: [],
      surveyExpanded: false,
      isShare: false,
      linkShare:'',
      messageHeading:'',
      initialStatesVF: [],
      initalDistrictsVF: [],
      initialTalukasVF: [],
      initialVillagesVF: [],
      filteredStatesVF: [],
      filteredDistrictsVF: [],
      filteredTalukasVF: [],
      filteredVillagesVF: [],
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      openVillageFilter: false,
      expandedVF: false,
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      // SUCCESS MODAL INTERFACE
      successModalOpen: false,
      successModalMsg: "",
      // LOADING BTN INTERFACE
      addFarmerLoading: false,
      addFELoading: false,
      addAdminLoading: false,
      addSALoading: false,
      updateFarmerLoading: false,
      deleteFarmerLoading: false,
      cropImageModal: false,
      croppedImage: "",
      farmerProfileImage: { icon: "" },
      // CROP-MAPPING
      OpenFarmMapping: false,      
      farmMappingURL: null,
      coordinates: [],
      latlngHash: null,
      tempCoordinates:[],
      markerDefault: null,
      userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      farmMappingFile: null,      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.setAssignVillageFilterAPIResponses(apiRequestCallId, responseJson);
        switch (apiRequestCallId) {
          case this.getFarmerByIdApiCallId:
            this.getFarmerByIdApiCallIdResp(responseJson);
            break;
          case this.getFarmDiaryByIdApiCallId:
            this.getFarmDiaryByIdApiCallIdResp(responseJson);
            break;
          case this.addFarmerApiCallId:
            this.addFarmerApiCallIdResp(responseJson);
            break;
          case this.updateFarmerApiCallId:
            this.updateFarmerApiCallIdResp(responseJson);
            break;
          case this.getVillageApiCallId:
            this.getVillageApiCallIdResp(responseJson);
            break;
          case this.addFEApiCallId:
            this.addFEApiCallIdResp(responseJson);
            break;
          case this.getTalukaApiCallId:
            this.getTalukaApiCallIdResp(responseJson);
            break;
          case this.getFElistApiCallId:
            this.getFElistApiCallIdResp(responseJson);
            break;
          case this.getStateApiCallId:
            this.getStateApiCallIdResp(responseJson);
            break;
          case this.getDistrictApiCallId:
            this.getDistrictApiCallIdResp(responseJson);
            break;
          case this.getLocationsListFEApiCallId:
            this.getLocationsListFEApiCallIdResp(responseJson);
            break;
          case this.getMobileDeviceModelFEApiCallId:
            this.getMobileDeviceModelFEApiCallIdResp(responseJson);
            break;
          case this.getLandTypeFarmerApiCallId:
            this.getLandTypeFarmerApiCallIdResp(responseJson);
            break;
          case this.getFilterVillageApiCallId:
            this.getFilterVillageApiCallIdResp(responseJson);
            break;
          case this.getFilterFeApiCallId:
            this.getFilterFEApiCallIdResp(responseJson);
            break;
          case this.getFilterDistrictApiCallId:
            this.getFilterDistrictApiCallIdResp(responseJson);
            break;
          case this.getFilterTalukaApiCallId:
            this.getFilterTalukaApiCallIdResp(responseJson);
            break;
          case this.getSourceOfIrrigationFarmerApiCallId:
            this.getSourceOfIrrigationFarmerApiCallIdResp(responseJson);
            break;
          case this.getOwnerListFarmerApiCallId:
            this.getOwnerListFarmerApiCallIdResp(responseJson);
            break;
          case this.getUnitOfMeasureFarmerApiCallId:
            this.getUnitOfMeasureFarmerApiCallIdResp(responseJson);
            break;
          case this.getSoilTextureFarmerApiCallId:
            this.getSoilTextureFarmerApiCallIdResp(responseJson);
            break;
          case this.getTypeIrrigationListingApiCallId:
            this.getTypeIrrigationListingApiCallIdResp(responseJson);
            break;
          case this.getEducationFarmerApiCallId:
            this.getEducationFarmerApiCallIdResp(responseJson);
            break;
          case this.getUnitFarmAreaApiCallId:
            this.getUnitFarmAreaApiCallIdResp(responseJson);
            break;
          case this.getDistanceInApiCallId:
            this.getDistanceInApiCallIdResp(responseJson);
            break;
          case this.addSuperAdminApiCallId:
            this.addSuperAdminApiCallIdResp(responseJson);
            break;
          case this.addAdminApiCallId:
            this.addAdminApiCallIdResp(responseJson);
            break;
          case this.deleteUserFarmerApiCallId:
            this.deleteUserApiCallIdResp(responseJson);
            break;
          case this.getUserSurveyByIdApiCallId:
            this.getUserSurveyByIdApiCallIdResp(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }

  handleCloseCropModalFarmer = () => {
    this.setState({ cropImageModal: false })
  }

  handleSubmitFarmerProgfileImage = (cropperRef: any) => {
    let croppedImageFarmer = cropperRef.current?.cropper.getCroppedCanvas();
    croppedImageFarmer.toBlob((blob: any) => {
      const file = new File([blob], "FarmerProfile.png", { type: 'image/png' });
      this.setState({ cropImageModal: false, croppedImage: file}, () => {
        this.updateFarmerAvatar(file);
      })
    });
  }

  handleFarmerProfileImageUpload = async (event: any) => {
    event.persist();
    const selectedFile = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.setState(prev => {
        return { farmerProfileImage: { icon: reader.result as string }, cropImageModal: true }
      });
    };
    reader.readAsDataURL(selectedFile);
    if (event.currentTarget) {
      event.currentTarget.value = '';
    }
  }

  onProfileImageUpload = (event: any) => {
    event.currentTarget.files.length && this.handleFarmerProfileImageUpload(event)
  }

  updateFarmerAvatar = async (avatar: any) => {
    this.setState({ updateFarmerLoading: true })
    const farmerId = this.state.currentFarmerData.id;

    const isUnRegisteredFarmer = !this.state.currentFarmerData.land_detail_id?.length;

    let header: any = {
      token: this.state.TokenValue,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isUnRegisteredFarmer ? configJSON.postAPiMethod : configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isUnRegisteredFarmer ? `${configJSON.updateAvatar}` : `${configJSON.getTableAPiEndPoint}/${farmerId}?role_farmer=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();

    reqFormdata.append("data[attributes][avatar]", avatar);

    if(isUnRegisteredFarmer) {
      reqFormdata.append("id", farmerId);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // ASSIGN-VILLAGE FILTER RESPONSE FUNCTIONS
  setAssignVillageFilterAPIResponses = (apiRequestId: any, responseData: any) => {
    switch (apiRequestId) {
      case this.getVillageFilterTalukaData:
        this.getAssignVillageFilterTalukaDataRes(responseData);
        break;
      case this.getVillageFilterVillageData:
        this.getAssignVillageFilterVillageDataRes(responseData);
        break;
      case this.getVillageFilterDistrictData:
        this.getAssignVillageFilterDistrictDataRes(responseData);
        break;
      case this.getVillageFilterStateData:
        this.getAssignVillageFilterStateDataRes(responseData);
        break;
    }
  };
  getAssignVillageFilterVillageDataRes = (responseJson: any[]) => {
    if (responseJson) {
      let villageList: any[] = [];

      if (responseJson?.length > 0) {
        if (this.state.selectedLocationArr.length > 0) {
          villageList = responseJson.filter((el) => {
            return !this.state.selectedLocationArr.find((element) => {
              return element.id === el.id;
            });
          });
        } else {
          villageList = responseJson;
        }
        this.setState({
          filteredVillagesVF: villageList,
          initialVillagesVF: villageList,
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      } else {
        this.setState({
          filteredVillagesVF: [],
          initialVillagesVF: [],
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      }
    }
  };

  getAssignVillageFilterDistrictDataRes = (respJsonData: any[]) => {
    if (respJsonData?.length) {
      this.setState({
        filteredDistrictsVF: respJsonData,
        initalDistrictsVF: respJsonData,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    } else {
      this.setState({
        filteredDistrictsVF: [],
        initalDistrictsVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    }
  };

  getAssignVillageFilterTalukaDataRes = (respJsonData: any[]) => {
    if (respJsonData?.length) {
      this.setState({
        filteredTalukasVF: respJsonData,
        initialTalukasVF: respJsonData,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    } else {
      this.setState({
        filteredTalukasVF: [],
        initialTalukasVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    }
  };

  getAssignVillageFilterStateDataRes = (respJsonData: any[]) => {
    if (respJsonData?.length) {
      this.setState({
        filteredStatesVF: respJsonData,
        initialStatesVF: respJsonData,
      });
    } else {
      this.setState({
        filteredStatesVF: [],
        initialStatesVF: [],
      });
    }
  };

  // Customizable Area Start
  getSourceOfIrrigationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getSourceOfIrrigationData: responseJson });
    }
  };

  getTypeIrrigationListingApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getTypeIrrigationListingData: responseJson });
    }
  };

  getLocationsListFEApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getLocationsListFEData: responseJson });
    } else {
      this.setState({ getLocationsListFEData: [] });
    }
  };

  getFilterTalukaApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getFilterTalukaData: responseJson });
    }
  };

  getMobileDeviceModelFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getMobileDeviceModelFEData: responseJson });
    }
  };

  getFilterDistrictApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getFilterDistrictData: responseJson });
    }
  };

  getFilterVillageApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getFilterVillageData: responseJson });
    }
  };

  getSoilTextureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getSoilTextureFarmerData: responseJson });
    }
  };

  getFilterFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getFilterFEData: responseJson });
    }
  };

  getVillageApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getVillageData: responseJson });
    }
  };

  getOwnerListFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getOwnerListFarmerData: responseJson });
    }
  };

  getEducationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getEducationFarmerData: responseJson });
    }
  };

  getUnitOfMeasureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getUnitOfMeasureFarmerData: responseJson });
    }
  };

  getDistanceInApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ DistanceInData: responseJson });
    }
  };

  addSuperAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({
        userModal: false,
        successAddSuperAdmin: responseJson.data,
      });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_SA
      })
      this.setState({ addSALoading: false })
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalMsg: responseJson.errors[0],
          errorModalOpen: true,
        });
        this.setState({ addSALoading: false })
      } else {
        this.setState({
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
          errorModalOpen: true,
        });
        this.setState({ addSALoading: false })
      }
    }
  };

  addFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({ successAddFE: responseJson.data, userModal: false });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FE
      })
      this.setState({ addFELoading: false })
    } else {
      if (responseJson?.errors) {
        let newMsg = "";
        let errorMsg: string = responseJson.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responseJson?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorModalMsg: newMsg,
          errorModalOpen: true,
        });
        this.setState({ addFELoading: false })
      } else {
        this.setState({
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
          errorModalOpen: true,
        });
        this.setState({ addFELoading: false })
      }
    }
  };

  addAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({ successAddAdmin: responseJson.data, userModal: false });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_ADMIN
      })
      this.setState({ addAdminLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalMsg: responseJson.errors[0],
          errorModalOpen: true,
        });
        this.setState({ addAdminLoading: false })
      }
    }
  };

  getFarmerByIdApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      if (responseJson.data) {
        this.setState({
          currentFarmerLoading: false,
          currentFarmerData: { ...responseJson.data.attributes, ...{ id: responseJson.data.id } },
          currentFarmerMeta: responseJson.meta,
        });
        return;
      }
      this.setState({ currentFarmerLoading: false });
    }
  };

  getFarmDiaryByIdApiCallIdResp = (responseJson: any[]) => {
    if (responseJson.length) {
      const mergedFarmDiaryData = responseJson.map((elem) => elem.data);
      let mergedFarmDiaryDataFlat = mergedFarmDiaryData.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);
      this.setState({ farmDiaryData: mergedFarmDiaryDataFlat });
    }
  };

  addFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({
        successAddFarmer: responseJson.data,
        userModal: false,
      });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FARMER
      })
      this.setState({ addFarmerLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addFarmerLoading: false })
      }
    }
  };

  updateFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      const farmerId = window.location.pathname.split("/")[2];
      this.setState({
        successUpdateFarmer: responseJson.data,
        selectedLocationArr: [],
      });
      this.fetchFarmerByIdData(farmerId);
      this.handleClose();
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.UPDATE_FARMER
      })
      this.setState({ updateFarmerLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ updateFarmerLoading: false })
      }
    }
  };


  deleteUserApiCallIdResp = (responseJson: any) => {
    if (responseJson?.account_user) {
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.DELETE_FARMER
      })
      this.setState({ deleteFarmerLoading: false })
      window.location.replace("/users");
    } else if (responseJson?.data?.base) {
      this.setState({
        errorModalMsg: responseJson?.data?.base[0],
        errorModalOpen: true
      })
      this.setState({ deleteFarmerLoading: false })
    } else {
      this.setState({
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        errorModalOpen: true
      })
      this.setState({ deleteFarmerLoading: false })
    }
    this.handleClose()
  };

  getFElistApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ FElistData: responseJson });
    } else {
      this.setState({ FElistData: [] });
    }
  };

  getStateApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({
        getStateData: responseJson,
        getFilterStateData: responseJson,
      });
    }
  };

  getDistrictApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getDistrictData: responseJson });
    }
  };

  getUnitFarmAreaApiCallIdResp = (responseJson: any) => {
    if (responseJson) {
      this.setState({ unitFarmAreaData: responseJson });
    }
  };

  getLandTypeFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getLandTypeFarmerData: responseJson });
    }
  };
  getUserSurveyByIdApiCallIdResp = (responseJson: any) => {
    this.setState({
      userSurveyData: responseJson?.data || [],
    })
  };

  handleSurveyAccordionChange = (panel: any) => (
    event: any, isExpanded: boolean
  ) => {
    this.setState({
      surveyExpanded: isExpanded ? panel : false
    })
  };

  handleShareLink = (serveyObj?: any) => {
    const shareLink = configJSON.surveyDetailsShareLink + serveyObj?.type + '/' + serveyObj?.attributes?.survey_id + '/' + serveyObj?.attributes?.user_account_id
    const heading = `Survey Response : ` + serveyObj?.attributes?.survey_name
    this.setState({
      isShare: !this.state.isShare,
      linkShare: shareLink,
      messageHeading: heading
    })
  }

  // HANDLE FARM-MAPPING FUNCTION
  onSaveFarmMapping = (
    latlngHash: any, 
    file: any, url: string, 
    savedCoordinates: any[]
    ) => {    
    this.setState({ latlngHash: latlngHash, OpenFarmMapping: false, 
      farmMappingURL: url, farmMappingFile: file, 
      tempCoordinates: savedCoordinates })
  }
  closeFarmMappingModal = () => { this.setState({ 
    OpenFarmMapping: false 
  }) }

  getCurrentLocation = () => {
    const success = (pos: any) => {

      this.setState({
        userLocation: {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }

      }, () => {

        localStorage.setItem('userLocationFarmMapping', JSON.stringify(this.state.userLocation))
      })
    }
    const error = (error: any) => {
      this.setState({ userLocation: null
      })
    }
    if(navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  setcoordinates = (data: any) => {
    // get string of latitude_longitude
    let latLongStrng = data.attributes.land_details[0]?.attributes.latitude_longitude

    if (latLongStrng && latLongStrng?.length > 0) {

      latLongStrng = JSON.parse(latLongStrng);
      // convert string to array of object
      let coordinatesArrayTemp = [];
      for (let i = 0; i < Object.keys(latLongStrng).length; i++) {
        coordinatesArrayTemp.push(latLongStrng[i]);
      }

      // re-change key names of array
      let CoordinatesFinalArr = [];
      if (coordinatesArrayTemp.length > 0) {
        // Loop through each object in the original array and modify the keys
        for (const coordinate of coordinatesArrayTemp) {
          const modifiedCoord = {
            "lat": coordinate.latitude,
            "lng": coordinate.longitude
          };

          CoordinatesFinalArr.push(modifiedCoord);
        }
      }
      if (CoordinatesFinalArr.length > 0) {
        const centroidTemp = getPolygonCentroid(CoordinatesFinalArr)
        this.setState({ 
          markerDefault: centroidTemp 
        })
      }
      return CoordinatesFinalArr;
    } else {
      return []
    }
  }

  async componentDidMount(): Promise<void> {
    this.setUserAdminRole()
    const farmerId = window.location.pathname.split("/")[2];
    this.fetchFarmerByIdData(farmerId);
    this.fetchFarmDiaryById(+farmerId);
    this.fetchMobileDeviceModelFE()
    this.fetchStates();
    this.fetchEducationFarmer();
    this.fetchOwnerListFarmer();
    this.fetchUnitOfMeasureFarmer();
    this.fetchLandTypeFarmer();
    this.fetchSoilTextureFarmer();
    this.fetchSourceOfIrrigationFarmer();
    this.fetchTypeIrrigation();
    this.fetchLocationsListFE();
    this.fetchMobileDeviceModelFE();
    this.fetchUnitFarmAreaUrlData();
    this.fetchDistnaceInData();
    this.fetchFElist();
    this.fetchUserSurveys(+farmerId);
    // Assign Village
    this.getStatesVF();
  }
  setUserAdminRole = () => {
    if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.ADMIN) {
      this.setState({ isLoggedUserAdmin: true });
    } else if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.SUPER_ADMIN) {
      this.setState({ isLoggedUserAdmin: false });
    } else {
      this.setState({ isLoggedUserAdmin: true });
    }
  };
  handleFarmerEditClick = async (data: any) => {
    let setFE = null;

    let feId = data.attributes?.assigned_fe_id;
    if (feId) {
      setFE = this.state.FElistData?.find((item: any) => item.id === feId);
    }
    const farmerDoB = moment(data.attributes?.date_of_birth);
    const stateID = stateIDStatus(data);
    if (stateID) {
      this.setState({ disableStateFarmer: true });
    } else {
      this.setState({ disableStateFarmer: false });
    }

    await this.fetchDistrict(stateID);
    const distID = distIDStatus(data);
    await this.fetchTaluka(distID);
    const talukaId = talukaIDStatus(data);
    await this.fetchVillage(talukaId);
    const villageID = VillageIDStatus(data);

    this.setState({
      openEditUserModal: true,
      farmerRowData: data,
      farmerInitialValues: {
        firstnameFarmer: data.attributes.first_name,
        middlenameFarmer: data.attributes.middle_name,
        surnameFarmer: data.attributes.last_name,
        mobileNumberFarmer: data.attributes.phone_number,
        adharcardNumberFarmer: data.attributes.aadhaar_number,
        dateOfBirthFarmer: farmerDoB,
        totalFamilyMemberFarmer: data?.attributes?.total_family_members,
        educationFarmer: educationFarmerStatus(data),
        stateFarmer: stateID,
        genderFarmer: data.attributes.gender,
        districtFarmer: distID,
        talukaFarmer: talukaId,
        villageFarmer: villageID,
        farmnameFarmer: farmnameFarmerStatus(data),
        farmSurveyNumberFarmer: farmSurveyNumberFarmerStatus(data),
        ownershipFarmer: ownershipFarmerStatus(data),
        totalFarmAreaFarmer: totalFarmAreaFarmerStatus(data),
        unitOfMeasureFarmer: unitOfMeasureFarmerStatus(data),
        horizontalDistanceFarmer: horizontalDistanceFarmerStatus(data),
        distanceInFarmer: distanceInFarmerStatus(data),
        motorInHpFarmer: motorInHpFarmerStatus(data),
        depthOfPumpFarmer: depthOfPumpFarmerStatus(data),
        soilTextureFarmer: soilTextureFarmerStatus(data),
        landTypeFarmer: this.landTypeFarmerStatus(data),
        sourceOfIrrigationFarmer: sourceOfIrrigationFarmerStatus(data),
        typeOfIrrigationFarmer: typeOfIrrigationFarmerStatus(data),
      },
      selectedFEformData: setFE,
      farmImageURL: data.attributes?.land_details[0]?.attributes.farm_image?.url,
      farmImageFile: null,
      farmMappingURL: data.attributes.land_details[0]?.attributes.farm_mapping_image?.url,
      coordinates: this.setcoordinates(data),
      tempCoordinates: this.setcoordinates(data),
      userModal: true,
    });
  };

  handleFarmerDelete = () => {
    this.setState({
      openDeleteFarmerModal: true,
    });
  };

  handleAssignFEchange = (event: any, value: any) => {
    this.setState({ selectedFEformData: value });
  };

  closeVillageFilterVF = () => {
    this.setState({
      expandedVF: false,
      openVillageFilter: false,
      districtInputValueVF: "",
      stateInputValueVF: "",
      villageInputValueVF: "",
      talukaInputValueVF: "",
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      isDisabledVF: {
        district: true,
        state: false,
        village: true,
        taluka: true,
      },
    });
  }
  handleSubmitAccordionVF = () => {
    const result: boolean = this.checkAccordianError();
    if (result === true) {
      this.setState({
        expandedVF: false,
      });
    } else {
      const filteredData = this.state.getLocationsListFEData.find(
        (item: any) => item.id === this.state.selectedVillageVF.id
      );
      let newVillageValue = [...this.state.selectedLocationArr];
      newVillageValue.push(filteredData);
      this.setState(
        {
          openVillageFilter: false,
          selectedLocationArr: newVillageValue,
        },
        () => {
          this.closeVillageFilterVF();
        }
      );
    }
  }
  handleVillageFilterClearVF = () => {
    this.setState({
      stateInputValueVF: "",
      expandedVF: false,
      talukaInputValueVF: "",
      districtInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: this.state.initialStatesVF,
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
      filteredVillagesVF: this.state.initialVillagesVF,
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isDisabledVF: {
        state: false,
        taluka: true,
        district: true,
        village: true,
      },
      isAccordianErrorVF: {
        district: false,
        state: false,
        village: false,
        taluka: false,
      },
    });
  }
  handleTalukaSearchVF = (event: any) => {
    const query = event?.target?.value?.toLowerCase();
    this.setState({ talukaInputValueVF: event?.target?.value });
    const filteredData = query
      ? this.state.initialTalukasVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukasVF;
    this.setState({
      filteredTalukasVF: filteredData,
    });
  }
  handleStateSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValueVF: e?.target?.value });
    const filteredData = query
      ? this.state.initialStatesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStatesVF;
    this.setState({
      filteredStatesVF: filteredData,
    });
  }
  handleVillageSearchVF = (event: any) => {
    const query = event?.target?.value?.toLowerCase();
    this.setState({ villageInputValueVF: event?.target?.value });
    const filteredData = query
      ? this.state.initialVillagesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillagesVF;
    this.setState({
      filteredVillagesVF: filteredData,
    });
  }
  handleChangeTalukaFieldVF = (talukaData: any) => {
    this.setState(
      {
        selectedTalukaVF: talukaData,
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
        this.getVillagesVF();
      }
    );
  }
  handleDistrictSearchVF = (event: any) => {
    const query = event?.target?.value?.toLowerCase();
    this.setState({ districtInputValueVF: event?.target?.value });
    const filteredData = query
      ? this.state.initalDistrictsVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistrictsVF;
    this.setState({
      filteredDistrictsVF: filteredData,
    });
  }
  handleChangeStateFieldVF = (stateData: any) => {
    this.setState(
      {
        selectedStateVF: stateData,
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
        this.getDistrictsVF();
      }
    );
  }
  handleChangeVillageFieldVF = (villageData: any) => {
    this.setState(
      {
        expandedVF: false,
        selectedVillageVF: villageData,
      }, () => {
        this.checkAccordianError();
      }
    );
  }
  handleChangeDistrictFieldVF = (districtData: any) => {
    this.setState(
      {
        selectedDistrictVF: districtData,
        expandedVF: false,
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
      }, () => {
        this.checkAccordianError();
        this.getTalukasVF();
      }
    );
  }

  handleChangePanelVF = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expandedVF: newExpanded ? panel : false,
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredStatesVF: this.state.initialStatesVF,
      filteredVillagesVF: this.state.initialVillagesVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
    });
  };

  checkAccordianError = () => {
    let isDistrictValueEmpty = false;
    let isStateValueEmpty = false;
    let isVillageValueEmpty = false;
    let isTalukaValueEmpty = false;

    if (
      this.state.selectedStateVF?.id === "" || this.state.selectedDistrictVF?.id === "" ||
      this.state.selectedTalukaVF?.id === "" || this.state.selectedVillageVF?.id === ""
    ) {
      if (this.state.selectedStateVF?.id === "") {
        isStateValueEmpty = true;
      }
      if (this.state.selectedDistrictVF?.id === "") {
        isDistrictValueEmpty = true;
      }
      if (this.state.selectedTalukaVF?.id === "") {
        isTalukaValueEmpty = true;
      }
      if (this.state.selectedVillageVF?.id === "") {
        isVillageValueEmpty = true;
      }
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateValueEmpty,
          district: isDistrictValueEmpty,
          taluka: isTalukaValueEmpty,
          village: isVillageValueEmpty,
        },
      });
      return true;
    } else {
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateValueEmpty,
          district: isDistrictValueEmpty,
          taluka: isTalukaValueEmpty,
          village: isVillageValueEmpty,
        },
      });
      return false;
    }
  }


  opacityVillageCalculatorViewForm = () => {
    if (this.state.selectedVillageVF.name === "Select Village") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityTalukaCalculatorViewForm = () => {
    if (this.state.selectedTalukaVF.name === "Select Taluka") {
      return 0.48
    }
    else {
      return 1
    }
  }

  opacityDistrictCalculatorViewForm = () => {
    if (this.state.selectedDistrictVF.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }

  opacityStateCalculatorViewForm = () => {
    if (this.state.selectedStateVF.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }

  getDistrictsVF = () => {
    let params = this.state.selectedStateVF?.id;
    if (this.state.selectedStateVF?.id) {
      this.setState({
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: true,
          taluka: true,
          village: true,
        },
      });

      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getDistrictList}?state_id=${params}`
      );
      this.getVillageFilterDistrictData = reqApiMsg.messageId;
      const headers = {
        token: this.state.TokenValue,
        "Content-Type": configJSON.validationApiContentType,
      };
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }
  getStatesVF = () => {
    this.setState({
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isDisabledVF: {
        ...this.state.isDisabledVF,
        village: true,
        district: true,
        taluka: true,
      },
    });

    const reqApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    reqApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );
    this.getVillageFilterStateData = reqApiMsg.messageId;
    const headers = {
      token: this.state.TokenValue,
      "Content-Type": configJSON.validationApiContentType,
    };
    reqApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
  }
  getTalukasVF = () => {
    let params = this.state.selectedDistrictVF?.id;
    if (this.state.selectedDistrictVF?.id) {
      this.setState({
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          village: true,
          taluka: true,
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
      });

      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTalukaList}?district_id=${params}`
      );
      this.getVillageFilterTalukaData = reqApiMsg.messageId;
      const headers = {
        token: this.state.TokenValue,
        "Content-Type": configJSON.validationApiContentType,
      };
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }
  getVillagesVF = () => {
    let params = this.state.selectedTalukaVF?.id;
    if (this.state.selectedTalukaVF?.id) {
      this.setState({
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          village: true,
        },
      });
      const headers = {
        token: this.state.TokenValue,
        "Content-Type": configJSON.validationApiContentType,
      };

      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVillageList}?taluka_id=${params}`
      );

      this.getVillageFilterVillageData = reqApiMsg.messageId;
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }

  checkLandType = (id: any) => {
    if (id && this.state.getLandTypeFarmerData.length) {
      const matchedObj = this.state.getLandTypeFarmerData.find(
        (each: any) => each.id === Number(id)
      );
      this.setState({ userLandTypeFarmer: matchedObj });
    } else {
      this.setState({ userLandTypeFarmer: null });
    }
  }


  getTalukaFarmerValue = (val: any) => {
    return this.state.getTalukaData?.length ? val?.talukaFarmer : "";
  };

  landTypeFarmerStatus = (dataVal: any) => {
    if (
      dataVal.attributes.land_details &&
      dataVal.attributes.land_details[0]?.attributes?.land_type_id &&
      dataVal.attributes.land_details[0]?.attributes?.land_type_id.length
    ) {
      this.checkLandType(
        dataVal.attributes.land_details[0].attributes.land_type_id[0]?.id
      );
      return dataVal.attributes.land_details[0].attributes.land_type_id[0]?.id;
    } else {
      return "";
    }
  };


  getVillageFarmerValue = (values: any) => {
    return this.state.getVillageData?.length ? values?.villageFarmer : "";
  };
  getDistrictFarmerValue = (val: any) => {
    return this.state.getDistrictData?.length ? val?.districtFarmer : "";
  };
  showVillageFilterModal = () => {
    this.setState({ openVillageFilter: true });
  }

  getPasswordType = () => {
    return this.state.showEyeIconPassword ? "password" : "text";
  };

  getConfirmPasswordType = () => {
    return this.state.showEyeIconConfirmPassword ? "password" : "text";
  };

  handleSubmitFarmer = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFarmer(values)
      : this.addFarmer(values);
  };

  handleErrorModalClose = () => {
    this.setState({ errorModalOpen: false, errorModalMsg: "" });
  };
  handleSuccessModalClose = () => {
    this.setState({ successModalOpen: false, successModalMsg: "" });
  };

  disableDistrictFarmerValue = () => {
    return this.state.getStateData.length && this.state.getDistrictData.length
      ? false
      : true;
  };

  disableTalukaFarmerValue = () => {
    return this.state.getDistrictData.length && this.state.getTalukaData.length
      ? false
      : true;
  };

  disableVillageFarmerValue = () => {
    return this.state.getTalukaData.length && this.state.getVillageData.length
      ? false
      : true;
  };

  updateSeletedUserTypeAddUser = (userRole: string) => {
    this.setState({ seletedUserTypeAddUser: userRole });
  };

  getTalukaApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getTalukaData: responseJson });
    }
  };

  onFeLocationsChange = (event: any, values: any) => {
    this.setState({
      selectedLocationArr: values,
    });
  };

  handleVillageDelete = (event: any, village: any) => {
    const BEvillageObject: any = this.state.defaultAssignedVillages.find(
      (item: any) => String(item.attributes.village_id) === String(village.id)
    );
    if (BEvillageObject) {
      this.setState({
        deletedVillagesList: [
          ...this.state.deletedVillagesList,
          {
            _destroy: true,
            id: BEvillageObject?.id,
            village_id: BEvillageObject?.attributes.village_id,
          },
        ],
      });
    }
    const { selectedLocationArr = [] } = this.state;
    const updatedOptions = selectedLocationArr?.filter(
      (option: any) => option !== village
    );

    this.setState({ selectedLocationArr: updatedOptions });
  };

  assignVillagePlaceholder = () => {
    return this.state.selectedLocationArr.length === 0
      ? "Search by village name"
      : "";
  };

  handleClose = () => {
    this.setState({
      farmImageFile: null,
      farmImageURL: null,
      openDeleteModal: false,
      selectedFEformData: null,
      deleteUserData: null,
      openDeleteFarmerModal: false,
      userModal: false,
      disableStateFarmer: false,
      getVillageData: [],
      getDistrictData: [],
      getTalukaData: [],
      // handle admin form states
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      // handle FE form states
      defaultAssignedVillages: [],
      selectedLocationArr: [],
      deletedVillagesList: [],
      seletedUserTypeAddUser: 'Farmers',
      // crop mapping
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      latlngHash: null,
      OpenFarmMapping: false,
      tempCoordinates: [],
    });
  };

  fetchStates = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStateApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchDistnaceInData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistanceInApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDistanceInAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchFElist = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFElistApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFEListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchUnitFarmAreaUrlData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUnitFarmAreaApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UnitFarmAreaUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchMobileDeviceModelFE = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMobileDeviceModelFEApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMobileDeviceModelFEAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchLocationsListFE = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLocationsListFEApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTypeIrrigation = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTypeIrrigationListingApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTypeIrrigationListingAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchUnitOfMeasureFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUnitOfMeasureFarmerApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUnitOfMeasureFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSoilTextureFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSoilTextureFarmerApiCallId = requestMessage.messageId;
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoilTextureFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSourceOfIrrigationFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSourceOfIrrigationFarmerApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSourceOfIrrigationFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchLandTypeFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLandTypeFarmerApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandTypeFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addFieldExecutive = (feBody: any) => {
    this.setState({ addFELoading: true })
    let feLocationsArr: any[] = [];

    const dobFe = moment(feBody.dateOfBirthFE).format("DD/MM/YYYY");
    const selectedLocList = this.state.selectedLocationArr;
    if (selectedLocList.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        feLocationsArr.push({ village_id: each.id });
      });
    } else {
      feLocationsArr = [];
    }
    const header = {
      Accept: configJSON.jsonContentType,
      "content-type": configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFEApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_fe=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: feBody.firstnameFE,
          role_id: 1,
          phone_number: feBody.mobileNumberFE,
          accounts_villages_attributes: feLocationsArr,
          location: feBody.locationFE,
          last_name: feBody.lastnameFE,
          email: feBody.emailAddressFE,
          user_name: feBody.usernameFE,
          total_family_members: 0,
          date_of_birth: dobFe,
          mobile_type_id: feBody.mobileDeviceModelFE,
          full_phone_number: "+91" + feBody.mobileNumberFE,
        },
      }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchOwnerListFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnerListFarmerApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOwnerListFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchEducationFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEducationFarmerApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.jsonContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteFarmerUser = () => {
    this.setState({ deleteFarmerLoading: true })
    let userId = window.location.pathname.split("/")[2];
    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteUserFarmerApiCallId = requestMessage.messageId;

      const header = {
        token: this.state.TokenValue,
        Accept: configJSON.jsonContentType,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteUserApiEndPoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.userNotFound,
      });
      this.setState({ deleteFarmerLoading: false })
      return false;
    }
  };

  addSuperAdmin = (superAdminBody: any) => {
    this.setState({ addSALoading: true })
    const dob = moment(superAdminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      Accept: configJSON.getTableAPiContentType,
      "content-type": configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSuperAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_super_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          last_name: superAdminBody.lastnameAdmin,
          email: superAdminBody.emailAddressAdmin,
          dob: dob,
          first_name: superAdminBody.firstnameAdmin,
          user_name: superAdminBody.usernameAdmin,
          password: superAdminBody.passwordAdmin,
          full_phone_number: "91" + superAdminBody.mobileNumberAdmin,
          password_confirmation: superAdminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addAdmin = (adminBody: any) => {
    this.setState({ addAdminLoading: true })
    const dob = moment(adminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      Accept: configJSON.getTableAPiContentType,
      "content-type": configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAdminAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          last_name: adminBody.lastnameAdmin,
          full_phone_number: "91" + adminBody.mobileNumberAdmin,
          first_name: adminBody.firstnameAdmin,
          dob: dob,
          email: adminBody.emailAddressAdmin,
          user_name: adminBody.usernameAdmin,
          password: adminBody.passwordAdmin,
          password_confirmation: adminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addFarmer = (farmerBody: any) => {
    this.setState({ addFarmerLoading: true })
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;

    const dobFarmer = moment(farmerBody.dateOfBirthFarmer).format("DD/MM/YYYY");


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFarmerApiCallId = requestMessage.messageId;

    const header = {
      token: this.state.TokenValue,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_farmer=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();

    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", dobFarmer);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    if (this.state.farmImageFile) {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][farm_image]",
        this.state.farmImageFile
      );
    }
    // farm_mapping_image
    if (this.state.farmMappingFile) {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][farm_mapping_image]", this.state.farmMappingFile );
    }
    if (this.state.latlngHash) {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][latitude_longitude]", this.state.latlngHash
      );
    }
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][name]",
      farmerBody.farmnameFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][ownership_of_land_id]",
      farmerBody.ownershipFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][survey_number]",
      farmerBody.farmSurveyNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area]",
      farmerBody.totalFarmAreaFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
      farmerBody.totalFarmAreaFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][unit_farm_area_id]",
      farmerBody.unitOfMeasureFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][distance_in_id]",
      farmerBody.distanceInFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][horizontal_distance]",
      farmerBody.horizontalDistanceFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][pump_depth]",
      farmerBody.depthOfPumpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][motor_horse_power]",
      farmerBody.motorInHpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][land_type_id]",
      farmerBody.landTypeFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][type_irrigation_id]",
      farmerBody.typeOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][soil_texture_id]",
      farmerBody.soilTextureFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][source_irrigation_id]",
      farmerBody.sourceOfIrrigationFarmer
    );
    if (this.state.selectedFEformData) {
      reqFormdata.append("data[attributes][assigned_fe_id]", FEid);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showUserModal() {
    this.setState({
      openEditUserModal: false,
      adminInitialValues: {
        lastnameAdmin: "",
        emailAddressAdmin: "",
        firstnameAdmin: "",
        usernameAdmin: "",
        userLandTypeFarmer: null,
        dateOfBirthAdmin: null,
        mobileNumberAdmin: "",
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feInitialValues: {
        firstnameFE: "",
        mobileNumberFE: "",
        dateOfBirthFE: null,
        locationFE: "",
        lastnameFE: "",
        usernameFE: "",
        emailAddressFE: "",
        mobileDeviceModelFE: "",
      },
      selectedLocationArr: [],
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      farmerInitialValues: {
        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",
        genderFarmer: "",
        stateFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        farmnameFarmer: "",
        farmSurveyNumberFarmer: "",
        ownershipFarmer: "",
        totalFarmAreaFarmer: "",
        horizontalDistanceFarmer: "",
        unitOfMeasureFarmer: "",
        distanceInFarmer: "",
        motorInHpFarmer: "",
        depthOfPumpFarmer: "",
        soilTextureFarmer: "",
        landTypeFarmer: "",
        sourceOfIrrigationFarmer: "",
        typeOfIrrigationFarmer: "",
      },
      // crop mapping
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      latlngHash: null,
      OpenFarmMapping: false,
      tempCoordinates: [],
      userModal: true,
    });
  }

  updateFarmer = (farmerBody: any) => {
    this.setState({ updateFarmerLoading: true })
    const farmerId = this.state.farmerRowData.attributes.id;
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;
    const farmerNewdob = moment(farmerBody.dateOfBirthFarmer).format(
      "DD/MM/YYYY"
    );
    const header = {
      token: this.state.TokenValue,
    };
    const landDetailsId =
      this.state.farmerRowData.attributes.land_detail_id[0] || "";


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${farmerId}?role_farmer=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", farmerNewdob);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    if (this.state.farmImageFile) {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][farm_image]",
        this.state.farmImageFile
      );
    }
    // farm_mapping_image
    if (this.state.farmMappingFile) {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][farm_mapping_image]",
        this.state.farmMappingFile
      );
    }
    if (this.state.latlngHash) {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][latitude_longitude]",
        this.state.latlngHash
      );
    }
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][id]",
      landDetailsId
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][name]",
      farmerBody.farmnameFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][survey_number]",
      farmerBody.farmSurveyNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area]",
      farmerBody.totalFarmAreaFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][ownership_of_land_id]",
      farmerBody.ownershipFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][unit_farm_area_id]",
      farmerBody.unitOfMeasureFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][distance_in_id]",
      farmerBody.distanceInFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
      farmerBody.totalFarmAreaFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][motor_horse_power]",
      farmerBody.motorInHpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][pump_depth]",
      farmerBody.depthOfPumpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][horizontal_distance]",
      farmerBody.horizontalDistanceFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][land_type_id]",
      farmerBody.landTypeFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][type_irrigation_id]",
      farmerBody.typeOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][soil_texture_id]",
      farmerBody.soilTextureFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][source_irrigation_id]",
      farmerBody.sourceOfIrrigationFarmer
    );

    reqFormdata.append("data[attributes][assigned_fe_id]", FEid);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  editModalStatus = () => {
    return this.state.openEditUserModal === true ? true : false;
  };

  parseImages = (img?: string): string | undefined => {
    if (!img) return undefined;
    return img;
  };

  async handleImageUpload(event: any) {
    const selectedFile = event.target.files[0];
    const urlImage = await this.getBase64(selectedFile);
    this.setState({ farmImageURL: urlImage, farmImageFile: selectedFile });
  }

  fetchFarmerByIdData = (farmerId: string) => {
    this.setState({
      currentFarmerData: { first_name: "", last_name: "" },
      currentFarmerMeta: {},
      currentFarmerLoading: true,
    });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}/${farmerId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchFarmDiaryById = (id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmDiaryByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.farmDiaryBaseUrl}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchUserSurveys = (id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getUserSurveyByIdApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.SURVEYS_USER_RESPONSE}${id}`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  fetchVillage = (villageId: string | number) => {
    if (!villageId) {
      this.setState({ getVillageData: [] });
      return false;
    }
    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVillageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}?taluka_id=${villageId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchDistrict = (stateId: string | number) => {
    if (!stateId || stateId === "") {
      this.setState({
        getDistrictData: [],
        getVillageData: [],
        getTalukaData: [],
      });
      return false;
    }

    const header = {
      token: this.state.TokenValue,
      Accept: configJSON.getTableAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDistrictApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictAPiEndPoint}?state_id=${stateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTaluka = (districtId: string | number) => {
    if (!districtId || districtId === "") {
      this.setState({ getVillageData: [], getTalukaData: [], });
      return false;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaApiCallId = requestMessage.messageId;

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaAPiEndPoint}?district_id=${districtId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}