export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
// export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img133ea26e5bc45814488c34550a886ffd66ddcbdb = require("../../global_assets/133ea26e5bc45814488c34550a886ffd66ddcbdb.png");

export const imgfarmLogo = require("../assets/z-logo.svg");
export const PersonSVG = require("../assets/z-person.svg");
export const DateSVG = require("../assets/z-date.svg");
export const ChatSVG = require("../assets/z-chat.svg");
export const HelpSVG = require("../assets/z-help.svg");
export const NotificationSVG = require("../assets/z-notification.svg");
export const ScheduleSVG = require("../assets/z-schedule.svg");
export const SurveySVG = require("../assets/z-survey.svg");
export const Person = require("../assets/y-person.svg");
export const Date = require("../assets/y-calendar.svg");
export const Chat = require("../assets/y-chat.svg");
export const Help = require("../assets/y-help.svg");
export const Bell = require("../assets/y-bell.svg");
export const Event = require("../assets/y-events.svg");
export const Survey = require("../assets/y-surveys.svg");
export const Home = require("../assets/y-home.svg");
export const imgIconlist = require("../assets/List_Icon.png")
export const imgIconGraph = require("../assets/Graph_Icon.png")
export const BlankCheckbox = require("../assets/BlankCheckbox.png")
export const checkedCheckBox = require("../assets/checkedCheckBox.png")
export const imgIconFilter = require("../assets/filter_Icon.png");
export const LocationSVG = require("../assets/location.svg")
export const preSowingImg = require("../assets/preSowingImg.png")
export const sowingImg = require("../assets/sowingImg.png")
export const gapFillingImg = require("../assets/gapFillingImg.png")
export const weedManagementImg = require("../assets/weedManagementImg.png")
export const nutrientManagementImg = require("../assets/nutrientManagementImg.png")
export const pestManagementImg = require("../assets/pestManagementImg.png")
export const irrigationImg = require("../assets/irrigationImg.png")
export const harvestImg = require("../assets/harvestImg.png")
export const sellImg = require("../assets/sellImg.png")
export const AddCircleOutlineOutlinedIcon = require("../assets/AddCircleOutlineOutlinedIcon.svg")
export const RemoveCircleOutlineIcon = require("../assets/RemoveCircleOutlineIcon.svg")
export const rupeeIconSVG = require("../assets/rupeeIcon.svg")
export const CropMappingImg = require("../assets/CropMappingImg.png")
export const linkSeparatorSVG = require("../assets/linkSeparatorSVG.svg")
export const cameraPlusSVG = require("../assets/cameraPlus.png")
export const mapPlusPNG = require("../assets/mapPlus.png")
export const mapPlusSVG = require("../assets/mapPlus.png")
export const ChecokedRadioIconSVG = require("../assets/CheckedRadioButton.svg")
export const UnchecokedRadioIconSVG = require("../assets/UncheckedRadioButton.svg")
export const MappingIcon = require("../assets/MappingIcon.svg") 

export const RegistrationDropdownIcon = require("../assets/RegistrationDropdown.svg") 
export const CalenderDropdownIcon = require("../assets/CalenderDropdown.svg") 
export const FarmdiaryDropdownIcon = require("../assets/FarmdiaryDropdown.svg") 
export const NotificationDropdownIcon = require("../assets/NotificationDropdown.svg") 

export const smallCloseIconSVG = require("../assets/smallCloseIcon.svg") 
export const EditIconOrangeSVG = require("../assets/EditIconOrange.svg")
export const deleteIconRedSVG = require("../assets/deleteIconRed.svg") 
export const FilterCloseIconSVG = require("../assets/FilterCloseIcon.svg") 


export const MapStartSearchIconSVG = require("../assets/MapStartSearchIcon.svg") 
export const MapEndLocationIconSVG = require("../assets/MapEndLocationIcon.svg") 
export const LeftRoundIconSVG = require("../assets/LeftRoundIcon.svg") 
export const RightRoundIconSVG = require("../assets/RightRoundIcon.svg") 
export const BackArrowIconSVG = require("../assets/BackArrowIcon.svg") 
