import React from "react";
import {
    // Customizable Area Start
    Box,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    Breadcrumbs,
    List,
    ListItem,
    ListItemText,
    Link,
    Avatar,
    Button,
    Grid,
    FormLabel,
    FormControl,
    MenuItem,
    Select,
    Divider,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    Badge,
    Tooltip,
    Card,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent,    
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import './AdminConsoleUserFarmDiary.web.css'
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
    AddCircleOutlineOutlinedIcon,
    gapFillingImg,
    harvestImg,
    imgIconFilter,
    irrigationImg,
    nutrientManagementImg,
    pestManagementImg,
    preSowingImg,
    RemoveCircleOutlineIcon,
    rupeeIconSVG,
    sellImg,
    sowingImg,
    weedManagementImg,
    linkSeparatorSVG,
    mapPlusSVG,
    BackArrowIconSVG
} from "./assets";
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import AdminConsoleUserFarmDiaryController, {
    Props
} from "./AdminConsoleUserFarmDiaryController";

import CustomModal from "../../../components/src/customModal.web";
import UserTableFilter from "../../../components/src/UserTableFilter";
import { CameraStyledBadge } from "../../../components/src/AdminConsoleHelper";
import EditIcon from "@material-ui/icons/Edit";
import FarmMapping from "../../../components/src/FarmMapping/FarmMapping.web";
import InfiniteScroll from "react-infinite-scroll-component";
const theme = createTheme({
    palette: {
        primary: {
            main: "#000",
            contrastText: "#fff",
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },
    },
    typography: {
        h4: {
            fontWeight: 600,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "36px",
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
            padding: "16px 0px",
            textTransform: "uppercase",
        },
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    },
});

const inputprop = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 0.1,
        step: "any"
    }
}
const inputPropmin = {
    inputProps: {
        min: 0.1,
        step: "any"
    }
}
const minDateFarmDiaryPhase = new Date('2021-01-01');
const minDateFarmDiaryPhaseMessage = 'Minimum year should be 2021';
// Customizable Area End


export default class AdminConsoleUserFarmDiary extends AdminConsoleUserFarmDiaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    farmMappingImage = ({ attachment, item, itemIndex }: { attachment: string; item: any; itemIndex: number }) => {
        let imgPartial = `${attachment}?new_image=${new Date().getTime()}`
        if (attachment && attachment.startsWith('https://maps.googleapis.com/maps/api/staticmap')) {
            imgPartial = attachment
        }              
        return (
            <>
                {attachment ? (
                    <>
                        <CameraStyledBadge
                            overlap="rectangular"
                            badgeContent={
                                <Box>
                                    <label htmlFor="edit-crop-mapping">
                                        <Tooltip title="Edit">
                                            <IconButton
                                                component="span"
                                                aria-label="edit"
                                                disableRipple={true}
                                                style={{ padding: "0.375rem" }}
                                                onClick={() => {                                                                                                        
                                                    this.setState({ currentSowingItemIndex: itemIndex,selectedSowingItem:item },()=>{                                                        
                                                        this.setState({OpenFarmMapping: true})
                                                    })
                                                }}
                                            >
                                                <EditIcon fontSize="small" htmlColor="#fff" />
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                </Box>
                            } >
                            <Box>
                                <Card variant="outlined" style={styleWeb.badgeCardRoot}>
                                    <CardMedia
                                        component="img"
                                        image={imgPartial}
                                        src={imgPartial}
                                        alt="cover-images"
                                    />
                                </Card>
                            </Box>
                        </CameraStyledBadge>
                    </>
                ) : (
                    <>
                        <Box
                            style={{
                                background: "#FCFAF7",
                                border: "1px solid #F0EBE0",
                                borderRadius: "12px",
                                padding: "16px",
                                boxSizing: "border-box",
                                height: "150px",                                
                            }}
                        >
                            <Typography className="uploadTxt"> Plot farm's location on map...
                            </Typography>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <label htmlFor="add-crop-mapping"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <IconButton
                                        component={"span"}
                                        onClick={() => {
                                            this.setState({ currentSowingItemIndex: itemIndex, selectedSowingItem: item }, () => {
                                                this.setState({ OpenFarmMapping: true })
                                                if (!item.crop_mapping?.url) {
                                                    this.fetchCurrentLocation()
                                                }
                                            })
                                        }}
                                        data-testid="openFarmMappingbtn">
                                        <img src={mapPlusSVG} alt="mapIcon" />
                                    </IconButton>
                                </label>
                            </Box>
                        </Box>
                    </>
                )}                
            </>
        )
    };

    getCustomErrorMessage = (item: any, key: string) => {
        return (
            <div>
                <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
                    {(item?.errors && item?.errors[key]) ? item?.errors[key] : ""}
                </Typography>
            </div>
        )
    };
    dateIcon = () => { return (<DateRangeIcon className="customDatePickerIcon" />) }

    fieldSowing = (item: any, index: number) => this.getSelectValue(item, "crop_name_id") && (this.checkCotton(this.getSelectValue(item, "crop_name_id")) ? <Grid item xs={12} lg={6} className="text-field-container">
            <FormLabel className="phasesLabelCustom">
                Number of Packets
            </FormLabel>
            <FormControl>
                <TextField
                    type="number"
                    variant="outlined"
                    placeholder="for Cotton"
                    color="secondary"
                    data-testid="noOfPacketsTestid"
                    name="noOfPackets"
                    value={this.getInputValue(item, "number_of_packet")}
                    onChange={(e: any) => { this.setFieldValueSowing(index, "noOfPackets", e.target.value) }}
                    error={this.getErrorBoolean(item, "number_of_packet")}
                    helperText={this.getErrorText(item, "number_of_packet")}
                    onKeyDown={this.keyDown}
                    InputProps={inputPropmin}
                />
            </FormControl>
        </Grid>
            :
            <Grid item xs={12} lg={6} className="text-field-container">
                <FormLabel className="phasesLabelCustom">
                    Quantity in KG
                </FormLabel>
                <FormControl>
                    <TextField
                        type="number"
                        variant="outlined"
                        placeholder="for other crops"
                        color="secondary"
                        data-testid="qtyInKgTestid"
                        name="qtyInKg"
                        value={this.getInputValue(item, "quantity_in_kg_other_crop")}
                        onChange={(e: any) => { this.setFieldValueSowing(index, "qtyInKg", e.target.value) }}
                        error={this.getErrorBoolean(item, "quantity_in_kg_other_crop")}
                        helperText={this.getErrorText(item, "quantity_in_kg_other_crop")}
                        onKeyDown={this.keyDown}
                        InputProps={inputPropmin}
                    />
                </FormControl>
            </Grid>)

    preSowingPhase = () => {
        return (
            <Box className='preSowingWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={preSowingImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Pre-Sowing</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="preSowingDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("preSowing")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.preSowing.map((item, index) => {
                    return (
                        <>
                            {(this.state.preSowing.length > 1 && index !== 0 && !item?._destroy && this.showHRline("preSowing")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}

                            <Box className="preSowingFormWrapper" key={index}>
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} sm={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Pre-Sowing Activity
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            color="secondary"
                                                            variant="outlined"
                                                            data-testid='preSowingActivityTestid'
                                                            name="preSowingActivity"
                                                            value={this.getSelectValue(item, "pre_sowing_activity_id")}
                                                            onChange={(e: any) => { this.setFieldValuePreSowing(index, "preSowingActivity", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "pre_sowing_activity_id")}
                                                            displayEmpty
                                                            disabled={this.isItemHasId(item)}
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Pre-Sowing Activity</span>
                                                            </MenuItem>
                                                            {this.state.preSowingActivityData?.length ?
                                                                this.state.preSowingActivityData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                        data-label={ech.name}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'pre_sowing_activity_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="datePreSowingTestid"
                                                                placeholder="DD/MM/YYYY"
                                                                name="datePreSowing"
                                                                value={this.getDateValue(item, "date")}
                                                                onChange={(date) => { this.setFieldValuePreSowing(index, "datePreSowing", date) }}
                                                                error={this.getErrorBoolean(item, "date")}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                                keyboardIcon={this.dateIcon()}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonPreSowingTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonPreSowing"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValuePreSowing(index, "cropSeasonPreSowing", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* HARROWING */}
                                            {!this.showCompostForm(item) &&
                                                <>
                                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Rupees/Acres"
                                                                    color="secondary"
                                                                    data-testid='costPreSowingTestid'
                                                                    name="costPreSowing"
                                                                    value={this.getInputValue(item, "cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePreSowing(index, "costPreSowing", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "cost")}
                                                                    helperText={this.getErrorText(item, "cost")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputprop}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={8}>
                                                            {(this.state.preSowing.length > 1 && this.showRemoveBtn("preSowing")) &&
                                                                <Box className="removeEntryBtnWrapper">
                                                                    <IconButton
                                                                        data-testid={`rmvPresowingTestid${index}`}
                                                                        onClick={() => {
                                                                            this.handleRemoveSubSection("preSowing", index, item?.id)
                                                                        }}
                                                                    >
                                                                        <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                                    </IconButton>
                                                                </Box>}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {/* COMPOSIT FORM */}
                                            {this.showCompostForm(item) &&
                                                <>
                                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Compost Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Rupees/Acres"
                                                                    color="secondary"
                                                                    data-testid='compostCostTestid'
                                                                    name="compostCost"
                                                                    value={this.getInputValue(item, "compost_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePreSowing(index, "compostCost", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "compost_cost")}
                                                                    helperText={this.getErrorText(item, "compost_cost")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputprop}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Labor Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Rupees/Acres"
                                                                    color="secondary"
                                                                    data-testid='laborCostPreSowingTestid'
                                                                    name="laborCostPreSowing"
                                                                    value={this.getInputValue(item, "labor_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePreSowing(index, "laborCostPreSowing", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputprop}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4}>
                                                            {(this.state.preSowing.length > 1 && this.showRemoveBtn("preSowing")) &&
                                                                <Box className="removeEntryBtnWrapper">
                                                                    <IconButton
                                                                        data-testid={`rmvPresowingTestid${index}`}
                                                                        onClick={() => {
                                                                            this.handleRemoveSubSection("preSowing", index, item?.id)
                                                                        }}
                                                                    >
                                                                        <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                                    </IconButton>
                                                                </Box>}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </Box>
                                    </>
                                )}
                                {(this.state.preSowing.length === index + 1) &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button variant="contained" className='addEntryBtn' disabled>
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnPreSowingTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("preSowing", this.initialpreSowingValues, this.state.preSowing);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>
                    )
                }
                )}
            </Box>
        )
    }
    sowingPhase = () => {
        return (
            <Box className='sowingWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={sowingImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Sowing</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="sowingDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("sowing")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.sowing.length > 0 && this.state.sowing.map((item, index) => {
                    const attachementURL = item.attributes.crop_mapping?.url
                    return (
                        <>
                            {(this.state.sowing.length > 1 && index > 0 && !item?._destroy && this.showHRline("sowing")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="sowingFormWrapper" key={index+5}>
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                placeholder="DD/MM/YYYY"
                                                                data-testid="dateSowingTestid"
                                                                name="dateSowing"
                                                                value={this.getDateValue(item, "date_of_sowing")}
                                                                onChange={(date) => { this.setFieldValueSowing(index, "dateSowing", date) }}
                                                                error={this.getErrorBoolean(item, "date_of_sowing")}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Name
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropNameTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropName"
                                                            value={this.getSelectValue(item, "crop_name_id")}
                                                            onChange={(e: any) => { this.setFieldValueSowing(index, "cropName", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_name_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Name</span>
                                                            </MenuItem>
                                                            {this.state.cropNameData?.length ?
                                                                this.state.cropNameData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_name_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Area
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Area"
                                                            color="secondary"
                                                            data-testid="cropAreaTestid"
                                                            name="cropArea"
                                                            value={this.getInputValue(item, "crop_area")}
                                                            onChange={(e: any) => { this.setFieldValueSowing(index, "cropArea", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_area") || Boolean(this.isCropAreaAvailable())}
                                                            helperText={this.getErrorText(item, "crop_area") || this.isCropAreaAvailable()}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Unit of Measure
                                                    </FormLabel>
                                                    <FormControl fullWidth>

                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="unitOfMeasureSowingTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="unitOfMeasureSowing"
                                                            value={this.getSelectValue(item, "unit_farm_area_id")}
                                                            onChange={(e: any) => { this.setFieldValueSowing(index, "unitOfMeasureSowing", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "unit_farm_area_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Unit</span>
                                                            </MenuItem>
                                                            {this.state.unitFarmAreaData?.length ?
                                                                this.state.unitFarmAreaData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.label}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'unit_farm_area_id')}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                <Grid item xs={12} lg={6} className="text-field-container text-field-container-mapping uploadFarmImageWrapper">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Mapping
                                                    </FormLabel>
                                                    {this.farmMappingImage({ attachment: attachementURL,item , itemIndex:index })}
                                                </Grid>
                                                <Grid item xs={12} lg={6} className="text-field-container">
                                                    <Grid container wrap="nowrap" className="text-field-main-container">
                                                        <Grid item xs={12} lg={6} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Crop Season
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="cropSeasonTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="cropSeason"
                                                                    value={this.getSelectValue(item, "crop_season_id")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "cropSeason", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "crop_season_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Season</span>
                                                                    </MenuItem>
                                                                    {this.state.CropSeasonData?.length ?
                                                                        this.state.CropSeasonData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Crop Type
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="cropTypeTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="cropType"
                                                                    value={this.getSelectValue(item, "crop_type_id")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "cropType", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "crop_type_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Crop Type</span>
                                                                    </MenuItem>
                                                                    {this.state.CropTypeData?.length ?
                                                                        this.state.CropTypeData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'crop_type_id')}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container wrap="nowrap" className="text-field-main-container">
                                                        <Grid item xs={12} lg={6} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Variety
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="varietyTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="variety"
                                                                    value={this.getSelectValue(item, "variety_id")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "variety", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "variety_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Variety</span>
                                                                    </MenuItem>
                                                                    {this.state.VarietyData?.length ?
                                                                        this.state.VarietyData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'variety_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Seed Source
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="seedSourceTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="seedSource"
                                                                    value={this.getSelectValue(item, "seed_source_id")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "seedSource", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "seed_source_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Seed Source</span>
                                                                    </MenuItem>
                                                                    {this.state.SeedSourceData?.length ?
                                                                        this.state.SeedSourceData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'seed_source_id')}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                {this.fieldSowing(item, index)}
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                <Grid item xs={12} lg={6} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Seed Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Seed Cost"
                                                            color="secondary"
                                                            data-testid="seedPriceTestid"
                                                            name="seedPrice"
                                                            value={this.getInputValue(item, "seed_price")}
                                                            onChange={(e: any) => { this.setFieldValueSowing(index, "seedPrice", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "seed_price")}
                                                            helperText={this.getErrorText(item, "seed_price")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={6} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="laborCostSowingTestid"
                                                            name="laborCostSowing"
                                                            value={this.getInputValue(item, "labor_cost")}
                                                            onChange={(e: any) => { this.setFieldValueSowing(index, "laborCostSowing", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "labor_cost")}
                                                            helperText={this.getErrorText(item, "labor_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {this.getCropNameFromId(item?.attributes?.crop_name_id[0]?.id) === "Cotton" &&
                                                <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                    <Grid item xs={12} lg={6} className="d-flex fd-row">
                                                        <Box className="radioLabelFontWrapper">
                                                            <FormLabel className="phasesLabelCustom">Non-GMO Seed?</FormLabel>
                                                        </Box>
                                                        <Box>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    row
                                                                    name="nonGMOseed"
                                                                    value={String(item?.attributes?.gmo_seed)}
                                                                    data-testid="nonGMOseedTestid"
                                                                    onChange={(e: any) => {
                                                                        this.setFieldValueSowing(index, "nonGMOseed", e.target.value)
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        value={"true"}
                                                                        control={<Radio />}
                                                                        label={
                                                                            <span className="checkboxLabelFont">Yes</span>
                                                                        }
                                                                    />
                                                                    <FormControlLabel
                                                                        value={"false"}
                                                                        control={<Radio />}
                                                                        label={
                                                                            <span className="checkboxLabelFont">No</span>
                                                                        }
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} className="d-flex fd-row">
                                                        <Box className="radioLabelFontWrapper">
                                                            <FormLabel className="phasesLabelCustom">Seed Treatment?</FormLabel>
                                                        </Box>
                                                        <Box>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    row
                                                                    name="seedTreatment"
                                                                    data-testid="seedTreatmentTestid"
                                                                    value={String(item?.attributes?.seed_treatment)}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "seedTreatment", e.target.value) }}
                                                                >
                                                                    <FormControlLabel
                                                                        value={"true"}
                                                                        control={<Radio />}
                                                                        label={
                                                                            <span className="checkboxLabelFont">Yes</span>
                                                                        }
                                                                    />
                                                                    <FormControlLabel
                                                                        value={"false"}
                                                                        control={<Radio />}
                                                                        label={
                                                                            <span className="checkboxLabelFont">No</span>
                                                                        }
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding" justifyContent="flex-end">
                                                {
                                                    String(item?.attributes?.seed_treatment) === 'true' && this.getCropNameFromId(item?.attributes?.crop_name_id[0]?.id) === "Cotton" &&
                                                    <>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Bio-Agent Name
                                                            </FormLabel>


                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="bioAgentNameTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="bioAgentName"
                                                                    value={this.getSelectValue(item, "bio_agent_id")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "bioAgentName", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "bio_agent_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Name</span>
                                                                    </MenuItem>
                                                                    {this.state.BioAgentNameData?.length ?
                                                                        this.state.BioAgentNameData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'bio_agent_id')}
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Bio-Agent Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    color="secondary"
                                                                    data-testid="bioAgentCostTestid"
                                                                    name="bioAgentCost"
                                                                    value={this.getInputValue(item, "bio_agent_cost")}
                                                                    onChange={(e: any) => { this.setFieldValueSowing(index, "bioAgentCost", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "bio_agent_cost")}
                                                                    helperText={this.getErrorText(item, "bio_agent_cost")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                }
                                                <Grid item xs={12} lg={4}>
                                                    {this.state.sowing.length > 1 && this.showRemoveBtn("sowing") &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid={`removeBtnSowingTestid${index}`}
                                                                onClick={(event: any) => {
                                                                    this.handleRemoveSubSection("sowing", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.sowing.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnSowingTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("sowing", this.state.initialSowingValues, this.state.sowing);
                                                    }}

                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>
                    )
                })}
            </Box>
        )
    }
    gapFillingPhase = () => {
        return (
            <Box className='gapFillingWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={gapFillingImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Gap filling</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="gapFillingDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("gapFilling")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.gapFilling.map((item, index) => {
                    return (
                        <>
                            {(this.state.gapFilling.length > 1 && index > 0 && !item?._destroy && this.showHRline("gapFilling")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="gapFillingFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding mediaRemove">
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="dateGapFillingTestid"
                                                                name="dateGapFilling"
                                                                value={this.getDateValue(item, "date")}
                                                                onChange={(date) => {
                                                                    this.setFieldValueGapFilling(index, "dateGapFilling", date)
                                                                }}
                                                                error={this.getErrorBoolean(item, "date")}
                                                                format="DD/MM/yyyy"
                                                                placeholder="DD/MM/YYYY"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            data-testid="laborCostGapFillingTestid"
                                                            color="secondary"
                                                            value={this.getInputValue(item, "labor_cost")}
                                                            name="laborCostGapFilling"
                                                            onChange={(e: any) => {
                                                                this.setFieldValueGapFilling(index, "laborCostGapFilling", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "labor_cost")}
                                                            helperText={this.getErrorText(item, "labor_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Price of Seed [if any]
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="priceOfSeedTestid"
                                                            name="priceOfSeed"
                                                            value={this.getInputValue(item, "price_of_seed")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueGapFilling(index, "priceOfSeed", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "price_of_seed")}
                                                            helperText={this.getErrorText(item, "price_of_seed")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonGapTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonGap"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValueGapFilling(index, "cropSeasonGap", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                {(this.state.gapFilling.length > 1 && this.showRemoveBtn("gapFilling")) &&
                                                    <Box className="remove-icon newRemove">
                                                        <IconButton
                                                            data-testid={`removeBtnGapFillingTestid${index}`}
                                                            onClick={() => {
                                                                this.handleRemoveSubSection("gapFilling", index, item?.id)
                                                            }}
                                                        >
                                                            <img src={RemoveCircleOutlineIcon} alt="remove_icon" />
                                                        </IconButton>
                                                    </Box>
                                                }
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.gapFilling.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnGapFillingTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("gapFilling", this.initialgapFillingValues, this.state.gapFilling);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>
                    )
                })}
            </Box>
        )
    }
    weedManagementPhase = () => {
        return (
            <Box className='weedManagementWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={weedManagementImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Weed management</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="weedManagementDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("weedManagement")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.weedManagement.map((item, index) => {
                    return (
                        <>
                            {(this.state.weedManagement.length > 1 && index > 0 && !item?._destroy && this.showHRline("weedManagement")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="weedManagementFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Weeding Date
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="dateofWeedingTestid"
                                                                placeholder="DD/MM/YYYY"
                                                                name="dateofWeeding"
                                                                value={this.getDateValue(item, "weeding_date")}
                                                                onChange={(date) => {
                                                                    this.setFieldValueWeed(index, "dateofWeeding", date)
                                                                }}
                                                                error={this.getErrorBoolean(item, "weeding_date")}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Weeding Type
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="weedTypeTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="weedType"
                                                            value={this.getSelectValue(item, "weeding_type_id")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueWeed(index, "weedType", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "weeding_type_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Type</span>
                                                            </MenuItem>
                                                            {this.state.WeedingTypeData?.length ?
                                                                this.state.WeedingTypeData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'weeding_type_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Machine Charges
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="machineChargeTestid"
                                                            name="machineCharge"
                                                            value={this.getInputValue(item, "machine_charges")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "machineCharge", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "machine_charges")}
                                                            helperText={this.getErrorText(item, "machine_charges")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="laborCostWeedTestid"
                                                            name="laborCostWeed"
                                                            value={this.getInputValue(item, "labor_cost")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "laborCostWeed", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "labor_cost")}
                                                            helperText={this.getErrorText(item, "labor_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Weedicide Name
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="WeedicideNameTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="WeedicideName"
                                                            value={this.getSelectValue(item, 'weedicide_id')}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "WeedicideName", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "weedicide_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Weedicide Name</span>
                                                            </MenuItem>
                                                            {this.state.WeedicideNameData?.length ?
                                                                this.state.WeedicideNameData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'weedicide_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Quantity of Weedicide
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Enter Quantity of Weedicide"
                                                            color="secondary"
                                                            data-testid="QtyOfWeedicideTestid"
                                                            name="QtyOfWeedicide"
                                                            value={this.getInputValue(item, "quantity_of_weedicide")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "QtyOfWeedicide", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "quantity_of_weedicide")}
                                                            helperText={this.getErrorText(item, "quantity_of_weedicide")}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Cost of Weedicide
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="costOfWeedicideTestid"
                                                            name="costOfWeedicide"
                                                            value={this.getInputValue(item, "cost_of_weedicide")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "costOfWeedicide", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "cost_of_weedicide")}
                                                            helperText={this.getErrorText(item, "cost_of_weedicide")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor Cost of Spraying
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="laborCostSprayingTestid"
                                                            name="laborCostSpraying"
                                                            value={this.getInputValue(item, "labor_cost_of_spraying")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "laborCostSpraying", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "labor_cost_of_spraying")}
                                                            helperText={this.getErrorText(item, "labor_cost_of_spraying")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonWeedTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonWeed"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValueWeed(index, "cropSeasonWeed", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={9}>
                                                    {(this.state.weedManagement.length > 1 && this.showRemoveBtn("weedManagement")) &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid={`deletBtnWeedTestid${index}`}
                                                                onClick={(event: any) => {
                                                                    this.handleRemoveSubSection("weedManagement", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.weedManagement.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnWeedTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("weedManagement", this.initialWeedManagementValues, this.state.weedManagement);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>}
                            </Box>
                        </>)
                })}
            </Box>
        )
    }
    nutrientManagementPhase = () => {
        return (
            <Box className='nutrientManagementWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={nutrientManagementImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Nutrient management</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="nutrientManagementDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("nutrientManagement")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.nutrientManagement.map((item, index) => {
                    return (
                        <>
                            {(this.state.nutrientManagement.length > 1 && index > 0 && !item?._destroy && this.showHRline("nutrientManagement")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}

                            <Box className="nutrientManagementFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Nutrient Management
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="nutrientSelectTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="nutrientSelect"
                                                            value={this.getSelectValue(item, "nutrient_managment_id")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueNutrient(index, "nutrientSelect", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "nutrient_managment_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Nutrient</span>
                                                            </MenuItem>
                                                            {this.state.NutrientManagementData?.length ?
                                                                this.state.NutrientManagementData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'nutrient_managment_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date of Application
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="dateOfApplicationNutrientTestid"
                                                                placeholder="DD/MM/YYYY"
                                                                name="dateOfApplicationNutrient"
                                                                value={this.getDateValue(item, "date_of_application")}
                                                                onChange={(date) =>
                                                                    this.setFieldValueNutrient(index, "dateOfApplicationNutrient", date)
                                                                }
                                                                error={this.getErrorBoolean(item, "date_of_application")}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Quantity of Nutrient
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Quantity"
                                                            color="secondary"
                                                            data-testid="qtyOfNutrientTestid"
                                                            name="qtyOfNutrient"
                                                            value={this.getInputValue(item, "quantity_of_nutrient")}
                                                            onChange={(e: any) => { this.setFieldValueNutrient(index, "qtyOfNutrient", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "quantity_of_nutrient")}
                                                            helperText={this.getErrorText(item, "quantity_of_nutrient")}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Unit of Measure
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="unitOfMeasureNutrientTestid"
                                                            color="secondary"
                                                            variant="outlined"
                                                            name="unitOfMeasureNutrient"

                                                            value={this.getSelectValue(item, "unit_of_measure_id")}
                                                            onChange={(e: any) => { this.setFieldValueNutrient(index, "unitOfMeasureNutrient", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "unit_of_measure_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Unit</span>
                                                            </MenuItem>
                                                            {this.state.UnitofMeasureData?.length ?
                                                                this.state.UnitofMeasureData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'unit_of_measure_id')}

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Fertilizer Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="fertilizerCostTestid"
                                                            name="fertilizerCost"
                                                            value={this.getInputValue(item, "fertilizer_cost")}
                                                            onChange={(e: any) => { this.setFieldValueNutrient(index, "fertilizerCost", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "fertilizer_cost")}
                                                            helperText={this.getErrorText(item, "fertilizer_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            color="secondary"
                                                            variant="outlined"
                                                            data-testid="laborCostNutrientTestid"
                                                            name="laborCostNutrient"
                                                            value={this.getInputValue(item, "labor_cost")}
                                                            onChange={(e: any) => { this.setFieldValueNutrient(index, "laborCostNutrient", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "labor_cost")}
                                                            helperText={this.getErrorText(item, "labor_cost")}
                                                            placeholder="Total Cost"
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonNutrientTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonNutrient"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValueNutrient(index, "cropSeasonNutrient", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    {(this.state.nutrientManagement.length > 1 && this.showRemoveBtn("nutrientManagement")) &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid={`removeBtnNutrientTestid${index}`}
                                                                onClick={(event: any) => {
                                                                    this.handleRemoveSubSection("nutrientManagement", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.nutrientManagement.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnNutrientManagementTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("nutrientManagement", this.initialNutrientManagementValues, this.state.nutrientManagement);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>)
                })}
            </Box>
        )
    }
    pestManagementPhase = () => {
        return (
            <Box className='pestManagementWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={pestManagementImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Pest management</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="pestManagementDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("pestManagement")}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.pestManagement.map((item, index) => {
                    return (
                        <>
                            {(this.state.pestManagement.length > 1 && index > 0 && !item?._destroy && this.showHRline("pestManagement")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="pestManagementFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={this.getPestGridLG(item)} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Pest Management
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="pestManagementActivityTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="pestManagementActivity"
                                                            value={this.getSelectValue(item, "pest_managment_id")}
                                                            onChange={(e: any) => { this.setFieldValuePestManagement(index, "pestManagementActivity", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "pest_managment_id")}
                                                            displayEmpty
                                                            disabled={this.isItemHasId(item)}
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Pest Management</span>
                                                            </MenuItem>
                                                            {this.state.PestManagementData?.map((ech: any) => (
                                                                <MenuItem
                                                                    key={ech.id}
                                                                    value={ech.id}
                                                                >
                                                                    {ech.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'pest_managment_id')}
                                                    </FormControl>
                                                </Grid>
                                                {/* BIO-AGENT */}
                                                {this.showBioAgentForm(item) &&
                                                    <>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Name of Bio-Agent Release
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="bioAgentReleaseTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="bioAgentRelease"
                                                                    value={this.getSelectValue(item, "bio_agent_release_id")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "bioAgentRelease", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "bio_agent_release_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Type of Bio-agent Release</span>
                                                                    </MenuItem>
                                                                    {this.state.BioAgentReleaseData?.map((ech: any) => (
                                                                        <MenuItem
                                                                            key={ech.id}
                                                                            value={ech.id}
                                                                        >
                                                                            {ech.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'bio_agent_release_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Date of Release
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDatePicker
                                                                        className="phaseDateInpuCustom"
                                                                        name="dateOfRelease"
                                                                        value={this.getDateValue(item, "date_of_release")}
                                                                        onChange={(date) => { this.setFieldValuePestManagement(index, "dateOfRelease", date) }}
                                                                        error={this.getErrorBoolean(item, "date_of_release")}
                                                                        color="secondary"
                                                                        format="DD/MM/yyyy"
                                                                        inputVariant="outlined"
                                                                        placeholder="DD/MM/YYYY"
                                                                        data-testid="dateOfReleaseTestid"
                                                                        maxDate={new Date()}
                                                                        keyboardIcon={this.dateIcon()}
                                                                        minDate={minDateFarmDiaryPhase}
                                                                        minDateMessage={minDateFarmDiaryPhaseMessage}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                    </>}
                                                {/* FOLIAR */}
                                                {this.showFoliarSprayForm(item) &&
                                                    <>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Pesticide Name
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="pesticideNameTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="pesticideName"
                                                                    value={this.getSelectValue(item, "pesticide_id")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "pesticideName", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "pesticide_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Pesticide</span>
                                                                    </MenuItem>
                                                                    {this.state.PesticideNameData?.length ?
                                                                        this.state.PesticideNameData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'pesticide_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Date of Application
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDatePicker
                                                                        className="phaseDateInpuCustom"
                                                                        data-testid="dateOfApplicationPestTestid"
                                                                        placeholder="DD/MM/YYYY"
                                                                        name="dateOfApplicationPest"
                                                                        value={this.getDateValue(item, "date_of_application")}
                                                                        onChange={(date) => { this.setFieldValuePestManagement(index, "dateOfApplicationPest", date) }}
                                                                        error={this.getErrorBoolean(item, "date_of_application")}
                                                                        format="DD/MM/yyyy"
                                                                        inputVariant="outlined"
                                                                        color="secondary"
                                                                        maxDate={new Date()}
                                                                        keyboardIcon={this.dateIcon()}
                                                                        minDate={minDateFarmDiaryPhase}
                                                                        minDateMessage={minDateFarmDiaryPhaseMessage}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Machine cost for Spraying
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    data-testid="machineCostSprayingTestid"
                                                                    name="machineCostSpraying"
                                                                    value={this.getInputValue(item, "machine_cost_of_spraying")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "machineCostSpraying", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "machine_cost_of_spraying")}
                                                                    helperText={this.getErrorText(item, "machine_cost_of_spraying")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>}
                                                {/* TRAP-INSTALLATION */}
                                                {this.showTrapInstallationForm(item) &&
                                                    <>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Type of Trap
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    name="typeOfTrap"
                                                                    value={this.getSelectValue(item, "type_of_trap_id")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "typeOfTrap", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "type_of_trap_id")}
                                                                    data-testid="typeOfTrapTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Type of Trap</span>
                                                                    </MenuItem>
                                                                    {this.state.TypeOfTrapsData?.length ?
                                                                        this.state.TypeOfTrapsData.map((ech: any) => (
                                                                            <MenuItem key={ech.id} value={ech.id}>
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'type_of_trap_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Date of Installation
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDatePicker
                                                                        className="phaseDateInpuCustom"
                                                                        data-testid="dateOfInstallationTrapTestid"
                                                                        placeholder="DD/MM/YYYY"
                                                                        name="dateOfInstallationTrap"
                                                                        value={this.getDateValue(item, "date_of_installation")}
                                                                        onChange={(date) => { this.setFieldValuePestManagement(index, "dateOfInstallationTrap", date) }}
                                                                        error={this.getErrorBoolean(item, "date_of_installation")}
                                                                        format="DD/MM/yyyy"
                                                                        color="secondary"
                                                                        inputVariant="outlined"
                                                                        maxDate={new Date()}
                                                                        keyboardIcon={this.dateIcon()}
                                                                        minDate={minDateFarmDiaryPhase}
                                                                        minDateMessage={minDateFarmDiaryPhaseMessage}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                            {/* BIO-AGENT */}
                                            {this.showBioAgentForm(item) &&
                                                <>
                                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Quantity
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    type="number"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    placeholder="Enter Quantity"
                                                                    data-testid="qtyBioAgentTestid"
                                                                    name="qtyBioAgent"
                                                                    value={this.getInputValue(item, "quantity")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "qtyBioAgent", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "quantity")}
                                                                    helperText={this.getErrorText(item, "quantity")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputPropmin}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Unit of Measure
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="unitOfMeasureBioAgentTestid"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    name="unitOfMeasureBioAgent"
                                                                    value={this.getSelectValue(item, "unit_of_measure_id")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "unitOfMeasureBioAgent", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "unit_of_measure_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Unit</span>
                                                                    </MenuItem>
                                                                    {this.state.UnitofMeasureData?.length ?
                                                                        this.state.UnitofMeasureData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'unit_of_measure_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Cost of Input
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    color="secondary"
                                                                    data-testid="costOfInputTestid"
                                                                    name="costOfInput"
                                                                    value={this.getInputValue(item, "cost_of_input")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "costOfInput", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "cost_of_input")}
                                                                    helperText={this.getErrorText(item, "cost_of_input")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Labor Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    color="secondary"
                                                                    data-testid="laborCostBioAgentTestid"
                                                                    name="laborCostBioAgent"
                                                                    value={this.getInputValue(item, "labor_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "laborCostBioAgent", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {/* FOLIAR */}
                                            {this.showFoliarSprayForm(item) &&
                                                <>
                                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Quantity of Pesticide
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    type="number"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    placeholder="Enter Quantity"
                                                                    data-testid="qtyOfPesticideTestid"
                                                                    name="qtyOfPesticide"
                                                                    value={this.getInputValue(item, "quantity_of_pesticide")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "qtyOfPesticide", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "quantity_of_pesticide")}
                                                                    helperText={this.getErrorText(item, "quantity_of_pesticide")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputPropmin}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Unit of Measure
                                                            </FormLabel>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    className="selectDDCustom"
                                                                    data-testid="unitOfMeasureFoliarTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    name="unitOfMeasureFoliar"
                                                                    value={this.getSelectValue(item, "unit_of_measure_id")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "unitOfMeasureFoliar", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "unit_of_measure_id")}
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                >
                                                                    <MenuItem value={""} disabled>
                                                                        <span className="opcity-48">Select Unit</span>
                                                                    </MenuItem>
                                                                    {this.state.UnitofMeasureData?.length ?
                                                                        this.state.UnitofMeasureData.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        )) : null}
                                                                </Select>
                                                                {this.getCustomErrorMessage(item, 'unit_of_measure_id')}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Pesticide Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    data-testid="pesticideCostTestid"
                                                                    name="pesticideCost"
                                                                    value={this.getInputValue(item, "pesticide_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "pesticideCost", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "pesticide_cost")}
                                                                    helperText={this.getErrorText(item, "pesticide_cost")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={3} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Labor Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    data-testid="laborCostFoliarTestid"
                                                                    name="laborCostFoliar"
                                                                    value={this.getInputValue(item, "labor_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "laborCostFoliar", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {/* TRAP */}
                                            {this.showTrapInstallationForm(item) &&
                                                <>
                                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Number of Trap
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Enter Number"
                                                                    color="secondary"
                                                                    data-testid="numberOfTrapTestid"
                                                                    name="numberOfTrap"
                                                                    value={this.getInputValue(item, "number_of_trap")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "numberOfTrap", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "number_of_trap")}
                                                                    helperText={this.getErrorText(item, "number_of_trap")}
                                                                    onKeyDown={this.keyDown}
                                                                    InputProps={inputPropmin}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Cost of Trap
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    color="secondary"
                                                                    data-testid="costOfTrapTestid"
                                                                    name="costOfTrap"
                                                                    value={this.getInputValue(item, "cost_of_trap")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "costOfTrap", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "cost_of_trap")}
                                                                    helperText={this.getErrorText(item, "cost_of_trap")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} lg={4} className="text-field-container">
                                                            <FormLabel className="phasesLabelCustom">
                                                                Labor Cost
                                                            </FormLabel>
                                                            <FormControl>
                                                                <TextField
                                                                    className="inputIconTextFieldCustom"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    placeholder="Total Cost"
                                                                    color="secondary"
                                                                    data-testid="laborCostTrapTestid"
                                                                    name="laborCostTrap"
                                                                    value={this.getInputValue(item, "labor_cost")}
                                                                    onChange={(e: any) => { this.setFieldValuePestManagement(index, "laborCostTrap", e.target.value) }}
                                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                                    InputProps={inputprop}
                                                                    onKeyDown={this.keyDown}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                <Grid item xs={12} lg={6} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonPestTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonPest"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValuePestManagement(index, "cropSeasonPest", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    {(this.checkRemove("pestManagement")) &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid="deletBtnPestTestid"
                                                                onClick={(event: any) => {
                                                                    this.handleRemoveSubSection("pestManagement", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.pestManagement.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnPestTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("pestManagement", this.initialPestManagementValues, this.state.pestManagement);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>}
                            </Box>
                        </>
                    )
                })}
            </Box>
        )
    }
    irrigationFieldAdded = (item: any, index: number) => {
        return (
            <>
                {!item?._destroy && (
                    <>
                        <Box>
                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                <Grid item xs={12} lg={4} className="text-field-container">
                                    <FormLabel className="phasesLabelCustom">
                                        Date of Application
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                className="phaseDateInpuCustom"
                                                data-testid="dateOfIrrigationTestid"
                                                placeholder="DD/MM/YYYY"
                                                name="dateOfIrrigation"
                                                value={this.getDateValue(item, "date_of_irrigation")}
                                                onChange={(date) => { this.setFieldValueIrrigation(index, "dateOfIrrigation", date) }}
                                                error={this.getErrorBoolean(item, "date_of_irrigation")}
                                                format="DD/MM/yyyy"
                                                inputVariant="outlined"
                                                color="secondary"
                                                maxDate={new Date()}
                                                keyboardIcon={this.dateIcon()}
                                                minDate={minDateFarmDiaryPhase}
                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4} className="text-field-container">
                                    <FormLabel className="phasesLabelCustom">
                                        Crop Season
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            className="selectDDCustom"
                                            data-testid="cropSeasonIrrigationTestid"
                                            variant="outlined"
                                            color="secondary"
                                            name="cropSeasonIrrigation"
                                            value={this.getSelectValue(item, "crop_season_id")}
                                            onChange={(e: any) => { this.setFieldValueIrrigation(index, "cropSeasonIrrigation", e.target.value) }}
                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value={""} disabled>
                                                <span className="opcity-48">Select Season</span>
                                            </MenuItem>
                                            {this.state.CropSeasonData?.length ?
                                                this.state.CropSeasonData.map((ech: any) => (
                                                    <MenuItem
                                                        key={ech.id}
                                                        value={ech.id}
                                                    >
                                                        {ech.name}
                                                    </MenuItem>
                                                )) : null}
                                        </Select>
                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4} className="text-field-container">
                                    <FormLabel className="phasesLabelCustom">
                                        Irrigation Cost
                                    </FormLabel>
                                    <FormControl>
                                        <TextField
                                            className="inputIconTextFieldCustom"
                                            type="number"
                                            variant="outlined"
                                            placeholder="Total Cost"
                                            color="secondary"
                                            data-testid="irrigationCostTestid"
                                            name="irrigationCost"
                                            value={this.getInputValue(item, "irrigation_cost")}
                                            onChange={(e: any) => { this.setFieldValueIrrigation(index, "irrigationCost", e.target.value) }}
                                            error={this.getErrorBoolean(item, "irrigation_cost")}
                                            helperText={this.getErrorText(item, "irrigation_cost")}
                                            onKeyDown={this.keyDown}
                                            InputProps={inputprop}
                                        />
                                    </FormControl>
                                </Grid>
                                
                            </Grid>
                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                <Grid item xs={12} lg={6} className="text-field-container">
                                    <FormLabel className="phasesLabelCustom">
                                        Source of Irrigation
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            className="selectDDCustom"
                                            data-testid="sourceOfIrrigationTestid"
                                            variant="outlined"
                                            color="secondary"
                                            name="sourceOfIrrigation"
                                            value={this.getSelectValue(item, "source_irrigation_id")}
                                            onChange={(e: any) => { this.setFieldValueIrrigation(index, "sourceOfIrrigation", e.target.value) }}
                                            error={this.getErrorBoolean(item, "source_irrigation_id")}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value={""} disabled>
                                                <span className="opcity-48">Select Source</span>
                                            </MenuItem>
                                            {this.state.SourceofIrrigationData?.length ?
                                                this.state.SourceofIrrigationData.map((ech: any) => (
                                                    <MenuItem
                                                        key={ech.id}
                                                        value={ech.id}
                                                    >
                                                        {ech.label}
                                                    </MenuItem>
                                                )) : null}
                                        </Select>
                                        {this.getCustomErrorMessage(item, 'source_irrigation_id')}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} className="text-field-container">
                                    <FormLabel className="phasesLabelCustom">
                                        Type of Irrigation
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            className="selectDDCustom"
                                            data-testid="typeOfIrrigationTestid"
                                            variant="outlined"
                                            color="secondary"
                                            name="typeOfIrrigation"
                                            value={this.getSelectValue(item, "type_irrigation_id")}
                                            onChange={(e: any) => { this.setFieldValueIrrigation(index, "typeOfIrrigation", e.target.value) }}
                                            error={this.getErrorBoolean(item, "type_irrigation_id")}
                                            displayEmpty
                                            disabled={this.isItemHasId(item)}
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            <MenuItem value={""} disabled>
                                                <span className="opcity-48">Select Type</span>
                                            </MenuItem>
                                            {this.state.TypeofIrrigationData?.length ?
                                                this.state.TypeofIrrigationData.map((ech: any) => (
                                                    <MenuItem
                                                        key={ech.id}
                                                        value={ech.id}
                                                    >
                                                        {ech.label}
                                                    </MenuItem>
                                                )) : null}
                                        </Select>
                                        {this.getCustomErrorMessage(item, 'type_irrigation_id')}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* SPRINKLER */}
                            {this.showSprinklerIrrigationForm(item) &&
                                <>
                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Hours of Irrigation
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Enter Hours"
                                                    color="secondary"
                                                    data-testid="hoursOfIrrigationSprinklerTestid"
                                                    name="hoursOfIrrigationSprinkler"
                                                    value={this.getInputValue(item, "hours_of_irrigation")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "hoursOfIrrigationSprinkler", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "hours_of_irrigation")}
                                                    helperText={this.getErrorText(item, "hours_of_irrigation")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Number of Nozzels
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Enter Number"
                                                    color="secondary"
                                                    data-testid="numberOfNozelsTestid"
                                                    name="numberOfNozels"
                                                    value={this.getInputValue(item, "number_of_nozels")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "numberOfNozels", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "number_of_nozels")}
                                                    helperText={this.getErrorText(item, "number_of_nozels")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Labor Cost
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    className="inputIconTextFieldCustom"
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Total Cost"
                                                    color="secondary"
                                                    data-testid="laborCostSprinklerTestid"
                                                    name="laborCostSprinkler"
                                                    value={this.getInputValue(item, "labor_cost")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "laborCostSprinkler", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputprop}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {/* RENTED */}
                            {this.showRentedIrrigationForm(item) &&
                                <>
                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Hours of Irrigation
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Enter Hours"
                                                    color="secondary"
                                                    data-testid="hoursOfIrrigationRentedTestid"
                                                    name="hoursOfIrrigationRented"
                                                    value={this.getInputValue(item, "hours_of_irrigation")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "hoursOfIrrigationRented", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "hours_of_irrigation")}
                                                    helperText={this.getErrorText(item, "hours_of_irrigation")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Irrigation Cost
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    className="inputIconTextFieldCustom"
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Total Cost"
                                                    color="secondary"
                                                    data-testid="irrigationCostRentedTestid"
                                                    name="irrigationCostRented"
                                                    value={this.getInputValue(item, "irrigation_cost")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "irrigationCostRented", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "irrigation_cost")}
                                                    helperText={this.getErrorText(item, "irrigation_cost")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputprop}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Labor Cost
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    className="inputIconTextFieldCustom"
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Total Cost"
                                                    color="secondary"
                                                    data-testid="laborCostRentedTestid"
                                                    name="laborCostRented"
                                                    value={this.getInputValue(item, "labor_cost")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "laborCostRented", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                    InputProps={inputprop}
                                                    onKeyDown={this.keyDown}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {/* DRIP */}
                            {this.showDripIrrigationForm(item) &&
                                <>
                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                        <Grid item xs={12} lg={3} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Hours of Irrigation
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Enter Hours"
                                                    color="secondary"
                                                    data-testid="hoursOfIrrigationDripTestid"
                                                    name="hoursOfIrrigationDrip"
                                                    value={this.getInputValue(item, "hours_of_irrigation")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "hoursOfIrrigationDrip", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "hours_of_irrigation")}
                                                    helperText={this.getErrorText(item, "hours_of_irrigation")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Spacing of Drippers
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Spacing in Foot"
                                                    color="secondary"
                                                    data-testid="spacingOfDrippersTestid"
                                                    name="spacingOfDrippers"
                                                    value={this.getInputValue(item, "spacing_of_dripper")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "spacingOfDrippers", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "spacing_of_dripper")}
                                                    helperText={this.getErrorText(item, "spacing_of_dripper")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Drip Irrigated Area
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Drip in Acres"
                                                    color="secondary"
                                                    data-testid="dripIrrigatedAreaTestid"
                                                    name="dripIrrigatedArea"
                                                    value={this.getInputValue(item, "drip_irrigated_area")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "dripIrrigatedArea", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "drip_irrigated_area")}
                                                    helperText={this.getErrorText(item, "drip_irrigated_area")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Row to row spacing
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Row to Row Spacing in Foot"
                                                    color="secondary"
                                                    data-testid="rowTorowSpacingTestid"
                                                    name="rowTorowSpacing"
                                                    value={this.getInputValue(item, "row_to_row_spacing")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "rowTorowSpacing", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "row_to_row_spacing")}
                                                    helperText={this.getErrorText(item, "row_to_row_spacing")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>}
                            {/* OTHERS */}
                            {this.showIrrigationFloodForm(item) &&
                                <>
                                    <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                        <Grid item xs={12} lg={6} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Hours of Irrigation
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Enter Hours"
                                                    color="secondary"
                                                    data-testid="hoursOfIrrigationFloodTestid"
                                                    name="hoursOfIrrigationFlood"
                                                    value={this.getInputValue(item, "hours_of_irrigation")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "hoursOfIrrigationFlood", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "hours_of_irrigation")}
                                                    helperText={this.getErrorText(item, "hours_of_irrigation")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputPropmin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={6} className="text-field-container">
                                            <FormLabel className="phasesLabelCustom">
                                                Labor Cost
                                            </FormLabel>
                                            <FormControl>
                                                <TextField
                                                    className="inputIconTextFieldCustom"
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder="Total Cost"
                                                    color="secondary"
                                                    data-testid="laborCostFloodTestid"
                                                    name="laborCostFlood"
                                                    value={this.getInputValue(item, "labor_cost")}
                                                    onChange={(e: any) => { this.setFieldValueIrrigation(index, "laborCostFlood", e.target.value) }}
                                                    error={this.getErrorBoolean(item, "labor_cost")}
                                                    helperText={this.getErrorText(item, "labor_cost")}
                                                    onKeyDown={this.keyDown}
                                                    InputProps={inputprop}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>

                            }
                        </Box>
                    </>
                )}
            </>
        )
    }
    irrigationField = () => {
        return (
            <>
                {this.state.irrigation.map((item, index) => {
                    return (
                        <>
                            {(this.state.irrigation.length > 1 && index > 0 && !item?._destroy && this.showHRline("irrigation")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="irrigationFormWrapper">
                                {this.irrigationFieldAdded(item, index)}
                                <Box className='newAddEntry'>
                                    <Box className='d-flex ai-center'>
                                        {!item?._destroy && (
                                            <>
                                                {(this.checkRemove("irrigation")) &&
                                                    <Box>
                                                        <IconButton
                                                            data-testid="deletBtnIrrigationTestid"
                                                            onClick={(event: any) => {
                                                                this.handleRemoveSubSection("irrigation", index, item?.id)
                                                            }}
                                                        >
                                                            <img src={RemoveCircleOutlineIcon} alt='plusIcon' />
                                                        </IconButton>
                                                    </Box>}
                                            </>
                                        )}
                                        {this.state.irrigation.length === index + 1 &&
                                            <>
                                                <Button
                                                    variant="contained"
                                                    className='addEntryBtn'
                                                    disabled
                                                >
                                                    Add Another Entry
                                                </Button>
                                                <Box>
                                                    <IconButton
                                                        data-testid="addBtnIrrigationTestid"
                                                        onClick={(event: any) => {
                                                            this.handleAddSubSection("irrigation", this.initialIrrigationValues, this.state.irrigation);
                                                        }}
                                                    >
                                                        <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                    </IconButton>
                                                </Box>
                                            </>}
                                    </Box>
                                </Box>
                            </Box>
                        </>)
                })}
            </>
        )
    }
    irrigationPhase = () => {
        return (
            <Box className='irrigationWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={irrigationImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Irrigation</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="irrigationDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("irrigation")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.userFarmDiary?.attributes?.land_type?.label !== "Rainfed" ?
                    this.irrigationField()
                    :

                    <Box className="error-text-irrigation-box">
                        <Typography className="error-text-irrigation-heading">
                            No irrigation data needed
                        </Typography>
                        <Typography className="error-text-irrigation-paragraph">
                            You have selected Rainfed in type of irrigation in land details. If you want to change please change it from profile.
                        </Typography>
                    </Box>

                }
            </Box>
        )
    }
    harvestPhase = () => {
        return (
            <Box className='harvestWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={harvestImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Harvest</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="harvestDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("harvest")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.harvest.map((item: any, index: any) => {
                    return (
                        <>
                            {(this.state.harvest?.length > 1 && index > 0 && !item?._destroy && this.showHRline("harvest")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="harvestFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date of Picking
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="dateOfPickingTestid"
                                                                color="secondary"
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                placeholder="DD/MM/YYYY"
                                                                name="dateOfPicking"
                                                                value={this.getDateValue(item, "date_of_picking")}
                                                                onChange={(date) => {
                                                                    this.setFieldValueHarvest(index, "dateOfPicking", date)
                                                                }}
                                                                error={this.getErrorBoolean(item, "date_of_picking")}
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Quantity Picked
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Quantity in KG"
                                                            color="secondary"
                                                            data-testid="quantityPickedTestid"
                                                            name="quantityPicked"
                                                            value={this.getInputValue(item, "quantity_picked")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueHarvest(index, "quantityPicked", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "quantity_picked")}
                                                            helperText={this.getErrorText(item, "quantity_picked")}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Total Picking Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Picking Charges"
                                                            color="secondary"
                                                            data-testid="TotalPickingCostTestid"
                                                            name="TotalPickingCost"
                                                            value={this.getInputValue(item, "total_picking_cost")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueHarvest(index, "TotalPickingCost", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "total_picking_cost")}
                                                            helperText={this.getErrorText(item, "total_picking_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Storage type
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="storageTypeTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="storageType"
                                                            value={this.getSelectValue(item, "storage_type_id")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueHarvest(index, "storageType", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "storage_type_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Storage Type</span>
                                                            </MenuItem>
                                                            {this.state.storageTypeData?.length ?
                                                                this.state.storageTypeData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'storage_type_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonHarvestTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonHarvest"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValueHarvest(index, "cropSeasonHarvest", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    {(this.state.harvest.length > 1 && this.showRemoveBtn("harvest")) &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid={`deletBtnHarvestTestid${index}`}
                                                                onClick={(event: any) => {
                                                                    this.handleRemoveSubSection("harvest", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.harvest.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnHarvestTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("harvest", this.initialHarvestValues, this.state.harvest);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>)
                })}
            </Box>
        )
    }
    sellPhase = () => {
        return (
            <Box className='sellWrapper'>
                <Box className='phaseTitleWrapper pd-8'>
                    <Box className='d-flex ai-center'>
                        <Box className='phaseIconWrapper'>
                            <img src={sellImg} />
                        </Box>
                        <Box>
                            <Typography className='phaseTitlefont'>Sell</Typography>
                        </Box>
                    </Box>
                    <Box className='deletePhaseBtnWrapper'>
                        <Box className='d-flex ai-center'>
                            <IconButton
                                data-testid="sellDltTestid"
                                onClick={(event: any) => this.toggleDeletePopup("sell")}
                                disabled={false}
                            >
                                <DeleteIcon style={{ color: "#B3261E" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {this.state.sell.map((item, index) => {
                    return (
                        <>
                            {(this.state.sell.length > 1 && index > 0 && !item?._destroy && this.showHRline("sell")) &&
                                <Box className="ps-16 mt-16 mb-16">
                                    <Divider className="small-divider" />
                                </Box>}
                            <Box className="sellFormWrapper">
                                {!item?._destroy && (
                                    <>
                                        <Box>
                                            <Grid container wrap="nowrap" className="text-field-main-container linePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Date of Sell
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                className="phaseDateInpuCustom"
                                                                data-testid="dateOfSellTestid"
                                                                placeholder="DD/MM/YYYY"
                                                                name="dateOfSell"
                                                                value={this.getDateValue(item, "date_of_sell")}
                                                                onChange={(date) => {
                                                                    this.setFieldValueSell(index, "dateOfSell", date)
                                                                }}
                                                                error={this.getErrorBoolean(item, "date_of_sell")}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                maxDate={new Date()}
                                                                keyboardIcon={this.dateIcon()}
                                                                minDate={minDateFarmDiaryPhase}
                                                                minDateMessage={minDateFarmDiaryPhaseMessage}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Quantity of Cotton Sold
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Quantity in KG"
                                                            color="secondary"
                                                            data-testid="qtyOfCottonSoldTestid"
                                                            name="qtyOfCottonSold"
                                                            value={this.getInputValue(item, "quantity_of_cotton_sold")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueSell(index, "qtyOfCottonSold", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "quantity_of_cotton_sold")}
                                                            helperText={this.getErrorText(item, "quantity_of_cotton_sold")}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Cotton Price
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Price/kg"
                                                            color="secondary"
                                                            data-testid="cottonPriceTestid"
                                                            name="cottonPrice"
                                                            value={this.getInputValue(item, "cotton_price")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueSell(index, "cottonPrice", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "cotton_price")}
                                                            helperText={this.getErrorText(item, "cotton_price")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Total Amount
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Amount"
                                                            color="secondary"
                                                            data-testid="totalAmountTestid"
                                                            disabled
                                                            name="totalAmount"
                                                            value={this.getInputValue(item, "total_amount")}
                                                            onChange={(e: any) => {
                                                                this.setFieldValueSell(index, "totalAmount", e.target.value)
                                                            }}
                                                            error={this.getErrorBoolean(item, "total_amount")}
                                                            helperText={this.getErrorText(item, "total_amount")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin linePadding">
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Buyer Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="text"
                                                            variant="outlined"
                                                            placeholder="Enter Name"
                                                            color="secondary"
                                                            data-testid="buyerNameTestid"
                                                            name="buyerName"
                                                            value={this.getInputValue(item, "buyer_name")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "buyerName", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "buyer_name")}
                                                            helperText={this.getErrorText(item, "buyer_name")}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Transportation Cost
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            data-testid="transportationCostTestid"
                                                            name="transportationCost"
                                                            value={this.getInputValue(item, "transportation_cost")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "transportationCost", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "transportation_cost")}
                                                            helperText={this.getErrorText(item, "transportation_cost")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Vehicle Type
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="vehicleTypeTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="vehicleType"
                                                            value={this.getSelectValue(item, "vehical_type_id")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "vehicleType", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "vehical_type_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Vehicle</span>
                                                            </MenuItem>
                                                            {this.state.vehicleTypeData?.length ?
                                                                this.state.vehicleTypeData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'vehical_type_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Labor cost of load & unload
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            className="inputIconTextFieldCustom"
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Total Cost"
                                                            color="secondary"
                                                            name="laborCostOfLoad"
                                                            data-testid="laborCostOfLoadTestid"
                                                            value={this.getInputValue(item, "labor_cost_of_load_and_unload")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "laborCostOfLoad", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "labor_cost_of_load_and_unload")}
                                                            helperText={this.getErrorText(item, "labor_cost_of_load_and_unload")}
                                                            InputProps={inputprop}
                                                            onKeyDown={this.keyDown}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container wrap="nowrap" className="text-field-main-container lineMargin removeLinePadding">
                                                <Grid item xs={12} lg={6} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Distance from Farmer location to Buyer
                                                    </FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            type="number"
                                                            variant="outlined"
                                                            placeholder="Distance in km"
                                                            color="secondary"
                                                            data-testid="distanceFarmerToBuyerTestid"
                                                            name="distanceFarmerToBuyer"
                                                            value={this.getInputValue(item, "distance_from_farmer_location_to_buyer")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "distanceFarmerToBuyer", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "distance_from_farmer_location_to_buyer")}
                                                            helperText={this.getErrorText(item, "distance_from_farmer_location_to_buyer")}
                                                            onKeyDown={this.keyDown}
                                                            InputProps={inputPropmin}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3} className="text-field-container">
                                                    <FormLabel className="phasesLabelCustom">
                                                        Crop Season
                                                    </FormLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="selectDDCustom"
                                                            data-testid="cropSeasonSellTestid"
                                                            variant="outlined"
                                                            color="secondary"
                                                            name="cropSeasonSell"
                                                            value={this.getSelectValue(item, "crop_season_id")}
                                                            onChange={(e: any) => { this.setFieldValueSell(index, "cropSeasonSell", e.target.value) }}
                                                            error={this.getErrorBoolean(item, "crop_season_id")}
                                                            displayEmpty
                                                            inputProps={{ "aria-label": "Without label" }}
                                                        >
                                                            <MenuItem value={""} disabled>
                                                                <span className="opcity-48">Select Season</span>
                                                            </MenuItem>
                                                            {this.state.CropSeasonData?.length ?
                                                                this.state.CropSeasonData.map((ech: any) => (
                                                                    <MenuItem
                                                                        key={ech.id}
                                                                        value={ech.id}
                                                                    >
                                                                        {ech.name}
                                                                    </MenuItem>
                                                                )) : null}
                                                        </Select>
                                                        {this.getCustomErrorMessage(item, 'crop_season_id')}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    {(this.state.sell.length > 1 && this.showRemoveBtn("sell")) &&
                                                        <Box className="removeEntryBtnWrapper">
                                                            <IconButton
                                                                data-testid={`removeBtnSellTestid${index}`}
                                                                onClick={() => {
                                                                    this.handleRemoveSubSection("sell", index, item?.id)
                                                                }}
                                                            >
                                                                <img src={RemoveCircleOutlineIcon} alt='removeIcon' />
                                                            </IconButton>
                                                        </Box>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                                {this.state.sell.length === index + 1 &&
                                    <Box className='newAddEntry'>
                                        <Box className='d-flex ai-center'>
                                            <Button
                                                variant="contained"
                                                className='addEntryBtn'
                                                disabled
                                            >
                                                Add Another Entry
                                            </Button>
                                            <Box>
                                                <IconButton
                                                    data-testid="addBtnSellTestid"
                                                    onClick={(event: any) => {
                                                        this.handleAddSubSection("sell", this.initialSellValues, this.state.sell);
                                                    }}
                                                >
                                                    <img src={AddCircleOutlineOutlinedIcon} alt='plusIcon' />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>}
                            </Box>
                        </>)
                })}
            </Box>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation, id } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <NavigationMenu navigation={navigation} id={id}>
                <ThemeProvider theme={theme}>
                    <Box className='mainContainerFarmDiaryWrapper'>
                        {/* Left Div */}
                        <Box className='userListFarmDiaryMain'>
                            <CustomModal
                                open={!!this.state.deletePopup}
                                onClose={() => this.toggleDeletePopup(null)}
                                confirmHandler={() => this.state.deletePopup && this.handleDelete(this.state.deletePopup)}
                                confirmTitle="Delete"
                                title="Delete Entry"
                                description="Are you sure? You want to delete entry."
                            />

                            <CustomModal
                                open={!!this.state.errorPopup}
                                onClose={() => this.toggleErrorPopup(null)}
                                title={this.state.popupTitle}
                                description={this.state.errorPopup}
                            />

                            <Box>
                                <Typography className='headingTitle'>
                                    Users
                                </Typography>
                            </Box>
                            <Box className='breadcrumbMain'>
                                <Breadcrumbs
                                    separator={
                                        <img src={linkSeparatorSVG} alt={"separator"} />
                                    }
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        className="menuLinkUser"
                                        href="/users"
                                    >
                                        Users
                                    </Link>
                                    <Typography className="menuTextUser">
                                        Farm Diary Entries
                                    </Typography>
                                </Breadcrumbs>
                            </Box>
                            <Box className='userListWrapper mt-8'>
                                <Box className='userListTitleWrapper'>
                                    <Typography className='userListTitle'>
                                        List of Farmers
                                    </Typography>
                                </Box>
                                <Box className='searchInputWrapper'>
                                    <Box style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <Box
                                            style={{
                                                background: "#FCFAF7",
                                                borderRadius: "28px",
                                                border: "2px solid #F0EBE0",
                                                width: "100%",
                                                display: 'flex',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                padding: "4px 0px"
                                            }}
                                        >
                                            <Input
                                                id="searchTableId"
                                                autoComplete="off"
                                                disableUnderline
                                                fullWidth
                                                data-testid="serchFarmerInputTestid"
                                                placeholder="Search farmer...."
                                                type="search"
                                                name="searchField"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            style={{ color: "#1C2324", opacity: 0.48, marginLeft: "19px" }}
                                                        />
                                                    </InputAdornment>
                                                }
                                                value={this.state.searchFarmerName}
                                                onChange={this.handleChangeSearch}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton
                                                className="filter-icon"
                                                data-testid="farmerListFilterIconBtnTestid"
                                                disabled={this.state.farmerDataLoading}
                                                onClick={(event: any) => {
                                                    this.setState({
                                                        isFilterOpen: true,
                                                        anchorEl: event.currentTarget,
                                                    });
                                                }}
                                            >
                                                <Badge variant="dot" color="primary" className="filterApplied" invisible={this.state.hideBadgeFL} >
                                                    <img src={imgIconFilter} />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className='listWrapper' id="scrollableDiv">
                                    <List component="nav" aria-label="main mailbox folders">
                                        {
                                            this.isfarmerLoading() ? (
                                                <>
                                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CircularProgress color="secondary" />
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                        <InfiniteScroll
                                                            dataLength={this.state.farmerData?.length}                                                            
                                                            next={this.fetchNextFarmers}
                                                            scrollableTarget="scrollableDiv"
                                                            hasMore={true}
                                                            loader={<></>}
                                                        >
                                                    {this.isfarmerData() ? (
                                                        this.state.farmerData.map((each: any, index: number) => (
                                                            <>
                                                                {
                                                                    ((each?.attributes?.first_name || each?.attributes?.last_name) && each?.attributes?.phone_number && each?.attributes?.land_detail_id[0]) &&
                                                                    <ListItem
                                                                        key={index + 3}
                                                                        data-testid={`userListItem${index}`}
                                                                        className='userlistIteam'
                                                                        button
                                                                        selected={this.state.userFarmDiary?.id === each?.id ? true : false}
                                                                        onClick={() => this.handleListItemClick(each)}
                                                                    >
                                                                        <ListItemText className='userListFont' primary={each?.attributes?.first_name + " " + each?.attributes?.last_name} />
                                                                    </ListItem>
                                                                }

                                                            </>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <Typography className="ps-16">- No Farmers Found</Typography>
                                                        </>
                                                    )}
                                                    </InfiniteScroll>
                                                </>
                                            )
                                        }
                                    </List>
                                </Box>
                            </Box>
                        </Box>

                        {/* Right Div */}
                        <Box className="diaryWrapper">
                            {this.state.farmDiaryLoading ?
                                (<>
                                    <Box className="farmDiaryloader">
                                        <Box className='farmDiaryloaderWrapper'>
                                            <CircularProgress color="secondary" />
                                        </Box>
                                    </Box>
                                </>) : (
                                    <>
                                        {this.state.userFarmDiary ? (<>
                                            <Box className='userFarmDiaryDetailMain'>
                                                <form onSubmit={(event: any) => { this.submitFarmDiaryForm(event) }} autoComplete="off">
                                                    {/* Title */}
                                                    <Box className='usernameTitleWrapper'>
                                                        <Box className='avtarTitleWrapper'>
                                                            <Box className='avtarWrapper'>
                                                                <Avatar className='userAvtarCustom'>
                                                                    {this.userBadge()}
                                                                </Avatar>
                                                            </Box>
                                                            <Box className='usernameFontWrapper'>
                                                                <Tooltip title={this.userName()}>
                                                                    <Typography noWrap className='userNameTitleFont wrapTextWidth'>{this.userName()}</Typography>
                                                                </Tooltip>
                                                                <Tooltip title={this.userFEname()}>
                                                                    <Typography noWrap className='wrapTextWidth'><span className='userNameSubTitleFont b-400'>Field Executive: </span><span className='userNameSubTitleFont b-600'>
                                                                        {this.userFEname()}
                                                                    </span></Typography>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                        <Box className='farmDiaryUpdateBtnWrapper'>
                                                            <Button
                                                                variant="contained"
                                                                className='orangeUpdateBtn'
                                                                type="submit"
                                                                disabled={this.state.updateBtnLoading}
                                                            >
                                                                Update {this.state.updateBtnLoading && <CircularProgress style={{ color: "#FFF", width: "18px", height: "18px", marginLeft: "10px" }} />}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Box className='farmDiaryPhasesWrapper'>
                                                        {this.preSowingPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.sowingPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.gapFillingPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.weedManagementPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.nutrientManagementPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.pestManagementPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.irrigationPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.harvestPhase()}
                                                        <Divider className="full-divider" />
                                                        {this.sellPhase()}
                                                    </Box>
                                                </form>
                                            </Box>
                                        </>)
                                            : (
                                                <>
                                                    <Box className="farmDiaryloader">
                                                        <Box className='farmDiaryloaderWrapper'>
                                                            <Typography className="farmDiaryloaderWrapperText">
                                                                No Farm Diary Entries
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                    </>
                                )
                            }
                            <UserTableFilter
                                data-testid={'userTableFilterFarmDiary'}
                                openTableFilter={this.state.isFilterOpen}
                                anchorEl={this.state.anchorEl}
                                HideFEFilter={false}
                                handleCloseFilter={this.handleCloseTableFilterPopupFL}
                                handleVillageFilterClear={this.handleVillageFilterClearFL}
                                handleSubmitFilter={this.handleSubmitFilterFL}
                                expanded={this.state.expandedFL}
                                handleChangePanelState={this.handleChangePanelFL("panel1")}
                                handleChangePanelDistrict={this.handleChangePanelFL("panel2")}
                                handleChangePanelTaluka={this.handleChangePanelFL("panel3")}
                                handleChangePanelVillage={this.handleChangePanelFL("panel4")}
                                handleChangeAtoZ={this.handleChangeAtoZFL}
                                handleChangeZtoA={this.handleChangeZtoAFL}
                                isDisabled={this.state.isDisabledFL}
                                sortAscending={this.state.sortAscendingFL}
                                sortDescending={this.state.sortDescendingFL}
                                filteredStates={this.state.filteredStatesFL}
                                filteredDistricts={this.state.filteredDistrictsFL}
                                filteredTalukas={this.state.filteredTalukasFL}
                                filteredVillages={this.state.filteredVillagesFL}
                                selectedState={this.state.selectedStateFL}
                                selectedDistrict={this.state.selectedDistrictFL}
                                selectedTaluka={this.state.selectedTalukaFL}
                                selectedVillage={this.state.selectedVillageFL}
                                stateInputValue={this.state.stateInputValueFL}
                                districtInputValue={this.state.districtInputValueFL}
                                talukaInputValue={this.state.talukaInputValueFL}
                                villageInputValue={this.state.villageInputValueFL}
                                handleChangeStateField={this.handleChangeStateFieldFL}
                                handleChangeDistrictField={this.handleChangeDistrictFieldFL}
                                handleChangeTalukaField={this.handleChangeTalukaFieldFL}
                                handleChangeVillageField={this.handleChangeVillageFieldFL}
                                handleStateSearch={this.handleStateSearchFL}
                                handleDistrictSearch={this.handleDistrictSearchFL}
                                handleTalukaSearch={this.handleTalukaSearchFL}
                                handleVillageSearch={this.handleVillageSearchFL}
                                opacityStateCalculator={this.opacityStateCalculatorFL}
                                opacityDistrictCalculator={this.opacityDistrictCalculatorFL}
                                opacityTalukaCalculator={this.opacityTalukaCalculatorFL}
                                opacityVillageCalculator={this.opacityVillageCalculatorFL}
                                handleChangePanelFE={this.handleChangePanelFL("panel5")}
                                filteredFieldExecutive={this.state.filteredFieldExecutiveFL}
                                selectedFieldExecutive={this.state.selectedFieldExecutiveFL}
                                fieldExecutiveInputValue={this.state.fieldExecutiveInputValueFL}
                                handleChangeFieldExecutiveField={this.handleChangeFieldExecutiveFieldFL}
                                handleFieldExectiveSearch={this.handleFieldExectiveSearchFL}
                                opacityFieldExecutiveCalculator={this.opacityFieldExecutiveCalculatorFL}
                                filterHasError={this.state.filterHasErrorFL}
                            />
                        </Box>
                    </Box>
                    {/* CROP-MAPPING MODAL STARTS */}
                    <Dialog
                        open={this.state.OpenFarmMapping}
                        aria-labelledby="farm-mapping-modal"
                        PaperProps={{
                            style: { borderRadius: 12, width: "712px" },
                        }}
                        maxWidth={"md"}
                    >
                        <DialogTitle>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <IconButton onClick={() => {
                                    this.setState({ OpenFarmMapping: false, })
                                }}                                    
                                    style={{ padding: 0, marginRight: "16px" }}
                                    data-testid="backFarmMappingbtn"
                                >
                                    <img src={BackArrowIconSVG} />
                                </IconButton>
                                <Typography variant="subtitle1">
                                    Crop Mapping
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent style={{ padding: 0 }}>
                            <FarmMapping
                                OpenFarmMapping={this.state.OpenFarmMapping}
                                closeFarmMapping={this.closeFarmMappingModal}
                                onSave={this.onSaveFarmMapping}
                                userLocation={this.state.userLocation}
                                isEditModal={false}
                                CoordinatesArr={this.coordinateModal()}
                                markerDefault={this.markerDefaultModal()}
                            />
                        </DialogContent>
                    </Dialog>
                    {/* CROP-MAPPING MODAL ENDS */}
                </ThemeProvider>
            </NavigationMenu>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styleWeb = {
    // Customizable Area Start
    badgeCardRoot: {
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        minHeight: "150",
        padding: "0",
    } as React.CSSProperties,
    // Customizable Area End
}

// Customizable Area End
