import moment from 'moment'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from '../../../framework/src/Utilities';


export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    error: string;
    loading: boolean;
    // Customizable Area Start
    token: string | null;
    profile_avatar: any;
    profile_user: any;
    profile_email: string;
    profile_phone: string;
    profile_dob: string,
    profile_id: string | number;
    profile_firstname: string;
    profile_lastname: string;
    profile_location: string;
    profile_role: string;
    role: string;
    openLogoutPopup: boolean;
    deletePopup: boolean;
    userModal: boolean;
    editIntValue: any;
    popupTitle: "Error" | "Successful";
    profile_avatar_image: any;
    cropImageModal: boolean;
    croppedImage: any;
    isPasswordFilled: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}

export type TUser = {
    "id": number,
    "attributes": {
        "email": string,
        "created_at": Date,
        "updated_at": Date,
        "role": string,
        "first_name": string,
        "last_name": string,
        "phone_number": string,
        "full_phone_number": string,
        "dob": string,
        "user_name": string,
        "google_unique_auth_id": string | null,
        "google_type": boolean,
        "country_code": string,
        "avatar": string
    }
}

export default class ProfileScreenController extends BlockComponent<Props, S, SS> {

    static instance: ProfileScreenController;
    adminProfileCallId: string = '';
    fieldExecCallId: string = '';
    deleteUserCallId: string = "";
    editUserCallId: string = "";
    avatarUserCallId: string = "";
    removeNotification : string  = "";

    tokenize: ({ token, otp_token }: { otp_token: string | null; token?: string; }) => null = ({ token, otp_token }: { otp_token: string | null; token?: string; }) => {
        this.setState({ token: token ? token : otp_token, loading: true }, () => {
            this.getAdmin();
        });
        return null;
    }

    constructor(props: Props) {
        super(props);
        ProfileScreenController.instance = this;

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            profile_avatar: '',
            profile_user: '',
            profile_email: '',
            profile_phone: '',
            profile_dob: '',
            profile_id: '',
            profile_firstname: '',
            profile_lastname: '',
            profile_location: '',
            profile_role: '',
            role: "",
            token: "",
            loading: false,
            error: "",
            openLogoutPopup: false,
            deletePopup: false,
            userModal: false,
            popupTitle: "Error",
            editIntValue: {
                firstnameAdmin: "",
                lastnameAdmin: "",
                mobileNumberAdmin: "",
                emailAddressAdmin: "",
                usernameAdmin: "",
                dateOfBirthAdmin: null,
                passwordAdmin: null,
                confirmPasswordAdmin: null,
                isPassword: true,
                isConfigPassword: true,
            },
            cropImageModal: false,
            croppedImage: "",
            profile_avatar_image: "",
            isPasswordFilled: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.getAdmin();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const otp_token = window.localStorage.getItem("tokenn");
        this.tokenize({ otp_token });
    }

    getAdmin() {
        // Customizable Area Start
        this.adminProfileCallId = this.apiCall(
            configJSON.validationApiMethodType,
            configJSON.adminGetUrl,
        )

        // Customizable Area End
    }

    updateProfileData(data: TUser): void {
        if ( (this.state.profile_email && data?.attributes?.email !== this.state.profile_email) || (this.state.isPasswordFilled) ) {
            this.handleLogout();
        }

        this.setState(
            (prev) => {
                const date = data.attributes.dob.split("-");
                const event = new Date(`${date[0]}, ${date[1]}, ${date[2]}`).toDateString().split(" ");
                const profile_dob = `${event[2]} ${event[1]} ${event[3]}`;
                const profile_phone: string = `+91-${data.attributes.phone_number}`;

                return {
                    ...prev,
                    profile_avatar: data.attributes.avatar,
                    profile_email: data.attributes.email,
                    profile_user: data.attributes.user_name,
                    profile_id: data.id,
                    role: data.attributes.role,
                    profile_firstname: data.attributes.first_name,
                    profile_lastname: data.attributes.last_name,
                    profile_location: '',
                    profile_phone,
                    profile_dob,
                    profile_role: data.attributes.role
                };
            }
        );
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const successResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (successResponse && !successResponse.errors) {
                switch (apiRequestCallId) {
                    case this.adminProfileCallId: {
                        return this.updateProfileData(successResponse.data);
                    }
                    case this.deleteUserCallId:
                    case this.removeNotification:  {
                        return this.handleLogout();
                    }
                    case this.editUserCallId: {
                        return this.setState({ userModal: false }, () => this.getAdmin())
                    }
                    case this.avatarUserCallId: {
                        this.toggleErrorPopup("Photo is updated", true)
                        return window.location.reload();
                    }
                }
            } else {
                this.errorHandler(errorResponse || successResponse)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    errorHandler = (errorResponse?: {errors: string[] } ) => {
        this.setState({
            error: (errorResponse?.errors && Array.isArray(errorResponse?.errors)) ?  errorResponse?.errors[0] : "Something went wrong",
            loading: false,
            popupTitle: "Error"
        });
    }

    handleOpenLogout = () => {
        this.setState({
            openLogoutPopup: true
        })
    }
    handleCloseLogout = () => {
        this.setState({
            openLogoutPopup: false
        })
    }

    deleteNotification = async() => {
       const deviceId = await getStorageData("deviceId");
       if(deviceId){
        this.removeNotification = this.apiCall(configJSON.POST_METHOD_TYPE , `${configJSON.removeNotification}?device_token_id=${deviceId}`)
       } else {
        this.handleLogout();
       }
    }

    handleLogout = () => {
        window.localStorage.clear()
        window.location.replace("/EmailAccountLoginBlock");
    }

    changeType = (isPassword: boolean) => isPassword ? 'password' : 'text'

    toggleDeletePopup = (value: boolean) => this.setState({ deletePopup: value });

    toggleErrorPopup = (value: string, isSuccessful?: boolean) => this.setState({ error: value, popupTitle: isSuccessful ? "Successful" : "Error" });

    getErrorMessage = (touched: any, errors: any, value: string) => touched[value] && Boolean(errors[value]);

    getHelperText = (touched: any, errors: any, value: string) => touched[value] && errors[value]

    uploadImg = (file: any) => {
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                this.setState({ profile_avatar: fileReader.result }, () => this.updateAdmin());
            };
        }

    }

    requestBody = (dob: string, superAdminNewBody?: any) => ({
        data: {
            attributes: {
              role: this.state.profile_role,
              first_name: superAdminNewBody?.firstnameAdmin || this.state.profile_firstname,
              last_name: superAdminNewBody?.lastnameAdmin || this.state.profile_lastname,
              email: superAdminNewBody?.emailAddressAdmin || this.state.profile_email,
              dob,
              phone_number: superAdminNewBody?.mobileNumberAdmin || this.state.profile_phone,
              full_phone_number: superAdminNewBody ? ("+91" + superAdminNewBody?.mobileNumberAdmin) : this.state.profile_phone,
              user_name: superAdminNewBody?.usernameAdmin || this.state.profile_user,
              password: superAdminNewBody?.passwordAdmin,
              password_confirmation: superAdminNewBody?.confirmPasswordAdmin,
            },
        },
    })

    handleCloseCropModal = () => {
        this.setState({ cropImageModal: false })
    }

    handleSubmitProgfileImage = (cropperRef: any) => {
        let croppedImage = cropperRef.current?.cropper.getCroppedCanvas();
        croppedImage.toBlob((blob: any) => {
            const file = new File([blob], "profile.png", { type: 'image/png' });
            this.setState({ croppedImage: file, cropImageModal: false }, () => {
                this.updateAvatar(file);
            })
        });
    }

    handleUserProfileImageUpload = (event: any) => {
        event.persist();
        const selectedFile = event.currentTarget.files[0];
        if(selectedFile?.size >= configJSON.imageSizeLimit){
            this.setState({ error: 'Max allowed size is 25MB' })
            return ;
         }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState(() => {
                return { profile_avatar_image: reader.result as string, cropImageModal: true }
            });
        };
        reader.readAsDataURL(selectedFile);
        if (event.currentTarget) {
            event.currentTarget.value = '';
        }
    }

    onProfileImageUpload = (event: any) => {
        event.currentTarget.files.length && this.handleUserProfileImageUpload(event)
    }

    updateAvatar = async (profile_avatar: any) => {
        const { profile_id, profile_role } = this.state
        const endPoint = `${configJSON.getTableAPiEndPoint}/${profile_id}?role_${profile_role}=true`;

        const formData = new FormData();
        formData.append("data[attributes][avatar]", profile_avatar);

        this.avatarUserCallId = this.apiCall(
            configJSON.putAPiMethod,
            endPoint,
            formData,
            true
        )
    }

    updateAdmin = (superAdminNewBody?: any) => {
        const { profile_id, profile_role } = this.state
        const dob = superAdminNewBody ? moment(superAdminNewBody?.dateOfBirthAdmin).format("DD/MM/YYYY") : this.state.profile_dob;
        const endPoint = `${configJSON.getTableAPiEndPoint}/${profile_id}?role_${profile_role}=true`;

        const requestBody = this.requestBody(dob, superAdminNewBody)

        this.setState({isPasswordFilled: !!requestBody.data.attributes.password && !!requestBody.data.attributes.password_confirmation })

        this.editUserCallId = this.apiCall(
            configJSON.putAPiMethod,
            endPoint,
            JSON.stringify(requestBody)
        )
    };

    toggleEditPopup = (value: boolean) => {
        this.setState((state) => ({
            userModal: value,
            editIntValue: {
                firstnameAdmin: state.profile_firstname,
                lastnameAdmin: state.profile_lastname,
                mobileNumberAdmin: state.profile_phone.split('-')[1],
                emailAddressAdmin: state.profile_email,
                usernameAdmin: state.profile_user,
                dateOfBirthAdmin: state.profile_dob,
                passwordAdmin: "",
                confirmPasswordAdmin: "",
                isPassword: true,
                isConfigPassword: true,
            }
        }))
    };

    deleteUser = () => {
        const { profile_id, profile_role } = this.state
        if (profile_id) {
            const endPoint = `${configJSON.deleteUserApiEndPoint}/${profile_id}?role_${profile_role}=true`;
            this.deleteUserCallId = this.apiCall(configJSON.DELETE_METHOD_TYPE, endPoint)
        }

    }


    apiCall = (method: string, endpoint: string, body?: any, headerWithContent?: boolean) => {
        let header: any = {
            token: localStorage.getItem("tokenn"),
        };

        if (!headerWithContent) {
            header["Content-Type"] = "application/json";
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        if (body) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        }

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };


    // Customizable Area End
};