import React from "react";

// Customizable Area Start
import { Container, Box, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  img2067c513bd99a9a03ba8acb39c9e3615ec175f0a,
  imge849d80e786d55f5dd81455aee3fc68c96e4719f,
  SplashScreenBg
} from "./assets";

// Customizable Area End

import LandinPageAdminController, { Props } from "./LandingPageAdminController";

export default class LandingPageAdmin extends LandinPageAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.splashBg}>
          <Box style={webStyle.logo1}>
            <img
              src={img2067c513bd99a9a03ba8acb39c9e3615ec175f0a}
              style={webStyle.logoimg1}
            />
          </Box>
          <Box style={webStyle.logo2}>
            <Typography style={webStyle.text}>from</Typography>
            <img
              src={imge849d80e786d55f5dd81455aee3fc68c96e4719f}
              style={webStyle.logoimg2}
            />
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  }
});

const webStyle = {
  splashBg: {
    background: `#DF5200 url(${SplashScreenBg}) no-repeat fixed center`,
    [theme.breakpoints.down("sm")]:{
        height:'100vh'
    }
  },
  logo1: {
    textAlign: "center" as "center",
    marginTop: "15%"
  },
  logoimg1: {
    width: "300px",
    height: "400px"
  },
  logoimg2: {
    width: "190px",
    height: "40px"
  },
  logo2: {
    textAlign: "center" as "center",
    marginTop: "15%",
    paddingBottom: 10
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#FCFAF7"
  }
};
// Customizable Area End
