import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start

  CardMedia,
  CircularProgress,
  Badge,
  FormControl,
  Tabs,
  Tab,
  Grid,
  Modal,
  Card,
  CardHeader,
  CardContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import moment from "moment"
import {
  Add
  , Close, Edit, PhotoCamera, PlayArrow
} from "@material-ui/icons";
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from "@material-ui/icons/Search";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import InfiniteScroll from "react-infinite-scroll-component";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import {
  Library,
} from "./VideoManagementController";


// Customizable Area End

import VideoManagementController, {
  Props,
  configJSON,
} from "./VideoManagementController";
import LinkShare from "../../LinkShare/src/LinkShare.web";

export default class VideoManagement extends VideoManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTabs = () => {
    return ["English", "Gujarati", "Hindi"].map((tab: string) => {
      return (
        <Tab key={tab} value={tab.toLowerCase()} label={tab} style={{ ...styles.languageTabs, color: this.state.languageTab.toLowerCase() == tab.toLowerCase() ? "#DF5200" : "#1C2324", }} />
      );
    })
  }

  ImageFragment = ({ attachment }: { attachment: any }) => {
    let mediaType = this.checkMediaType(attachment?.src)
    let image = attachment.src
    if (image) {
      return <StyledBadge
        style={{ marginBottom: "16px",position:"relative" }}
        overlap="rectangular"
        badgeContent={
          <Box sx={{width:"100%"}}>
            <input
              type="file"
              style={styles.hide}
              id="edit-icon-badge-file"
              onChange={(e) => this.handleImageSelect(e)}
              data-testid={"modal_image_handle_select"}
              accept="image/*,video/*"
            />
            <Box style={{ display: "flex", alignItems: "center", gap: "17px",position:"absolute",top:"22px",right:"22px" }}>
              <label htmlFor="edit-icon-badge-file">
                <IconButton
                  data-testid={"modal_image_fragment_select"}
                  component="span"
                  aria-label="edit upload picture"
                  disableRipple={true}
                  style={styles.badgeButton}
                >
                  <Edit fontSize="small" htmlColor="#fff" style={{fontSize: "1rem"}} />
                </IconButton>
              </label>
              {(this.state.faqAddModelOpen || this.state.faqEditModel) && <label htmlFor="delete-icon-badge-file">
                <IconButton
                  data-testid={"modal_image_fragment_select"}
                  component="span"
                  aria-label="delete upload picture"
                  disableRipple={true}
                  style={styles.badgeButton}
                  onClick={this.handleFaqDeleteImage}
                >
                  <DeleteIcon  fontSize="small" htmlColor="#fff" style={{fontSize: "1rem"}} />
                </IconButton>
              </label>}
            </Box>
          </ Box>
        }
      >
        {mediaType === "img" && (
          <Card variant="outlined" style={styles.badgeCardRoot}>
            <CardMedia
              component={mediaType}
              src={attachment.src}
              alt="cover-images"
            />
          </Card>
        )}
        {mediaType === "video" && (

          <CustomVideo url={attachment.src} isDetailedView={true} height="200px" width="100%" />
        )}
      </StyledBadge>
    } else {
      return <>
        <Box
          style={{
            ...styles.formImageContainer,
            border:
              this.state.alertOpen && !attachment?.src && (this.state.libraryEditModal || this.state.isLibraryModalOpen)
                ? "1px solid #f44336"
                : "1px solid #F0EBE0",
          }}
        >
          <input
            accept="image/*,video/*"
            type="file"
            style={styles.hide}
            id="icon-button-file"
            onChange={(e) => this.handleImageSelect(e)}
            data-testid={"modal_image_handle_select"}
          />
          <label
            htmlFor="icon-button-file"
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconButton
              data-testid={"modal_image_fragment_select"}
              aria-label="upload image"
              component={"span"}
            >
              <PhotoCamera style={styles.photoCamera} />
            </IconButton>
          </label>
        </Box>
        {this.state.alertOpen && !attachment?.src && (this.state.libraryEditModal || this.state.isLibraryModalOpen) && (
          <Typography variant="body1" style={styles.modalCardFieldError}>
            Image/Video is required
          </Typography>
        )}
      </>
    }
  };

  renderImageVideo = (image: any, isDetailedView: boolean,height:string) => {
    if (!image) {
      return <StyledImageBox src={"https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} alt="video" style={isDetailedView ? styles.videoDetaildImg : {}} />
    }
    let mediaType = this.checkMediaType(image)
    if (mediaType == "img" && isDetailedView) {
      return <img src={image} alt="Image" style={styles.videoDetaildImg} />
    }
    if (mediaType == "img" && !isDetailedView) {
      return <StyledImageBox src={image} alt="Image" style={{height:height}}/>
    }
    if (mediaType == "video") {
      return <CustomVideo url={image} isDetailedView={isDetailedView}  width="100%" height={height} />

    }
  }

  faqCard = (faq: any, i: number) => {
    const { image, name, faqs } = faq.attributes
    if((faqs?.data.length == 0)){
      return
    }
    return <> 
      <AccordionBox style={{ borderRadius: "12px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box style={{ padding: "0px 12px 0px 0px" }}>
            <img src={image} style={styles.faqimg} />
          </Box>
          <Box>
            <Typography style={styles.faqheading}>
              {name}
            </Typography>
            <Typography variant="inherit" style={styles.libraryLastUpdate}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{faqs.data[0]?.attributes?.updated_at && moment(faqs.data[0].attributes.updated_at).format("DD MMM YYYY")}</Typography></Typography>
          </Box>
        </AccordionSummary>
        <Divider style={{ backgroundColor: "#E4DDC9", height: "2px", marginBottom: '5px' }} />
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {faqs.data.map((item: any, i: number) => {
            const { question, description, libraries_faq_id } = item.attributes
            return <Accordion
              key={i}
              style={{
                boxShadow: "none",
                backgroundColor: "#F0EBE0",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: 24 }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#1C2324",
                    opacity: "0.72",
                    fontFamily:configJSON.fontFamily
                  }}
                >
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{
                display: "flex",
                flexDirection: "column",
              }}>
                <Typography style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#1C2324",
                  opacity: "0.72",
                  wordWrap: "break-word",
                  fontFamily:configJSON.fontFamily
                }}>
                  {description}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    marginTop: "13px",
                    marginBottom: "12px",
                  }}
                >
                  <Box style={{ flex: 1, display: "flex"}}>
                    <ShareIcon
                      style={{
                        color: "#286b77",
                        marginTop: "-1px",
                        width: "24px",
                        height: "24px",
                        cursor:'pointer'
                      }}
                      onClick={()=> this.handleShareLink(item)}
                    />
                    <Typography
                      variant="h6"
                      style={{
                        color: "#286b77",
                        fontWeight: 500,
                        fontSize: "14px",
                        marginLeft: "6px",
                        lineHeight: "22px",
                        fontFamily: configJSON.fontFamily
                      }}
                    >
                      Share in Chat
                    </Typography>
                  </Box>
                  <Box style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                    {libraries_faq_id?.id ? <EditIcon htmlColor="#EB8F06" style={styles.icon} data-testid="edit_faq_button" onClick={() => this.handleClickFaqEditIcon(libraries_faq_id?.id)} /> : null}
                  </Box>
                  <Box>
                    {libraries_faq_id?.id ? <DeleteIcon htmlColor="#B3261E" style={styles.icon} data-testid="delete_faq_button" onClick={() => this.handleClickFaqDeleteIcon(libraries_faq_id?.id)} /> : null}
                  </Box>
                </Box>
              </AccordionDetails>
              {(faqs.data.length - 1 !== i) && <DividerBox style={{ backgroundColor: "#E4DDC9", height: "2px" }} />}
            </Accordion>
          })}
        </AccordionDetails>
      </AccordionBox >
    </>
  }

  libraryCard = (library: Library, index: number) => {
    const { topic, description, image, libraries_faq_id, language, updated_at } = library.attributes
    console.log("libraryCard",library)
    if (this.state.selectedLibrary === index) {
      return <Box key={index} style={{ ...styles.libraryCard, padding: "1rem" }}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography title={topic} variant="h6" style={{ ...styles.librarySubHeading }}>{topic}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="inherit" style={{ ...styles.libraryLastUpdate, textAlign: "right", display: "block" }}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{moment(updated_at).format("DD MMM YYYY")}</Typography></Typography>
          </Grid>
        </Grid>
        <Box sx={{ margin: "8px 0 12px 0" }}>
          {this.renderImageVideo(image, true,"150px")}
        </Box>
        <Typography variant="inherit" style={{ ...styles.paragraph }}>{description}</Typography>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "12px" }}>
          <Typography variant="inherit" data-testid="read_less_button" style={styles.readMore} onClick={this.onClickReadLess}>Read Less</Typography>
          <Box style={styles.cardAction}>
            <ShareIcon htmlColor="#006A77" style={styles.icon} onClick={()=> this.handleShareLink(library)}/>
            {libraries_faq_id ? language && <EditIcon htmlColor="#EB8F06" data-testid="edit_library_button" style={styles.icon} onClick={() => this.handleClickEdit(libraries_faq_id, language)} /> : null}

            {libraries_faq_id ?
              <DeleteIcon htmlColor="#B3261E" data-testid="delete_library_button" style={styles.icon} onClick={() => this.handleClickDeleteIcon(libraries_faq_id)} />
              : null}
          </Box>
        </Box>
      </Box>
    }
    return <StyledBoxCard key={index} style={styles.libraryCard} display="flex">
      {this.renderImageVideo(image, false,"130px")}
      <Box style={styles.libraryCardContent}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography title={topic} variant="h6" style={{ ...styles.librarySubHeading }}>{topic}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="inherit" style={{ ...styles.libraryLastUpdate, textAlign: "right", display: "block" }}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{moment(updated_at).format("DD MMM YYYY")}</Typography></Typography>
          </Grid>
        </Grid>

        <LineClampTypography variant="inherit"
        >{description}</LineClampTypography>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "12px" }}>
          <Typography variant="inherit" style={styles.readMore} data-testid="read_more_button" onClick={() => this.onClickReadMore(index)}>Read More</Typography>
          <Box style={styles.cardAction}>
            <ShareIcon htmlColor="#006A77" style={styles.icon} onClick={()=> this.handleShareLink(library)}/>
            {libraries_faq_id ? language && <EditIcon htmlColor="#EB8F06" style={styles.icon} data-testid="edit_library_button" onClick={() => this.handleClickEdit(libraries_faq_id, language)} /> : null}
            {libraries_faq_id ? <DeleteIcon htmlColor="#B3261E" style={styles.icon} data-testid="delete_library_button" onClick={() => this.handleClickDeleteIcon(libraries_faq_id)} /> : null}


          </Box>
        </Box>
      </Box>
    </StyledBoxCard>
  }

  renderForm = (data: any, index: number) => {
    if (this.state.languageTab.toLowerCase() == data.language.toLowerCase()) {
      return <FormControl key={index} fullWidth style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Topic Name
            <span style={{ marginLeft: "5px" }}>*</span>
          </Typography>
          <input
            style={{ ...styles.modalTextField, border: (this.state.alertOpen && !data.topic.trim()) ? "1px solid #f44336" : "1px solid #F0EBE0", }}
            placeholder="Enter name of topic"
            value={data.topic}
            name="topic_name"
            onChange={(e) => this.updatedTopicName(e, index)}
            data-testid={"modal_input_topic_name"}
          />
          {this.state.alertOpen && !data.topic && <Typography
            variant="body1"
            style={
              styles.modalCardFieldError
            }
          >
            Topic name is required
          </Typography>}
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Upload Image/Video
            <span style={{ marginLeft: "5px" }}>*</span>
          </Typography>
          {this.ImageFragment({ attachment: this.state.libraryFormImage })}
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Description
            <span style={{ marginLeft: "5px" }}>*</span>
          </Typography>
          <textarea
            style={{ ...styles.modalTextField, border: (this.state.alertOpen && !data.description.trim()) ? "1px solid #f44336" : "1px solid #F0EBE0", }}
            rows={12}
            placeholder="Enter description here...."
            value={data.description}
            name="description"
            onChange={(e) => this.updatedDescription(e, index)}
            data-testid={"modal_input_topic_description"}
          />
          {this.state.alertOpen && !data.description && <Typography
            variant="body1"
            style={
              styles.modalCardFieldError
            }
          >
            Description is required
          </Typography>}
        </Box>
      </FormControl>
    }
  }

  renderBorder = (alertOpen: boolean, field: any) => {
    if (alertOpen && !field) {
      return "1px solid #f44336"
    }
    return "1px solid #F0EBE0"
  }

  renderSelectOption = (data: any) => {
    if (this.state.languageTab.toLowerCase() == "hindi") {
      return data.name_hindi
    }
    if (this.state.languageTab.toLowerCase() == "gujarati") {
      return data.name_gujarati
    }
    return data.name
  }

  renderfaqForm = (item: any, index: number) => {
    if (
      this.state.languageTab.toLowerCase() == item.language.toLowerCase()
    ) {
      return (
        <FormControl key={index} fullWidth style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box>
            <Typography variant="subtitle2" style={styles.modalCardContentEditModalTypography}>Topic of FAQ <span style={{ marginLeft: "5px" }}>*</span></Typography>
            <FormControl fullWidth
              style={{
                fontFamily: configJSON.fontFamily,
                borderRadius: "0.75rem",
                border: this.renderBorder(this.state.alertOpen, this.state.selectedFaqCategory)
              }
              }
            >
              <Select
                data-testid="tableSelectionMenuTestid"
                className="table-select-menuitem"
                value={this.state.selectedFaqCategory}
                onChange={this.handleEventChange}
                label="Select topic of FAQ"
                variant="outlined"
                IconComponent={(props) => (
                  <ExpandMoreIcon
                    {...props}
                    sx={{ marginRight: "15px" }}
                  />
                )}
              >
                <MenuItem value={0} disabled>
                  <span className="opcity-48" style={{ fontFamily: configJSON.fontFamily }}>Select topic of FAQ</span>
                </MenuItem>
                {[...this.state.faqCategories].map((data: any, i: number) => {
                  return (
                    <MenuItem key={data.id} value={data.id} style={{ opacity: 0.72 }} >{this.renderSelectOption(data)}</MenuItem>
                  );
                })}

              </Select>
            </FormControl>
            {
              this.state.alertOpen && !this.state.selectedFaqCategory && (
                <Typography
                  variant="body1"
                  style={styles.modalCardFieldError}
                >
                  Please select topic of FAQ
                </Typography>
              )
            }
          </Box >

          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Question
              <span style={{ marginLeft: "5px" }}>*</span>
            </Typography>
            <input
              style={{
                ...styles.modalTextField,
                border: this.renderBorder(this.state.alertOpen, item.question.trim())
              }}
              placeholder="Enter Question of FAQ"
              value={item.question}
              name="topic_name"
              onChange={(e) => this.updatedFaqQuestionName(e, index)}
              data-testid={"modal_input_topic_name"}
            />
            {this.state.alertOpen && !item.question && (
              <Typography variant="body1"
                style={styles.modalCardFieldError}>
                Question of FAQ is required
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Upload Image/Video
            </Typography>
            {this.ImageFragment({ attachment: this.state.libraryFormImage })}

          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Description
              <span style={{ marginLeft: "5px" }}>*</span>
            </Typography>
            <textarea
              style={{
                ...styles.modalTextField,
                border: this.renderBorder(this.state.alertOpen, item.description.trim())
              }}
              rows={12}
              placeholder="Enter description here...."
              value={item.description}
              name="description"
              onChange={(e) => this.updatedFaqDescription(e, index)}
              data-testid={"modal_input_topic_description"}
            />
            {this.state.alertOpen && !item.description && (
              <Typography variant="body1" style={styles.modalCardFieldError}>
                Description is required
              </Typography>
            )}
          </Box>

        </FormControl >
      );
    }
  };

  renderLibrarySection = () => {
    return <Grid item xs={12} lg={6} >
      <StyledBoxSection >
        <Grid item container xs={12} alignItems="center" style={{
            padding: "1.375rem 1.5rem",
        }}>
          <Grid item xs={6}>
            <Typography variant='h4' style={styles.sectionHeadingText}>{configJSON.librarySectionHeading}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                padding: "7px",
                background: "#FCFAF7",
                borderRadius: "28px",
                border: "2px solid #F0EBE0",
                width: "100%",
              }}
            >
              <Input
                id="searchInputId"
                type="search"
                name="searchInput"
                disableUnderline
                fullWidth
                style={{  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                autoComplete="off"
                className="tableSearchInputfilter searchByTopic"
                data-testid="tableInputSearchTestid"
                placeholder="Search by topic"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "#1C2324", opacity: 0.48 }}
                    />
                  </InputAdornment>
                }
                onChange={this.handleSearch}
              />
            </Box>
          </Grid>
        </Grid>
        <div id="scrollableLibraryDiv" style={{ overflowY: "auto", maxHeight:"815px", padding: "0 1.5rem"}}>
          <InfiniteScroll
            dataLength={this.state.librariesData?.length}
            scrollableTarget="scrollableLibraryDiv"
            next={()=>{
              this.getAllLibraries()
            }}
            hasMore={true}
            loader={<></>}
          >
            <Box sx={styles.libraryCards}>
              {this.state.librariesData?.length > 0 && this.state.librariesData.map((library: Library, i: number) => {
                return this.libraryCard(library, i)
              })}
            </Box>
          </InfiniteScroll>
        </div>
        {this.state.isFetchingLibraryData && <Box sx={{ display: 'flex', justifyContent: "center" }}>
          <CircularProgress size={30} />
        </Box>}
      </StyledBoxSection>
    </Grid>
  }
  renderFaqSection = () => {
    return <Grid item xs={12} lg={6} >
      <StyledBoxSection >
        <Grid item container xs={12} alignItems="center" style={{ padding: "1.375rem 1.5rem"}}>
          <Grid item xs={6}>
            <Typography variant='h4' style={styles.sectionHeadingText}>{configJSON.faqSectionHeading}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                padding: "7px",
                background: "#FCFAF7",
                borderRadius: "28px",
                border: "2px solid #F0EBE0",
                width: "100%",
              }}
            >
              <Input
                id="searchInputId"
                type="search"
                name="searchInput"
                style={{  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                disableUnderline
                fullWidth
                autoComplete="off"
                className="tableSearchInputfilter searchByTopic"
                data-testid="tableInputSearchFaqTestid"
                placeholder="Search by topic"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "#1C2324", opacity: 0.48 }}
                    />
                  </InputAdornment>
                }
                onChange={this.handleFaqSearch}
              />
            </Box>
          </Grid>
        </Grid>
        <div id="scrollableFaqDiv" style={{ overflowY: "auto", maxHeight: "815px", padding: "0 1.5rem" }}>
          <InfiniteScroll
            dataLength={this.state.faqsData?.length}
            scrollableTarget="scrollableFaqDiv"
            next={() => {
              this.getAllFaqs()
            }}
            hasMore={true}
            loader={<></>}
          >
            <Box sx={styles.faqCards} >
              {this.state.faqsData?.length > 0 && this.state.faqsData.map((faq: any, i: number) => {
                return this.faqCard(faq, i)
              })}
            </Box>
          </InfiniteScroll>
        </div>
        
        {this.state.isFecthingFaqData && <Box sx={{ display: 'flex', justifyContent: "center" }}>
          <CircularProgress size={30} />
        </Box>}
      </StyledBoxSection>
    </Grid>
  }

  renderLibraryForm = () => {
    return <Grid item>
      <Modal
        open={this.state.isLibraryModalOpen || this.state.libraryEditModal}
        onClose={this.handleButtonCancel}
        data-testid="close_modal_button"
        style={styles.modalRoot}
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card variant="outlined" style={styles.modalCardRoot}>
          <CardHeader
            style={styles.modalCardHeader}
            disableTypography={true}
            action={
              <IconButton
                aria-label="close"
                data-testid="close_modal_button"

                onClick={this.handleButtonCancel}
              >
                <Close />
              </IconButton>
            }
            title={
              <Typography style={styles.modalHeaderTypography}>
                {`${this.state.libraryEditModal ? "Edit" : "Add New in"}`}  Library
              </Typography>
            }
          />
          <CardContent style={{ paddingInline: 0, paddingBlockStart: '.5rem', paddingBottom: 0 }}>
            <Box style={{ paddingInline: "2rem" }} >
              <StyledTabs
                value={this.state.languageTab.toLowerCase()}
                onChange={this.handleTabChange}
                variant="fullWidth"
                data-testid={"modal_assign_language_tab"}
              >
                {this.renderTabs()}
              </StyledTabs>
            </Box>
            <Box sx={{ padding: "2rem" }}>
              {this.state.formData.map(
                (data: any, index: number) => {
                  return this.renderForm(data, index);
                }
              )}
              <Button
                component="span"
                aria-label="add-library"
                data-testid={"modal_add_library_button"}
                disableRipple={true}
                style={{ ...styles.buttonComponent, ...styles.buttonComponentAddNewPhase, marginTop: "32px" }}
                onClick={this.state.libraryEditModal ? this.editTopic : this.addNewTOpic}
                disabled={this.state.isAddingLibrary}
              >
                {
                  !this.state.isAddingLibrary ? <Typography style={{ ...styles.buttonTxt, ...styles.addButtonTxt }}>
                    {`${this.state.libraryEditModal ? "Edit" : " Add New"}`}  Topic
                  </Typography> : <CircularProgress size={28} style={{ color: "#fff" }} />
                }

              </Button>
              {this.state.isLibraryAdded && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "#75bd68",
                marginTop: "8px"
              }}>
                Library {`${this.state.libraryEditModal ? "edited" : "added"}`} successfully
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Box>

          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  renderLibraryDeleteModal = () => {
    return <Grid item>
      <Modal
        open={this.state.deleteLiraryModal}
        onClose={this.handleButtonCancel}
        aria-labelledby="modal-calendar-alert-validation"
        aria-describedby="modal-calendar-alert"
        data-testid="close_modal_button"
        style={styles.secondaryModalBox}
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          style={styles.modalCardBoxSuccess}
        >
          <CardContent style={styles.secondaryModalCardsContent}>
            <Container
              style={styles.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontFamily: configJSON.fontFamily,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px",
                color: "#1C2324",
              }}>
                Delete Library
              </Typography>

              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{
                  fontFamily: configJSON.fontFamily,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#1C2324",
                  textAlign: "center"
                }}>
                  Are you sure you want to delete? All the content related to this topic will be deleted
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  onClick={this.deleteTopic}
                  data-testid={"modal_delete_button"}
                  disabled={this.state.isDeleting}
                >
                  {
                    !this.state.isDeleting ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                  }

                </Button>
                <Button
                  fullWidth
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleButtonCancel}
                  data-testid="close_modal_button"

                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
              {this.state.isDeleted && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "#75bd68",
              }}>
                Library deleted successfully
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  renderFaqForm = () => {
    return <Grid item>
      <Modal
        open={this.state.faqAddModelOpen || this.state.faqEditModel}
        onClose={this.handleFaqButtonCancel}
        data-testid="close_faq_modal_button"
        style={styles.modalRoot}
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card variant="outlined" style={styles.modalCardRoot}>
          <CardHeader
            style={styles.modalCardHeader}
            disableTypography={true}
            action={
              <IconButton
                aria-label="close"
                data-testid="close_modal_button"

                onClick={this.handleFaqButtonCancel}
              >
                <Close />
              </IconButton>
            }
            title={
              <Typography style={styles.modalHeaderTypography}>
                {`${this.state.faqEditModel ? "Edit" : "Add New in"}`}  FAQ
              </Typography>
            }
          />
          <CardContent style={{ paddingInline: 0, paddingBlockStart: '.5rem', paddingBottom: 0 }}>
            <Box style={{ paddingInline: "2rem" }} >
              <StyledTabs
                value={this.state.languageTab.toLowerCase()}
                onChange={this.handleTabChange}
                variant="fullWidth"
                data-testid={"modal_assign_language_tab_faq"}
              >
                {this.renderTabs()}
              </StyledTabs>
            </Box>
            <Box sx={{ padding: "2rem" }}>
              {this.state.faqFormData.map((item: any, index: number) => {
                return this.renderfaqForm(item, index)
              })}
              <Button
                component="span"
                aria-label="add-faq"
                data-testid={"modal_add_faq_button"}
                disableRipple={true}
                style={{
                  ...styles.buttonComponent,
                  ...styles.buttonComponentAddNewPhase,
                  marginTop: "32px",
                }}
                onClick={
                  this.state.faqEditModel ? this.editFaq : this.addFaqTopic
                }
                disabled={this.state.isAddingFaq}
              >
                {!this.state.isAddingFaq ? (
                  <Typography
                    style={{
                      ...styles.buttonTxt,
                      ...styles.addButtonTxt,
                    }}
                  >
                    {`${this.state.faqEditModel ? "Edit" : " Add New"}`} FAQ
                  </Typography>
                ) : (
                  <CircularProgress size={28} style={{ color: "#fff" }} />
                )}
              </Button>
              {this.state.isFaqAdded && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "#75bd68",
                marginTop: "8px"
              }}>
                Faq {`${this.state.faqEditModel ? "edited" : "added"}`} successfully
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Box>

          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  renderBigFileErrorModal = () => {
    return <Grid item>
      <Modal
        open={this.state.isSelectedFileBig}
        onClose={this.handleAlertCancel}
        aria-labelledby="modal-calendar-alert-validation"
        aria-describedby="modal-calendar-alert"
        style={styles.secondaryModalBox}
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          style={styles.modalCardBoxSuccess}
        >
          <CardContent style={styles.secondaryModalCardsContent}>
            <Container
              style={styles.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontFamily: configJSON.fontFamily,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px",
                color: "#1C2324",
              }}>
                Error
              </Typography>

              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{
                  fontFamily: configJSON.fontFamily,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#1C2324",
                  textAlign: "center"
                }}>
                  Select file is bigger than expected, Please select the media size less then 250MB
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  onClick={this.handleAlertCancel}
                  data-testid={"modal_alert_cancel_button"}
                >
                  <strong>GO BACK</strong>
                </Button>
                <Button
                  fullWidth
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleAlertCancel}
                  data-testid={"modal_alert_cancel_button"}
                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }
  renderDeleteFaqModal = () => {
    return <Grid item>
      <Modal
        open={this.state.deleteFaqModal}
        onClose={this.handleButtonCancel}
        aria-labelledby="modal-calendar-alert-validation"
        aria-describedby="modal-calendar-alert"
        data-testid="close_modal_button"
        style={styles.secondaryModalBox}
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          style={styles.modalCardBoxSuccess}
        >
          <CardContent style={styles.secondaryModalCardsContent}>
            <Container
              style={styles.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontFamily: configJSON.fontFamily,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px",
                color: "#1C2324",
              }}>
                Delete FAQ
              </Typography>

              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{
                  fontFamily: configJSON.fontFamily,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#1C2324",
                  textAlign: "center"
                }}>
                  Are you sure you want to delete? All the content related to this topic will be deleted
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  onClick={this.deleteFaq}
                  data-testid={"modal_delete_faq_button"}
                  disabled={this.state.isDeletingFaq}
                >
                  {
                    !this.state.isDeletingFaq ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                  }

                </Button>
                <Button
                  fullWidth
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleButtonCancel}
                  data-testid="close_modal_button"

                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
              {this.state.isDeletedFaq && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "#75bd68",
              }}>
                FAQ deleted successfully
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={this.props.id}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={styles.rootGrid}>
            <Box sx={styles.rootGridContainer}>
              <Typography variant='h4' style={styles.heading}>{configJSON.heading1}</Typography>
              <Box sx={styles.buttonContainer}>
                <StyeledButton
                  aria-label="add-library"
                  disableRipple={true}
                  onClick={this.handleAddLibrary}
                  data-testid={'add_library_modal_button'}
                >
                  <Add />
                  <Typography style={styles.buttonTxt}>
                    {configJSON.addLibraryButtonText}
                  </Typography>
                </StyeledButton>
                <StyeledButton
                  aria-label="edit-profile"
                  disableRipple={true}
                  onClick={this.handleAddFaqForm}
                  data-testid={'add_faq_modal_button'}
                >
                  <Add />
                  <Typography style={styles.buttonTxt}>
                    {configJSON.addFaqButtonText}
                  </Typography>
                </StyeledButton>
              </Box>
            </Box>
          </Grid>
          {this.renderLibrarySection()}
          {this.renderFaqSection()}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              {this.renderLibraryForm()}
              {this.renderLibraryDeleteModal()}
              {this.renderBigFileErrorModal()}
              {this.renderFaqForm()}
              {this.renderDeleteFaqModal()}
            </Grid>
          </Grid>
        </Grid>
        {this.state.isShare && <LinkShare isShare={this.state.isShare}
          handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
          messageHeading={this.state.messageHeading}/>}
      </NavigationMenu >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface CustomVideoProps {
  url: string
  isDetailedView?: boolean
  height?:string
  width?:string
}
interface CustomVideoState {
  isPlaying: boolean,

}
export class CustomVideo extends React.Component<CustomVideoProps, CustomVideoState>{
  state: CustomVideoState = {
    isPlaying: false,

  };
  videoRef: any = React.createRef()

  playVideo = () => {
    if (this.state.isPlaying) {
      this.videoRef.current?.pause();
      this.setState({ isPlaying: false });
    } else {
      this.videoRef.current?.play();
      this.setState({ isPlaying: true });
    }
  }

  renderVideoView = () => {
    if (this.props.isDetailedView) {
      return <video ref={this.videoRef}  style={{ objectFit: "cover", width: this.props.width, height: this.props.height, borderRadius: "8px" }} controls={this.state.isPlaying} >
        <source src={this.props.url} />
      </video>
    }
    return  <StyledVideoBox ref={this.videoRef} controls={this.state.isPlaying} >
      <source src={this.props.url} />
    </StyledVideoBox>
  }

  render() {
    return <CustomVideoBox sx={{width: this.props.isDetailedView ? "100%":"",maxHeight:this.props.height}} data-testid={"play-video-button"} onClick={() => this.playVideo()}>
      {this.renderVideoView()}
      {!this.state.isPlaying && <PlayArrow onClick={(e) => {
        e.stopPropagation()
        this.playVideo()
      }} style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        margin: 0,
        color: "white",
        height: "32px",
        width: "32px",
        borderRadius: "50%",
        background: "rgb(28 35 36 / 56%)",
        textAlign: "center"
      }} />}
    </CustomVideoBox>
  }
}

const CustomVideoBox=styled(Box)({
  position:"relative",
  '@media (max-width:600px)': {
    width: '100%',
  },
})
const LineClampTypography = styled(Typography)({
  height: "46px",
  overflow: 'hidden',
  lineHeight: "22px",
  opacity: "0.72",
  fontWeight: 400,
  fontSize: "14px",
  color: "#1C2324",
  wordBreak:"break-all",
  marginTop: "4px",
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  fontFamily:configJSON.fontFamily

});
const DividerBox = styled(Divider)({
  backgroundColor: "#E4DDC9",
  height: "2px",
});
const AccordionBox = styled(Accordion)({
  backgroundColor: "#F0EBE0",
  boxShadow: "none",
  minHeight: "80px",
  borderRadius: "12px",
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0!important'
  },
  '& .MuiAccordion-root.Mui-expanded:before': {
    opacity: 1,
    height: '2px',
    background: 'rgba(0, 0, 0, 0.06)'
  },
  '&.MuiAccordion-root:before': {
    height: '0!important',
  },
  '& .MuiAccordion-root:before': {
    margin: '0 20px',
    height: '2px',
    background: 'rgba(0, 0, 0, 0.06)'
  },
  '& .MuiAccordionSummary-root': {
    padding: '0px 16px!important',
  },
  '& .MuiAccordionSummary-content': {
    margin: '16px 0px!important',
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
    '& .MuiPaper-rounded': {
      padding: '0px 24px!important',
    },
    '& .MuiPaper-rounded .MuiAccordionSummary-root': {
      padding: '0px!important',
    },
    '& MuiPaper-rounded .MuiAccordionSummary-root': {
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0px!important',
    },
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  }

});

const StyledTabs = styled(Tabs)(() => ({
  color: "#1C2324",
  "& .MuiTabs-indicator": {
    width: "100%",
    height: "2px",
    backgroundColor: '#DF5200',

  }
}));
const StyeledButton = styled(Button)({
  border: "1px solid #F0EBE0",
  borderRadius: "1.85rem",
  backgroundColor: "#DF5200",
  color: "#FFF",
  padding: ".875rem 2rem",
  "&:hover": {
    background: "#DF5200",
  },
  '@media (max-width:600px)': {
    padding: "0.675rem 1rem",
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    }
  },
})

const StyledImageBox = styled('img')({
  minWidth: '130px',
  maxWidth:"130px",
  height: '130px',
  objectFit: "cover",
  '@media (max-width:600px)': {
    width: '100%',
    minWidth: "100%",
  },
})

const StyledVideoBox = styled('video')({
  minWidth: "130px",
  maxWidth:"130px",
  height: "130px",
  objectFit: "cover",
  borderRadius: "8px 0 0 8px",
  '@media (max-width:600px)': {
    width: '100%',
    minWidth: "100%",
  },
})

const StyledBoxCard = styled(Box)({
  backgroundColor: "#F0EBE0",
  borderRadius: "12px",
  overflow: "hidden",
  display: "flex",
  '@media (max-width:600px)': {
    flexWrap: "wrap"
  },
})
const StyledBadge = styled(Badge)({
  width: '100%'
});
const TypographyBox = styled(Typography)({
  height: "44px",
  display: "block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  webkitLineClamp: 2,
  webkitBoxOrient: 'vertical',
});
const StyledBoxSection = styled(Box)({
  backgroundColor: "#F8F6F1",
  borderRadius: "12px",
  '@media (max-width:600px)': {
    padding: "0.675rem 1rem",
  },
});

const styles = {
  rootGrid: {
    flexGrow: 1,
    width: "100%"
  } as React.CSSProperties,
  rootGridContainer: { display: "flex", justifyContent: "space-between", flexWrap: "wrap" },
  libraryCards: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  faqCards: {
    marginTop: "1.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    border: "none"
  },
  libraryCard: {
    backgroundColor: "#F0EBE0",
    borderRadius: "12px",
    overflow: "hidden"
  },

  libraryCardContent: {
    padding: "10px",
    width: "100%"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  heading: {
    fontFamily: configJSON.fontFamily,
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#1C2324",
  } as React.CSSProperties,
  faqheading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: '2px',
    textTransform: 'capitalize',
    color: "#1C2324",
    fontFamily: configJSON.fontFamily,
  } as React.CSSProperties,
  faqimg: {
    width: "48px",
    height: "48px",
  } as React.CSSProperties,
  horiline: {
    color: "#E4DDC9",
    height: "2px",
  } as React.CSSProperties,
  librarySubHeading: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: configJSON.fontFamily,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  } as React.CSSProperties,
  libraryLastUpdate: {
    fontWeight: 400,
    fontStyle: "italic",
    fontFamily: configJSON.fontFamily,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.56,
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    alignSelf: "center",
  } as React.CSSProperties,
  paragraph: {
    opacity: "0.72",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1C2324",
    lineHeight: "22px",
    marginTop: "4px",
    fontFamily: configJSON.fontFamily
  } as React.CSSProperties,
  addButtonContainer: {
    alignSelf: "center",
    paddingBlock: "1.5rem",
    paddingInline: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem"
  } as React.CSSProperties,


  languageTabs: {
    fontFamily: configJSON.fontFamily,
    fontSize: "1.2rem",
    fontWeight: 500,
    lineHeight: "28px",
    borderBlockEnd: "2px solid rgba(28, 35, 36, .12)",
  },

  buttonComponentAddNewPhase: {
    backgroundColor: "#DF5200"
  } as React.CSSProperties,

  buttonTxt: {
    fontFamily: configJSON.fontFamily,
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFF",
    whiteSpace: "nowrap",
    marginLeft: ".25rem"
  } as React.CSSProperties,

  addButtonTxt: {
    color: "#fff",
    opacity: 1
  } as React.CSSProperties,


  containerHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  } as React.CSSProperties,
  videoImg: {
    maxWidth: '130px',
    height: '130px',
    objectFit: "cover",
    borderRadius: "12px 0 0 12px",

  } as React.CSSProperties,
  videoDetaildImg: {
    width: '100%',
    height: '150px',
    borderRadius: "12px",
    objectFit: "cover",
  } as React.CSSProperties,
  sectionHeadingText: {
    fontFamily: configJSON.fontFamily,
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
  } as React.CSSProperties,
  readMore: {
    color: "#DF5200",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    fontFamily: configJSON.fontFamily,
    textTransform: "uppercase",
    cursor: "pointer"
  } as React.CSSProperties,
  icon: {
    width: "24px",
    marginLeft: "8px",
    height: "24px",
    cursor: "pointer"
  } as React.CSSProperties,
  cardAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px"
  },
  modalRoot: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem", //
    backdropFilter: "blur(5px)",
    // overflow: "scroll",
    // position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  } as React.CSSProperties,

  modalCardHeader: {
    paddingInline: "2rem",
    paddingBlockEnd: 0,
  },
  modalHeaderTypography: {
    fontFamily: configJSON.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#1C2324"
  },
  modalCardRoot: {
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "712px",
    width:"100%",
    height: "90%",
    fontFamily: configJSON.fontFamily,
    fontSize: "1rem",
    overflowY: "scroll",
  } as React.CSSProperties,
  modalCardContentEditModalTypography: {
    fontFamily: configJSON.fontFamily,
    paddingBlockEnd: 1,
    fontSize: ".875rem",
    fontWeight: 400,
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "8px"
  } as React.CSSProperties,

  modalCardFieldError: {
    fontFamily: configJSON.fontFamily,
    fontSize: ".75rem",
    fontWeight: 400,
    color: "#f44336",
  },

  modalTextField: {
    fontFamily: configJSON.fontFamily,
    width: "100%",
    borderRadius: "0.75rem",
    paddingBlock: "1rem",
    paddingInline: "1.13rem",
    fontSize: "1rem",
    resize: "none",
    backgroundColor: '#FCFAF7',
    border: "1px solid #F0EBE0",
    '::placeholder': {
      color: "red",
      fontWeight: 300
    },
  } as React.CSSProperties,
  hide: {
    display: "none"
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "4px",
  },
  formImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "0.75rem",
    marginTop: "0.5rem",
    width: "100%",
    height: "200px"
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: .5
  },
  badgeCardRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "0.75rem",
    height: '189px'
  },
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    backdropFilter: "blur(5px)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%"
  } as React.CSSProperties,
  modalCardBoxSuccess: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 528,
    height: 360,
    fontSize: "16px",
    // overflow: "scroll",
    display: "flex",
    "flexDirection": "column",
    justifyContent: "space-between",
    borderRadius: "12px",
  } as React.CSSProperties,
  secondaryModalCardsContent: {
    // backgroundColor: "red",
    padding: 0,
    paddingBlockStart: "4.5rem",
    height: "100%"
  },

  secondaryModalContentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    fontSize: "16px",
    alignItems: "center",
    height: "100%",
    width: "100%"
  } as React.CSSProperties,
  modalimageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "3rem",
  } as React.CSSProperties,
  modalTextSucessContainer: {
    display: "flex",
    gap: "1rem",
    fontSize: "16px"
  } as React.CSSProperties,
  modalButtonGoBack: {
    width: "142px",
    backgroundColor: "#B3261E",
    color: "#fff",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
    paddingBlock: "0.875rem"
  } as React.CSSProperties,

  modalButtonCardCancel: {
    width: "142px",
    backgroundColor: "#E4DDC9",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
  } as React.CSSProperties,
  buttonComponent: {
    borderRadius: "1.85rem",
    paddingBlock: ".875rem",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    width: "100%",
  } as React.CSSProperties,
  lable: {
    fontSize: '14px',
    color: '#1C2324',
    opacity: 0.64,
    lineHeight: '1.5vw',
  } as React.CSSProperties,

};
// Customizable Area End
