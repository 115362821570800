import React from "react";

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  Tooltip
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {DateRange, WatchLater, KeyboardArrowDown } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

const theme = createTheme({
  spacing: 4,
  typography: {

    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px", 
      textTransform: 'uppercase'  
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px",
      textTransform:'capitalize'
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 0px"
    },
    body2: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});


interface SurveyContent {
  handleShareLinkPopup: (linkType?: string,linkId?:any,accountId?:any,type?:any) => void;
  isLinkPopup: boolean;
  surveyObj: any
}

const SurveyChatContent = (props: SurveyContent) => {
  const { handleShareLinkPopup, isLinkPopup,surveyObj } = props
  
   const questionSection = (question: any, questionIdx: number) => {
    let field = (<TextField
      id="outlined-multiline-static"
      multiline
      rows={4}
      defaultValue="User will write answer here...."
      variant="outlined"
      disabled
      style={classes.textArea}
      InputProps={{
        style:{borderRadius:'12px'}
      }}
    />)

    if (question?.attributes.question_type?.includes("Date")) {
      field = (
        <TextField
          defaultValue="DD/MM/YYYY"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><DateRange /></InputAdornment>,
            style:{borderRadius:'12px',padding:'0px',paddingRight:'14px'}
          }}
          variant="outlined"
        />
      )
    }

    else if (question?.attributes.question_type?.includes("Time")) {
      field = (
        <TextField
          defaultValue="Time"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><WatchLater /></InputAdornment>,
            style:{borderRadius:'12px',padding:'0px',paddingRight:'14px'}
          }}
          variant="outlined"
        />
      )
    }
    else if (question?.attributes.question_type?.includes("Dropdown")) {

      field = (
        <TextField
          defaultValue="Choose option"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><KeyboardArrowDown /></InputAdornment>,
            style:{borderRadius:'12px',padding:'0px'}
          }}
          fullWidth
          variant="outlined"
        />
      )
    } else if (question?.attributes.question_type?.includes("Check")) {
      field = <div style={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel control={<Checkbox />} label={<span style={classes.option}>{data.attributes.value}</span>} key={`checkBox-${question.id}-${index}`} disabled />)}</div>
    }
    else if (question?.attributes.question_type?.includes("Radio")) {
      field = <div style={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel value={data.attributes.value} control={<Radio />} label={<span style={classes.option}>{data.attributes.value}</span>} key={`radio-${question.id}-${index}`} disabled />)}</div>
    }

    return (
      <Box style={classes.boxWraperForServeyInput} key={`question-${question.id}-${questionIdx}`}>
        <div>
          <div style={classes.bottomBorder}>
            <Tooltip title={question.attributes.question || ""}>
              <Typography style={classes.question} >
                {question.attributes.question}
              </Typography>
            </Tooltip>
          </div>
          {field}
        </div>
      </Box>
    )
  }
 

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px"},
        }}
      >
         <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <DialogTitle className="modalDialogTitle">
                <Typography variant="subtitle1">
                  {"Survey"}
                </Typography>
              </DialogTitle>
            </div>
            <div style={{ marginRight: 20, color: '#1C2324' }}>
              <IconButton
                aria-label="close"
                data-testid="modalCloseBtnTestid"
                onClick={(e)=>{
                const linkType = 'survey'
                handleShareLinkPopup(linkType)
              }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        <DialogContent dividers className="modalBodyWrapper">
          <Box style={{ width: '480px',marginLeft:'22px', marginBottom:'15px' }}>
           <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
           <Typography variant="subtitle2"> 
             {surveyObj?.name}
            </Typography>
            <Typography variant="body2"> 
             {moment(surveyObj?.created_at).format('D MMMM YYYY')}
            </Typography>
           </div>            
           <Box style={{paddingBottom:'10px',marginLeft:'10px',marginRight:'10px'}}>
            {
              surveyObj?.questions?.data?.map((question:any, index:any) => questionSection(question, index))
            }
            </Box>
          </Box>         
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start
const classes = {
  textArea: {
    width: "100%",
    "& div": {
      padding: '0 !important',
    }
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column" as const,
  },
  boxWraperForServeyInput: {
    padding: '10px',
    borderRadius: '10px 15px',
    background: '#FCFAF7',
    border: '1px solid #F0EBE0',
    width: '100%',
    marginTop:'10px',
    questionCard: {
      width: "100%",
      minHeight: "100px",
      marginTop: "15px"
    },
  },
  questionCard: {
    width: "100%",
    minHeight: "100px",
    marginTop: "15px"
  },
  betweenCardHeading: {
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
    option: {
      color: '#1C2324',
      display: "inline-block",
      width: "200px",
      whiteSpace: "nowrap" as const,
      overflow: "hidden",
      textOverflow: "ellipsis",   
    },
  },
  bottomBorder: {
    borderBottomStyle: 'groove' as const,
    paddingBottom: '10px',
    borderColor: '#ffffff61',
    marginBottom: '15px',
  },
  question: {
    display: "inline-block",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
    padding:'0px',
    fontStyle: 'normal'
  },
  option: {
    color: '#1C2324',
    display: "inline-block",
    width: "200px",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",   
  },
};

// Customizable Area End

export default SurveyChatContent;