Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.registerFcmTokenEndPoint="admin/bx_block_admin_chat/devices"
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboardContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.adminGetUrl = "admin/show_current_admin";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTksImV4cCI6MTY4NjQ4MjYxMiwidG9rZW5fdHlwZSI6ImFkbWluX2xvZ2luIn0.bp74gMvDCZcMogDfRBGfa2a7zTTAAZEEJPPpSh8pdlkPVryevAYEoOmCBKdFuEApmH7p4hZvzbCQYikvCil_pQ";

exports.screenDefaultsTexts = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  heading1: "Your Profile",
  profileTextEmail: "Email",
  profileTextUsername: "Username",
  profileTextLocation: "Location",
  profileTextDOB: "Date of Birth",
  buttonText1: "edit",
  buttonText2: "delete"
};

exports.navigationScreenDefaults = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  logoText: "by Materra Private Limited",
  placeholderText: "What are you looking for?",
};

exports.landingScreenDefaults = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  subHeadingText: "Quick Links",
  buttonText: "add user",
};

exports.calendarScreenDefaults = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  heading1: "Calendar",
  buttonText: "ADD NEW",
  modalHeaderText: "Add New"
};
// Customizable Area End