import React from 'react';

interface CheckConditionType {
    children:   JSX.Element  ;
    condition: boolean
}

const CheckCondition = (props: CheckConditionType) => {
    const { children, condition } = props
    if (condition) {
        return children
    }
    return (
        <div style={{ fontSize: '12px' }} >No Result</div>
    )
}

export default CheckCondition;