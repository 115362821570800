import moment from 'moment';
import * as Yup from 'yup';

const mobileRegEx = /^[6789]\d{9}$/;
const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
const usernameRegex = /^[a-zA-Z0-9@_]+$/;
const nameRegex = /^[A-Za-z]+(?: ?[A-Za-z])*$/;

// YUP-SCHEMA VALIDATIONS STARTS
export const SchemaFarmerForm = (userLandTypeFarmer: any, isLandtypeIrrigation: () => {}) => Yup.object().shape({
    firstnameFarmer: Yup.string()
        .trim()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    middlenameFarmer: Yup.string()
        .trim()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Middle Name")
        .nullable(),
    surnameFarmer: Yup.string()
        .trim()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Surname Name")
        .nullable(),
    mobileNumberFarmer: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Mobile Number")
        .required("Please enter Mobile Number")
        .nullable(),
    adharcardNumberFarmer: Yup.string()
        .matches(/^\d{12}$/, "Please enter 12 digit Number Only")
        // .required("Please enter Adharcard Number")
        .nullable(),
    dateOfBirthFarmer: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    totalFamilyMemberFarmer: Yup.number()
        .integer("Value must be an number only.")
        .min(1, "Minimum required value is 1")
        .max(50, "Maximum allowed value is 50")
        .required("Please add Family Members")
        .nullable(),
    educationFarmer: Yup.string()
        .required("Please select Education")
        .nullable(),
    stateFarmer: Yup.string().required("Please select State").nullable(),
    genderFarmer: Yup.string().required("Please select Gender").nullable(),
    districtFarmer: Yup.string().required("Please select District").nullable(),
    talukaFarmer: Yup.string().required("Please select Taluka").nullable(),
    villageFarmer: Yup.string().required("Please select Village").nullable(),
    farmnameFarmer: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please add Farm Name")
        .nullable(),
    farmSurveyNumberFarmer: Yup.string()
        .matches(alphaNumericRegex, "Only alphaNumeric are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .required("Please add Farm Survey Number")
        .nullable(),
    ownershipFarmer: Yup.string()
        .required("Please select Ownership")
        .nullable(),
    totalFarmAreaFarmer: Yup.number()
        .min(0, "Minimum required value is 0 Acre")
        .max(50, "Maximum allowed value is 50 Acre")
        .required("Please add Farm Area")
        .nullable(),
    unitOfMeasureFarmer: Yup.string().required("Please select Unit").nullable(),
    horizontalDistanceFarmer: Yup.number()
        .min(0.1, "Minimum required value 0.1")
        .required("Please add Distance")
        .nullable(),
    distanceInFarmer: Yup.string().required("Please select Unit"),
    motorInHpFarmer: Yup.number()
        .required("Please add Motor in HP")
        .min(0.1, "Minimum value is 0.1")
        .max(10000, "Maximum value is 10000"),
    depthOfPumpFarmer: Yup.number()
        .required("Please add Depth of Pump")
        .min(0.1, "Value must be at least 0.1")
        .max(10000, "Value must be at most 10000"),
    soilTextureFarmer: Yup.string()
        .required("Please select Soil Texture")
        .nullable(),
    landTypeFarmer: Yup.string().required("Please select Land Type").nullable(),
    sourceOfIrrigationFarmer: Yup.string()
        .when("landTypeFarmer", {
            is: (value) =>
                value &&
                value == userLandTypeFarmer?.id &&
                isLandtypeIrrigation(),
            then: Yup.string().required("Please select Source of Irrigation"),
            otherwise: Yup.string(),
        })
        .nullable(),
    typeOfIrrigationFarmer: Yup.string()
        .when("landTypeFarmer", {
            is: (value) =>
                value &&
                value == userLandTypeFarmer?.id &&
                isLandtypeIrrigation(),
            then: Yup.string().required("Please select Type of Irrigation"),
            otherwise: Yup.string(),
        })
        .nullable(),
});

export const SchemaFieldExecutiveForm = Yup.object().shape({
    firstnameFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberFE: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Mobile Number")
        .required("Please enter Mobile Number")
        .nullable(),
    locationFE: Yup.string()
        .trim()        
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .nullable(),
    dateOfBirthFE: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    emailAddressFE: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameFE: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    mobileDeviceModelFE: Yup.string()
        .required("Please select Mobile Device")
        .nullable(),
});

export const SchemaAdminFormADD = Yup.object().shape({
    firstnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberAdmin: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Mobile Number")
        .required("Please enter Mobile Number")
        .nullable(),
    emailAddressAdmin: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameAdmin: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    dateOfBirthAdmin: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of Birth is required"),
    passwordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .required("Password is required")
        .nullable(),
    confirmPasswordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed")
        .oneOf([Yup.ref("passwordAdmin"), null], "Passwords must match")
        .required("Confirm Password is required")
        .nullable(),
});

export const SchemaAdminFormEDIT = Yup.object().shape({
    firstnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter First Name")
        .nullable(),
    lastnameAdmin: Yup.string()
        .trim()
        .matches(nameRegex, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .required("Please enter Last Name")
        .nullable(),
    mobileNumberAdmin: Yup.string()
        .matches(mobileRegEx, "Please enter 10 digit Mobile Number")
        .required("Please enter Mobile Number")
        .nullable(),
    emailAddressAdmin: Yup.string()
        .email("Please enter valid Email Address")
        .required("Please enter Email Address")
        .nullable(),
    usernameAdmin: Yup.string()
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed")
        .matches(
            usernameRegex,
            'Only alphanumeric characters, "@" and "_" are allowed'
        )
        .required("Please enter Username")
        .nullable(),
    dateOfBirthAdmin: Yup.date()
        .nullable()
        .typeError("Please enter valid Date")
        .test("valid-date", "Invalid Date", (value) => {
            if (!value) {
                return true; // Treat null as a valid input
            }
            return moment(value).isValid();
        })
        .max(
            moment().subtract(18, "years").add(1, "days").format("YYYY-MM-DD"),
            "Minimum age limit is 18 years"
        )
        .min(
            moment().subtract(120, "years").format("YYYY-MM-DD"),
            "Maximum age limit is 120 years"
        )
        .required("Date of birth is required"),
    passwordAdmin: Yup.string()
        .min(6, "Minimum 6 characters are required")
        .max(20, "Maximum 20 characters are allowed"),
    confirmPasswordAdmin: Yup.string()
        .when("passwordAdmin", {
            is: (value) => value && value.length > 1,
            then: Yup.string()
                .oneOf([Yup.ref("passwordAdmin"), null], "Passwords must match")
                .min(6, "Minimum 6 characters are required")
                .max(20, "Maximum 20 characters are allowed")
                .required("Please enter Confirm Password"),
            otherwise: Yup.string(),
        })
        .nullable(),
});
// YUP-SCHEMA VALIDATIONS ENDS

export const debounce = (cb: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => cb(...args), delay);
    };
};

export const keyDown = (evt: any) =>
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
export const keyDownInt = (evt: any) =>
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();

export const inputMinZero = {
    min:0
}

export const parseImages = (img?: string): string | undefined => {
    if (!img) return undefined;
    return img;
};
export function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getErrorMessage(touched: any, errors: any, value: string) {
    return touched[value] && Boolean(errors[value]);
};

export function getHelperText(touched: any, errors: any, value: string) {
    return touched[value] && errors[value];
};

export function stateIDStatus(data: any): string {
    return data.attributes?.state_id?.length
      ? data.attributes?.state_id[0]?.id
      : "";
}

export function distIDStatus(data: any): string {
    return data.attributes.district_id && data.attributes.district_id.length
      ? data.attributes.district_id[0]?.id
      : "";
}

export function talukaIDStatus(data: any): string {
    return data.attributes.taluka_id && data.attributes.taluka_id.length
      ? data.attributes.taluka_id[0]?.id
      : "";
}

export function VillageIDStatus(data: any): string {
    return data.attributes.village_id && data.attributes.village_id.length
      ? data.attributes.village_id[0]?.id
      : "";
}

export function educationFarmerStatus(data: any): string {
    return data.attributes.highest_education_id &&
      data.attributes.highest_education_id.length
      ? data?.attributes?.highest_education_id[0]?.id
      : "";
}

export function farmnameFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.name) ||
      ""
    );
}

export function totalFarmAreaFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.farm_area) ||
      ""
    );
}

export function unitOfMeasureFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.unit_farm_area_id
      ? data.attributes.land_details[0]?.attributes.unit_farm_area_id?.id
      : "";
}

export function horizontalDistanceFarmerStatus(data: any): string {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.horizontal_distance
      : "";
}

export function motorInHpFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.motor_horse_power) ||
      ""
    );
}

export function depthOfPumpFarmerStatus(data: any): string {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.pump_depth) ||
      ""
    );
}

export function soilTextureFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.soil_texture_id &&
      data.attributes.land_details[0]?.attributes.soil_texture_id.length &&
      data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      : "";
}

export function sourceOfIrrigationFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
        .length &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
      ? data.attributes.land_details[0].attributes.source_irrigation_id[0]?.id
      : "";
}

export function typeOfIrrigationFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id.length &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      : "";
}

export function farmSurveyNumberFarmerStatus(data: any): string {
    return data.attributes.land_details
      ? data.attributes.land_details[0]?.attributes.survey_number
      : "";
}

export function ownershipFarmerStatus(data: any): string {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.ownership_of_land_id?.id
      : "";
}

export function distanceInFarmerStatus(data: any): string {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.distance_in_id
      ? data.attributes.land_details[0]?.attributes.distance_in_id?.id
      : "";
}

export const checkUserIsLoogedIn = (userEmail: any) => {
    const loggedinUserEmail = localStorage.getItem("emailId")
    return String(userEmail) === String(loggedinUserEmail)
}

export const logoutUpdatedUser = () => {
    window.localStorage.clear()
    window.location.replace("/EmailAccountLoginBlock");
}

export const NavigationToHome = (userEmail: any, responseEmail: any) => {    
    const checkUserIsLoogedInBoolean = checkUserIsLoogedIn(userEmail)
    if (checkUserIsLoogedInBoolean) {
        const isLoggedInUserEmailUpdated = String(userEmail) !== String(responseEmail)
        if (isLoggedInUserEmailUpdated) {
            logoutUpdatedUser()
        }                        
    }
}

export function floorValueWithBase10(number: number) {
    if (isNaN(number)) {
        return 0;
    } else if (number >= 0) {
        return Math.floor(number / 10) * 10;
    } else {
        return Math.ceil(number / 10) * 10;
    }
}

export function ceilingValueWithBase10(number: number) {
    if (isNaN(number)) {
        return 0;
    } else if (number >= 0) {
        return Math.ceil(number / 10) * 10;
    } else {
        return Math.floor(number / 10) * 10;
    }
}
