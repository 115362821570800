import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  // Customizable Area Start
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { Box } from "@material-ui/core";
import moment from "moment";
import Divider from '@material-ui/core/Divider';

const arrowUpSVG = require("./arrow-up.svg");
const arrowDownSVG = require("./arrow-down.svg");

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px",
      textTransform: 'uppercase'
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px"
    },
    body1: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop: '10px',
      paddingBottom: '10px'
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});


interface CropCalendarContent {
  handleExpandDetails:() => void;
  handleShareLinkPopup: (linkType?: string, linkId?: any, accountId?: any, type?: any) => void;
  isLinkPopup: boolean;
  expandCropCalendarInputDetails: boolean;
  cropCalendarObj: any
}

const CropCalendarChatContent = (props: CropCalendarContent) => {
  const { handleExpandDetails, handleShareLinkPopup, isLinkPopup, expandCropCalendarInputDetails, cropCalendarObj } = props
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px" },
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <DialogTitle className="modalDialogTitle">
              <Typography variant="subtitle1">
                Crop Calendar 
              </Typography>
            </DialogTitle>
          </Box>
          <Box style={{ marginRight: 20, color: '#1C2324' }}>
            <IconButton
              aria-label="close"
              data-testid="modalCloseBtnTestid"
              onClick={() => {
                const linkType = 'cropcalendar'
                handleShareLinkPopup(linkType)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <DialogContent dividers className="modalBodyWrapper">
          <Box style={{ margin: '0 20px' }}>
            <Box>
              {cropCalendarObj?.image_or_video === 'Image' &&
                <img src={cropCalendarObj?.attachment?.url}
                  alt=""
                  style={{ 
                    maxHeight: '275px', 
                    width: '100%', 
                    borderRadius: '6px', 
                    boxSizing: 'border-box', 
                    marginTop: '5px' 
                  }} />
                }
              
                {cropCalendarObj?.image_or_video === "Video" &&
                  <video src={cropCalendarObj?.attachment?.url} 
                         style={{ 
                          objectFit: "cover", 
                          maxHeight: '275px', 
                          width: '100%', 
                          borderRadius: '6px', 
                          boxSizing: 'border-box',
                          marginTop: '5px' 
                          }} 
                          controls />
                  }
            </Box>
            <Box style={{ margin: '0 -10px'}}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography style={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textTransform: 'uppercase'
                }}>
                  {cropCalendarObj?.phase_sub_activity_progress_name}
                </Typography>
                <Typography style={{
                  color: '#006A77',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  lineHeight: '18px',
                }}>
                  {cropCalendarObj?.before_after_day} days before Sowing
                </Typography>
              </Box>
              <Typography style={{
                fontSize: '12px',
                fontStyle: 'normal',
                lineHeight: '18px',
                textTransform: 'uppercase'
              }}>
                  {cropCalendarObj?.phase_activity_details?.name}
                </Typography>
              <Typography variant="body2">
                {cropCalendarObj?.description}
              </Typography>
            </Box>
            {
              cropCalendarObj?.input_details && <Box style={{ width: '480px', borderRadius: '12px', boxSizing: 'border-box', background: '#F0EBE0', marginBottom: '15px' }}>

              <Button onClick={handleExpandDetails} style={{ width: '100%' }}>
                <Box style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '6px'
                }}>
                <Typography style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  lineHeight: '22px',
                  textTransform: 'none'
                 }}> Input Details </Typography>
                 <Box style={{ marginRight: '10px' }}>
                  {
                    expandCropCalendarInputDetails ? <img src={arrowUpSVG} alt="" /> : <img src={arrowDownSVG} alt="" />
                  }
                 </Box>
                </Box>
                 
               </Button>  
                <Collapse in={expandCropCalendarInputDetails}>
                <Divider style={{ margin: '0 23px 5px 23px' }} />
                  {

                    <Box style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '14px',
                      fontStyle: 'normal'
                    }}>
                    { cropCalendarObj?.input_details.map((item: any, index: number) => (
                      <Box key={index}>
                        <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '0 20px',
                                padding: '5px',
                                boxSizing: 'border-box',
                              }}>
                          <span className="item-key">{item.key}</span>
                          <span className="item-value">{item.value}</span>
                        </Box>
                      </Box>
                    ))}
                    </Box>
                  }
                </Collapse>
            </Box>  
            }
            
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start

const styles = {
  cropCalenderList : {
    display: 'flex',
    flexDirection: 'column'
  },
  cropCalenderItem: {
    marginBottom: '10px'
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    boxSizing: 'border-box',
  },
  itemKey: {

  },
  itemValue: {

  }
}

// Customizable Area End

export default CropCalendarChatContent;