Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "VideoManagement";
exports.labelBodyText = "VideoManagement Body";
exports.heading1="Materrapedia";
exports.addLibraryButtonText="ADD IN LIBRARY";
exports.addFaqButtonText="ADD IN FAQ";
exports.btnExampleTitle = "CLICK ME";
exports.librarySectionHeading="LIBRARY";
exports.faqSectionHeading="FAQs";
exports.fontFamily= "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif";
exports.materrapediaShareLink = "https://co:farm/";

exports.getMethod = "GET";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTksImV4cCI6MTY4NjQ4MjYxMiwidG9rZW5fdHlwZSI6ImFkbWluX2xvZ2luIn0.bp74gMvDCZcMogDfRBGfa2a7zTTAAZEEJPPpSh8pdlkPVryevAYEoOmCBKdFuEApmH7p4hZvzbCQYikvCil_pQ";
exports.getLibraryAPiEndPoint = "admin/bx_block_materrapedia/libraries";
exports.getLibraryByFaqIdAPiEndPoint = "admin/bx_block_materrapedia/libraries";

exports.getLibraryFaqsMethod = "GET";
exports.getLibraryFaqsAPiEndPoint = "admin/bx_block_materrapedia/faqs";
exports.GetLibraryFaqsApiContentType = "application/json";

exports.getFaqCategoryMethod = "GET";
exports.getFaqCategoryAPiEndPoint = "bx_block_materrapedia/faq_categories";
exports.GetFaqCategoryApiContentType = "application/json";

exports.LibraryApiContentType = "application/json";
exports.postMethod="POST";
exports.libraryActionsAPiEndPoint="admin/bx_block_materrapedia/libraries"

exports.postFaqMethod="POST";
exports.faqApiContentType = "application/json";
exports.faqAPiEndPoint="admin/bx_block_materrapedia/faqs"

exports.deleteMethod="DELETE"
exports.patchMethod="PATCH"

exports.patchFaqMethod="PATCH"
exports.deleteFaqMethod="DELETE"




// Customizable Area End