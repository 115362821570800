Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "exampleUrl";
// exports.exampleAPiEndPoint = "/themes/themes";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OfflineBrowsing";
exports.labelBodyText = "OfflineBrowsing Body";

exports.testApiEndpoint = "exampleUrl";
exports.testApiEndpoint = "/themes/themes";

exports.offlineText = "Offline Mode";
exports.onlineText = "Online Mode";

exports.endPointApiExample = "exampleUrl";
// exports.endPointApiExample = "themes/themes";
exports.contentTypeApiExample = "application/json";
exports.methodTypeApiExample = "GET";
// Customizable Area End