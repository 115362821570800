export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Iconsvector = require("../assets/filter_icon.svg");
export const AuioWaveFrame = require("../assets/waveform.svg");
export const deleteSVG = require("../assets/delete.svg");
export const micSVG = require("../assets/microphone.svg");
export const playSVG = require("../assets/play.svg");
export const pauseSVG = require("../assets/IconsPause.svg");
export const cameraSVG = require("../assets/camera_icon.svg");
export const groupIconSVG = require("../assets/group_icon.svg");
export const addIconSVG = require("../assets/add-icon.svg");
export const editIcon = require("../assets/edit_icon.svg");
export const audioSVG = require("../assets/audio.svg");
export const videoSVG = require("../assets/video.svg");
export const closeSVG = require("../assets/close.svg");
export const gallerySVG = require("../assets/gallery.svg");
export const documentSVG = require("../assets/document.svg");
export const whiteCameraSVG = require("../assets/white_camera.svg");
export const messageDeliveredSVG = require("../assets/message_delivered.svg");
export const messageSeenSVG = require("../assets/message_seen.svg");
export const messageSentSVG = require("../assets/message_sent.svg");

