import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IOfflineBrowsingContext } from "./OfflineBrowsingProviderController";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  exampleData: {} | null;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OfflineBrowsingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      exampleData: null,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sendNetworkRequest = (offlineBrowsingContext: IOfflineBrowsingContext) => {
    const { sendNetworkSafeApiRequest } = offlineBrowsingContext;
    sendNetworkSafeApiRequest(
      this.apiCallId,
      configJSON.methodTypeApiExample,
      configJSON.endPointApiExample,
      {
        token: this.state.token,
        "Content-Type": configJSON.contentTypeApiExample,
      }
    );
  };

  saveDataOffline = (
    offlineBrowsingContext: IOfflineBrowsingContext,
    dataToSave: Object
  ) => {
    const { setNetworkSafeState, offlineState } = offlineBrowsingContext;

    const exampleData = JSON.stringify(dataToSave);

    setNetworkSafeState("exampleData", exampleData);
  };

  getSavedData = async (offlineBrowsingContext: IOfflineBrowsingContext) => {
    const { getNetworkSafeState } = offlineBrowsingContext;

    const exampleData = await getNetworkSafeState("exampleData");

    this.setState({ exampleData: JSON.parse(exampleData) });
  };

  // Customizable Area End
}
