import React from 'react'

import {
  Box,
  Input,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  FormLabel,
  MenuItem,
  Avatar,
  Chip
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { imgIconFilter, smallCloseIconSVG } from '../../blocks/AdminConsole/src/assets';
import Autocomplete from "@material-ui/lab/Autocomplete";

interface CustomFEUserForm {
  feInitialValues: any,
  validationSchemaFEobj: any,
  handleSubmitFEUser: (value: any) => void,
  getErrorMessage: (touched: any, errors: any, value: string) => boolean,
  getHelperText: (touched: any, errors: any, value: string) => boolean,
  getCustomErrorMessage: (touched: any, errors: any, value: string) => any,
  getSubmitFormBtnTxt: () => string,
  getMobileDeviceModelFEData: any[],
  showTableFilterModal: () => void
  anchorCurrentTarget: (event: any) => void
  selectedLocationArr: any[];
  getLocationsListFEData: any[];
  onFeLocationsChange: (event: any, values: any) => void,
  assignVillagePlaceholder: () => string,
  villageNameAvatar: (index: number, option: any) => JSX.Element,
  handleVillageDelete: (event: any, option: any) => void,
  disableSubmitBtn?:boolean
}

const FieldExecutiveForm = (props: CustomFEUserForm) => {
  const { feInitialValues,
    validationSchemaFEobj,
    handleSubmitFEUser,
    getErrorMessage,
    getCustomErrorMessage,
    getLocationsListFEData,
    getHelperText,
    getSubmitFormBtnTxt,
    getMobileDeviceModelFEData,
    showTableFilterModal,
    anchorCurrentTarget,
    selectedLocationArr,
    onFeLocationsChange,
    assignVillagePlaceholder,
    villageNameAvatar,
    handleVillageDelete,
    disableSubmitBtn=false } = props

  return (
    <>
      <Formik
        data-testid="fieldExecutiveFormikTestid"
        initialValues={feInitialValues}
        validationSchema={validationSchemaFEobj}
        onSubmit={(values) => {
          handleSubmitFEUser(values)
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            className="customFormSpacing"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="firstnameFEId" className="customLabelField">
                  First Name
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    style={webStyle.customInputField}
                    placeholder="Enter First Name"
                    type="text"
                    name="firstnameFE"
                    variant="outlined"
                    color="secondary"
                    value={values.firstnameFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "firstnameFE"
                    )}
                    helperText={getHelperText(
                      touched,
                      errors,
                      "firstnameFE"
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="lastnameFEId" className="customLabelField">
                  Last Name
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    style={webStyle.customInputField}
                    placeholder="Enter Last Name"
                    type="text"
                    name="lastnameFE"
                    variant="outlined"
                    color="secondary"
                    value={values.lastnameFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "lastnameFE"
                    )}
                    helperText={getHelperText(
                      touched,
                      errors,
                      "lastnameFE"
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="mobileNumberFEId" className="customLabelField">
                  Mobile Number
                </FormLabel>
                <FormControl fullWidth>
                  <Input
                    disableUnderline
                    fullWidth
                    style={{
                      marginTop: "8px",
                      padding: "8px 8px 8px 0",
                      background: "#FCFAF7",
                      borderRadius: "12px",
                      border: "1px solid #F0EBE0",
                      height:"56px"
                    }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="custom-mobile-input"
                    name="mobileNumberFE"
                    startAdornment={
                      <InputAdornment position="start">
                        <Box
                          style={{
                            padding: "0 16px",
                            borderRight: "1px solid #F0EBE0",
                          }}
                        >
                          <p>+91</p>
                        </Box>
                      </InputAdornment>
                    }
                    value={values.mobileNumberFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "mobileNumberFE"
                    )}
                  />
                  {getCustomErrorMessage(
                    touched,
                    errors,
                    "mobileNumberFE"
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="locationFEId" className="customLabelField">
                  Location
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    style={webStyle.customInputField}
                    placeholder="Enter Village, State"
                    type="text"
                    name="locationFE"
                    variant="outlined"
                    color="secondary"
                    value={values.locationFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "locationFE"
                    )}
                    helperText={getHelperText(
                      touched,
                      errors,
                      "locationFE"
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="dateOfBirthFEId" className="customLabelField">
                  Date of Birth
                </FormLabel>
                <FormControl fullWidth style={{ marginTop: "8px" }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className="customDatePicker"
                      clearable
                      data-testid="dateOfBirthFETestid"
                      value={values.dateOfBirthFE}
                      placeholder="DD/MM/YYYY"
                      onChange={(date) =>
                        setFieldValue("dateOfBirthFE", date)
                      }
                      maxDate={moment().subtract(18, "years").toDate()}
                      invalidDateMessage={`Invalid date`}
                      format="DD/MM/yyyy"
                      inputVariant="outlined"
                      color="secondary"
                      name="dateOfBirthFE"
                      error={getErrorMessage(
                        touched,
                        errors,
                        "dateOfBirthFE"
                      )}
                      helperText={getHelperText(
                        touched,
                        errors,
                        "dateOfBirthFE"
                      )}
                      keyboardIcon={
                        <DateRangeIcon style={webStyle.customDateIcon} />
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="emailAddressFEId" className="customLabelField">
                  Email Address
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    style={webStyle.customInputField}
                    placeholder="Enter Email Id"
                    type="email"
                    name="emailAddressFE"
                    variant="outlined"
                    color="secondary"
                    value={values.emailAddressFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "emailAddressFE"
                    )}
                    helperText={getHelperText(
                      touched,
                      errors,
                      "emailAddressFE"
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel id="usernameFEId" className="customLabelField">
                  Username
                </FormLabel>
                <FormControl fullWidth>
                  <TextField
                    style={webStyle.customInputField}
                    placeholder="Enter Username"
                    type="text"
                    name="usernameFE"
                    variant="outlined"
                    color="secondary"
                    value={values.usernameFE}
                    onChange={handleChange}
                    error={getErrorMessage(
                      touched,
                      errors,
                      "usernameFE"
                    )}
                    helperText={getHelperText(
                      touched,
                      errors,
                      "usernameFE"
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="formfieldWrapper"
              >
                <FormLabel
                  id="mobileDeviceModelFEId"
                  className="customLabelField"
                >
                  Mobile Device Model
                </FormLabel>
                <FormControl fullWidth>
                  <Select
                    value={values.mobileDeviceModelFE}
                    variant="outlined"
                    className="mt-8 farmerFormDDClass"
                    name="mobileDeviceModelFE"
                    color="secondary"
                    error={getErrorMessage(
                      touched,
                      errors,
                      "mobileDeviceModelFE"
                    )}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <span className="opcity-48">Select Device</span>
                    </MenuItem>
                    {getMobileDeviceModelFEData.length > 0 &&
                      getMobileDeviceModelFEData.map(
                        (ech: any) => (
                          <MenuItem
                            key={ech.id}
                            value={ech.id}
                            data-testid={`getMobileDeviceModelFEData-${ech.id}`}
                          >
                            {ech?.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  {getCustomErrorMessage(
                    touched,
                    errors,
                    "mobileDeviceModelFE"
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid style={{ paddingRight: "0px" }}
                xs={11}
                sm={11}
                md={11}
                lg={11}
                xl={11}
              >
                <FormLabel id="assignVillageFEId" className="customLabelField">
                  Assign Village
                </FormLabel>
                <FormControl fullWidth>
                  <Autocomplete
                    className="customAutoComplete mt-8"
                    multiple
                    data-testid="assigneVillageTestid"
                    options={getLocationsListFEData}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedLocationArr}
                    onChange={onFeLocationsChange}
                    filterSelectedOptions
                    ChipProps={{
                      avatar: (
                        <Avatar
                          style={{
                            background: "#108B88",
                            color: "#fff",
                          }}
                        />
                      ),
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="secondary"
                        placeholder={assignVillagePlaceholder()}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          style={{
                            backgroundColor: "#F0EBE0",
                            color: "#1C2324",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                          }}
                          key={option.id}
                          avatar={villageNameAvatar(index, option)}
                          label={option.name}
                          {...getTagProps({ index })}
                          data-testid={`villageDeleteTestid-${index}`}
                          onDelete={(event: any) =>
                            handleVillageDelete(event, option)
                          }
                          deleteIcon={<img src={smallCloseIconSVG} />}
                        />
                      ));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                style={{
                  marginTop: "18px",
                  padding: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Box>
                  <IconButton
                    data-testid="iconBtnFE"
                    onClick={(event) => {
                      anchorCurrentTarget(event)
                      showTableFilterModal();
                    }}
                  >
                    <img src={imgIconFilter} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Box className="modalSubmitFEButtonWrapper">
              <Button
                variant="contained"
                className="modalSubmitButton"
                fullWidth
                type="submit"
                disabled={disableSubmitBtn}
              >
                {getSubmitFormBtnTxt()}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}


// Customizable Area Start
const webStyle = {
  center: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItem: "center",
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  tableWrapper: {
    background: "#F8F6F1",
    border: "2px",
    borderRadius: "12px",
  },
  tableFilterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "25px",
  },
  mainTable: {
    height: "583px",
  },
  maincard: {
    padding: "12px 15px",
    background: "#F8F6F1",
    border: "2px solid #F0EBE0",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageIcons: {
    width: "107px",
    height: "96px",
  },
  font: {
    position: "relative",
    "&::after": {
      content: "' '",
      width: "2px",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "-10px",
      background:
        "linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)",
    },
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  customInputField: {
    margin: "8px 0 0 0",
  },
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  },
  hide: {
    display: "none",
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: 0.5,
  },
  badgeCardRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
    marginTop: "8px",
    marginBottom: "20px",
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "0.375rem",
  },
  addUserModalBtnStyle: {
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: "14px 32px",
    boxShadow: "none",
  }
};
// Customizable Area End

export default FieldExecutiveForm;