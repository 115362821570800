import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface StateList {
    id: number;
    name: string;
    name_english: string;
    name_hindi: string;
    name_gujrati: string;
    active: boolean;
    created_at: string;
    updated_at: string;
}
interface DistrictList {
    id: number;
    state_id: number
    name: string;
    name_english: string;
    name_hindi: string;
    name_gujrati: string;
    active: boolean;
    created_at: string;
    updated_at: string;
}
interface TalukaList {
    id: number;
    district_id: number
    name: string;
    name_english: string;
    name_hindi: string;
    name_gujrati: string;
    active: boolean;
    created_at: string;
    updated_at: string;
}
interface VillageList {
    id: number;
    taluka_id: number;
    name: string;
    name_english: string;
    name_hindi: string;
    name_gujrati: string;
    active: boolean;
    created_at: string;
    updated_at: string;
}
interface S {

    // Customizable Area Start
    userID: null | string;
    dropDownTitle: string;
    TokenValue: string | null;
    dropDownData: any[];
    dropdownAPILoading: boolean;
    dropdownFilteredData: any[];
    errorMsg: string;
    loading: boolean;
    selectedId: number;
    selectedSubId: number;
    selectedKeyName: string;
    selectedData: any;
    section1InputsEnglish: string[];
    section2InputsHindi: string[];
    section3InputsGujrati: string[];
    stateList: StateList[],
    districtList: DistrictList[],
    talukaList: TalukaList[],
    villageList: VillageList[],
    selectedState: any,
    selectedDistrict: any,
    selectedTaluka: any,
    selectedVillage: any,
    filteredStates: any[],
    filteredDistricts: any[],
    filteredTalukas: any[],
    filteredVillages: any[],
    initialStates: any[],
    initalDistricts: any[],
    initialTalukas: any[],
    initialVillages: any[],
    specificDropDownData: any[],
    error: string,
    errorType: string,
    idUnsuccessfull: any,
    searchInput: string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
type BodyData = {
    drop_down: {
        [key: string]: Array<{
            name: string;
            name_english: string;
            name_hindi: string;
            name_gujrati: string;
            active: boolean;
        }>;
    };
};
export default class DropdownController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getDropdownApiCall: string = "";
    addDropdownApiCall: string = "";
    getStateListApiCallId: string = "";
    getDistrictListApiCallId: string = "";
    getTalukaListApiCallId: string = "";
    getVillageListApiCallId: string = "";
    getSpecificDropdownApiCall: string = "";
    getStateData: string = "";
    getDistrictData: string = "";
    getTalukaData: string = "";
    getVillageData: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {

            // Customizable Area Start
            searchInput: '',
            idUnsuccessfull: null,
            userID: localStorage.getItem("userID"),
            dropDownTitle: configJSON.dropDownTitle,
            TokenValue: localStorage.getItem("tokenn"),
            dropDownData: [],
            dropdownAPILoading: false,
            dropdownFilteredData: [],
            errorMsg: "",
            selectedId: -1,
            selectedSubId: -1,
            selectedKeyName: "",
            selectedData: [],
            loading: false,
            section1InputsEnglish: [''],
            section2InputsHindi: [''],
            section3InputsGujrati: [''],
            stateList: [],
            districtList: [],
            talukaList: [],
            villageList: [],
            specificDropDownData: [],
            selectedState:
            {
                name: "Select State",
                id: "",
            },
            selectedDistrict: {
                name: "Select District",
                id: "",
            },
            selectedTaluka: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillage: {
                name: "Select Village",
                id: "",
            },
            filteredStates: [],
            filteredDistricts: [],
            filteredTalukas: [],
            filteredVillages: [],
            initialStates: [],
            initalDistricts: [],
            initialTalukas: [],
            initialVillages: [],
            error: '',
            errorType: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    fetchLists = () => {
        this.getStateList();
        this.getDistrictList();
        this.getTalukaList();
        this.getVillageList();
    };
    getFilterListCall = ({ successResponse, errorResponse, callId }: any) => {

        if (successResponse?.errors) {
            const [error] = successResponse.errors
            this.setState({
                error: error,
                errorType: "Warning !"
            });

        } else {
            let data: any = successResponse;
            if (data) {
                this.apiRequestCallGetFilterListCallResponse(data, callId);
            } else {
                this.setState({
                    error: 'Something went wrong, data not found',
                    errorType: "Failed !"
                });
            }
        }
    }
    apiRequestCallGetFilterListCallResponse = (data: any, callId: string) => {
        let key: "stateList" | "talukaList" | "villageList" | "districtList" | "";
        switch (callId) {
            case this.getStateListApiCallId:
                key = "stateList"
                this.setState({ [key]: data });
                break;
            case this.getTalukaListApiCallId:
                key = "talukaList"
                this.setState({ [key]: data });
                break;
            case this.getVillageListApiCallId:
                key = "villageList"
                this.setState({ [key]: data });
                break;
            case this.getDistrictListApiCallId:
                key = "districtList"
                this.setState({ [key]: data });
                break;
        }
    }
    handleResetSection = () => {
        this.setState({
            section1InputsEnglish: [''],
            section2InputsHindi: [''],
            section3InputsGujrati: ['']
        })
    }
    handleSelectData = (data: any) => {
        this.setState({ selectedData: data })
        this.setState({
            selectedSubId: -1,
            selectedKeyName: ""
        })
    }
    extractMessage = (data: any) => {
        const result = data.find((item: any) => item.hasOwnProperty('message'))

        if (result) {
            const words = result.message.split(' ');
            if (!isNaN(words[0])) {
                words.shift();
            }
            this.setState({
                error: words.includes("true") ? "updated successfully" : words.join(' '),
                errorType: "Warning !",
                section1InputsEnglish: [""],
                section2InputsHindi: [""],
                section3InputsGujrati: [""]
            }, () => {
                // This callback function will be called after the state is updated.
                this.getSpecificList();
            }
            );
        }
    }
    handleDataCrud = (data: any) => {
        if (data) {
            this.setState({
                error: 'Updated Successfully',
                errorType: "Success"
            });
            this.setState({
                section1InputsEnglish: [""],
                section2InputsHindi: [""],
                section3InputsGujrati: [""]
            }, () => {
                // This callback function will be called after the state is updated.
                this.getSpecificList();
            }
            );
        } else {
            this.setState({
                error: 'Something went wrong, data not found',
                errorType: "Failed !"
            });
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        // Customizable Area Start
        if (this.isFilterListApiCall(apiRequestCallId)) {
            this.handleFilterListResponse(responseJson, errorResponse, apiRequestCallId);
        } else if (apiRequestCallId === this.getDropdownApiCall) {
            this.getDropdownDataApiCallIdResp(responseJson);
        } else if (apiRequestCallId === this.getSpecificDropdownApiCall) {
            this.handleSpecificDropdownApiResponse(responseJson);
        } else if (apiRequestCallId === this.addDropdownApiCall) {
            this.handleAddDropdownApiResponse(responseJson, successResponse);
        }
        // Customizable Area End
    }

    isFilterListApiCall(apiRequestCallId: any) {
        return (
            apiRequestCallId === this.getVillageListApiCallId ||
            apiRequestCallId === this.getStateListApiCallId ||
            apiRequestCallId === this.getTalukaListApiCallId ||
            apiRequestCallId === this.getDistrictListApiCallId
        );
    }

    handleFilterListResponse(responseJson: any, errorResponse: any, apiRequestCallId: any) {
        if (!this.isFilterListApiCall(apiRequestCallId)) {
            return;
        }
        this.getFilterListCall({ successResponse: responseJson, errorResponse, callId: apiRequestCallId });
    }

    handleSpecificDropdownApiResponse(responseJson: any) {
        this.setState({ specificDropDownData: responseJson[0][1],loading:false });
    }

    handleAddDropdownApiResponse(responseJson: any, successResponse: any) {
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            return;
        }

        const hasValidMessages = responseJson.some(
            (item) =>
                'message' in item &&
                typeof item.message === 'string' &&
                item.message.trim() !== ''
        );

        if (hasValidMessages) {
            this.extractMessage(responseJson);
        } else {
            this.handleDataCrud(successResponse);
        }

    }


    // web events

    // Customizable Area Start


    extractDataAboveHeading = (data: any) => {
        const extractedArrays = [];
        let currentArray = [];

        for (const obj of data) {
            if ("Heading" in obj) {
                if (currentArray.length > 0) {
                    const keyName = currentArray[0].Heading;
                    extractedArrays.push({ [keyName]: currentArray });
                }
                currentArray = [];
            }

            currentArray.push(obj);
        }

        if (currentArray.length > 0) {
            const keyName = currentArray[0].Heading;
            extractedArrays.push({ [keyName]: currentArray });
        }

        return extractedArrays;
    };
    getDropdownDataApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null) {
            this.setState({
                dropDownData: responseJson,
            });
            const filteredData = this.extractDataAboveHeading(responseJson)
            this.setState({ dropdownFilteredData: filteredData })
            this.setState({ dropdownAPILoading: false });
        } else {
            this.setState({
                dropDownData: [],
            });
            this.setState({ dropdownAPILoading: false });
        }
    };
    handleAccordion = (id: number) => {
        this.setState(({ selectedId }) => ({
            selectedId: selectedId === id ? -1 : id
        }))
    }
    handleSubAccordion = (id: number, keyName: string) => {
        this.setState(({ selectedSubId }) => ({
            selectedSubId: selectedSubId === id ? -1 : id,
            selectedKeyName: keyName
        }), () => {
            this.getSpecificList(); // Call your function after state is updated
        });
    }

    updateInput = (searchInput : string) => this.setState({ searchInput });

    async componentDidMount(): Promise<void> {
        this.fetchLists();
        this.fetchDropDownData();
        this.getStateList();
        this.getTalukaList();
        this.getDistrictList();
        this.getVillageList();

    }

    handleInputChange = (section: number, index: number, value: string) => {
        const { section1InputsEnglish, section2InputsHindi, section3InputsGujrati } = this.state;
        let newInputs: string[] = [];
        switch (section) {
            case 1:
                newInputs = [...section1InputsEnglish];
                newInputs[index] = value;
                this.setState({ section1InputsEnglish: newInputs });
                break;
            case 2:
                newInputs = [...section2InputsHindi];
                newInputs[index] = value;
                this.setState({ section2InputsHindi: newInputs });
                break;
            case 3:
                newInputs = [...section3InputsGujrati];
                newInputs[index] = value;
                this.setState({ section3InputsGujrati: newInputs });
                break;
            default:
                break;
        }
    };

    handleAddInput = (section: number) => {
        const { section1InputsEnglish, section2InputsHindi, section3InputsGujrati } = this.state;
        let newInputs: string[] = [];
        let newInputs1: string[] = [];
        let newInputs2: string[] = [];
        newInputs = [...section1InputsEnglish, ''];
        this.setState({ section1InputsEnglish: newInputs });
        newInputs1 = [...section2InputsHindi, ''];
        this.setState({ section2InputsHindi: newInputs1 });
        newInputs2 = [...section3InputsGujrati, ''];
        this.setState({ section3InputsGujrati: newInputs2 });
    };

    handleRemoveInput = (section: number, index: number) => {
        const { section1InputsEnglish, section2InputsHindi, section3InputsGujrati } = this.state;
        let newInputs: string[] = [];
        let newInputs1: string[] = [];
        let newInputs2: string[] = [];
        newInputs = [...section1InputsEnglish];
        newInputs.splice(index, 1);
        this.setState({ section1InputsEnglish: newInputs });
        newInputs1 = [...section2InputsHindi];
        newInputs1.splice(index, 1);
        this.setState({ section2InputsHindi: newInputs1 });
        newInputs2 = [...section3InputsGujrati];
        newInputs2.splice(index, 1);
        this.setState({ section3InputsGujrati: newInputs2 });
    };

    // error responses functions
    handleErrorResponse = (errorReponse: any) => {
        if (errorReponse === undefined) {
            this.setState({
                errorMsg: "Something went wrong",
                loading: false
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
                loading: false
            });
        }
    }
    convertToSnakeCase(str: any) {
        return str
            .toLowerCase()
            .replace(/\s+/g, '_') // Replace spaces with underscores
            .replace(/[^a-z0-9_]/g, ''); // Remove any characters that are not letters, numbers, or underscores
    }
    fetchDropDownData = () => {
        this.setState({
            dropdownAPILoading: true,
        });
        let endpoint: string = `${configJSON.getDropdownAPIEndPoint}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getDropdownApiCall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getTableAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    addDropDownData = (data: any) => {
        this.setState({
            loading: true
        });
        let endpoint: string = `${configJSON.getDropdownAPIEndPoint}/${this.state.userID}`;


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue,
        };
        const outputString = this.convertToSnakeCase(this.state.selectedKeyName);
        let bodyData: BodyData = {
            drop_down: {
                [outputString]: data,
            },
        };

        this.addDropdownApiCall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyData)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PATCH_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getStateList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "Accept": configJSON.acceptAll,
            token: this.state.TokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getStateListApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getStateListApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getTalukaList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "Accept": configJSON.acceptAll,
            token: this.state.TokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTalukaListApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTalukaListApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDistrictList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "Accept": configJSON.acceptAll,
            token: this.state.TokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDistrictListApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDistrictListApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getVillageList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "Accept": configJSON.acceptAll,
            token: this.state.TokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getVillageListApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getVillageListApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleErrorClear = () => {
        this.setState({ error: "", errorType: "" })
    }
    getSpecificList = async () => {
        let endpoint: string = `${configJSON.getDropdownAPIEndPoint}?name=${this.state.selectedKeyName}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getSpecificDropdownApiCall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getTableAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
