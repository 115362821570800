import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ClassNameMap, ClassKeyOfStyles } from "@material-ui/styles";
export interface SurveyListType {
  id: string;
  attributes: {
    name: string;
    name_hindi: string;
    name_gujarati: string;
    active: boolean;
    crop_season_id: number;
    submitted_by: string;
    created_at?: string;
    survey_response_count: number;
  };
}
interface OptionPercentageType  {
  id: number,
  percentage: number,
  user_response_count: number, 
  value:  string,
}
export interface QuestionType {
  question_id: string;
  question: string;
  question_type: string;
  answers: Array<string>;
  option_percentage: Array<OptionPercentageType>;
}
export interface SurveyResultType {
  id: string;
  type: string;
  attributes: {
    survey_id: string;
    survey_name: string;
    created_at: string;
    response: Array<QuestionType>;
  };
}

export interface ChartDataType {
  name: string;
  value: number;
  responseCount: number;
}

export const COLORS = ["#b3261e", "#eb8f06", "#006a77", "#FF8042", "#07B180", "#9F07B1"];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  surveyList: Array<SurveyListType>;
  surveyPage: number;
  activeSurvey: SurveyListType | null;
  surveyResult: SurveyResultType | null;
  deletePopup: string | null;
  popupDescription: string | null;
  popupTitle: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleUserSurveyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  surveyListId = "";
  surveyResultId = "";
  deleteSurveyId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      surveyList: [],
      surveyPage: 1,
      activeSurvey: null,
      surveyResult: null,
      deletePopup: "",
      popupDescription: null,
      popupTitle: "Error",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson !== null) {
        switch (apiRequestCallId) {
          case this.surveyResultId:
            return this.setState({ surveyResult: responseJson?.data[0] });
          case this.surveyListId:
            return this.handleSurveysListResponse(responseJson);
          case this.deleteSurveyId:
            return this.handleDeleteSurvey();
          default:
            return null;
        }
      } else if (errorResponse !== null) {
        this.setState({
          popupDescription:
            errorResponse.toString() || "Something is not Right!",
          popupTitle: "Error",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getSurveysList();
  }

  handleActiveSurveys = (data: SurveyListType) =>
    this.setState({ activeSurvey: data }, () => this.getSurveysResult());

  toggleDeletePopup = (deletePopup: string | null) => 
    this.setState({ deletePopup });

 getChartData = (option_percentage: Array<OptionPercentageType>) => {
  const result: Array<{ name: string; value: number; responseCount: number, color :  string }> =
    [];

  option_percentage.forEach(
    (values) =>
      values.percentage > 0 &&
      result.push({
        name: values.value,
        value: values.percentage,
        responseCount: values.user_response_count,
        color: COLORS[result.length > 2 ? result.length % COLORS.length : result.length]
      })
  );
  return result;
};

handleSurveysListResponse = (surveys: any) => {
  const surveyList = [...this.state.surveyList, ...surveys.data]
  const activeSurvey = surveyList[0];
  this.setState(prev => ({
    ...prev,
    surveyList: [...surveyList],
    activeSurvey: activeSurvey,
    surveyPage: prev.surveyPage + 1
  }),
  () => this.getSurveysResult() 
  );
}

handleMoreSurveysList = () => {
  this.getSurveysList();
}

  //Api Call
  getSurveysList = () => {
    const endpoint = `${configJSON.SURVEYS_LIST}?per_page=7&page=${this.state.surveyPage}`;
    this.surveyListId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      endpoint
    );
  };

  getSurveysResult = () => {
    this.surveyResultId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      `${configJSON.GET_SURVEYS_RESULT}${this.state.activeSurvey?.id}?per_page=3&page=1`
    );
  };

  handleDelete = (id: string) => {
    this.deleteSurveyId = this.apiCall(
      configJSON.DELETE_METHOD_TYPE,
      `${configJSON.SURVEYS_LIST}/${id}`
    );
  };

  handleDeleteSurvey = () => {
    const updatedSurveyList = this.state.surveyList?.filter(survey => survey.id != this.state.deletePopup);
    this.setState({ surveyList: updatedSurveyList });
    if (this.state.activeSurvey?.id == this.state.deletePopup) {
      this.setState({ activeSurvey: this.state.surveyList[0], deletePopup: null },
                    () => this.getSurveysResult());
    }
    this.setState({ deletePopup: null });
  }

  apiCall = (method: string, endpoint: string, body?: string) => {
    const header = {
      token: localStorage.getItem("tokenn"),
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
