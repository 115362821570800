import React from 'react';
import { Chip, Avatar } from "@material-ui/core";
import {
  Close
} from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

const colors =  ["#6a7d31", "#EB8F06", "#108b88"]

const useStyles = makeStyles({
    customChip:{
        color: '#1C2324',
        fontSize: '16px',
        fontWeight: 400,
        backgroundColor: '#F0EBE0',
        marginTop: '5px',
        marginRight: '7px',
        marginBottom: '5px'
    },
    roleType:{
        marginLeft: '5px',
        fontSize: '12px',
        color: 'gray'
    }
  });

export const CustomChip = (props: any) => {
    const classes = useStyles();

    const { label, roleType, index } = props
    const  nameArr = label?.split(' ');
        const tag = nameArr &&  (nameArr?.length > 2 ? `${nameArr[0][0]} ${nameArr[1][0]}` : `${nameArr[0][0]}`);
    
    return (
        <Chip
            className={classes.customChip}
            {...props}
            avatar={
                <Avatar className='autocompleteAvatart' style={{ textTransform: "uppercase", width: "30px", height: "30px", marginLeft: "2px", color: "#fff", background: colors[ (index + 1)  % colors.length ] }}>
                    {tag}
                </Avatar>
            }
            label={
                <div >
                   <span>{label}</span> 
                    <span className={classes.roleType} >{roleType}</span>
                </div>
            }
            deleteIcon={<Close />}
        />
    );
};