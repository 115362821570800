import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";
import { getBase64 } from "../../../components/src/helper";
const userCurrentLocation = localStorage.getItem('userLocationFarmMapping');
const initalValueUserLocation = userCurrentLocation ? JSON.parse(userCurrentLocation) : { lat: 22.7, lng: 75.8 }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    TokenValue: string | null;
    farmerData: any[];
    farmerDataLoading: boolean;
    farmDiaryLoading: boolean;
    userFarmDiary: any;
    searchFarmerName: string;
    preSowing: any[];
    sowing: any[];
    gapFilling: any[];
    weedManagement: any[];
    nutrientManagement: any[];
    pestManagement: any[];
    irrigation: any[];
    harvest: any[];
    sell: any[];
    typeOfIrrigationDD: any;
    preSowingActivityData: any[];
    cropNameData: any[];
    unitFarmAreaData: any[];
    CropSeasonData: any[];
    CropTypeData: any[];
    VarietyData: any[];
    SeedSourceData: any[];
    BioAgentNameData: any[];
    BioAgentReleaseData: any[];
    TypeOfTrapsData: any[];
    WeedingTypeData: any[];
    WeedicideNameData: any[];
    NutrientManagementData: any[];
    UnitofMeasureData: any[];
    PestManagementData: any[];
    PesticideNameData: any[];
    SourceofIrrigationData: any[];
    TypeofIrrigationData: any[];
    storageTypeData: any[];
    vehicleTypeData: any[];
    updateBtnLoading: boolean;
    deletePopup: string | null;
    errorPopup: string | null;
    popupTitle: string;
    // FARMER INTERFACE STARTS
    isFilterOpen: boolean,
    anchorEl: any,
    expandedFL: any;
    savedSortAscendingFL: boolean,
    savedSortDescendingFL: boolean,
    savedIsDisabledFL: any,
    savedStateFL: any,
    savedDistrictFL: any,
    savedTalukaFL: any,
    savedVillageFL: any,
    savedFieldExecutiveFL: any,
    sortAscendingFL: boolean,
    sortDescendingFL: boolean,
    initialStatesFL: any[],
    initalDistrictsFL: any[],
    initialTalukasFL: any[],
    initialVillagesFL: any[],
    initialFieldExectiveFL: any[],
    filteredStatesFL: any[],
    filteredDistrictsFL: any[],
    filteredTalukasFL: any[],
    filteredVillagesFL: any[],
    filteredFieldExecutiveFL: any[],
    selectedStateFL:
    {
        name: "Select State",
        id: "",
    },
    selectedDistrictFL: {
        name: "Select District",
        id: "",
    },
    selectedTalukaFL: {
        name: "Select Taluka",
        id: "",
    },
    selectedVillageFL: {
        name: "Select Village",
        id: "",
    },
    selectedFieldExecutiveFL: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
    },
    stateInputValueFL: "",
    districtInputValueFL: "",
    talukaInputValueFL: "",
    villageInputValueFL: "",
    fieldExecutiveInputValueFL: "",
    isDisabledFL: {
        state: boolean,
        district: boolean,
        taluka: boolean,
        village: boolean,
        fe: boolean,
    },
    filterHasErrorFL: boolean
    hideBadgeFL: boolean
    // FARMER INTERFACE ENDS
    pageCountFarmer:number
    [key: string]: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
interface SetFieldErrorParams {
    sectionName: string;
    fieldName: string;
    isError: boolean;
    errors: any
}
const debounce = (cb: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => cb(...args), delay)
    }
}

// Customizable Area End


export default class AdminConsoleUserFarmDiaryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFarmerApiCallId: string = ""
    getUserFarmDiaryApiCallId: string = ""
    getPreSowingActivityApiCallId: string = ""
    getCropNameApiCallId: string = ""
    getUnitFarmAreaApiCallId: string = ""
    getCropSeasonApiCallId: string = ""
    getCropTypeApiCallId: string = ""
    getVarietyApiCallId: string = ""
    getSeedSourceApiCallId: string = ""
    getBioAgentNameApiCallId: string = ""
    getBioAgentReleaseApiCallId: string = ""
    getTypeOfTrapsApiCallId: string = ""
    getWeedingTypeApiCallId: string = ""
    getWeedicideNameApiCallId: string = ""
    getNutrientManagementApiCallId: string = ""
    getUnitofMeasureApiCallId: string = ""
    getPestManagementApiCallId: string = ""
    getPesticideNameApiCallId: string = ""
    getSourceofIrrigationApiCallId: string = ""
    getTypeofIrrigationApiCallId: string = ""
    getStorageTypeApiCallId: string = ""
    getVehicleTypeApiCallId: string = ""
    getUpdateFarmDiaryApiCallId: string = ""
    // FARMER FILTER STARTS
    getFieldExecutiveDataApiFLCallId: string = "";
    getVillageDataApiFLCallId: string = "";
    getTalukaDataApiFLCallId: string = "";
    getDistrictDataApiFLCallId: string = "";
    getStateDataApiFLCallId: string = "";
    // Customizable Area End

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            popupTitle: "Error",
            errorPopup: null,
            TokenValue: localStorage.getItem("tokenn"),
            farmerData: [],
            farmerDataLoading: true,
            farmDiaryLoading: true,
            userFarmDiary: undefined,
            searchFarmerName: "",
            typeOfIrrigationDD: "",
            radioNonGMO: "",
            deletePopup: null,
            preSowing: [{
                "id": "",
                "type": "",
                "attributes": {
                    "date": new Date(),
                    "cost": "",
                    "compost_cost": "",
                    "labor_cost": "",
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "account_id": null,
                    "land_detail_id": null,
                    "total_expenses": "",
                    "type": "",
                    "crop_season_id": [],
                    "pre_sowing_activity_id": []
                },
                "errors": {}
            }],
            sowing: [{
                "id": "",
                "type": "sowing",
                "attributes": {
                    "date_of_sowing": new Date(),
                    "crop_name_id": [],
                    "crop_area": null,
                    "unit_farm_area_id": [],
                    "crop_season_id": [],
                    "crop_type_id": [],
                    "crop_mapping": null,                    
                    "seed_price": null,
                    "gmo_seed": true,
                    "seed_treatment": true,
                    "bio_agent_id": "",
                    "bio_agent_cost": null,
                    "account_id": null,
                    "latitude_longitude": null,
                    "variety_id": [],
                    "seed_source_id": [],
                    "number_of_packet": null,
                    "quantity_in_kg_other_crop": null,
                    "land_detail_id": null,
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::Sowing",                    
                    "latlngHash": null,            
                    "coordinates": [],
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "labor_cost": null,                    
                    "markerDefault": null,
                    "crop_mapping_file": null,                    
                },
                "errors": {}
            }],
            gapFilling: [{
                "id": "",
                "type": "gap_filling",
                "attributes": {
                    "date": new Date(),
                    "labor_cost": "",
                    "price_of_seed": "",
                    "account_id": null,
                    "land_detail_id": null,
                    "crop_season_id": [],
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::GapFilling"
                },
                "errors": {}
            }],
            weedManagement: [{
                "id": "",
                "type": "weed_management",
                "attributes": {
                    "weeding_date": new Date(),
                    "machine_charges": "",
                    "labor_cost": "",
                    "quantity_of_weedicide": null,
                    "cost_of_weedicide": "",
                    "labor_cost_of_spraying": "",
                    "weeding_type_id": null,
                    "weedicide_id": null,
                    "land_detail_id": null,
                    "crop_season_id": [],
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::WeedManagement"
                },
                "errors": {}
            }],
            nutrientManagement: [{
                "id": "",
                "type": "nutrient",
                "attributes": {
                    "nutrient_managment_id": [],
                    "quantity_of_nutrient": "",
                    "unit_of_measure_id": [],
                    "date_of_application": new Date(),
                    "labor_cost": "",
                    "fertilizer_cost": "",
                    "land_detail_id": null,
                    "crop_season_id": [],
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::Nutrient"
                },
                "errors": {}
            }],
            pestManagement: [{
                "id": "",
                "type": "",
                "attributes": {
                    "pest_managment_id": [],
                    "unit_of_measure_id": [],
                    "labor_cost": null,
                    "pesticide_id": [],
                    "date_of_application": new Date(),
                    "machine_cost_of_spraying": null,
                    "quantity_of_pesticide": null,
                    "pesticide_cost": null,
                    "bio_agent_release_id": [],
                    "date_of_release": new Date(),
                    "quantity": null,
                    "cost_of_input": null,
                    "type_of_trap_id": [],
                    "date_of_installation": new Date(),
                    "number_of_trap": null,
                    "cost_of_trap": null,
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "account_id": null,
                    "land_detail_id": null,
                    "total_expenses": "",
                    "crop_season_id": [],
                    "type": ""
                },
                "errors": {}
            }],
            irrigation: [{
                "id": "",
                "type": "",
                "attributes": {
                    "type": "",
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "account_id": null,
                    "land_detail_id": null,
                    "total_expenses": "",
                    "crop_season_id": [],
                    "date_of_irrigation": new Date(),
                    "hours_of_irrigation": null,
                    "source_irrigation_id": [],
                    "type_irrigation_id": [],
                    "labor_cost": "",
                    "spacing_of_dripper": null,
                    "drip_irrigated_area": null,
                    "row_to_row_spacing": null,
                    "number_of_nozels": null,
                    "irrigation_cost": ""
                },
                "errors": {}
            }],
            harvest: [{
                "id": "",
                "type": "harvest",
                "attributes": {
                    "date_of_picking": new Date(),
                    "quantity_picked": null,
                    "total_picking_cost": null,
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "account_id": null,
                    "land_detail_id": null,
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::Harvest",
                    "crop_season_id": [],
                    "storage_type_id": []
                },
                "errors": {}
            }],
            sell: [{
                "id": "",
                "type": "sell",
                "attributes": {
                    "date_of_sell": new Date(),
                    "quantity_of_cotton_sold": null,
                    "cotton_price": "",
                    "total_amount": 0,
                    "distance_from_farmer_location_to_buyer": null,
                    "buyer_name": "",
                    "transportation_cost": "",
                    "labor_cost_of_load_and_unload": "",
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "account_id": null,
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::Sell",
                    "crop_season_id": [],
                    "vehical_type_id": []
                },
                "errors": {}
            }],
            preSowingActivityData: [],
            cropNameData: [],
            unitFarmAreaData: [],
            CropSeasonData: [],
            CropTypeData: [],
            VarietyData: [],
            SeedSourceData: [],
            BioAgentNameData: [],
            BioAgentReleaseData: [],
            TypeOfTrapsData: [],
            WeedingTypeData: [],
            WeedicideNameData: [],
            NutrientManagementData: [],
            UnitofMeasureData: [],
            PestManagementData: [],
            PesticideNameData: [],
            SourceofIrrigationData: [],
            TypeofIrrigationData: [],
            storageTypeData: [],
            vehicleTypeData: [],
            updateBtnLoading: false,
            initialSowingValues: {
                "id": "",
                "type": "sowing",
                "attributes": {
                    "date_of_sowing": new Date(),
                    "crop_name_id": [],
                    "crop_area": null,
                    "unit_farm_area_id": [],
                    "crop_season_id": [],
                    "crop_type_id": [],
                    "crop_mapping": null,
                    "latitude_longitude": null,
                    "variety_id": [],
                    "seed_source_id": [],
                    "number_of_packet": null,
                    "quantity_in_kg_other_crop": null,
                    "seed_price": null,
                    "gmo_seed": true,
                    "seed_treatment": true,
                    "bio_agent_id": "",
                    "bio_agent_cost": null,
                    "account_id": null,
                    "land_detail_id": null,
                    "crop_start_year": "",
                    "crop_end_year": "",
                    "labor_cost": null,
                    "total_expenses": "",
                    "type": "BxBlockFarmDairy::Sowing",
                    "latlngHash": null,            
                    "coordinates": [],
                    "markerDefault": null,
                    "crop_mapping_file": null,                    
                },
                "errors": {}
            },

            // FARMER FILTER STARTS
            anchorEl: null,
            isFilterOpen: false,
            expandedFL: false,

            savedSortAscendingFL: false,
            savedSortDescendingFL: false,
            savedIsDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            savedStateFL:
            {
                name: "Select State",
                id: "",
            },
            savedDistrictFL: {
                name: "Select District",
                id: "",
            },
            savedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            savedVillageFL: {
                name: "Select Village",
                id: "",
            },
            savedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            sortAscendingFL: false,
            sortDescendingFL: false,

            initialStatesFL: [],
            initalDistrictsFL: [],
            initialTalukasFL: [],
            initialVillagesFL: [],
            initialFieldExectiveFL: [],

            filteredStatesFL: [],
            filteredDistrictsFL: [],
            filteredTalukasFL: [],
            filteredVillagesFL: [],
            filteredFieldExecutiveFL: [],

            selectedStateFL:
            {
                name: "Select State",
                id: "",
            },
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },

            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",

            isDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            filterHasErrorFL: false,
            hideBadgeFL: true,
            // FARMER FILTER ENDS
            // CROP-MAPPING
            OpenFarmMapping: false,            
            currentSowingItemIndex:null,
            selectedSowingItem:null,
            userLocation: initalValueUserLocation,          
            pageCountFarmer:1
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    getStartYear = (date: any) => {
        let setDate = date ? date : new Date()
        return String(moment(setDate).subtract(1, 'year').year())
    }
    getEndYear = (date: any) => {
        let setDate = date ? date : new Date()
        return String(moment(setDate).year())
    }
    // Customizable Area End
    initialpreSowingValues = {
        "id": "",
        "type": "",
        "attributes": {
            "date": new Date(),
            "cost": "",
            "compost_cost": "",
            "labor_cost": "",
            "crop_start_year": "",
            "crop_end_year": "",
            "account_id": null,
            "land_detail_id": null,
            "total_expenses": "",
            "type": "",
            "crop_season_id": [],
            "pre_sowing_activity_id": []
        },
        "errors": {}
    }
    initialgapFillingValues = {
        "id": "",
        "type": "gap_filling",
        "attributes": {
            "date": new Date(),
            "labor_cost": "",
            "price_of_seed": "",
            "account_id": null,
            "land_detail_id": null,
            "crop_season_id": [],
            "crop_start_year": "",
            "crop_end_year": "",
            "total_expenses": "",
            "type": "BxBlockFarmDairy::GapFilling"
        },
        "errors": {}
    };
    initialWeedManagementValues = {
        "id": "",
        "type": "weed_management",
        "attributes": {
            "weeding_date": new Date(),
            "machine_charges": "",
            "labor_cost": "",
            "quantity_of_weedicide": null,
            "cost_of_weedicide": "",
            "labor_cost_of_spraying": "",
            "weeding_type_id": null,
            "weedicide_id": null,
            "land_detail_id": null,
            "crop_season_id": [],
            "crop_start_year": "",
            "crop_end_year": "",
            "total_expenses": "",
            "type": "BxBlockFarmDairy::WeedManagement"
        },
        "errors": {}
    };
    initialNutrientManagementValues = {
        "id": "",
        "type": "nutrient",
        "attributes": {
            "nutrient_managment_id": [],
            "quantity_of_nutrient": "",
            "unit_of_measure_id": [],
            "date_of_application": new Date(),
            "labor_cost": "",
            "fertilizer_cost": "",
            "land_detail_id": null,
            "crop_season_id": [],
            "crop_start_year": "",
            "crop_end_year": "",
            "total_expenses": "",
            "type": "BxBlockFarmDairy::Nutrient"
        },
        "errors": {}
    }
    initialPestManagementValues = {
        "id": "",
        "type": "",
        "attributes": {
            "pest_managment_id": [],
            "unit_of_measure_id": [],
            "labor_cost": null,
            "pesticide_id": [],
            "date_of_application": new Date(),
            "machine_cost_of_spraying": null,
            "quantity_of_pesticide": null,
            "pesticide_cost": null,
            "bio_agent_release_id": [],
            "date_of_release": new Date(),
            "quantity": null,
            "cost_of_input": null,
            "type_of_trap_id": [],
            "date_of_installation": new Date(),
            "number_of_trap": null,
            "cost_of_trap": null,
            "crop_start_year": "",
            "crop_end_year": "",
            "account_id": null,
            "land_detail_id": null,
            "total_expenses": "",
            "crop_season_id": [],
            "type": ""
        },
        "errors": {}
    }
    initialIrrigationValues = {
        "id": "",
        "type": "",
        "attributes": {
            "type": "",
            "crop_start_year": "",
            "crop_end_year": "",
            "account_id": null,
            "land_detail_id": null,
            "total_expenses": "",
            "crop_season_id": [],
            "date_of_irrigation": new Date(),
            "hours_of_irrigation": null,
            "source_irrigation_id": [],
            "type_irrigation_id": [],
            "labor_cost": "",
            "spacing_of_dripper": null,
            "drip_irrigated_area": null,
            "row_to_row_spacing": null,
            "number_of_nozels": null,
            "irrigation_cost": ""
        },
        "errors": {}
    }
    initialHarvestValues = {
        "id": "",
        "type": "harvest",
        "attributes": {
            "date_of_picking": new Date(),
            "quantity_picked": null,
            "total_picking_cost": null,
            "crop_start_year": "",
            "crop_end_year": "",
            "account_id": null,
            "land_detail_id": null,
            "total_expenses": "",
            "type": "BxBlockFarmDairy::Harvest",
            "crop_season_id": [],
            "storage_type_id": []
        },
        "errors": {}
    }
    initialSellValues = {
        "id": "",
        "type": "sell",
        "attributes": {
            "date_of_sell": new Date(),
            "quantity_of_cotton_sold": null,
            "cotton_price": "",
            "total_amount": 0,
            "distance_from_farmer_location_to_buyer": null,
            "buyer_name": "",
            "transportation_cost": "",
            "labor_cost_of_load_and_unload": "",
            "crop_start_year": "",
            "crop_end_year": "",
            "account_id": null,
            "total_expenses": "",
            "type": "BxBlockFarmDairy::Sell",
            "crop_season_id": [],
            "vehical_type_id": []
        },
        "errors": {}
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && responseJson) {
                // Customizable Area Start
                switch (apiRequestCallId) {
                    case this.getFarmerApiCallId:
                        this.getFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getUserFarmDiaryApiCallId:
                        this.getUserFarmDiaryApiCallIdResp(responseJson);
                        break;
                    case this.getPreSowingActivityApiCallId:
                        this.getPreSowingActivityApiCallIdResp(responseJson);
                        break;
                    case this.getCropNameApiCallId:
                        this.getCropNameApiCallIdResp(responseJson);
                        break;
                    case this.getUnitFarmAreaApiCallId:
                        this.getUnitFarmAreaApiCallIdResp(responseJson);
                        break;
                    case this.getCropSeasonApiCallId:
                        this.getCropSeasonApiCallIdResp(responseJson);
                        break;
                    case this.getCropTypeApiCallId:
                        this.getCropTypeApiCallIdResp(responseJson);
                        break;
                    case this.getVarietyApiCallId:
                        this.getVarietyApiCallIdResp(responseJson);
                        break;
                    case this.getSeedSourceApiCallId:
                        this.getSeedSourceApiCallIdResp(responseJson);
                        break;
                    case this.getBioAgentNameApiCallId:
                        this.getBioAgentNameApiCallIdResp(responseJson);
                        break;
                    case this.getBioAgentReleaseApiCallId:
                        this.getBioAgentReleaseApiCallIdResp(responseJson);
                        break;
                    case this.getTypeOfTrapsApiCallId:
                        this.getTypeOfTrapsApiCallIdResp(responseJson);
                        break;
                    case this.getWeedingTypeApiCallId:
                        this.getWeedingTypeApiCallIdResp(responseJson);
                        break;
                    case this.getWeedicideNameApiCallId:
                        this.getWeedicideNameApiCallIdResp(responseJson);
                        break;
                    case this.getNutrientManagementApiCallId:
                        this.getNutrientManagementApiCallIdResp(responseJson);
                        break;
                    case this.getUnitofMeasureApiCallId:
                        this.getUnitofMeasureApiCallIdResp(responseJson);
                        break;
                    case this.getPestManagementApiCallId:
                        this.getPestManagementApiCallIdResp(responseJson);
                        break;
                    case this.getPesticideNameApiCallId:
                        this.getPesticideNameApiCallIdResp(responseJson);
                        break;
                    case this.getSourceofIrrigationApiCallId:
                        this.getSourceofIrrigationApiCallIdResp(responseJson);
                        break;
                    case this.getTypeofIrrigationApiCallId:
                        this.getTypeofIrrigationApiCallIdResp(responseJson);
                        break;
                    case this.getStorageTypeApiCallId:
                        this.getStorageTypeApiCallIdResp(responseJson);
                        break;
                    case this.getVehicleTypeApiCallId:
                        this.getVehicleTypeApiCallIdResp(responseJson);
                        break;
                    case this.getUpdateFarmDiaryApiCallId:
                        this.getUpdateFarmDiaryApiCallIdResp(responseJson);
                        break;
                    case this.getStateDataApiFLCallId:
                        this.getStateDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getDistrictDataApiFLCallId:
                        this.getDistrictDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getTalukaDataApiFLCallId:
                        this.getTalukaDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getVillageDataApiFLCallId:
                        this.getVillageDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getFieldExecutiveDataApiFLCallId:
                        this.getFieldExecutiveDataApiFLCallIdRes(responseJson);
                        break;
                }
                // Customizable Area End
            }
            if (errorReponse) {
                // Customizable Area Start
                console.log("ERROR-RESPONSE", errorReponse)
                // Customizable Area End
            }
            // Customizable Area Start
            // Customizable Area End
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setStateArr = (sectionName: string, initialState: any, arr: any[]) => {
        const assignArr = arr?.length ? arr : [initialState]
        this.setState({ [sectionName]: assignArr })
    }
    getFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && responseJson.data) {
            const isFilterNotApplied: boolean = this.checkFilterErrorFL()
            if (isFilterNotApplied) {
                this.setState({
                    hideBadgeFL: true
                })
            } else {
                this.setState({
                    hideBadgeFL: false
                })
            }

            this.setState({
                farmerDataLoading: false,
                farmDiaryLoading:false,
                farmerData: [...this.state.farmerData, ...responseJson.data],
            }, () => {
                this.setState({ userFarmDiary: this.state.userFarmDiary ? this.state.userFarmDiary : this.state.farmerData[0] })
                this.handleCloseTableFilterPopupFL()
            })
        } else {
            this.setState({
                farmerDataLoading: false,
                farmerData: [],
                farmDiaryLoading:false
            }, () => {
                this.handleCloseTableFilterPopupFL()
            })
        }
    }
    setCordinates = (obj: any) => {
        // get string of latitude_longitude
        let latLongString = obj.attributes.latitude_longitude ? obj.attributes.latitude_longitude : null

        let centroidTemp:any = null;
        if (latLongString && latLongString.length >0) {
            latLongString = JSON.parse(latLongString);
            // convert string to array of object
            let coordinatesArrayTemp = [];
            for (let i = 0; i < Object.keys(latLongString).length; i++) {
                coordinatesArrayTemp.push(latLongString[i]);
            }
            // re-change key names of array
            let CoordinatesArr = [];
            if (coordinatesArrayTemp.length > 0) {
                // Loop through each object in the original array and modify the keys
                for (const coordinate of coordinatesArrayTemp) {
                    const modifiedCoord = {
                        "lat": coordinate.latitude,
                        "lng": coordinate.longitude
                    };
                    CoordinatesArr.push(modifiedCoord);
                }
            }
            if (CoordinatesArr.length > 0) {
                centroidTemp = getPolygonCentroid(CoordinatesArr)            
            }

            return {CoordinatesArr,centroidTemp};
        } else {
            return {CoordinatesArr:[],centroidTemp};
        }
    }
    getUserFarmDiaryApiCallIdResp = (responseJson: any) => {
        if (responseJson) {
            let preSowingArr: any[] = [];
            let sowingArr: any[] = [];
            let gapFillingArr: any[] = [];
            let weedManagementArr: any[] = [];
            let nutrientArr: any[] = [];
            let pestManagementArr: any[] = [];
            let irrigationArr: any[] = [];
            let harvestArr: any[] = [];
            let sellArr: any[] = [];
            responseJson.forEach((each: any) => {
                if (each?.data?.length > 0) {
                    each.data.forEach((obj: any) => {
                        switch (obj.type) {
                            case "pre_sowing":
                                preSowingArr.push({ ...obj, errors: {} })
                                break;
                            case "sowing":
                                const {CoordinatesArr,centroidTemp} = this.setCordinates(obj)                                                            
                                sowingArr.push({
                                    ...obj,
                                    attributes: {
                                        ...obj.attributes,
                                        "latlngHash": null,
                                        "coordinates": CoordinatesArr,
                                        "markerDefault": centroidTemp,
                                        "crop_mapping_file": null,
                                    },
                                    errors: {}
                                })                             
                                break;
                            case "gap_filling":
                                gapFillingArr.push({ ...obj, errors: {} })
                                break;
                            case "weed_management":
                                weedManagementArr.push({ ...obj, errors: {} })
                                break;
                            case "nutrient":
                                nutrientArr.push({ ...obj, errors: {} })
                                break;
                            case "pest_management_foliar_spray":
                                pestManagementArr.push({ ...obj, errors: {} })
                                break;
                            case "pest_management_bio":
                                pestManagementArr.push({ ...obj, errors: {} })
                                break;
                            case "pest_management_trap":
                                pestManagementArr.push({ ...obj, errors: {} })
                                break;
                            case "irrigation":
                                irrigationArr.push({ ...obj, errors: {} })
                                break;
                            case "harvest":
                                harvestArr.push({ ...obj, errors: {} })
                                break;
                            case "sell":
                                sellArr.push({ ...obj, errors: {} })
                                break;
                        }
                    })
                }
            })

            this.setStateArr('preSowing', this.initialpreSowingValues, preSowingArr)
            this.setStateArr('sowing', this.state.initialSowingValues, sowingArr)
            this.setStateArr('gapFilling', this.initialgapFillingValues, gapFillingArr)
            this.setStateArr('weedManagement', this.initialWeedManagementValues, weedManagementArr)
            this.setStateArr('nutrientManagement', this.initialNutrientManagementValues, nutrientArr)
            this.setStateArr('pestManagement', this.initialPestManagementValues, pestManagementArr)
            this.setStateArr('irrigation', this.initialIrrigationValues, irrigationArr)
            this.setStateArr('harvest', this.initialHarvestValues, harvestArr)
            this.setStateArr('sell', this.initialSellValues, sellArr)

            this.setState({ farmDiaryLoading: false })
        }
    }
    getPreSowingActivityApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ preSowingActivityData: responseJson })
        } else {
            this.setState({ preSowingActivityData: [] })
        }
    }
    getCropNameApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ cropNameData: responseJson })
        } else {
            this.setState({ cropNameData: [] })
        }
    }
    getUnitFarmAreaApiCallIdResp = (responseJson: any) => {
        if (responseJson) {
            this.setState({ unitFarmAreaData: responseJson })
        } else {
            this.setState({ unitFarmAreaData: [] })
        }
    }
    getCropSeasonApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ CropSeasonData: responseJson })
        } else {
            this.setState({ CropSeasonData: [] })
        }
    }
    getCropTypeApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ CropTypeData: responseJson })
        } else {
            this.setState({ CropTypeData: [] })
        }
    }
    getVarietyApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ VarietyData: responseJson })
        } else {
            this.setState({ VarietyData: [] })
        }
    }
    getSeedSourceApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ SeedSourceData: responseJson })
        } else {
            this.setState({ SeedSourceData: [] })
        }
    }
    getBioAgentNameApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ BioAgentNameData: responseJson })
        } else {
            this.setState({ BioAgentNameData: [] })
        }
    }
    getBioAgentReleaseApiCallIdResp = (responseJson: any) => {
        if (responseJson) {
            this.setState({ BioAgentReleaseData: responseJson })
        } else {
            this.setState({ BioAgentReleaseData: [] })
        }
    }
    getTypeOfTrapsApiCallIdResp = (responseJson: any) => {
        if (responseJson) {
            this.setState({ TypeOfTrapsData: responseJson })
        } else {
            this.setState({ TypeOfTrapsData: [] })
        }
    }
    getWeedingTypeApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ WeedingTypeData: responseJson })
        } else {
            this.setState({ WeedingTypeData: [] })
        }
    }
    getWeedicideNameApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ WeedicideNameData: responseJson })
        } else {
            this.setState({ WeedicideNameData: [] })
        }
    }
    getNutrientManagementApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ NutrientManagementData: responseJson })
        } else {
            this.setState({ NutrientManagementData: [] })
        }
    }
    getUnitofMeasureApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ UnitofMeasureData: responseJson })
        } else {
            this.setState({ UnitofMeasureData: [] })
        }
    }
    getPestManagementApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ PestManagementData: responseJson })
        } else {
            this.setState({ PestManagementData: [] })
        }
    }
    getPesticideNameApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ PesticideNameData: responseJson })
        } else {
            this.setState({ PesticideNameData: [] })
        }
    }
    getSourceofIrrigationApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ SourceofIrrigationData: responseJson })
        } else {
            this.setState({ SourceofIrrigationData: [] })
        }
    }
    getTypeofIrrigationApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ TypeofIrrigationData: responseJson })
        } else {
            this.setState({ TypeofIrrigationData: [] })
        }
    }
    getStorageTypeApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ storageTypeData: responseJson })
        } else {
            this.setState({ storageTypeData: [] })
        }
    }
    getVehicleTypeApiCallIdResp = (responseJson: any) => {
        if (responseJson && responseJson !== null) {
            this.setState({ vehicleTypeData: responseJson })
        } else {
            this.setState({ vehicleTypeData: [] })
        }
    }
    getUpdateFarmDiaryApiCallIdRespError = (responseJson: any) => {
        let error = "";
        if (responseJson?.errors) {
            error = responseJson.errors[0].errors
        } else if (responseJson?.error) {
            error = responseJson?.error
        } else {
            error = "Something went wrong"
        }
        this.setState({ updateBtnLoading: false, errorPopup: error, popupTitle: 'Error' })
    }
    getUpdateFarmDiaryApiCallIdResp = (responseJson: any) => {
        // New Logic for error handle
        if (responseJson?.length) {
            const errorArray: any[] = [];
            responseJson.forEach((item: any) => {
                if (item?.errorMsg) {
                    errorArray.push(...item.errorMsg);
                }
            });
            if (errorArray.length > 0) {
                this.setState({ updateBtnLoading: false, errorPopup: errorArray[0], popupTitle: 'Error' })
            } else {
                if (!responseJson?.errors && !responseJson?.error) {
                    this.fetchUserFarmDiary()
                    this.setState({ updateBtnLoading: false, errorPopup: 'User Farm diary updated successfully!', popupTitle: "Successful" })
                }
            }
        } else if (responseJson?.errors || responseJson?.error) {
            this.getUpdateFarmDiaryApiCallIdRespError(responseJson)
        } else {
            this.setState({ updateBtnLoading: false })
        }
    }
    // FARMER FILTER STARTS
    getStateDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialStatesFL: responseJson,
                filteredStatesFL: responseJson
            })
        } else {
            this.setState({
                initialStatesFL: [],
                filteredStatesFL: []
            })
        }

    }
    getDistrictDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initalDistrictsFL: responseJson,
                filteredDistrictsFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    district: false,
                },
            })
        } else {
            this.setState({
                initalDistrictsFL: [],
                filteredDistrictsFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    district: false,
                },
            })
        }
    }
    getTalukaDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialTalukasFL: responseJson,
                filteredTalukasFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    taluka: false,
                },
            })
        } else {
            this.setState({
                initialTalukasFL: [],
                filteredTalukasFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    taluka: false,
                },
            })
        }
    }
    getVillageDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialVillagesFL: responseJson,
                filteredVillagesFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    village: false,
                },
            })
        } else {
            this.setState({
                initialVillagesFL: [],
                filteredVillagesFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    village: false,
                },
            })
        }
    }
    getFieldExecutiveDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialFieldExectiveFL: responseJson,
                filteredFieldExecutiveFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    fe: false,
                },
            })
        } else {
            this.setState({
                initialFieldExectiveFL: [],
                filteredFieldExecutiveFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    fe: false,
                },
            })
        }
    }
    // FARMER FILTER ENDS

    // Customizable Area End

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        this.fetchFarmerData();
        this.fetchPreSowingActivity();
        this.fetchCropNameData();
        this.fetchUnitFarmAreaUrlData();
        this.fetchCropSeasonData();
        this.fetchCropTypeData()
        this.fetchVarietyData()
        this.fetchSeedSourceData()
        this.fetchBioAgentNameData()
        this.fetchBioAgentReleaseData()
        this.fetchTypeOfTrapsData()
        this.fetchWeedingTypeData()
        this.fetchWeedicideNameData()
        this.fetchNutrientManagementData()
        this.fetchUnitofMeasureData()
        this.fetchPestManagementData()
        this.fetchPesticideNameData()
        this.fetchSourceofIrrigationData()
        this.fetchTypeofIrrigationData()
        this.fetchStorageTypeData();
        this.fetchVehicleTypeData();
        // FARMER FILTER STARTS
        this.getStatesFL();
        this.getDistrictsFL();
        this.getTalukasFL();
        this.getVillagesFL();
        this.getFieldExecutivesFL()
        // FARMER FILTER ENDS
        // Customizable Area End
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        // Customizable Area Start
        if (prevState.userFarmDiary !== this.state.userFarmDiary) {
            this.fetchUserFarmDiary()
        }
        if (prevState.userFarmDiary !== this.state.userFarmDiary) {
            const sowingValue = { ...this.state.initialSowingValues }
            sowingValue.attributes.unit_farm_area_id = [{ id: this.state?.userFarmDiary?.attributes?.unit_farm_area?.id, name: this.state?.userFarmDiary?.attributes?.unit_farm_area?.label }]
            this.setState({
                initialSowingValues: sowingValue,
                sowing: sowingValue
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    coordinateModal = () => (this.state.selectedSowingItem && this.state.selectedSowingItem.attributes.coordinates) ? this.state.selectedSowingItem.attributes.coordinates : []
    markerDefaultModal = () => { 
        if (this.state.selectedSowingItem) {
            if (this.state.selectedSowingItem.attributes.coordinates && !this.state.selectedSowingItem.attributes.coordinates.length) {
                return this.state.userLocation
            } else {
                return this.state.selectedSowingItem.attributes.markerDefault
            }
        }
    }
    keyDown = (evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
    userBadge = (): string => (this.state.userFarmDiary?.attributes?.first_name ? this.state.userFarmDiary?.attributes?.first_name[0].toUpperCase() : "") + (this.state.userFarmDiary?.attributes?.last_name ? this.state.userFarmDiary?.attributes?.last_name.trim()[0].toUpperCase() : "")
    getErrorBoolean = (item: any, key: string): boolean => {
        return item?.errors && item?.errors[key]
    }
    getErrorText = (item: any, key: string): string => {
        return ((item?.errors && item?.errors[key]) ? item?.errors[key] : "")
    }
    getInputValue = (item: any, key: string): string => {
        return ((item?.attributes && item?.attributes[key]) ? item?.attributes[key] : "")
    }
    getSelectValue = (item: any, key: string): string => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0]?.id : "")
    }
    getDateValue = (item: any, key: string): string => {
        return ((item?.attributes && item?.attributes[key]) ? item?.attributes[key] : new Date())
    }
    showCompostForm = (item: any) => {
        return Boolean(item?.attributes?.pre_sowing_activity_id?.length && (item?.attributes?.pre_sowing_activity_id[0]?.name === "Compost Application" ? true : false))
    }
    showBioAgentForm = (item: any) => {
        if (item?.attributes?.pest_managment_id?.length) {
            return (item?.attributes?.pest_managment_id[0]?.name === "Bio-Agent Release" ? true : false)
        }
        return (item?.type === "pest_management_bio" ? true : false)
    }
    showFoliarSprayForm = (item: any) => {
        if (item?.attributes?.pest_managment_id?.length) {
            return (item?.attributes?.pest_managment_id[0]?.name === "Foliar Spray" ? true : false)
        }
        return (item?.type === "pest_management_foliar_spray" ? true : false)
    }
    showTrapInstallationForm = (item: any) => {
        if (item?.attributes?.pest_managment_id?.length) {
            return (item?.attributes?.pest_managment_id[0]?.name === "Trap Installation" ? true : false)
        }
        return (item?.type === "pest_management_trap" ? true : false)
    }
    showIrrigationFloodForm = (item: any) => {
        const isNotDrip = Boolean(item?.attributes?.type_irrigation_id?.length && (item?.attributes?.type_irrigation_id[0]?.name !== "Drip" ? true : false))
        const isNotSprinkler = Boolean(item?.attributes?.type_irrigation_id?.length && (item?.attributes?.type_irrigation_id[0]?.name !== "Sprinkler" ? true : false))        
        return (isNotDrip && isNotSprinkler)
    }
    showDripIrrigationForm = (item: any) => {
        return Boolean(item?.attributes?.type_irrigation_id?.length && (item?.attributes?.type_irrigation_id[0]?.name === "Drip" ? true : false))
    }
    showSprinklerIrrigationForm = (item: any) => {
        return Boolean(item?.attributes?.type_irrigation_id?.length && (item?.attributes?.type_irrigation_id[0]?.name === "Sprinkler" ? true : false))
    }
    showRentedIrrigationForm = (item: any) => {
        return Boolean(item?.attributes?.type_irrigation_id?.length && (item?.attributes?.type_irrigation_id[0]?.name === "Rented Irrigation" ? true : false))
    }
    userFEname = () => {
        return (this.state.userFarmDiary?.attributes?.assigned_field_executive?.first_name ? this.state.userFarmDiary?.attributes?.assigned_field_executive?.first_name : "")
            + " " + (this.state.userFarmDiary?.attributes?.assigned_field_executive?.last_name ? this.state.userFarmDiary?.attributes?.assigned_field_executive?.last_name : "")
    }
    userName = () => {
        return (this.state.userFarmDiary?.attributes?.first_name ? this.state.userFarmDiary?.attributes?.first_name : "") + " " + (this.state.userFarmDiary?.attributes?.last_name ? this.state.userFarmDiary?.attributes?.last_name : "")
    }
    landDetailId = () => {
        return this.state.userFarmDiary?.attributes?.land_detail_id ? this.state.userFarmDiary?.attributes?.land_detail_id[0] : null
    }
    isItemHasId = (item: any) => {
        return item?.id ? true : false
    }
    getPestGridLG = (item: any) => {
        if (item?.attributes?.pest_managment_id?.length && item?.attributes?.pest_managment_id[0]?.id === "") {
            return 12
        } else if (this.showBioAgentForm(item)) {
            return 4

        } else if (this.showFoliarSprayForm(item)) {
            return 3
        } else if (this.showTrapInstallationForm(item)) {
            return 4
        }
        return 12
    }
    checkRemove = (stateName: string) => {
        return (this.state[stateName].length > 1 && this.showRemoveBtn(stateName))
    }

    debouncedLog = debounce(() => this.fetchFarmerData(), 500)
    handleChangeSearch = (e: any) => {
        this.setState({ searchFarmerName: e.target.value,farmerData:[],pageCountFarmer:1,farmerDataLoading: true })
        this.debouncedLog(e.target.value)
    }

    setPreSowingKeyArr = (item: any) => {
        if (this.showCompostForm(item)) {
            return ['date', 'compost_cost', 'labor_cost', 'pre_sowing_activity_id', "crop_season_id"]
        } else {
            return ['date', 'cost', 'pre_sowing_activity_id', "crop_season_id"]
        }
    }
    setSowingKeyArr = (item?: any) => {
        const sowingValidationFieldArray = ["date_of_sowing",
            "crop_name_id",
            "crop_area",
            "crop_season_id",
            "crop_type_id",
            "variety_id",
            "seed_source_id",
            "number_of_packet",
            "quantity_in_kg_other_crop",
            "seed_price",
            "labor_cost",
        ]
        const conditionalSowingFieldToValidate: string[] = [...sowingValidationFieldArray];
        if (this.getCropNameFromId(item?.attributes?.crop_name_id[0]?.id) === "Cotton") {
            conditionalSowingFieldToValidate.push("gmo_seed", "seed_treatment")
            if (String(item.attributes.seed_treatment) === "true") {
                conditionalSowingFieldToValidate.push("bio_agent_id", "bio_agent_cost")
            }
        }
        return conditionalSowingFieldToValidate;
    }
    setGapFillingKeyArr = (item?: any) => {
        return [
            "date",
            "labor_cost",
            "price_of_seed",
            "crop_season_id"
        ];
    }
    setWeedKeyArr = (item?: any) => {
        return [
            "weeding_date",
            "weeding_type_id",
            "machine_charges",
            "labor_cost",
            "weedicide_id",
            "quantity_of_weedicide",
            "cost_of_weedicide",
            "labor_cost_of_spraying",
            "crop_season_id"
        ];
    }
    setNutrientKeyArr = (item?: any) => {
        return [
            "nutrient_managment_id",
            "date_of_application",
            "quantity_of_nutrient",
            "unit_of_measure_id",
            "fertilizer_cost",
            "labor_cost",
            "crop_season_id"
        ];
    }
    setPestKeyArr = (item?: any) => {
        if (this.showBioAgentForm(item)) {
            return [
                "pest_managment_id",
                "crop_season_id",
                "bio_agent_release_id",
                "date_of_release",
                "quantity",
                "unit_of_measure_id",
                "cost_of_input",
                "labor_cost"
            ]
        } else if (this.showFoliarSprayForm(item)) {
            return [
                "pest_managment_id",
                "crop_season_id",
                "pesticide_id",
                "date_of_application",
                "machine_cost_of_spraying",
                "quantity_of_pesticide",
                "unit_of_measure_id",
                "pesticide_cost",
                "labor_cost"
            ]
        } else if (this.showTrapInstallationForm(item)) {
            return [
                "pest_managment_id",
                "crop_season_id",
                "type_of_trap_id",
                "date_of_installation",
                "number_of_trap",
                "cost_of_trap",
                "labor_cost"
            ]
        } else {
            return [
                "pest_managment_id",
                "crop_season_id"
            ]
        }
    }
    setIrrigationKeyArr = (item?: any) => {
        if (this.showSprinklerIrrigationForm(item)) {
            return [
                "date_of_irrigation",
                "source_irrigation_id",
                "type_irrigation_id",
                "hours_of_irrigation",
                "number_of_nozels",
                "labor_cost",
                "crop_season_id",
                "irrigation_cost",
            ]
        } else if (this.showIrrigationFloodForm(item)) {
            return [
                "date_of_irrigation",
                "source_irrigation_id",
                "type_irrigation_id",
                "hours_of_irrigation",
                "labor_cost",
                "crop_season_id",
                "irrigation_cost",
            ]
        } else if (this.showDripIrrigationForm(item)) {
            return [
                "date_of_irrigation",
                "source_irrigation_id",
                "type_irrigation_id",
                "hours_of_irrigation",
                "spacing_of_dripper",
                "drip_irrigated_area",
                "row_to_row_spacing",
                "crop_season_id",
                "irrigation_cost",
            ]
        } else if (this.showRentedIrrigationForm(item)) {
            return [
                "date_of_irrigation",
                "source_irrigation_id",
                "type_irrigation_id",
                "hours_of_irrigation",
                "irrigation_cost",
                "labor_cost",
                "crop_season_id",
                "irrigation_cost",
            ]
        } else {
            return [
                "date_of_irrigation",
                "source_irrigation_id",
                "type_irrigation_id",
                "crop_season_id",
                "irrigation_cost",
            ]
        }
    }
    setHarvestKeyArr = (item?: any) => {
        return [
            "date_of_picking",
            "quantity_picked",
            "total_picking_cost",
            "storage_type_id",
            "crop_season_id"
        ];
    }
    setSellKeyArr = (item?: any) => {
        return [
            "date_of_sell",
            "quantity_of_cotton_sold",
            "cotton_price",
            "total_amount",
            "buyer_name",
            "transportation_cost",
            "vehical_type_id",
            "labor_cost_of_load_and_unload",
            "distance_from_farmer_location_to_buyer",
            "crop_season_id"
        ];
    }
    howManyFieldNeedToValidation = ({ sectionName, item }: any) => {
        switch (sectionName) {
            case 'preSowing':
                return this.setPreSowingKeyArr(item);
            case 'sowing':
                {
                    return this.setSowingKeyArr(item)
                }
            case 'gapFilling':
                return this.setGapFillingKeyArr();
            case 'weedManagement':
                return this.setWeedKeyArr();
            case 'nutrientManagement':
                return this.setNutrientKeyArr();
            case 'pestManagement':
                return this.setPestKeyArr(item);
            case 'irrigation':
                return this.setIrrigationKeyArr(item);
            case 'harvest':
                return this.setHarvestKeyArr();
            case 'sell':
                return this.setSellKeyArr();
        }
    }

    checkFieldValidation = (fieldValue: any): boolean => {
        return (fieldValue === "" || fieldValue === null || fieldValue === undefined || fieldValue.length === 0) ? true : false
    }

    setFieldError = ({ sectionName, fieldName, isError, errors }: SetFieldErrorParams) => {
        if (isError) {
            const errorMessage = configJSON.ERROR_MESSAGES[sectionName][fieldName]
            errors[fieldName] = errorMessage
        } else {
            errors[fieldName] = null;
        }
    }
    convertToMetres(area: number | string, item: any) {
        switch (item) {
            case 'Acres':
                return Number(area) * 4046.86
            case 'Square Yard':
                return Number(area) * 0.836127
            case 'Square meter':
                return Number(area)
            default:
                return Number(area)
        }
    }

    isCropAreaAvailable = () => {

        const inputArea = this.state.sowing[0].attributes.crop_area;
        const inputAreadId = this.state.sowing[0].attributes.unit_farm_area_id[0]?.id
        const totalArea = this?.state?.userFarmDiary?.attributes?.total_farm_area
        const totalAreaId = this.state?.userFarmDiary?.attributes?.unit_farm_area?.id
        const inputAreaArr = this.state?.unitFarmAreaData?.filter((unit) =>

            Number(unit.id) === Number(inputAreadId)

        )
        const totalAreaArr = this.state?.unitFarmAreaData?.filter((unit) =>

            Number(unit.id) === Number(totalAreaId)

        )
        if (inputArea) {
            const val1 = this.convertToMetres(inputArea, inputAreaArr[0]?.label)
            const val2 = this.convertToMetres(totalArea, totalAreaArr[0]?.label)
            if (val1 > val2) {
                return 'Crop area should not exceed total Farm area';
            }
        }
    }

    checkCotton = (value: any) => this.state.cropNameData.find(data => data.name === "Cotton")?.id === value;

    checkSectionErrors = ({ sectionName, sectionStates }: any) => {
        let isError = false

        const newSectionStates: any[] = sectionStates.map((item: any) => {

            let isCotton = false;

            if (item.attributes?.crop_name_id && item.attributes?.crop_name_id[0]?.id) {
                isCotton = this.checkCotton(item.attributes?.crop_name_id[0]?.id);
            }

            const errors = {}
            const emptyKeyArr = [] as any;
            const validationFields = this.howManyFieldNeedToValidation({ sectionName, item })
            for (let [key, value] of Object.entries(item.attributes)) {
                if (validationFields?.includes(key) && !key.includes('total_amount') && !key.includes('date') && !key.includes('gmo_seed') && !key.includes('seed_treatment') && (!isCotton ? !key.includes('number_of_packet') : !key.includes('quantity_in_kg_other_crop'))) {
                    const isError = this.checkFieldValidation(value);
                    emptyKeyArr.push(isError);
                    this.setFieldError({ sectionName, fieldName: key, isError, errors })
                }
            }
            if (!emptyKeyArr.every((i: any) => i === true) && Object.values(errors).filter(i => i !== null).length > 0) {
                isError = true
                return {
                    ...item,
                    isWholeObjectEmpty: false,
                    errors,
                }
            } else if (emptyKeyArr.every((i: any) => i === true)) {
                return { ...item, isWholeObjectEmpty: true, errors: {} }
            } else {
                return { ...item, isWholeObjectEmpty: false, errors: {} }
            }
        })
        return { newSectionStates, isError }
    }

    getDateFormat = (date: any) => {
        return moment(date).format('YYYY-MM-DD')
    }
    getNonEmptyArr = (array: any[]) => {
        return array.filter((item: any) => item.length !== 0)
    }

    getPreSowingReqBody = (preSowingValidationArr: any) => {
        const preSowingReqBody = preSowingValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.pre_sowing_activity_id[0].name !== "Compost Application") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "pre_sowing_activity_id": item.attributes.pre_sowing_activity_id[0].id,
                    "date": moment(item.attributes.date).format('YYYY-MM-DD'),
                    "cost": item.attributes.cost,
                    "compost_cost": null,
                    "labor_cost": null,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date),
                    "crop_end_year": this.getEndYear(item.attributes.date)
                }
            } else {
                return []
            }
        })
        return preSowingReqBody
    }
    getpreSowingCompostReqBody = (preSowingValidationArr: any) => {
        const preSowingCompostReqBody = preSowingValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.pre_sowing_activity_id[0].name === "Compost Application") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "pre_sowing_activity_id": item.attributes.pre_sowing_activity_id[0].id,
                    "date": moment(item.attributes.date).format('YYYY-MM-DD'),
                    "compost_cost": item.attributes.compost_cost,
                    "labor_cost": item.attributes.labor_cost,
                    "cost": null,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date),
                    "crop_end_year": this.getEndYear(item.attributes.date)
                }
            } else {
                return []
            }
        })
        return preSowingCompostReqBody
    }
    getSowingReqBody = (sowingValidationArr: any) => {
        const sowingReqBody = sowingValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_sowing": this.getDateFormat(item.attributes.date_of_sowing),
                    "crop_name_id": item.attributes.crop_name_id[0].id,
                    "crop_area": item.attributes.crop_area,
                    "unit_farm_area_id": item.attributes.unit_farm_area_id[0].id,
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_type_id": item.attributes.crop_type_id[0].id,
                    "variety_id": item.attributes.variety_id[0].id,
                    "seed_source_id": item.attributes.seed_source_id[0].id,
                    "number_of_packet": item.attributes.number_of_packet,
                    "quantity_in_kg_other_crop": item.attributes.quantity_in_kg_other_crop,
                    "seed_price": item.attributes.seed_price,
                    "labor_cost": item.attributes.labor_cost,
                    "gmo_seed": item.attributes.gmo_seed,
                    "seed_treatment": item.attributes.seed_treatment,
                    "bio_agent_id": item.attributes?.bio_agent_id ? item.attributes?.bio_agent_id[0]?.id : "",
                    "bio_agent_cost": item.attributes.bio_agent_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_start_year": this.getStartYear(item.attributes.date_of_sowing),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_sowing),
                    "latitude_longitude":item.attributes.latlngHash,
                    "crop_mapping": item.attributes.crop_mapping_file ? item.attributes.crop_mapping_file : null 
                }
            } else {
                return []
            }
        })
        return sowingReqBody
    }
    getGapFillingReqBody = (gapFillingValidationArr: any) => {
        const gapFillingReqBody = gapFillingValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date": this.getDateFormat(item.attributes.date),
                    "labor_cost": item.attributes.labor_cost,
                    "price_of_seed": item.attributes.price_of_seed,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date),
                    "crop_end_year": this.getEndYear(item.attributes.date)
                }
            } else {
                return []
            }
        })
        return gapFillingReqBody
    }
    getWeedReqBody = (weedValidationArr: any) => {
        const weedReqBody = weedValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "weeding_date": this.getDateFormat(item.attributes.weeding_date),
                    "machine_charges": item.attributes.machine_charges,
                    "labor_cost": item.attributes.labor_cost,
                    "quantity_of_weedicide": item.attributes.quantity_of_weedicide,
                    "cost_of_weedicide": item.attributes.cost_of_weedicide,
                    "labor_cost_of_spraying": item.attributes.labor_cost_of_spraying,
                    "weeding_type_id": item.attributes.weeding_type_id[0].id,
                    "weedicide_id": item.attributes.weedicide_id[0].id,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.weeding_date),
                    "crop_end_year": this.getEndYear(item.attributes.weeding_date)
                }
            } else {
                return []
            }
        })
        return weedReqBody
    }
    getNutrientReqBody = (nutrientValidationArr: any) => {
        const nutrientReqBody = nutrientValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_application": this.getDateFormat(item.attributes.date_of_application),
                    "fertilizer_cost": item.attributes.fertilizer_cost,
                    "labor_cost": item.attributes.labor_cost,
                    "nutrient_managment_id": item.attributes.nutrient_managment_id[0].id,
                    "quantity_of_nutrient": item.attributes.quantity_of_nutrient,
                    "unit_of_measure_id": item.attributes.unit_of_measure_id[0].id,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_application),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_application)
                }
            } else {
                return []
            }
        })
        return nutrientReqBody
    }
    // pest-management
    getPestFoliarReqBody = (pestValidationArr: any) => {
        const pestFoliarReqBody = pestValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.pest_managment_id[0].name === "Foliar Spray") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "pest_managment_id": item.attributes.pest_managment_id[0].id,
                    "pesticide_id": item.attributes.pesticide_id[0].id,
                    "date_of_application": this.getDateFormat(item.attributes.date_of_application),
                    "machine_cost_of_spraying": item.attributes.machine_cost_of_spraying,
                    "quantity_of_pesticide": item.attributes.quantity_of_pesticide,
                    "unit_of_measure_id": item.attributes.unit_of_measure_id[0].id,
                    "pesticide_cost": item.attributes.pesticide_cost,
                    "labor_cost": item.attributes.labor_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_application),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_application)
                }
            } else {
                return []
            }
        })
        return pestFoliarReqBody
    }
    getPestTrapReqBody = (pestValidationArr: any) => {
        const pestTrapReqBody = pestValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.pest_managment_id[0].name === "Trap Installation") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "pest_managment_id": item.attributes.pest_managment_id[0].id,
                    "type_of_trap_id": item.attributes.type_of_trap_id[0].id,
                    "date_of_installation": this.getDateFormat(item.attributes.date_of_installation),
                    "number_of_trap": item.attributes.number_of_trap,
                    "cost_of_trap": item.attributes.cost_of_trap,
                    "labor_cost": item.attributes.labor_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_installation),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_installation)
                }
            } else {
                return []
            }
        })
        return pestTrapReqBody
    }
    getPestBioReqBody = (pestValidationArr: any) => {
        const pestBioReqBody = pestValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.pest_managment_id[0].name === "Bio-Agent Release") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "pest_managment_id": item.attributes.pest_managment_id[0].id,
                    "bio_agent_release_id": item.attributes.bio_agent_release_id[0].id,
                    "date_of_release": this.getDateFormat(item.attributes.date_of_release),
                    "quantity": item.attributes.quantity,
                    "unit_of_measure_id": item.attributes.unit_of_measure_id[0].id,
                    "cost_of_input": item.attributes.cost_of_input,
                    "labor_cost": item.attributes.labor_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_release),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_release)
                }
            } else {
                return []
            }
        })
        return pestBioReqBody
    }
    // irrigation
    getIrrigationFloodReqBody = (irrigationValidationArr: any) => {
        const irrigationFloodReqBody = irrigationValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && this.showIrrigationFloodForm(item)) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_irrigation": this.getDateFormat(item.attributes.date_of_irrigation),
                    "source_irrigation_id": item.attributes.source_irrigation_id[0].id,
                    "type_irrigation_id": item.attributes.type_irrigation_id[0].id,
                    "hours_of_irrigation": item.attributes.hours_of_irrigation,
                    "labor_cost": item.attributes.labor_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_irrigation),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_irrigation),
                    "irrigation_cost": item.attributes.irrigation_cost,
                }
            } else {
                return []
            }
        })
        return irrigationFloodReqBody
    }
    getIrrigationSprinklerReqBody = (irrigationValidationArr: any) => {
        const irrigationSprinklerReqBody = irrigationValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.type_irrigation_id[0].name === "Sprinkler") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_irrigation": this.getDateFormat(item.attributes.date_of_irrigation),
                    "source_irrigation_id": item.attributes.source_irrigation_id[0].id,
                    "type_irrigation_id": item.attributes.type_irrigation_id[0].id,
                    "hours_of_irrigation": item.attributes.hours_of_irrigation,
                    "number_of_nozels": item.attributes.number_of_nozels,
                    "labor_cost": item.attributes.labor_cost,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_irrigation),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_irrigation),
                    "irrigation_cost": item.attributes.irrigation_cost,
                }
            } else {
                return []
            }
        })
        return irrigationSprinklerReqBody
    }
    getIrrigationDripReqBody = (irrigationValidationArr: any) => {
        const irrigationDripReqBody = irrigationValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.type_irrigation_id[0].name === "Drip") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_irrigation": this.getDateFormat(item.attributes.date_of_irrigation),
                    "source_irrigation_id": item.attributes.source_irrigation_id[0].id,
                    "type_irrigation_id": item.attributes.type_irrigation_id[0].id,
                    "hours_of_irrigation": item.attributes.hours_of_irrigation,
                    "spacing_of_dripper": item.attributes.spacing_of_dripper,
                    "drip_irrigated_area": item.attributes.drip_irrigated_area,
                    "row_to_row_spacing": item.attributes.row_to_row_spacing,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_irrigation),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_irrigation),
                    "irrigation_cost": item.attributes.irrigation_cost,
                }
            } else {
                return []
            }
        })
        return irrigationDripReqBody
    }
    getIrrigationRentedReqBody = (irrigationValidationArr: any) => {
        const irrigationRentedReqBody = irrigationValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false && item.attributes.type_irrigation_id[0].name === "Rented Irrigation") {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_irrigation": this.getDateFormat(item.attributes.date_of_irrigation),
                    "source_irrigation_id": item.attributes.source_irrigation_id[0].id,
                    "type_irrigation_id": item.attributes.type_irrigation_id[0].id,
                    "hours_of_irrigation": item.attributes.hours_of_irrigation,
                    "irrigation_cost": item.attributes.irrigation_cost,
                    "labor_cost": item.attributes.labor_cost,
                    "spacing_of_dripper": null,
                    "drip_irrigated_area": null,
                    "row_to_row_spacing": null,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_irrigation),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_irrigation)
                }
            } else {
                return []
            }
        })
        return irrigationRentedReqBody
    }
    getHarvestReqBody = (harvestValidationArr: any) => {
        const harvestReqBody = harvestValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_picking": this.getDateFormat(item.attributes.date_of_picking),
                    "quantity_picked": item.attributes.quantity_picked,
                    "total_picking_cost": item.attributes.total_picking_cost,
                    "storage_type_id": item.attributes.storage_type_id[0].id,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_picking),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_picking)
                }
            } else {
                return []
            }
        })
        return harvestReqBody
    }
    getSellReqBody = (sellValidationArr: any) => {
        const sellReqBody = sellValidationArr.newSectionStates.map((item: any) => {
            if (item.isWholeObjectEmpty === false) {
                return {
                    ...(item?._destroy ? { "_destroy": item.id } : item.id && { "id": item.id }),
                    "date_of_sell": this.getDateFormat(item.attributes.date_of_sell),
                    "quantity_of_cotton_sold": item.attributes.quantity_of_cotton_sold,
                    "cotton_price": item.attributes.cotton_price,
                    "buyer_name": item.attributes.buyer_name,
                    "total_amount": item.attributes.total_amount,
                    "transportation_cost": item.attributes.transportation_cost,
                    "labor_cost_of_load_and_unload": item.attributes.labor_cost_of_load_and_unload,
                    "distance_from_farmer_location_to_buyer": item.attributes.distance_from_farmer_location_to_buyer,
                    "vehical_type_id": item.attributes.vehical_type_id[0].id,
                    "land_detail_id": this.landDetailId(),
                    "crop_season_id": item.attributes.crop_season_id[0].id,
                    "crop_start_year": this.getStartYear(item.attributes.date_of_sell),
                    "crop_end_year": this.getEndYear(item.attributes.date_of_sell)
                }
            } else {
                return []
            }
        })
        return sellReqBody
    }

    submitFarmDiaryForm = (event: any) => {
        event.preventDefault();
        this.setState({ updateBtnLoading: true })
        const responseCropArea = this.isCropAreaAvailable()

        // ||  this.isCropAreaAvailable(this.getInputValue(item, "crop_area"), this.getSelectValue(item, "unit_farm_area_id"),this?.state?.userFarmDiary?.attributes?.total_farm_area,this.state?.userFarmDiary?.attributes?.unit_farm_area?.id)

        const preSowingValidationArr = this.checkSectionErrors({ sectionName: 'preSowing', sectionStates: this.state.preSowing });
        const sowingValidationArr = this.checkSectionErrors({ sectionName: 'sowing', sectionStates: this.state.sowing });
        const gapFillingValidationArr = this.checkSectionErrors({ sectionName: 'gapFilling', sectionStates: this.state.gapFilling });
        const weedValidationArr = this.checkSectionErrors({ sectionName: 'weedManagement', sectionStates: this.state.weedManagement });
        const nutrientValidationArr = this.checkSectionErrors({ sectionName: 'nutrientManagement', sectionStates: this.state.nutrientManagement });
        const pestValidationArr = this.checkSectionErrors({ sectionName: 'pestManagement', sectionStates: this.state.pestManagement });
        const irrigationValidationArr = this.checkSectionErrors({ sectionName: 'irrigation', sectionStates: this.state.irrigation });
        const harvestValidationArr = this.checkSectionErrors({ sectionName: 'harvest', sectionStates: this.state.harvest });
        const sellValidationArr = this.checkSectionErrors({ sectionName: 'sell', sectionStates: this.state.sell });

        this.setState({
            preSowing: preSowingValidationArr.newSectionStates,
            sowing: sowingValidationArr.newSectionStates,
            gapFilling: gapFillingValidationArr.newSectionStates,
            weedManagement: weedValidationArr.newSectionStates,
            nutrientManagement: nutrientValidationArr.newSectionStates,
            harvest: harvestValidationArr.newSectionStates,
            pestManagement: pestValidationArr.newSectionStates,
            irrigation: irrigationValidationArr.newSectionStates,
            sell: sellValidationArr.newSectionStates
        })

        if (!preSowingValidationArr.isError && !sowingValidationArr.isError && !gapFillingValidationArr.isError &&
            !weedValidationArr.isError && !nutrientValidationArr.isError && !pestValidationArr.isError &&
            !irrigationValidationArr.isError && !harvestValidationArr.isError && !sellValidationArr.isError && !responseCropArea) {
            // pre-sowing 
            const preSowingArrData: any[] = this.getPreSowingReqBody(preSowingValidationArr)
            const preSowingCompostArrData: any[] = this.getpreSowingCompostReqBody(preSowingValidationArr)
            const sowingArrData: any[] = this.getSowingReqBody(sowingValidationArr)
            const gapFillingArrData: any[] = this.getGapFillingReqBody(gapFillingValidationArr)
            const weedArrData: any[] = this.getWeedReqBody(weedValidationArr)
            const nutrientArrData: any[] = this.getNutrientReqBody(nutrientValidationArr)
            // pest-management
            const pestFoliarArrData: any[] = this.getPestFoliarReqBody(pestValidationArr)
            const pestTrapArrData: any[] = this.getPestTrapReqBody(pestValidationArr)
            const pestBioArrData: any[] = this.getPestBioReqBody(pestValidationArr)
            // irrigation
            const irrigationFloodArrData: any[] = this.getIrrigationFloodReqBody(irrigationValidationArr)
            const irrigationSprinklerArrData: any[] = this.getIrrigationSprinklerReqBody(irrigationValidationArr)
            const irrigationDripArrData: any[] = this.getIrrigationDripReqBody(irrigationValidationArr)
            const irrigationRentedArrData: any[] = this.getIrrigationRentedReqBody(irrigationValidationArr)
            const harvestArrData: any[] = this.getHarvestReqBody(harvestValidationArr)
            const sellArrData: any[] = this.getSellReqBody(sellValidationArr)

            // Extract non-empty form objects from array
            const preSowingFinalData = this.getNonEmptyArr(preSowingArrData)
            const preSowingCompostFinalData = this.getNonEmptyArr(preSowingCompostArrData)
            const sowingFinalData = this.getNonEmptyArr(sowingArrData)
            const gapFillingFinalData = this.getNonEmptyArr(gapFillingArrData)
            const weedFinalData = this.getNonEmptyArr(weedArrData)
            const nutrientFinalData = this.getNonEmptyArr(nutrientArrData)
            const pestFoliarFinalData = this.getNonEmptyArr(pestFoliarArrData)
            const pestTrapFinalData = this.getNonEmptyArr(pestTrapArrData)
            const pestBioFinalData = this.getNonEmptyArr(pestBioArrData)
            const irrigationFloodFinalData = this.getNonEmptyArr(irrigationFloodArrData)
            const irrigationSprinklerFinalData = this.getNonEmptyArr(irrigationSprinklerArrData)
            const irrigationDripFinalData = this.getNonEmptyArr(irrigationDripArrData)
            const irrigationRentedFinalData = this.getNonEmptyArr(irrigationRentedArrData)
            const harvestFinalData = this.getNonEmptyArr(harvestArrData)
            const sellFinalData = this.getNonEmptyArr(sellArrData)

            // set http request body object
            const requestBody = {
                "farm_dairy": {
                    "pre_sowing": preSowingFinalData,
                    "pre_sowing_compost": preSowingCompostFinalData,
                    "sowing": sowingFinalData,
                    "gap_filling": gapFillingFinalData,
                    "weed_management": weedFinalData,
                    "nutrient": nutrientFinalData,
                    "pest_management_foliar_spray": pestFoliarFinalData,
                    "pest_management_trap": pestTrapFinalData,
                    "pest_management_bio": pestBioFinalData,
                    "irrigation": [...irrigationFloodFinalData,...irrigationSprinklerFinalData,...irrigationDripFinalData],                    
                    "harvest": harvestFinalData,
                    "sell": sellFinalData
                }
            }


            this.updateFarmDiary(requestBody)


        } else {
            this.setState({ updateBtnLoading: false, errorPopup: "Oops, Please enter complete Phase Data", popupTitle: 'Error' })
        }
    }
    handleListItemClick = (each: any) => {
        this.setState({ userFarmDiary: each })
    };
    showRemoveBtn = (activityBox: string,) => {
        const activityArr = [...this.state[activityBox]];
        const temp: any[] = activityArr.filter((item: any) => !item?._destroy)
        return temp.length > 1 ? true : false
    }
    showHRline = (activityBox: string,) => {
        const activityArr = [...this.state[activityBox]];
        const temp: any[] = activityArr.filter((item: any) => !item?._destroy)
        return temp.length > 1 ? true : false
    }
    handleAddSubSection = (activityBox: string, initialValues: any, state?: any) => {
        const checkField = state && this.checkSectionErrors({ sectionName: activityBox, sectionStates: state });
        if (checkField?.isError || checkField?.newSectionStates[checkField.newSectionStates.length - 1].isWholeObjectEmpty) {
            this.toggleErrorPopup("Please complete the existing form first.");
            return;
        }
        const updatedActivityBox = [...this.state[activityBox]];
        updatedActivityBox.push(initialValues);
        this.setState({
            [activityBox]: updatedActivityBox
        });
    }
    handleRemoveSubSection = (activityBox: string, itemIndex: number, itemId: any) => {
        if (itemIndex >= 0) {
            const updatedActivityBox = [...this.state[activityBox]]; // create a new copy of activityBox array
            const activityArr: any[] = updatedActivityBox.map((item: any, index: number) => {
                if (index === itemIndex && itemId !== "") { // check BE objects with index
                    return {
                        ...item,
                        _destroy: itemId
                    }
                } else if (index === itemIndex && itemId === "") { // check FE objects with index
                    return {
                        ...item,
                        _destroy: null
                    }
                }
                return item;
            })

            const newActivityArr: any[] = activityArr.filter((item: any) => item?._destroy !== null)

            this.setState({
                [activityBox]: newActivityArr // update the state with the new array without the deleted item
            });
        }
    }

    getInitialValues = (type: string | null) => {
        switch (type) {
            case "preSowing":
                return this.initialpreSowingValues;
            case "sowing":
                return this.state.initialSowingValues;
            case "gapFilling":
                return this.initialgapFillingValues;
            case "weedManagement":
                return this.initialWeedManagementValues;
            case "nutrientManagement":
                return this.initialNutrientManagementValues;
            case "pestManagement":
                return this.initialPestManagementValues;
            case "irrigation":
                return this.initialIrrigationValues;
            case "harvest":
                return this.initialHarvestValues;
            case "sell":
                return this.initialSellValues;
            default:
                return null;
        }
    }


    handleDelete = (activityBox: string) => {

        const initialValues = this.getInitialValues(activityBox)

        const updatedActivityBox = [...this.state[activityBox]]; // create a new copy of activityBox array
        const activityArr: any[] = updatedActivityBox.map((item: any, index: number) => {
            if (item?.id !== "") { // check BE objects
                return {
                    ...item,
                    _destroy: item?.id
                }
            } else if (item?.id === "") { // check FE objects
                return {
                    ...item,
                    _destroy: null
                }
            }
            return item;
        })
        const newActivityArr: any[] = activityArr.filter((item: any) => item?._destroy !== null)

        this.setState({
            [activityBox]: [...newActivityArr, initialValues],
            deletePopup: null
        });
    }
    setFieldValuePreSowing = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldPreSowingArr = [...this.state.preSowing];
        const newPreSowingArr = oldPreSowingArr.map((eachActivity: any, index: any) => {
            let labelArr = this.state.preSowingActivityData.length ? this.state.preSowingActivityData.find((each: any) => each.id === fieldValue) : []
            if (activityIndex === index) {
                switch (fieldName) {
                    case "preSowingActivity":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                ...(!eachActivity?.id && { "cost": null, "compost_cost": null, "labor_cost": null }),
                                pre_sowing_activity_id: [{ id: fieldValue, name: labelArr?.name }]
                            }
                        }
                    case "datePreSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date: fieldValue
                            }
                        }
                    case "costPreSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                cost: fieldValue,
                            }
                        }
                    case "compostCost":
                        return {
                            ...eachActivity,
                            type: "pre_sowing_compost",
                            attributes: {
                                ...eachActivity.attributes,
                                compost_cost: fieldValue
                            }
                        }
                    case "laborCostPreSowing":
                        return {
                            ...eachActivity,
                            type: "pre_sowing_compost",
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    case "cropSeasonPreSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                }
            }
            return eachActivity
        })
        this.setState({ preSowing: newPreSowingArr })
    }
    setFieldValueSowing = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldSowingArr = [...this.state.sowing];
        const newSowingArr = oldSowingArr.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_sowing: fieldValue
                            }
                        }
                    case "cropName":
                        if (this.getCropNameFromId(fieldValue) !== 'Cotton') {
                            return {
                                ...eachActivity,
                                attributes: {
                                    ...eachActivity.attributes,
                                    crop_name_id: [{ id: fieldValue }],
                                    seed_treatment: "",
                                    bio_agent_id: "",
                                    bio_agent_cost: "",
                                    gmo_seed: ""
                                }
                            }
                        }
                        else {
                            return {
                                ...eachActivity,
                                attributes: {
                                    ...eachActivity.attributes,
                                    crop_name_id: [{ id: fieldValue }],
                                    seed_treatment: true,
                                    bio_agent_id: "",
                                    bio_agent_cost: "",
                                    gmo_seed: true
                                }
                            }
                        }

                    case "cropArea":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_area: fieldValue
                            }
                        }
                    case "unitOfMeasureSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                unit_farm_area_id: [{ id: fieldValue }]
                            }
                        }
                    case "cropSeason":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                    case "cropType":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_type_id: [{ id: fieldValue }]
                            }
                        }
                    case "variety":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                variety_id: [{ id: fieldValue }]
                            }
                        }
                    case "seedSource":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                seed_source_id: [{ id: fieldValue }]
                            }
                        }
                    case "noOfPackets":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                number_of_packet: fieldValue
                            }
                        }
                    case "qtyInKg":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_in_kg_other_crop: fieldValue
                            }
                        }
                    case "seedPrice":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                seed_price: fieldValue
                            }
                        }
                    case "laborCostSowing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    case "nonGMOseed":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                gmo_seed: fieldValue
                            }
                        }
                    case "seedTreatment":
                        {
                            if (String(fieldValue) === "true") {
                                return {
                                    ...eachActivity,
                                    attributes: {
                                        ...eachActivity.attributes,
                                        seed_treatment: fieldValue
                                    }
                                }
                            }
                            else {
                                return {
                                    ...eachActivity,
                                    attributes: {
                                        ...eachActivity.attributes,
                                        seed_treatment: fieldValue,
                                        bio_agent_id: "",
                                        bio_agent_cost: ""
                                    }
                                }
                            }


                        }
                    case "bioAgentName":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                bio_agent_id: [{ id: fieldValue }]
                            }
                        }
                    case "bioAgentCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                bio_agent_cost: fieldValue
                            }
                        }
                }
            }
            return eachActivity

        })
        this.setState({ sowing: newSowingArr })
    }
    setFieldValueGapFilling = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldGapFillingArr = [...this.state.gapFilling];
        const newGapFillingArr = oldGapFillingArr.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateGapFilling":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date: fieldValue
                            }
                        }
                    case "laborCostGapFilling":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    case "priceOfSeed":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                price_of_seed: fieldValue
                            }
                        }
                    case "cropSeasonGap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                }
            }
            return eachActivity

        })
        this.setState({ gapFilling: newGapFillingArr })
    }
    setFieldValueWeed = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldWeedArr = [...this.state.weedManagement];
        const newWeedArr = oldWeedArr.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateofWeeding":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                weeding_date: fieldValue
                            }
                        }
                    case "weedType":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                weeding_type_id: [{ id: fieldValue }]
                            }
                        }
                    case "machineCharge":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                machine_charges: fieldValue
                            }
                        }
                    case "laborCostWeed":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    case "WeedicideName":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                weedicide_id: [{ id: fieldValue }]
                            }
                        }
                    case "QtyOfWeedicide":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_of_weedicide: fieldValue
                            }
                        }
                    case "costOfWeedicide":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                cost_of_weedicide: fieldValue
                            }
                        }
                    case "laborCostSpraying":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost_of_spraying: fieldValue
                            }
                        }
                    case "cropSeasonWeed":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                }
            }
            return eachActivity

        })
        this.setState({ weedManagement: newWeedArr })
    }
    setFieldValueNutrient = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldNutrientArr = [...this.state.nutrientManagement];
        const newNutrientArr = oldNutrientArr.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "nutrientSelect":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                nutrient_managment_id: [{ id: fieldValue }]
                            }
                        }
                    case "dateOfApplicationNutrient":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_application: fieldValue
                            }
                        }
                    case "qtyOfNutrient":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_of_nutrient: fieldValue
                            }
                        }
                    case "unitOfMeasureNutrient":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                unit_of_measure_id: [{ id: fieldValue }]
                            }
                        }
                    case "fertilizerCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                fertilizer_cost: fieldValue
                            }
                        }
                    case "laborCostNutrient":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    case "cropSeasonNutrient":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                }
            }
            return eachActivity

        })
        this.setState({ nutrientManagement: newNutrientArr })
    }
    setFieldValuePestManagement = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldPestManagementArr = [...this.state.pestManagement];
        const newPestManagementArr = oldPestManagementArr.map((eachActivity: any, index: any) => {
            let selctedActivityArr = this.state.PestManagementData.length ? this.state.PestManagementData.find((each: any) => each.id === fieldValue) : []
            if (activityIndex === index) {
                switch (fieldName) {
                    case "pestManagementActivity":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                ...(!eachActivity?.id && {
                                    "bio_agent_release_id": null,
                                    "date_of_release": new Date(),
                                    "quantity": null,
                                    "unit_of_measure_id": null,
                                    "cost_of_input": null,
                                    "labor_cost": null,
                                    "pesticide_id": null,
                                    "date_of_application": new Date(),
                                    "machine_cost_of_spraying": null,
                                    "quantity_of_pesticide": null,
                                    "pesticide_cost": null,
                                    "type_of_trap_id": null,
                                    "date_of_installation": new Date(),
                                    "number_of_trap": null,
                                    "cost_of_trap": null
                                }),
                                pest_managment_id: [{ id: fieldValue, name: selctedActivityArr?.name }]
                            }
                        }
                    case "cropSeasonPest":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                    case "bioAgentRelease":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                bio_agent_release_id: [{ id: fieldValue }]
                            }
                        }
                    case "dateOfRelease":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_release: fieldValue
                            }
                        }
                    case "qtyBioAgent":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity: fieldValue
                            }
                        }
                    case "unitOfMeasureBioAgent":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                unit_of_measure_id: [{ id: fieldValue }]
                            }
                        }
                    case "costOfInput":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                cost_of_input: fieldValue
                            }
                        }
                    case "laborCostBioAgent":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    // FOLIAR
                    case "pesticideName":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                pesticide_id: [{ id: fieldValue }]
                            }
                        }
                    case "dateOfApplicationPest":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_application: fieldValue
                            }
                        }
                    case "machineCostSpraying":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                machine_cost_of_spraying: fieldValue
                            }
                        }
                    case "unitOfMeasureFoliar":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                unit_of_measure_id: [{ id: fieldValue }]
                            }
                        }
                    case "qtyOfPesticide":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_of_pesticide: fieldValue
                            }
                        }
                    case "pesticideCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                pesticide_cost: fieldValue
                            }
                        }
                    case "laborCostFoliar":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    // TRAP
                    case "typeOfTrap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                type_of_trap_id: [{ id: fieldValue }]
                            }
                        }
                    case "dateOfInstallationTrap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_installation: fieldValue
                            }
                        }
                    case "numberOfTrap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                number_of_trap: fieldValue
                            }
                        }
                    case "costOfTrap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                cost_of_trap: fieldValue
                            }
                        }
                    case "laborCostTrap":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                }
            }
            return eachActivity

        })
        this.setState({ pestManagement: newPestManagementArr })
    }
    setFieldValueIrrigation = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldIrrigationArr = [...this.state.irrigation];
        const newIrrigationArr = oldIrrigationArr.map((eachActivity: any, index: any) => {
            let labelArr = this.state.TypeofIrrigationData.length ? this.state.TypeofIrrigationData.find((each: any) => each.id === fieldValue) : []
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateOfIrrigation":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_irrigation: fieldValue
                            }
                        }
                    case "sourceOfIrrigation":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                source_irrigation_id: [{ id: fieldValue }]
                            }
                        }
                    case "typeOfIrrigation":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                ...(!eachActivity?.id && {
                                    labor_cost: null,
                                    spacing_of_dripper: null,
                                    drip_irrigated_area: null,
                                    row_to_row_spacing: null,
                                    number_of_nozels: null,
                                }),
                                type_irrigation_id: [{ id: fieldValue, name: labelArr?.label }]
                            }
                        }
                    case "cropSeasonIrrigation":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                    case "irrigationCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                irrigation_cost: fieldValue
                            }
                        }
                    // FLOOD
                    case "hoursOfIrrigationFlood":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                hours_of_irrigation: fieldValue
                            }
                        }
                    case "laborCostFlood":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    // DRIP
                    case "hoursOfIrrigationDrip":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                hours_of_irrigation: fieldValue
                            }
                        }
                    case "spacingOfDrippers":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                spacing_of_dripper: fieldValue
                            }
                        }
                    case "dripIrrigatedArea":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                drip_irrigated_area: fieldValue
                            }
                        }
                    case "rowTorowSpacing":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                row_to_row_spacing: fieldValue
                            }
                        }
                    // SPRINKLER
                    case "hoursOfIrrigationSprinkler":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                hours_of_irrigation: fieldValue
                            }
                        }
                    case "numberOfNozels":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                number_of_nozels: fieldValue
                            }
                        }
                    case "laborCostSprinkler":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }
                    // RENTED
                    case "hoursOfIrrigationRented":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                hours_of_irrigation: fieldValue
                            }
                        }                    
                    case "laborCostRented":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost: fieldValue
                            }
                        }

                }
            }
            return eachActivity

        })
        this.setState({ irrigation: newIrrigationArr })
    }
    setFieldValueHarvest = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldActivityBox = [...this.state.harvest];
        const newActivityBox = oldActivityBox.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateOfPicking":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_picking: fieldValue
                            }
                        }
                    case "quantityPicked":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_picked: fieldValue
                            }
                        }
                    case "TotalPickingCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                total_picking_cost: fieldValue
                            }
                        }
                    case "storageType":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                storage_type_id: [{ id: fieldValue }]
                            }
                        }
                    case "cropSeasonHarvest":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }

                }
            }
            return eachActivity
        })
        this.setState({ harvest: newActivityBox })
    }
    getCropNameFromId = (id: any) => {
        const allCropArr = this.state.cropNameData;
        if (id) {
            const filtereCropArr = allCropArr.filter((item) => {
                return Number(item.id) === Number(id)
            })
            return filtereCropArr[0]?.name;
        }
        else {
            return ""
        }

    }
    setFieldValueSell = (activityIndex: number, fieldName: string, fieldValue: any) => {
        const oldSellArr = [...this.state.sell];
        const newSellArr = oldSellArr.map((eachActivity: any, index: any) => {
            if (activityIndex === index) {
                switch (fieldName) {
                    case "dateOfSell":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                date_of_sell: fieldValue
                            }
                        }
                    case "qtyOfCottonSold":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                quantity_of_cotton_sold: fieldValue,
                                total_amount: fieldValue && eachActivity.attributes.cotton_price ? parseFloat(fieldValue) * parseFloat(eachActivity.attributes.cotton_price) : 0
                            }
                        }
                    case "cottonPrice":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                cotton_price: fieldValue,
                                total_amount: fieldValue && eachActivity.attributes.quantity_of_cotton_sold ? parseFloat(fieldValue) * parseFloat(eachActivity.attributes.quantity_of_cotton_sold) : 0
                            }
                        }
                    case "totalAmount":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                total_amount: fieldValue
                            }
                        }
                    case "buyerName":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                buyer_name: fieldValue
                            }
                        }
                    case "transportationCost":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                transportation_cost: fieldValue
                            }
                        }
                    case "vehicleType":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                vehical_type_id: [{ id: fieldValue }]
                            }
                        }
                    case "laborCostOfLoad":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                labor_cost_of_load_and_unload: fieldValue
                            }
                        }
                    case "distanceFarmerToBuyer":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                distance_from_farmer_location_to_buyer: fieldValue
                            }
                        }
                    case "cropSeasonSell":
                        return {
                            ...eachActivity,
                            attributes: {
                                ...eachActivity.attributes,
                                crop_season_id: [{ id: fieldValue }]
                            }
                        }
                }
            }
            return eachActivity
        })
        this.setState({ sell: newSellArr })
    }
    //Latest changes

    // FARMER FUNCTIONS STARTS
    handleChangeAtoZFL = () => {
        this.setState({
            sortAscendingFL: !this.state.sortAscendingFL,
            sortDescendingFL: false
        });
    }
    handleChangeZtoAFL = () => {
        this.setState({
            sortDescendingFL: !this.state.sortDescendingFL,
            sortAscendingFL: false
        });
    }
    handleFieldExectiveSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ fieldExecutiveInputValueFL: e?.target?.value });
        const filteredArray = query
            ? this.state.initialFieldExectiveFL.filter((value: any) =>
                value.first_name.toLowerCase().includes(query) || value.last_name.toLowerCase().includes(query)
            )
            : this.state.initialFieldExectiveFL;
        this.setState({
            filteredFieldExecutiveFL: filteredArray,
        });

    }
    handleTalukaSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ talukaInputValueFL: e?.target?.value });
        const TalukafilteredArray = query
            ? this.state.initialTalukasFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialTalukasFL;
        this.setState({
            filteredTalukasFL: TalukafilteredArray,
        });
    }
    handleVillageSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ villageInputValueFL: e?.target?.value });
        const VillagefilteredArray = query
            ? this.state.initialVillagesFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialVillagesFL;
        this.setState({
            filteredVillagesFL: VillagefilteredArray,
        });
    }
    handleDistrictSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ districtInputValueFL: e?.target?.value });
        const DistrictfilteredArray = query
            ? this.state.initalDistrictsFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initalDistrictsFL;
        this.setState({
            filteredDistrictsFL: DistrictfilteredArray,
        });
    }
    handleStateSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase() || "";
        this.setState({ stateInputValueFL: e?.target?.value });
        const StatefilteredArray = query
            ? this.state.initialStatesFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialStatesFL;
        this.setState({
            filteredStatesFL: StatefilteredArray,
        });
    }
    handleChangePanelFL = (panel: any) => (event: any, newExpanded: any) => {
        this.setState({
            expandedFL: newExpanded ? panel : false,
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",
            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

        });
    };
    handleVillageFilterClearFL = () => {
        this.setState({
            isFilterOpen: false,
            anchorEl: null,
            sortDescendingFL: false,
            sortAscendingFL: false,
            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

            expandedFL: false,
            savedStateFL:
            {
                name: "Select State",
                id: "",
            },
            savedDistrictFL: {
                name: "Select District",
                id: "",
            },
            savedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            savedVillageFL: {
                name: "Select Village",
                id: "",
            },
            savedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            savedSortAscendingFL: false,
            savedSortDescendingFL: false,
            savedIsDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",
            selectedStateFL:
            {
                name: "Select State",
                id: "",
            },
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            isDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            filterHasErrorFL: false,
            // RESET USER TABLE STATES
            farmerTableCurrentPage: 0,
            FeTableCurrentPage: 0,
            adminTableCurrentPage: 0,
            searchFilterValue: "",
            hideBadgeFL: true,
            farmerData:[]
        }, () => {

            this.fetchFarmerData();
            this.getFieldExecutivesFL()
            this.getDistrictsFL();
            this.getTalukasFL();
            this.getVillagesFL();
            this.getStatesFL()


        })
    };
    handleCloseTableFilterPopupFL = () => {
        this.setState({
            isFilterOpen: false,
            expandedFL: false,
            selectedStateFL: this.state.savedStateFL,
            selectedDistrictFL: this.state.savedDistrictFL,
            selectedTalukaFL: this.state.savedTalukaFL,
            selectedVillageFL: this.state.savedVillageFL,
            selectedFieldExecutiveFL: this.state.savedFieldExecutiveFL,

            sortAscendingFL: this.state.savedSortAscendingFL,
            sortDescendingFL: this.state.savedSortDescendingFL,
            isDisabledFL: this.state.savedIsDisabledFL,
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",

            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

            filterHasErrorFL: false
        })
    }
    handleSubmitFilterFL = () => {
        const isError = this.checkFilterErrorFL()
        if (!isError) {
            this.setState({
                expandedFL: false,
                savedIsDisabledFL: this.state.isDisabledFL,
                savedSortAscendingFL: this.state.sortAscendingFL,
                savedSortDescendingFL: this.state.sortDescendingFL,
                savedStateFL: this.state.selectedStateFL,
                savedDistrictFL: this.state.selectedDistrictFL,
                savedTalukaFL: this.state.selectedTalukaFL,
                savedVillageFL: this.state.selectedVillageFL,
                savedFieldExecutiveFL: this.state.selectedFieldExecutiveFL,
                farmerData:[],
                pageCountFarmer:1,
                farmerDataLoading: true
            }, () => {
                this.fetchFarmerData();
            });
        } else {
            return false
        }
    }
    handleChangeFieldFL = (key1: any, key2: any, key3: any, key4: any, key5?: any) => {
        const isDisabledInitialValuesFL = {
            state: true,
            district: true,
            taluka: true,
            village: true,
            fe: true,
        }
        this.setState({
            isDisabledFL: {
                ...isDisabledInitialValuesFL,
                ...(key1 && { [key1]: false }),
                ...(key2 && { [key2]: false }),
                ...(key3 && { [key3]: false }),
                ...(key4 && { [key4]: false }),
                ...(key5 && { [key5]: false }),
            }
        })

    }
    handleChangeStateFieldFL = (stateData: any) => {
        this.setState({
            selectedStateFL: stateData,
            expandedFL: false,
        }, () => {
            this.getDistrictsFL()
        });
        this.handleChangeFieldFL("", "state", "district", "", "")
    }
    handleChangeDistrictFieldFL = (districtData: any) => {
        this.setState({
            selectedDistrictFL: districtData,
            expandedFL: false,
        }, () => {
            this.getTalukasFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", "district", "taluka", "", "")

    }
    handleChangeTalukaFieldFL = (talukaData: any) => {
        this.setState({
            selectedTalukaFL: talukaData,
            expandedFL: false,
        }, () => {
            this.getVillagesFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", "taluka", "village", "")
    }
    handleChangeVillageFieldFL = (villageData: any) => {
        this.setState({
            selectedVillageFL: villageData,
            expandedFL: false,
        }, () => {
            this.getFieldExecutivesFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", this.state.selectedTalukaFL?.name === "Select Taluka" ? "" : "taluka", "village", "fe")
    }
    handleChangeFieldExecutiveFieldFL = (fieldExectiveData: any) => {
        this.setState({
            expandedFL: false,
            selectedFieldExecutiveFL: fieldExectiveData
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", this.state.selectedTalukaFL?.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillageFL?.name === "Select Village" ? "" : "village", "fe")
    }
    opacityStateCalculatorFL = () => {
        return this.state.selectedStateFL?.name === "Select State" ? 0.48 : 1
    }
    opacityDistrictCalculatorFL = () => {
        return this.state.selectedDistrictFL?.name === "Select District" ? 0.48 : 1
    }
    opacityTalukaCalculatorFL = () => {
        return this.state.selectedTalukaFL?.name === "Select Taluka" ? 0.48 : 1
    }
    opacityVillageCalculatorFL = () => {
        return this.state.selectedVillageFL?.name === "Select Village" ? 0.48 : 1
    }
    opacityFieldExecutiveCalculatorFL = () => {
        return this.state.selectedFieldExecutiveFL?.first_name + " " + this.state.selectedFieldExecutiveFL?.last_name === "Select Field Executive" ? 0.48 : 1
    }
    checkFilterErrorFL = () => {
        if (this.state.sortAscendingFL ||
            this.state.sortDescendingFL ||
            this.state.selectedStateFL.id !== "" ||
            this.state.selectedDistrictFL.id !== "" ||
            this.state.selectedTalukaFL.id !== "" ||
            this.state.selectedVillageFL.id !== "" ||
            this.state.selectedFieldExecutiveFL.id !== ""
        ) {
            this.setState({
                filterHasErrorFL: false
            })
            return false;
        } else {
            this.setState({
                filterHasErrorFL: true
            })
            return true;
        }
    }
    fetchNextFarmers = () =>{
        this.setState(prev => ({ ...prev, pageCountFarmer: prev.pageCountFarmer+1 }),()=>{
            this.fetchFarmerData()
        });    
    }
    isfarmerLoading = (): boolean => {
        return this.state.farmerDataLoading && this.state.pageCountFarmer === 1
    }
    isfarmerData = (): boolean => this.state.farmerData.length > 0 
    // FARMER FUNCTIONS ENDS

    toggleDeletePopup = (deletePopup: string | null) => {
        this.setState({ deletePopup })
    }

    toggleErrorPopup = (errorPopup: string | null, title?: string) => {
        this.setState({ errorPopup, popupTitle: title || "Error" })
    }


    onSaveFarmMapping = async (latlngHash: any, file: any, url: string, savedCoordinates: any[]) => {
        
        const crop_mapping_file = await getBase64(file)
        const sowingArr = [...this.state.sowing];
        
        const updatedSowingArr = sowingArr.map((sowingRecord: any, index: any) => {
            if (this.state.currentSowingItemIndex === index) {
                this.setState({ selectedSowingItem: sowingRecord })
                return {
                    ...sowingRecord,
                    attributes: {
                        ...sowingRecord.attributes,
                        crop_mapping: {
                            url: url
                        },
                        latlngHash: latlngHash,
                        crop_mapping_file,
                        coordinates: savedCoordinates,
                    }
                }
            }
            return sowingRecord
        })            
        this.setState({ sowing:updatedSowingArr,OpenFarmMapping: false })
    }
    closeFarmMappingModal = () => { this.setState({ OpenFarmMapping: false }) }
    fetchCurrentLocation = () => {
        const successResult = (pos: any) => {
            this.setState({
                userLocation: {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                }
            }, () => {
                localStorage.setItem('userLocationFarmMapping', JSON.stringify(this.state.userLocation))
            })
        }
        const errorResult = (error: any) => {
            this.setState({
                userLocation: null
            })
        }
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successResult, errorResult);
        }
    }

    // API CALLS FUNCTIONS
    fetchUserFarmDiary = () => {
        if (!this.state.userFarmDiary?.id) {
            return false;
        }
        this.setState({ farmDiaryLoading: true })
        let endpoint = `admin/bx_block_admin_farm_dairy/admin_farm_dairies/${this.state.userFarmDiary?.id}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUserFarmDiaryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchFarmerData = () => {
        this.setState({ farmerDataLoading: true })
        const stateId = this.state.selectedStateFL?.id || ""
        const districtId = this.state.selectedDistrictFL?.id || ""
        const talukaId = this.state.selectedTalukaFL?.id || ""
        const villageId = this.state.selectedVillageFL?.id || ""
        const fieldExecutiveId = this.state.selectedFieldExecutive?.id || ""
        let page:number = this.state.pageCountFarmer
        
        let endpoint = `${configJSON.farmerListUrl}?search=${this.state.searchFarmerName}&state_id=${stateId}&district_id=${districtId}&taluka_id=${talukaId}&village_id=${villageId}&with_land_detail=${true}&per_page=${20}&page=${page}`;
        if (this.state.sortAscendingFL === true) {
            endpoint = endpoint + `&sort_by_a_to_z=${true}&field_executive_id=${fieldExecutiveId}`
        }
        else if (this.state.sortDescendingFL) {
            endpoint = endpoint + `&sort_by_z_to_a=${true}&field_executive_id=${fieldExecutiveId}`
        }
        else {
            endpoint = endpoint + `&field_executive_id=${fieldExecutiveId}`
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getFarmerApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchPreSowingActivity = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getPreSowingActivityApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.preSowingActivityUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchCropNameData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getCropNameApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CropNameUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchUnitFarmAreaUrlData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUnitFarmAreaApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.UnitFarmAreaUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchCropSeasonData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getCropSeasonApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CropSeasonUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchCropTypeData = () => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getCropTypeApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.CropTypeUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchVarietyData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getVarietyApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.VarietyUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchSeedSourceData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getSeedSourceApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SeedSourceUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchBioAgentNameData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getBioAgentNameApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BioAgentNameUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchBioAgentReleaseData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getBioAgentReleaseApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BioAgentReleaseUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchTypeOfTrapsData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getTypeOfTrapsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.TypeOfTrapsUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchWeedingTypeData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getWeedingTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.WeedingTypeUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchWeedicideNameData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getWeedicideNameApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.WeedicideNameUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchNutrientManagementData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getNutrientManagementApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.NutrientManagementUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchUnitofMeasureData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUnitofMeasureApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.UnitofMeasureUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchPestManagementData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getPestManagementApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.PestManagementUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchPesticideNameData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getPesticideNameApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.PesticideNameUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchSourceofIrrigationData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getSourceofIrrigationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SourceofIrrigationUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchTypeofIrrigationData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getTypeofIrrigationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.TypeofIrrigationUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchStorageTypeData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getStorageTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.StorageTypeUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchVehicleTypeData = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getVehicleTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.VehicleTypeUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    updateFarmDiary = (requestBody: any) => {
        const userId = this.state.userFarmDiary?.id || ""
        if (userId) {

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            const header = {
                "Accept": configJSON.acceptGlobal,
                "Content-Type": configJSON.getTableAPiContentType,
                token: this.state.TokenValue,
            };
            this.getUpdateFarmDiaryApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.updateFarmDiaryUrl}/${userId}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.PATCH_METHOD_TYPE
            );

            //request body
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(requestBody)
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            this.toggleErrorPopup("Something went wrong")
            return false
        }
    }

    // FARMER FILTER API CALL - STARTS
    getStatesFL() {
        this.setState({
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            isDisabledFL: {
                ...this.state.isDisabledFL,
                district: true,
                village: true,
                taluka: true,
                fe: true
            },
        });
        const headers = {
            token: this.state.TokenValue,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStateDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getStateList}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getDistrictsFL() {
        let params = this.state.selectedStateFL?.id || ""
        this.setState({

            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            isDisabledFL: {
                ...this.state.isDisabledFL,
                district: true,
                village: true,
                taluka: true,
                fe: true
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDistrictDataApiFLCallId = reqApiMSG.messageId;
        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDistrictList}?state_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getTalukasFL() {
        let params = this.state.selectedDistrictFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                taluka: true,
                village: true,
                fe: true
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTalukaDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTalukaList}?district_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getVillagesFL() {
        let params = this.state.selectedTalukaFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                village: true,
                fe: true
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVillageDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getVillageList}?taluka_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getFieldExecutivesFL() {
        let params = this.state.selectedVillageFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                fe: true,
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: "",
            }
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFieldExecutiveDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getFieldExecutiveList}?village_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    // FARMER FILTER ENDS

    // Customizable Area End
}