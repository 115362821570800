import React from "react";

import {
  // Customizable Area Start
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  Box,
  Link
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import moment from "moment";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { ResponsiveContainer, PieChart, Pie, Cell, LabelList } from "recharts";

import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { theme, styles } from "./AdminConsoleUserSurveyStyle.web";
import CustomModal from "../../../components/src/customModal.web";
import InfiniteScroll from "react-infinite-scroll-component";


// Customizable Area End

import AdminConsoleUserSurveyController, {
  Props,
  QuestionType,
  configJSON,
} from "./AdminConsoleUserSurveyController";

export class AdminConsoleUserSurvey extends AdminConsoleUserSurveyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  loaderSection = (error: string | null) => (
    <div className={this.props.classes.loader}>
      {error || <CircularProgress color="secondary" />}
    </div>
  );

  textAnswer = (answer: string, index: number) => (
    <div className={this.props.classes.description} key={`answer-${index}`}>
      <Typography>{answer}</Typography>
    </div>
  );

  renderAnswers = (answersData: QuestionType) => {
    if (
      answersData.question_type?.includes(configJSON.RADIO) ||
      answersData.question_type?.includes(configJSON.DROPDOWN) ||
      answersData.question_type?.includes(configJSON.CHECK)
    ) {
      const chartData = this.getChartData(answersData.option_percentage);
      if(chartData.length > 0){
        return (
          <div  className={this.props.classes.chartContainer} >
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={chartData}
                  labelLine={true}
                  label={({ name }) => `${name.slice(0,18)}${name.length > 17 ? "..." : '' }`}
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  outerRadius={100}
                  dataKey="value"
                >
                  {chartData.map(({ color }, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={color}
                    />
                  ))}
                  <LabelList
                    dataKey={({ value , payload}) =>
                      typeof value === "number" &&
                      `${parseInt(value.toString())}% \n [${payload.responseCount}]`
                    }
                    position="right"
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        );
      }
      return this.textAnswer("None of the options selected", 1)
    }

    return answersData.answers.map((answer, index) => {
      if (answersData.question_type?.includes(configJSON.DATE)) {
        return this.textAnswer(moment(answer,"DD/MM/YYYY").format("DD MMMM YYYY"), index);
      }

      if (answersData.question_type?.includes(configJSON.TIME)) {
        return this.textAnswer(
          moment(answer, "HH:mm").format("hh:mm a"),
          index
        );
      }

      return answer && this.textAnswer(answer, index);
    });
  };

  rightSection = () => {
    const { classes } = this.props;
    const { surveyResult, activeSurvey } = this.state;

    if(!surveyResult && !activeSurvey){
      return(
        <Typography className={classes.noResult} >No survey </Typography>
      )
    }

    return (
      <>
        <div className={classes.Survey}>
          <div className={classes.TopHeader}>
            <Tooltip title={surveyResult?.attributes.survey_name || ""} >
            <Typography className={classes.heading}>
              {surveyResult?.attributes.survey_name}
            </Typography>
            </Tooltip>
            <Typography className={classes.dateText}>
              {moment(surveyResult?.attributes.created_at).format("DD/MM/YYYY")}
            </Typography>
          </div>
          <div className={classes.TopHeader}>
            <Typography className={classes.responseText}>
              {activeSurvey?.attributes?.survey_response_count}{" "}
              {`Response${
                (activeSurvey?.attributes?.survey_response_count || 0) > 1
                  ? "s"
                  : ""
              }`}
            </Typography>
            <IconButton
              className={classes.deleteBtn}
              id="delete-btn"
              onClick={() =>
                this.toggleDeletePopup(surveyResult?.attributes.survey_id || "")
              }
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.listSectionScroll}>
          {surveyResult?.attributes.response.map((data, index) => (
            <div
              className={classes.questionBox}
              key={`survey-question-${data.question_id}-${index}`}
            >
              <div className={classes.question}>
                <Typography>{data.question}</Typography>
              </div>
              {this.renderAnswers(data)}
            </div>
          ))}
        </div>
      </>
    );
  };

  // Customizable Area End

  render() {
    const { navigation, id, classes } = this.props;
    const { surveyList, activeSurvey, surveyResult } = this.state;

    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <Box>
            <CustomModal
              open={!!this.state.deletePopup}
              onClose={() => this.toggleDeletePopup(null)}
              confirmHandler={() =>
                this.state.deletePopup &&
                this.handleDelete(this.state.deletePopup)
              }
              confirmTitle="Delete"
              title="Delete Survey"
              description={configJSON.deletePopupDescription}
            />
            <Grid container spacing={3}>
              <Grid item md={5} sm={12} xs={12}>
                <div>
                  <Typography variant="h5" className={classes.headerTitle}>
                    User
                  </Typography>
                  <Typography
                    variant="inherit"
                    className={classes.headerSubtitle}
                  >
                <Link
                  href="/users"
                  className={classes.pathFirstPart}
                >
                  User
                </Link>
                    
                    {` > Surveys`}
                  </Typography>
                  <div className={classes.listSectionLeftSide}>
                    <Typography className={classes.listHeader}>
                      List of Surveys
                    </Typography>

                    <div id="scrollableDiv" className={classes.scrollSection}>
                    <InfiniteScroll
                          dataLength={surveyList.length}
                          next={this.handleMoreSurveysList}
                          scrollableTarget="scrollableDiv"
                          hasMore={true}
                          loader={<></>}
                      >
                      {Array.isArray(surveyList)
                        ? surveyList.map((survey, index) => (
                            <div
                              className={`${classes.btnContainer} ${
                                activeSurvey?.id === survey.id
                                  ? classes.activeSurvey
                                  : ""
                              }`}
                              key={`survey-${index}-${survey.id}`}
                              onClick={() =>
                                activeSurvey?.id !== survey.id &&
                                this.handleActiveSurveys(survey)
                              }
                            >
                              <div>
                                <Tooltip title={survey.attributes.name}>
                                <Typography className={classes.heading}>
                                  {survey.attributes.name}
                                </Typography>
                                </Tooltip>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.responseText}
                                >
                                  {survey.attributes.survey_response_count}
                                  {` Response${
                                    survey.attributes.survey_response_count > 1
                                      ? "s"
                                      : ""
                                  }`}
                                </Typography>
                              </div>
                              <div className={classes.alignEnd}>
                                <Typography
                                  className={classes.dateText}
                                  variant="subtitle2"
                                >
                                  {moment(survey.attributes.created_at).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                                <IconButton
                                  className={classes.deleteBtn}
                                  onClick={() =>
                                    this.toggleDeletePopup(survey.id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))
                        : this.loaderSection(surveyList)}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={7} sm={12} xs={12}>
                <div className={`${classes.listSection} ${classes.w100}`}>
                  {(surveyResult || surveyList) 
                    ? this.rightSection()
                    : this.loaderSection(null)}
                </div>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withStyles(styles)(AdminConsoleUserSurvey);
// Customizable Area End
