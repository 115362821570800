import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import {
  createTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {loginbg, cofarmlogo, lineimage, googleLogo} from "./assets";
import "./EmailAccountLogin.css";
import CustomModal from "../../../components/src/customModal.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main:'#DF5200',
      contrastText: "#fff",
    },
    
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
  // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <CustomModal 
          open={!!this.state.errorPopup} 
          onClose={() => this.toggleErrorPopup(null)}
          title="Error"
          description={this.state.errorPopup}
          />
                            
          <Grid container>
            <Grid item md={6} lg={6} sm={12} xs={12}>
              <Box sx={webStyle.farmImgBox}>
                <img
                  src={cofarmlogo}
                  style={webStyle.cofarmImg}
                />
              </Box>
              <Box style={webStyle.loginBox}>
                <Box>
                  <Typography style={webStyle.welcomeTitle}>
                    {this.state.welcomeTitle}
                  </Typography>
                  <Typography style={webStyle.subtitle}>
                    {this.state.loginSubtitle}
                  </Typography>
                </Box>
                <Box>
                  <Box style={webStyle.textfieldBox}>
                    <Typography style={webStyle.labels}>Email</Typography>
                    <LoginTextField
                      id="outlined-basic"
                      data-testId="txtInputEmail"
                      placeholder={"Enter your email"}
                      fullWidth={true}
                      autoFocus={false}
                      value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)}
                    />
                    <Typography style={webStyle.errorText}>{this.state.emailErrorTxt}</Typography>
                  </Box>
                  <Box sx={webStyle.textfieldBox}>
                    <Typography style={webStyle.labels}>Password</Typography>
                    <FormControl variant="outlined" fullWidth={true}>
                      <LoginTextField
                        data-testId="txtInputPassword"
                        type={
                          this.state.enablePasswordField ? "password" : "text"
                        }
                        placeholder={"Enter your password"}
                        fullWidth={true}
                        value={this.state.password}
                        onChange={(e) => this.setPassword(e)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-testId="showPassword"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility style={webStyle.passIcon}/>
                              ) : (
                                <VisibilityOff style={webStyle.passIcon}/>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Typography style={webStyle.errorText}>{this.state.passwordErrorTxt}</Typography>
                  </Box>
                  {/* <Box
                    data-testId={"btnForgotPassword"}
                    style={webStyle.forgotPassText}
                    onClick={() => {}}
                  >
                    {this.state.forgotPasswordText}
                  </Box>
                  <Box style={webStyle.termsconditionBox}>
                    <Checkbox
                      data-testId={"termscondition"}
                      onClick={this.checkTermsConditions}
                      checkedIcon={<img src={tickCheckImg} style={webStyle.tickImg} />}
                      icon={<img src={emptyCheck} style={webStyle.checkBoxImg} />}
                      checked={this.state.checkTermsCond}
                    />{" "}
                    <Typography style={webStyle.termsText}>
                      I agree to{" "}
                      <span
                        style={webStyle.linkText}
                      >
                        Terms & Conditions
                      </span>{" "}
                      of company.
                    </Typography>
                  </Box> */}
                  <Box style={webStyle.loginBtnBox}>
                    <Button
                      data-testId={"btnEmailLogIn"}
                      variant="contained"
                      fullWidth
                      onClick={() => this.doEmailLogIn()}
                      style={webStyle.loginBtn}
                    >
                      {this.state.btnTxtLogin}
                    </Button>
                  </Box>
                  <Box style={webStyle.orBox}>
                    <img src={lineimage} style={webStyle.lineImg} />
                    <Typography style={webStyle.OrText}>
                      {this.state.labelOr}
                    </Typography>
                    <img src={lineimage} style={webStyle.lineImg} />
                  </Box>
                  <Box>
                    <Button
                      data-testId="btnSocialLogin"
                      variant="contained"
                      fullWidth
                      onClick={()=>this.loginWithGoogle(false)}
                      style={webStyle.gooeleLoginBtn}
                    >
                      <img src={googleLogo} style={webStyle.googleImg} />{" "}
                      {this.state.btnTxtGoogleLogin}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
            >
              <img src={loginbg} 
                style={webStyle.loginImg}
              />
            </Grid>
          </Grid>
      </ThemeProvider>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  farmImgBox:{
    padding: "3.3vw 0 0 5vw", 
  },
  cofarmImg:{
    width:'11vw', 
    height:'2.5vw'
  },
  loginBox:{
    padding: "8.3vw 11vw" 
  },
  welcomeTitle: {
    fontSize: "2.8vw",
    fontWeight: 600,
    textTransform:'uppercase',
    fontFamily:'IBM Plex Sans'
  },
  subtitle: {
    color: "#1C2324",
    opacity: "0.64",
    fontSize: "1.1vw",
    fontWeight: 400,
    fontFamily: 'IBM Plex Sans',
    lineHeight:'1.6vw',
    marginTop:'1.2vw',
  },
  errorText:{
    color:'red',
    fontSize:'1vw',
    padding:'.2rem 0 0 0',
  },
  linkText:{
    color: "#006A77",
    fontWeight: 500,
    textDecoration: "underline",
  },
  OrText:{
    opacity: "0.32",
    fontSize:'1.1vw',
    fontFamiliy: 'IBM Plex Sans',
    fontWeight: 500,
  },
  googleImg:{
    padding: "0 1rem", 
  },
  termsText: {
    fontSize: "1.1vw",
    color: "#1C2324",
    fontWeight: 400,
    fontFamily: "IBM Plex Sans",
    lineHeight:'1.6vw',
  },
  loginImg:{
  width:'100%', 
  height:'100%',
  },
  termsconditionBox:{
    display: "flex", 
    alignItems: "center",
    marginTop: "1.4rem"
  },
  CheckBox:{
    color: "#DF5200",
    width: 30,
    height: 30,
    borderRadius: "8px",
  },
  checkBoxImg:{
    width:'2.2vw',
    height:'2.2vw',
  },
  tickImg:{
    width:'2.2vw',
    height:'2.2vw',
  },
  forgotPassText: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "right",
    fontSize: "1.1vw",
    color: "#006A77",
    fontWeight: 400,
    marginTop: "-0.1rem",
    fontStyle: "italic",
    cursor:'pointer',
    fontFamily: "IBM Plex Sans",
    lineHeight:'1.6vw',
  },
  gooeleLoginBtn: {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "28px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "1.1vw",
    lineHeight: "1.6vw",
    color: "#1C2324",
    opacity: 0.72,
    boxShadow: "none",
    textTransform: 'none',
    height: '3.8vw',
  },
  loginBtnBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0px",
    marginTop: "1rem",
  },
  loginBtn: {
    background: "#DF5200",
    borderRadius: "32px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 700,
    fontSize: "1.2vw",
    lineHeight: "2vw",
    color: "#fff",
    boxShadow: "none",
    height:'3.8vw'
  },
  labels: {
    fontSize: "1vw",
    fontWeight: 400,
    marginBottom: "0.4rem",
    opacity: "0.64",
  },
  orBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "10px 0px",
    margin: "1.5rem 0 1.5rem 0",
  },
  lineImg:{
    width:'12vw',
  },
  logoBox: {
    position: "absolute",
    bottom: "-95%",
    left: "70%",
    zIndex: 10,
  },
  textfieldBox:{
    padding: "1.2vw 0",
  },
  passIcon:{
    color:'#1C2324',
    opacity: 0.32,
  },

};

const LoginTextField = withStyles({
  root: {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "1.1vw",
    lineHeight: "1.6vw",
    "& .Mui:focused": {
      borderColor: "1px solid #1C2324",
    },
    "& .MuiInputBase-input":{
      height:'2vw',
    },
    // "& ::placeholder":{
    //   fontSize: "1.1vw",
    // }
    
  },
})(OutlinedInput);
// Customizable Area End
