import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { debounce } from "lodash";

export interface LibraryAttributes {
  topic: string,
  description: string,
  libraries_faq_id: number
  language: string,
  image: string,
  updated_at: string

}

export interface Library {
  id: string,
  type: string
  attributes: LibraryAttributes
}
let intialFormData = [
  {
    topic: "",
    language: "English",
    description: "",
  },
  {
    topic: "",
    language: "Hindi",
    description: "",
  }, {
    topic: "",
    language: "Gujarati",
    description: "",
  }
]

let initialFaqFormData = [
  {
    question: "",
    language: "English",
    description: "",
  },
  {
    question: "",
    language: "Hindi",
    description: "",
  },
  {
    question: "",
    language: "Gujarati",
    description: "",
  }
]

type SelectLibrary=string|number|null

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedLibrary: number | null
  languageTab: string
  isLibraryModalOpen: boolean
  img_aux: string,
  librariesData: Library[],
  faqsData: any,
  formData: any,
  faqFormData: any,
  libraryFormImage: any
  deleteLiraryModal: boolean
  selectedDeleteLibrary: SelectLibrary
  isAddingLibrary: boolean
  isAddingFaq: boolean
  alertOpen: boolean
  isDeleted: boolean
  isLibraryAdded: boolean
  isFaqAdded: boolean
  isDeleting: boolean
  libraryEditModal: boolean
  selectedEditLibrary: number | null
  selectedVideo: number | null
  librariesDataAll: any
  isSelectedFileBig: boolean
  actionApiError: boolean
  isFetchingLibraryData: boolean
  selectedFaqCategory: any
  faqCategories: any
  faqAddModelOpen: boolean
  faqEditModel: boolean
  selectedEditFaq: number | null
  selectedDeleteFaq: number | null
  deleteFaqModal: boolean
  isDeletingFaq: boolean
  isDeletedFaq: boolean
  faqsDataAll: any
  isFecthingFaqData:boolean
  isShare: boolean,
  linkShare:string
  messageHeading:string
  libraryPage:number
  faqPage:number
  per_page:number
  librarySearchTopic:string
  faqSearchTopic:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllLibraryGetApiCallId: string = "";
  getAllFaqDataGetApiCallId: string = "";
  getFaqCategoryApiCallId: string = "";
  addAllLibraryPostApiCallId: string = "";
  addFaqPostApiCallId: string = "";
  editAllLibraryPatchApiCallId: string = "";
  editAllFaqPatchApiCallId = "";
  deletLibraryDeleteApiCallId: string = "";
  getLibraryApiCallId: string = "";
  faqDeleteApiCallId: string = "";
  getFaqApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedLibrary: null,
      languageTab: "english",
      img_aux: '',
      librariesData: [],
      faqsData: [],
      libraryFormImage: {src:"",file:null},
      isLibraryModalOpen: false,
      selectedDeleteLibrary: null,
      formData: JSON.parse(JSON.stringify(intialFormData)),
      faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)),
      isAddingLibrary: false,
      isAddingFaq: false,
      deleteLiraryModal: false,
      alertOpen: false,
      isDeleted: false,
      isLibraryAdded: false,
      isFaqAdded: false,
      isDeleting: false,
      libraryEditModal: false,
      selectedEditLibrary: null,
      selectedVideo: null,
      librariesDataAll: [],
      isSelectedFileBig: false,
      actionApiError: false,
      isFetchingLibraryData: false,
      selectedFaqCategory: 0,
      faqCategories: [],
      faqAddModelOpen: false,
      faqEditModel: false,
      selectedEditFaq: null,
      selectedDeleteFaq: null,
      deleteFaqModal: false,
      isDeletingFaq: false,
      isDeletedFaq: false,
      faqsDataAll: [],
      isFecthingFaqData:false,
      isShare: false,
      linkShare:'',
      messageHeading:'',
      libraryPage:1,
      faqPage:1,
      per_page:10,
      librarySearchTopic:"",
      faqSearchTopic:""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
      switch (apiRequestCallId) {
        case this.getAllLibraryGetApiCallId:
          this.haddleGetAllLibraries(responseJson)
          break;
        case this.addAllLibraryPostApiCallId:
          if (responseJson?.error) {
            this.setState({ isAddingLibrary: false, actionApiError: true })
            break;
          }
          this.setState({ isAddingLibrary: false, isLibraryAdded: true, actionApiError: false,libraryPage:1,librariesData:[] },()=>{
            this.getAllLibraries()
          })
          setTimeout(this.setStateOnAddLibrary, 2000);
          break;
        case this.addFaqPostApiCallId:
          if (responseJson?.error) {
            this.setState({ isAddingFaq: false, actionApiError: true })
            break;
          }
          this.setState({ isAddingFaq: false, isFaqAdded: true, actionApiError: false,selectedFaqCategory:0,faqPage:1,faqsData:[]},()=>{
            this.getAllFaqs()
          })
          setTimeout(this.setStateOnAddFaq, 2000);
          break;
        case this.deletLibraryDeleteApiCallId:
          if (responseJson?.error) {
            this.setState({ isDeleting: false, actionApiError: true })
            break;
          }
          this.setState({ isDeleting: false, isDeleted: true, actionApiError: false, libraryPage: 1, librariesData: [] }, () => {
            this.getAllLibraries()
          })
          setTimeout(this.setStateOnDeleteLibrary, 2000);
          break;
        case this.editAllLibraryPatchApiCallId:
          if (responseJson?.error) {
            this.setState({ isAddingLibrary: false, actionApiError: true })
            break;
          }
          this.setState({ isAddingLibrary: false, isLibraryAdded: true, actionApiError: false ,libraryPage:1,librariesData:[]},()=>{
            this.getAllLibraries()
          })
          setTimeout(this.setStateOnEditLibrary, 2000);
          break;
        case this.editAllFaqPatchApiCallId:
          if (responseJson?.error) {
            this.setState({ isAddingFaq: false, actionApiError: true })
            break;
          }
          this.setState({ isAddingFaq: false, isFaqAdded: true, faqPage:1,faqsData:[],actionApiError: false },()=>{
            this.getAllFaqs()
          })
          setTimeout(this.setStateOnEditFaq, 2000);
          break;
        case this.getLibraryApiCallId:
          let formData = responseJson?.data.map((item: any) => {
            let { topic, description, language, image } = item.attributes
            return { id: item.id, topic, description, language, image }
          })
          this.setState({ formData: formData, libraryFormImage:{src:formData[0]?.image,file:null} , libraryEditModal: true, selectedEditLibrary: responseJson.data[0].attributes.libraries_faq_id, languageTab: "English" })
          break;
        case this.getAllFaqDataGetApiCallId:
          this.handleGetAllFaqs(responseJson)
          break;
        case this.getFaqCategoryApiCallId:
          this.setState({ faqCategories: responseJson })
          break;
        case this.faqDeleteApiCallId:
          if (responseJson?.error) {
            this.setState({ isDeletingFaq: false, actionApiError: true })
            break;
          }
          this.setState({ isDeletingFaq: false, isDeletedFaq: true,faqPage:1,faqsData:[], actionApiError: false },()=>{
            this.getAllFaqs()
          })
          setTimeout(this.setStateOnDeleteFaq, 2000)
          break;
        case this.getFaqApiCallId:
            let faq_category=responseJson["data"][0]["attributes"]["faq_category_id"]?.id
            let image=responseJson["data"][0]["attributes"]["image"]
            let library_faq_id=responseJson["data"][0]["attributes"]["libraries_faq_id"]?.id
            let faqFormData = responseJson.data.map((item: any) => {
              let { question, description, language } = item.attributes
              return { id: item.id, question, description, language }
            })
            this.setState({ faqFormData,selectedFaqCategory: faq_category,libraryFormImage:{src:image,file:null} , faqEditModel: true, selectedEditFaq:library_faq_id, languageTab: "English" })
            break;
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAllLibraries();
    this.getAllFaqs();
    this.getFaqCategory();
  }
  getToken = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem("tokenn")
    }
    return configJSON.token

  }

  haddleGetAllLibraries=(responseJson:any)=>{
    if(responseJson?.data){
      this.setState({ librariesData: [...this.state.librariesData, ...responseJson.data], librariesDataAll: [...this.state.librariesData, ...responseJson.data], isFetchingLibraryData: false ,libraryPage:this.state.libraryPage+1})
    }
  }

  handleGetAllFaqs=(responseJson:any)=>{
    if(responseJson?.data){
      this.setState({ faqsData:[...this.state.faqsData,...responseJson.data] ,isFecthingFaqData:false ,faqPage:this.state.faqPage+1})
    }
  }

  setStateOnAddLibrary = () => {
    this.setState({ isLibraryAdded: false, formData: JSON.parse(JSON.stringify(intialFormData)), isLibraryModalOpen: false, libraryFormImage: {src:"",file:null}, alertOpen: false,actionApiError:false })
  }
  setStateOnAddFaq = () => {
    this.setState({ isFaqAdded: false, faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), faqAddModelOpen: false, libraryFormImage: {src:"",file:null}, alertOpen: false,actionApiError:false })
  }

  setStateOnEditFaq = () => {
    this.setState({ isFaqAdded: false, faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), faqEditModel: false,selectedFaqCategory:0 ,libraryFormImage: {src:"",file:null}, alertOpen: false,actionApiError:false })
  }
  setStateOnDeleteLibrary = () => {
    this.setState({ deleteLiraryModal: false, isDeleted: false })
  }
  setStateOnDeleteFaq = () => {
    this.setState({ deleteFaqModal: false, isDeletedFaq: false })
  }
setStateOnEditLibrary = () => {
    this.setState({ isLibraryAdded: false, formData: JSON.parse(JSON.stringify(intialFormData)), libraryEditModal: false, libraryFormImage: {src:"",file:null}, alertOpen: false })
  }

  onClickReadMore = (i: number) => {
    this.setState({ selectedLibrary: i })
  }

  onClickReadLess = () => {
    this.setState({ selectedLibrary: null })
  }

  handleButtonCancel = () => {
    this.setState({ isLibraryModalOpen: false, deleteLiraryModal: false, libraryEditModal: false, actionApiError: false, alertOpen: false, formData: JSON.parse(JSON.stringify(intialFormData)), libraryFormImage: {src:"",file:null}, deleteFaqModal: false })
  }
  handleTabChange = (e: any, tab: string) => {
    this.setState({ languageTab: tab })
  }
  handleAddLibrary = () => {
    this.setState({ isLibraryModalOpen: true, languageTab: "english", formData: JSON.parse(JSON.stringify(intialFormData)), alertOpen: false })
  }

  updatedTopicName = (e: any, i: number) => {
    let newFormData = this.state.formData
    newFormData[i].topic = e.target.value
    this.setState({ formData: newFormData, alertOpen: false })
  }

  updatedDescription = (e: any, i: number) => {
    let newFormData = this.state.formData
    newFormData[i].description = e.target.value
    this.setState({ formData: newFormData, alertOpen: false })
  }

  convertFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  handleAlertCancel = () => {
    this.setState({ alertOpen: false, isSelectedFileBig: false })
  }
  debouncedGetLibrariesList = debounce(this.getAllLibraries, 500);

  handleSearch = (e: any) => {
    let value = e.target.value.trim()
    this.setState({librarySearchTopic:value,libraryPage:1,librariesData:[]})
    this.debouncedGetLibrariesList()
  }

  debouncedGetFaqsList = debounce(this.getAllFaqs, 500);

  handleFaqSearch = (e: any) => {
    let value = e.target.value.trim()
    this.setState({faqSearchTopic:value,faqPage:1,faqsData:[]})
    this.debouncedGetFaqsList()
  }

  handleShareLink =(materrapediaObj?:any) => { 
    let shareLink;
    let heading;
    if (materrapediaObj) {
      if (materrapediaObj.type === 'faqs') {
        shareLink = configJSON.materrapediaShareLink + 'materrapediafaq/' + materrapediaObj.type + '/' + materrapediaObj.attributes.faq_category_id.id + '/' + materrapediaObj.id
        heading = materrapediaObj.type
      } else {
        shareLink = configJSON.materrapediaShareLink + 'materrapedialibraries/' + materrapediaObj.type + '/' + materrapediaObj.id
        heading = `${materrapediaObj.type} : ` + materrapediaObj.attributes?.topic
      }
      this.setState({
        isShare: !this.state.isShare,
        linkShare: shareLink,
        messageHeading: heading
      })
    } else {
      return
    }     
  }

  handleImageSelect = async (event: React.ChangeEvent<any>) => {
    const file: File = event.target.files[0]
    let mediaType = file.type.includes("video") ? "video" : "image"
    if ((file.size / (1024 * 1024) > 250)) {
      this.setState({ isSelectedFileBig: true })
      return
    }
    let base64Url = await this.convertFileToBase64(file)
    this.setState({ libraryFormImage: {src:base64Url,file} })
  }


  handleClickDeleteIcon = (libraries_faq_id: number) => {
    this.setState({ selectedDeleteLibrary: libraries_faq_id, deleteLiraryModal: true })
  }

  handleClickFaqDeleteIcon = (id: number) => {
    this.setState({ selectedDeleteFaq: id, deleteFaqModal: true })
  }

  handleClickFaqEditIcon = (id: number) => {
    if (!id) return null;
    this.getFaq(id)
    this.setState({ selectedEditFaq: id })
  }


  getFileTypeFromRegularUrl = (url: string) => {
    const extension: string = url.split('.').pop()?.toLowerCase() || '';
    const imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions: string[] = ['mp4', 'avi', 'mov', 'wmv', 'flv', "webm", "mkv"];

    if (videoExtensions.includes(extension)) {
      return "video"
    }
    if (imageExtensions.includes(extension)) {
      return "img"
    }
    return null
  }

  getFileTypeFromBase64Url = (base64Url: any) => {
    const match = base64Url.match(/^data:(.+);base64,/);
    if (match && match[1].startsWith('image/')) {
      return "img"
    }
    if (match && match[1].startsWith('video/')) {
      return "video"
    }
    return null
  }


  checkMediaType = (url: string): any => {
    if (!url) return null
    if (url.startsWith("http")) {
      return this.getFileTypeFromRegularUrl(url)
    } else {
      return this.getFileTypeFromBase64Url(url)
    }
  }

  handleClickEdit = async (libraries_faq_id: number, language: string) => {
    if (!libraries_faq_id) return null
    this.getLibrary(libraries_faq_id)
    this.setState({ selectedEditLibrary: libraries_faq_id })
}

  librarySubmissionValidation = (): boolean => {
    let valid = true
    for (let item of this.state.formData) {
         if (!item.topic.trim() || !item.description.trim() || !this.state.libraryFormImage?.src) {
        valid = false
        this.setState({languageTab:item.language})
        break
      }
  }
    return valid
  }

  handleAddFaqForm = () => {
    this.setState({ faqAddModelOpen: true , languageTab: "english", faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), alertOpen: false,selectedFaqCategory:0,libraryFormImage:{src:"",file:null},actionApiError:false})
  }
  handleEventChange = (e: any) => {
    this.setState({ selectedFaqCategory: e?.target?.value })
  }
  updatedFaqQuestionName = (e: any, index:number) => {
    let newFaqFormData = this.state.faqFormData
    newFaqFormData[index].question = e.target.value
    this.setState({ faqFormData: newFaqFormData, alertOpen: false })
  }
  updatedFaqDescription = (e: any , index:number) => {
    let newFaqFormData = this.state.faqFormData
    newFaqFormData[index].description = e.target.value
    this.setState({ faqFormData: newFaqFormData, alertOpen: false })
  }
 
  handleFaqButtonCancel = () => {
    this.setState({ faqAddModelOpen: false ,faqEditModel:false,faqFormData:JSON.parse(JSON.stringify(initialFaqFormData)),libraryFormImage:{src:"",file:null},actionApiError:false,selectedFaqCategory:0})
  }
  handleFaqDeleteImage = () => {
  this.setState({libraryFormImage:""})
  }
  faqSubmissionValidation = (): boolean => {
    let valid = true;
    for (let item of this.state.faqFormData) {
      if (!item.question.trim() || !item.description.trim() || !this.state.selectedFaqCategory) {
     valid = false
     this.setState({languageTab:item.language})
     break
   }
}
    return valid;
  };

  getAllLibraries() {
    this.setState({ isFetchingLibraryData: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
      language: "English"
    };
    this.getAllLibraryGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLibraryAPiEndPoint+`?page=${this.state.libraryPage}&per_page=${this.state.per_page}&topic=${this.state.librarySearchTopic}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllFaqs() {
    this.setState({isFecthingFaqData:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.GetLibraryFaqsApiContentType,
      token: this.getToken(),
      language: "English"
    };
    this.getAllFaqDataGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLibraryFaqsAPiEndPoint+`?page=${this.state.faqPage}&per_page=${this.state.per_page}&topic=${this.state.faqSearchTopic}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getLibraryFaqsMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaqCategory() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.GetFaqCategoryApiContentType,
      token: this.getToken(),
    };
    this.getFaqCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqCategoryAPiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getFaqCategoryMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addNewTOpic = () => {
    let isValid = this.librarySubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingLibrary: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.addAllLibraryPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    let reqFormdata = new FormData();
    let formData=[...this.state.formData]
    formData.forEach((item,i)=>{
      reqFormdata.append(`libraries[${i}][topic]`,item.topic)
      reqFormdata.append(`libraries[${i}][language]`,item.language)
      reqFormdata.append(`libraries[${i}][description]`,item.description)
      reqFormdata.append(`libraries[${i}][image]`,this.state.libraryFormImage.file)
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editTopic = async () => {
    let isValid = this.librarySubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingLibrary: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.editAllLibraryPatchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
  
    let reqFormdata = new FormData();
    let formData=[...this.state.formData]
    formData.forEach((item,i)=>{
      reqFormdata.append(`libraries[${i}][topic]`,item.topic)
      reqFormdata.append(`libraries[${i}][id]`,item.id)
      reqFormdata.append(`libraries[${i}][language]`,item.language)
      reqFormdata.append(`libraries[${i}][description]`,item.description)
      if(!this.state.libraryFormImage?.src.startsWith("http")){
        reqFormdata.append(`libraries[${i}][image]`,this.state.libraryFormImage.file)
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint + `/${this.state.selectedEditLibrary}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editFaq = async () => {
    let isValid = this.faqSubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingFaq: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.editAllFaqPatchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    let reqFormdata = new FormData();
    let formData=[...this.state.faqFormData]
    formData.forEach((item,i)=>{
      reqFormdata.append(`faqs[${i}][faq_category_id]`,this.state.selectedFaqCategory)
      reqFormdata.append(`faqs[${i}][language]`,item.language)
      reqFormdata.append(`faqs[${i}][id]`,item.id)
      reqFormdata.append(`faqs[${i}][description]`,item.description)
      reqFormdata.append(`faqs[${i}][question]`,item.question)
      if(this.state.libraryFormImage?.file && !this.state.libraryFormImage?.src?.startsWith("http")){
        reqFormdata.append(`faqs[${i}][image]`,this.state.libraryFormImage.file)
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint  + `/${this.state.selectedEditFaq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addFaqTopic = () => {
    let isValid = this.faqSubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingFaq: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.addFaqPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    let reqFormdata = new FormData();
    let formData=[...this.state.faqFormData]
    formData.forEach((item,i)=>{
      reqFormdata.append(`faqs[${i}][faq_category_id]`,this.state.selectedFaqCategory)
      reqFormdata.append(`faqs[${i}][language]`,item.language)
      reqFormdata.append(`faqs[${i}][description]`,item.description)
      reqFormdata.append(`faqs[${i}][question]`,item.question)
      if(this.state.libraryFormImage?.file){
        reqFormdata.append(`faqs[${i}][image]`,this.state.libraryFormImage.file)
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postFaqMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTopic = () => {
    this.setState({ isDeleting: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.deletLibraryDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint + `/${this.state.selectedDeleteLibrary}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteFaq = () => {
    this.setState({ isDeletingFaq: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.faqApiContentType,
      token: this.getToken(),
    };
    this.faqDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint + `/${this.state.selectedDeleteFaq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteFaqMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLibrary(id: number) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.getLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLibraryByFaqIdAPiEndPoint + `?libraries_faq_id=${id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaq(id: number) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.getFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint + `?libraries_faq_id=${id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
