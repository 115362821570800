import React, { useEffect, useState, useRef } from 'react';
import {
    GoogleMap, LoadScript, Polygon, Polyline, OverlayView, Marker
} from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    TextField,
    InputAdornment,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import './FarmMapping.web.css';
import { LeftRoundIconSVG, MapEndLocationIconSVG, MapStartSearchIconSVG, RightRoundIconSVG } from '../../../blocks/AdminConsole/src/assets';

interface LatLng {
    lat: number;
    lng: number;
}

type StaticMapParams = {
    center: string;
    zoom: number;
    size: string;
    coords?: LatLng[];
    key: string; // Your Google Maps API Key
};
interface FarmMappingProps {
    onSave: (latlngHash: string, file: any, url: string, coordinates: LatLng[]) => void,
    CoordinatesArr: LatLng[] | [],
    markerDefault: LatLng,
    userLocation: LatLng,
    isEditModal: boolean,
    closeFarmMapping: any,
    OpenFarmMapping: boolean,
}

const mapStyles = {
    height: '400px',
    width: '100%',
    borderRadius: '12px',
};

export const getPolygonCentroid = (coords: LatLng[]) => {
    let centroid = { lat: 0, lng: 0 };
    coords.forEach((coord) => {
        centroid.lat += coord.lat;
        centroid.lng += coord.lng;
    });
    centroid.lat /= coords.length;
    centroid.lng /= coords.length;
    return centroid;
};

const FarmMapping = (props: FarmMappingProps) => {
    const { onSave, CoordinatesArr, isEditModal, markerDefault, userLocation, closeFarmMapping, OpenFarmMapping } = props
    const [coordinates, setCoordinates] = useState<LatLng[]>(CoordinatesArr?.length ? CoordinatesArr : []);
    const [search, setSearch] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [zoom, setZoom] = useState(16);
    const [history, setHistory] = useState<LatLng[][]>(CoordinatesArr?.length ? [CoordinatesArr] : []);
    const [historyIndex, setHistoryIndex] = useState<number>(CoordinatesArr?.length ? 0 : -1);
    const [tempCoordinate, setTempCoordinate] = useState<LatLng | null>(null);
    const [polylines, setPolylines] = useState<any[]>([]);
    const [center, setCenter] = useState<LatLng>(markerDefault ? markerDefault : { lat: 22.7, lng: 75.8 });
    const [marker, setMarker] = useState<LatLng | null>(null);
    const [totalAcres, setTotalAcres] = useState<any>(null);
    const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
    const [countArea, setCountArea] = useState(true);
    const mapRef = useRef(null);

    useEffect(() => {
        const checkInterval = setInterval(() => {
            if (window.google && window.google.maps.geometry) {
                // Perform your calculations or operations here
                // Optionally, you can clear the interval if you no longer want to 
                setIsGoogleScriptLoaded(true);
                clearInterval(checkInterval);
            }
        }, 1000); // Check every 1000ms (1 second)

        // Cleanup on component unmount
        return () => {
            clearInterval(checkInterval);
        };
    }, []);

    useEffect(() => {
        if (isGoogleScriptLoaded && CoordinatesArr.length && OpenFarmMapping) {
            const centroid = getPolygonCentroid(CoordinatesArr);
            setCenter(centroid);
            setMarker(centroid);
        }
    }, [isEditModal, CoordinatesArr, isGoogleScriptLoaded, OpenFarmMapping])

    useEffect(() => {
        if (isGoogleScriptLoaded && userLocation && !coordinates.length) {
            setMarker(userLocation);
            setCenter(userLocation);
            fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + userLocation.lat + ',' + userLocation.lng + '&key=AIzaSyDbaGDdgArWGFmY6cXieJngZkdTFkq8Zb4')
                .then(response => response.json()) // parse the response as JSON
                .then(data => {
                    const addressVal = data && data?.results && data.results[0].formatted_address;
                    if (addressVal)
                        setAddress(addressVal)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [userLocation, isGoogleScriptLoaded])

    useEffect(() => {
        if (isGoogleScriptLoaded && countArea) {
            calculateAndSetPolygonArea(CoordinatesArr);
        }
    }, [marker, isEditModal, CoordinatesArr, isGoogleScriptLoaded, coordinates]);

    const calculateAndSetPolygonArea = (data: any) => {
        const area = calculatePolygonArea(data);
        if (area) {
            setTotalAcres(area.toFixed(2));
        }
    }

    // Define all the methods here
    const resetCoords = () => {
        setCoordinates([]);
        setHistory([]);
        setHistoryIndex(-1);
        setTempCoordinate(null);
        setPolylines([]);
        setTotalAcres(null)
    };

    const handleZoomChange = () => {
        if (mapRef.current && isGoogleScriptLoaded) {
            const currentMap: any = mapRef.current;
            setZoom(currentMap.getZoom());
        }
    }

    const moveTempCoordinate = (event: any) => {
        if (coordinates?.length === 4) {
            setTempCoordinate(null);
            return;
        }
        const currentPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        setTempCoordinate(currentPosition);
    };

    const onSearchChange = (search: string) => {
        setSearch(search);
    };

    const renderCircle = (coordinate: LatLng) => {
        const mapPaneName = OverlayView.OVERLAY_MOUSE_TARGET;
        return (
            <OverlayView
                key={`${coordinate.lat}-${coordinate.lng}`}
                position={coordinate}
                mapPaneName={mapPaneName}
            >
                <div
                    style={{
                        width: "10px",
                        height: "10px",
                        background: "#E4DDC9",
                        borderRadius: "50%",
                        boxShadow: "0 0 2px #000",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        marginLeft: "-5px", // Half of width
                        marginTop: "-5px" // Half of height
                    }}
                ></div>
            </OverlayView>
        );
    };

    const renderMarker = (marker: any, address: any) => {
        const showEllipses = address.length > 16; // Assuming you want ellipses for more than 20 characters
        const displayedText = showEllipses
            ? `${address.substring(0, 12)}...`
            : address;

        return (<OverlayView
            position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div style={{
                position: 'relative',
                transform: 'translate(-50%, -50%)', textAlign: 'center', fontFamily: 'IBM Plex Sans', fontSize: '16px', color: '#ffffff'
            }}>
                <div style={{ marginBottom: '5px' }}>{`${displayedText} ${totalAcres ? totalAcres + 'Acres' : ''}`}</div>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.167 3.33331C12.6045 3.33331 6.66699 8.5152 6.66699 16.5294C6.66699 21.647 10.4951 27.6657 18.1357 34.6017C18.7295 35.1327 19.6201 35.1327 20.2139 34.6017C27.8389 27.6657 31.667 21.647 31.667 16.5294C31.667 8.5152 25.7295 3.33331 19.167 3.33331ZM19.167 19.4261C17.4482 19.4261 16.042 17.9778 16.042 16.2076C16.042 14.4374 17.4482 12.989 19.167 12.989C20.8857 12.989 22.292 14.4374 22.292 16.2076C22.292 17.9778 20.8857 19.4261 19.167 19.4261Z" fill="white" />
                </svg>
            </div>
        </OverlayView>)
    }

    const updateAllPolyLines = (newCoordinates: LatLng[]) => {
        const polylines = [];

        if (!newCoordinates || !newCoordinates.length) {
            return;
        }
        for (let i = 0; i < newCoordinates.length - 1; i++) {
            const point1 = newCoordinates[i];
            const point2 = newCoordinates[i + 1];

            polylines.push(
                <Polyline
                    path={[point1, point2]}
                    options={{
                        strokeColor: "#DF5200",
                        strokeOpacity: 1,
                        strokeWeight: 2
                    }}
                    key={i}
                />
            );
        }
        return polylines;
    };

    const calculatePolygonArea = (path: LatLng[]) => {
        if (window.google && window.google.maps.geometry) {
            const pathCopy: any = JSON.parse(JSON.stringify(path));
            const googlePath = pathCopy.map((pt: any) => new window.google.maps.LatLng(pt.lat, pt.lng));
            const area = window.google.maps.geometry.spherical.computeArea(googlePath);

            // Convert square meters to acres (1 acre is approximately 4046.86 square meters)
            const areaInAcres = area / 4046.86;

            return areaInAcres;
        }
    };

    const addLatLng = (event: any) => {
        // if (coordinates.length === 4) {
        //     return;
        // }
        const clickedPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        const newCoordinates = [...coordinates, clickedPosition];

        if (newCoordinates.length === 4) {
            const centroid = getPolygonCentroid(newCoordinates);
            setMarker(centroid);

        }

        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(newCoordinates);
        const polylines: any = updateAllPolyLines(newCoordinates);

        setCoordinates(newCoordinates);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
        setTempCoordinate(null);
        setPolylines(polylines);
        calculateAndSetPolygonArea(newCoordinates);
    };

    const onSearchSelect = (address: string) => {
        geocodeByAddress(address)
            .then((results: any) => getLatLng(results[0]))
            .then(({ lat, lng }: LatLng) => {
                resetCoords();
                setCenter({ lat, lng });
                setMarker({ lat, lng });
                setAddress(address);
                setSearch(address);
                setTotalAcres(null);
            });
    };

    const undo = () => {
        if (historyIndex >= 0) {
            const newCoordinates = history[historyIndex - 1] || [];
            const polylines: any = updateAllPolyLines(newCoordinates);
            setCoordinates(newCoordinates);
            setHistoryIndex(historyIndex - 1);
            setPolylines(polylines);
            setCountArea(false)
            if (newCoordinates.length > 3) {
                calculateAndSetPolygonArea(newCoordinates);
            } else {
                setTotalAcres(null);
            }
        }
    };

    const redo = () => {
        if (historyIndex < history?.length - 1) {
            const newCoordinates = history[historyIndex + 1] || [];
            const polylines: any = updateAllPolyLines(newCoordinates);
            setCoordinates(newCoordinates);
            setHistoryIndex(historyIndex + 1);
            setPolylines(polylines);
            setCountArea(false)
            if (newCoordinates.length > 3) {
                calculateAndSetPolygonArea(newCoordinates);
            } else {
                setTotalAcres(null);
            }
        }
    };


    let tempPath: any = [];

    if (coordinates.length > 0) {
        const lastCoordinate = coordinates[coordinates.length - 1];
        tempPath = [lastCoordinate, tempCoordinate];
    }

    const circles = coordinates.map(renderCircle);

    const saveCropMapping = () => {
        let newCoordinates = [];
        // Loop through each object in the original array and modify the keys
        for (let i = 0; i < coordinates.length; i++) {
            let modifiedCoord = {
                "latitude": coordinates[i].lat,
                "longitude": coordinates[i].lng
            };
            newCoordinates.push(modifiedCoord);
        }

        // convert array to hash
        if (newCoordinates && newCoordinates.length > 3) {
            let latlngHash: any = {};
            let key;

            for (let i = 0; i < newCoordinates.length; i++) {
                key = i + '';
                latlngHash[key] = newCoordinates[i];
            }
            let reqBodylatlngHash = JSON.stringify(latlngHash)
            captureMap(newCoordinates, (file: any, url: string) => onSave(reqBodylatlngHash, file, url, coordinates));

        }

        // image 
    }

    const urlToFileObject = async (url: any, filename = "farmMapping.jpg", mimeType = "image/jpeg") => {
        // Fetch the image from the URL
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        // Convert the fetched image to a blob
        const blob = await response.blob();

        // Create a new File object from the Blob
        const file = new File([blob], filename, { type: mimeType });
        return file;
    };

    const captureMap = (coordinates: any[], successCallBack: any) => {
        const BASE_URL = "https://maps.googleapis.com/maps/api/staticmap?";

        const size = "600x640";
        const bounds = new google.maps.LatLngBounds();

        const convertedCoordinates = coordinates.map(coord => ({
            lat: coord.latitude,
            lng: coord.longitude
        }));

        convertedCoordinates.forEach((coord: any) => {
            bounds.extend(coord);
        });
        closeFarmMapping();

        const polyCenter = bounds.getCenter();
        const mapId: any = document.getElementById('mapId');
        const dummyMap = new google.maps.Map(mapId);  // Using a dummy div
        dummyMap.fitBounds(bounds);

        google.maps.event.addListenerOnce(dummyMap, 'bounds_changed', function () {
            let zoomLevel: any = dummyMap.getZoom();
            zoomLevel = zoomLevel - 1;
            // Polygon Styles
            const polygonFillColor = "0x000000"; // As defined in your component
            const polygonFillOpacity = 40; // 0.4 * 100 = 40 (In percentage, 0.4 becomes 40)
            const polylineColor = "0xDF5200";
            const polylineWeight = "2";

            let url = `${BASE_URL}center=${polyCenter?.lat()},${polyCenter?.lng()}&zoom=${zoomLevel}&size=${size}&maptype=satellite`;

            // Adding circles for each coordinate using markers
            coordinates.forEach((coord) => {
                url += `&markers=color:white%7Csize:tiny%7C${coord.latitude},${coord.longitude}`;
            });

            url += `&path=fillcolor:${polygonFillColor}${polygonFillOpacity}|color:${polylineColor}|weight:${polylineWeight}`;

            coordinates.forEach((coord) => {
                url += `|${coord.latitude},${coord.longitude}`;
            });

            if (coordinates.length >= 3) {
                url += `|${coordinates[0].latitude},${coordinates[0].longitude}`;
            }

            url += `&key=${'AIzaSyDbaGDdgArWGFmY6cXieJngZkdTFkq8Zb4'}`;

            urlToFileObject(url)
                .then((file) => {
                    successCallBack(file, url)
                })
                .catch((error) => {
                    console.error("There was a problem fetching the image:", error);
                });
            return url;

        });

    };


    return (
        <div>
            <LoadScript
                googleMapsApiKey={"AIzaSyDbaGDdgArWGFmY6cXieJngZkdTFkq8Zb4"}
                libraries={["places", "geometry"]}
            >
                <div id="farm-mapping" style={{ padding: '0 32px' }}>
                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={zoom}
                        id='mapId'
                        center={center}
                        onLoad={(map: any) => {
                            // Storing the loaded map instance in the ref for future use
                            mapRef.current = map;
                        }}
                        onClick={addLatLng}
                        onMouseMove={moveTempCoordinate}
                        onZoomChanged={handleZoomChange}
                        options={{
                            mapTypeControl: false,
                            zoomControl: false,
                            streetViewControl: false,
                            keyboardShortcuts: false,
                            clickableIcons: false,
                            mapTypeId: "satellite",
                            fullscreenControl: false,
                        }}
                    >
                        {circles.length >= 2 && circles}

                        {!!marker && renderMarker(marker, address)}

                        {polylines?.length >= 1 &&
                            coordinates?.length < 4 &&
                            polylines}

                        {tempPath.length === 2 && coordinates.length < 4 ? (
                            <Polyline
                                path={tempPath}
                                options={{
                                    strokeColor: "#DF5200",
                                    strokeOpacity: 1,
                                    strokeWeight: 2
                                }}
                                onClick={addLatLng}
                            />
                        ) : null}

                        {coordinates?.length >= 4 ? (
                            <Polygon
                                path={coordinates}
                                options={{
                                    fillColor: "#000000",
                                    fillOpacity: 0.4,
                                    strokeColor: "#DF5200",
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2
                                }}
                            />
                        ) : null}
                        <div className="map-overlay">
                            <PlacesAutocomplete
                                value={search}
                                onChange={onSearchChange}
                                onSelect={onSearchSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading
                                }: any) => (
                                    <>
                                        <div className="search-input">
                                            <TextField
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <img src={MapStartSearchIconSVG} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img src={MapEndLocationIconSVG} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...getInputProps({
                                                    placeholder: 'Search your location',
                                                    className: 'autocomplete-input',
                                                })}
                                            />
                                        </div>
                                        <div className="suggestions-dropdown">
                                            {loading && <div className="suggestion-item"><Typography style={{ fontFamily: "IBM Plex Sans" }}>Loading...</Typography></div>}
                                            {suggestions.map((suggestion: any) => (
                                                <div
                                                    {...getSuggestionItemProps(suggestion)}
                                                    className="suggestion-item"
                                                >
                                                    <Typography style={{ fontFamily: "IBM Plex Sans" }}> {suggestion.description}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </PlacesAutocomplete>
                            <Tooltip title={"Undo"}>
                                <IconButton onClick={undo} disableRipple style={{ padding: "0px 4px" }}>
                                    <img src={LeftRoundIconSVG} className='round-icon' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Redo"}>
                                <IconButton onClick={redo} disableRipple style={{ padding: "0px 4px" }}>
                                    <img src={RightRoundIconSVG} className='round-icon' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Clear"}>
                                <IconButton
                                    onClick={() => {
                                        resetCoords()
                                        setMarker(null)
                                        setSearch('')
                                        setAddress('')
                                    }}
                                    disableRipple
                                    style={{ padding: "0px 0px 0px 4px" }}
                                >
                                    <CloseIcon className='round-icon' style={{ width: "32px", height: "32px", color: "#fff" }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </GoogleMap>
                </div>
            </LoadScript>
            <Box className="farmMappingSubmitButton" my={3} px={4}>
                <Button
                    variant="contained"
                    className="modalSubmitButton"
                    fullWidth
                    onClick={saveCropMapping}
                    disabled={coordinates.length > 3 ? false : true}
                >
                    Save
                </Button>
            </Box>
        </div>
    );
};

export default FarmMapping;
