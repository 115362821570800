import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    error: string;
    loading: boolean;
    token: string;
    dashboard: any;
    chatCount: string;
    dateArray: string[];
    geolocation: string;
    locationError: string;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {

    static instance: LandingPageController;
    landingPageCallId: string = "";
    locationCallId: string = "";

    constructor(props: Props) {
        super(props);
        LandingPageController.instance = this;

        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            error: "",
            loading: false,
            token: "",
            dashboard: "",
            chatCount: "10",
            dateArray: [],
            geolocation: '',
            locationError: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.getDate();
        this.getLocation();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
            });
        }
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    getDate() {
        const dateArray: string[] = new Date().toDateString().split(' ').map((_: string, int) => {
            const days: Record<string, string> = {
                "mon": "Monday",
                "tue": "Tuesday",
                "wed": "Wednesday",
                thu: "Thursday",
                'fri': "Friday",
                'sat': "Saturday",
                sun: "Sunday",
            }

            const months: Record<string, string> = {
                "jan": "January",
                "feb": "February",
                "mar": 'March',
                "apr": "April",
                'may': "May",
                'jun': "June",
                "jul": "July",
                "aug": "August",
                "sep": "September",
                "oct": "October",
                "nov": "November",
                "dec": "December",
            }

            switch (int) {
                case 0:
                    return days[_.toLowerCase()];
                case 1:
                    return months[_.toLowerCase()];
                default:
                    return _
            }
        });
        this.setState(prev => {
            return { ...prev, dateArray }
        });
    }

    getLocation() {
        const success = (pos: any) => {
            this.getCurrentLocation(pos.coords.latitude, pos.coords.longitude);
        }
        const error = (error: any) => {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.setState({ locationError: "User has denied request for geolocation", geolocation: "Unknown" });
                    break;

                case error.POSITION_UNAVAILABLE:
                    this.setState({ locationError: "Location information is unavailable", geolocation: "Unavailable" });
                    break;

                case error.TIMEOUT:
                    this.setState({ locationError: "The location request timed out", geolocation: "Unknown" });
                    break;

                default:
                    this.setState({ locationError: "The location feature is not supported by this browser, please check your system preferences to allow location sharing", geolocation: "Unknown" });
            }
        }
        navigator.geolocation.getCurrentPosition(success, error);
    }

    getCurrentLocation(latitude: number, longitude: number) {
        // Customizable Area Start
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.landingPageCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getLocationUrl}?latitude=${latitude}&longitude=${longitude}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
    }

    setLocation(successResponse: any) {
        this.setState(prev => ({ ...prev, geolocation: successResponse.address.city ? successResponse.address.city : successResponse.address.state }));
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            const otp_token = window.localStorage.getItem("tokenn");
            this.setState({ token: token ? token : otp_token });
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const successResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.landingPageCallId) {
                if (successResponse && !successResponse.errors && successResponse.data) {
                    this.setLocation(successResponse.data)
                } else {
                    if (errorResponse === undefined) {
                        this.setState({
                            error: 'Something went wrong',
                            loading: false
                        });
                    }
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

}