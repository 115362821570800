import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { serialize } from "object-to-formdata";
export const configJSONBase = require("../../../framework/src/config");

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    error: string;
    loading: boolean;
    token: string;
    dashboard: string;
    open: boolean;
    edit: boolean;
    alert_open: boolean;
    errorDis: string;
    delete_open: boolean;
    global_start_day: number;
    global_end_day: number;

    languageTab: number;
    phases_attributes: TPhase[];
    activityImg: any;
    activityId: string;

    input_details_attributes_key: string;
    input_details_attributes_value: string;
    input_attributes: Record<"input_details_attributes_key" | "input_details_attributes_value", string>[],
    activity_profile_attributes: TActivityProfile[];
    sub_activity_profile_attributes: Record<string, TSubActivityProfile[]>;
    phase_group_collection: TPhaseProfile[];
    group_id: string;
    form_loading: boolean;
    button_loader: boolean;
    img_aux: string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export type TSubActivityProfile = {
    id: number,
    name_array: string[];
    _destroy?: 1
};

export type TActivityProfile = {
    id: string;
    type: string;
    activity_name_array: string[];
};

export type TSubActivityAPIProfile = {
    "id": number;
    "name": string;
    "phase_activity_id": number;
    "created_at": string;
    "updated_at": string;
    "name_hindi": string;
    "name_gujarati": string;
};

export type TActivityAPIProfile = {
    "id": string;
    "type": string;
    "attributes": {
        "name": string;
        "name_hindi": string;
        "name_gujarati": string;
        "phase_sub_activity_name": TSubActivityAPIProfile[];
    }
};

export type TChecker = {
    "check": boolean,
    "text": string
}

export type TInputAttributes = {
    id?: number;
    key: string;
    value: string;
    destroy?: boolean;
    _destroy?: number;
};

export type TSubActivity = {
    id?: string,
    phase_sub_activity_name: string,
    phase_sub_activity_id: number | string,
    before_after_day: number | string,
    description: string;
    file?: File | null;
    attachment?: string;
    _destroy?: number,
    is_completed: boolean;
    input_details_attributes: TInputAttributes[]
}

export type TActivity = {
    id?: string,
    "phase_activity_name": string,
    "phase_activity_id": number | string,
    "start_day": number | string,
    "end_day": number | string,
    "_destroy"?: number,
    sub_activity_progresses_attributes: TSubActivity[]
}

export type TPhase = {
    id?: string,
    "language": string,
    "name": string,
    "start_day": number | string,
    "end_day": number | string,
    phase_activity_progresses_attributes: TActivity[]
}

export type TPhaseProfile = {
    "id": string;
    "type": string;
    "attributes": {
        "name": string,
        "start_day": number,
        "end_day": number,
        "language": string,
        "sowing_date": null | Date,
        "days": number[],
        "phase_activity_progresses": Record<"data", TPhaseActivityProfile[]>
    };
    phase_group_id: string;
}

export type TPhaseProfileAPI = {
    "id": string;
    "type": string;
    "attributes": {
        "name": string,
        "start_day": number,
        "end_day": number,
        "language": string,
        "sowing_date": null | Date,
        "days": number[],
        "phase_activity_progresses": Record<"data", TPhaseActivityProfile[]>
    };
    phase_group_id: string;
}

export type TPhaseActivityProfile = {
    "id": string,
    "type": string,
    "attributes": {
        "start_day": number,
        "end_day": number,
        "phase_id": number,
        "phase_activity_id": number,
        "percentage": string,
        "phase_activity_name": string,
        "phase_sub_activity_progresses": Record<"data", TPhaseSubActivityProfile[]>
    }
}

export type TPhaseSubActivityProfile = {
    "id": string,
    "type": string,
    "attributes": {
        "phase_sub_activity_id": number,
        "phase_activity_progress_id": number,
        "before_after_day": number,
        "attachment": any,
        "is_completed": boolean,
        "description": string,
        "farmer_id": string | null,
        "phase_sub_activity_progress_name": string,
        "input_details": TPhaseSubActivityProfileInput[]
    }
}

export type TPhaseSubActivityProfileInput = {
    "id": number,
    "key": string,
    "value": string,
    "sub_activity_progress_id": number,
    "created_at": string | Date,
    "updated_at": string | Date
}

export type TPhaseGroup = {
    id: string;
    type: string;
    attributes: {
        owner: Record<string, any>;
        owner_type: string;
        phase: Record<"data", TPhaseProfile[]>;
    }
}

export type TRange = {
    start: number;
    end: number;
}

export type TResult = {
    numbersInRangeWithPairIndex: { [key: number]: number[] };
    numbersNotInRanges: number[];
}

export default class CalendarScreenController extends BlockComponent<Props, S, SS> {

    static instance: CalendarScreenController;
    activityCreateItemCallId: string = '';
    subActivityCreateItemCallId: string = '';
    groupGetItemCallId: string = '';
    phaseGroupGetItemCallId: string = '';
    activityGetItemCallId: string = '';
    phaseCreateItemCallId: string = "";
    phaseGroupDeleteItemCallId: string = "";
    phaseGroupPatchItemCallId: string = "";
    phases_attributes_data: TPhase[] = [
        {
            "language": "English",
            "name": "",
            "start_day": "",
            "end_day": "",
            "phase_activity_progresses_attributes": [
                {
                    "phase_activity_name": '',
                    "phase_activity_id": "",
                    "start_day": "",
                    "end_day": "",
                    "sub_activity_progresses_attributes": [{
                        phase_sub_activity_name: '',
                        "phase_sub_activity_id": "",
                        "before_after_day": "",
                        file: null,
                        attachment: "",
                        "description": "",
                        "is_completed": false,
                        "input_details_attributes": []
                    }]
                }
            ]
        },
        {
            "language": "Gujarati",
            "name": "",
            "start_day": "",
            "end_day": "",
            "phase_activity_progresses_attributes": [
                {
                    "phase_activity_name": '',
                    "phase_activity_id": "",
                    "start_day": "",
                    "end_day": "",
                    "sub_activity_progresses_attributes": [{
                        phase_sub_activity_name: '',
                        "phase_sub_activity_id": "",
                        "before_after_day": "",
                        file: null,
                        attachment: "",
                        "description": "",
                        "is_completed": false,
                        "input_details_attributes": []
                    }]
                }
            ]
        },
        {
            "language": "Hindi",
            "name": "",
            "start_day": "",
            "end_day": "",
            "phase_activity_progresses_attributes": [
                {
                    "phase_activity_name": '',
                    "phase_activity_id": "",
                    "start_day": "",
                    "end_day": "",
                    "sub_activity_progresses_attributes": [{
                        phase_sub_activity_name: '',
                        "phase_sub_activity_id": "",
                        "before_after_day": "",
                        file: null,
                        attachment: "",
                        "description": "",
                        "is_completed": false,
                        "input_details_attributes": []
                    }]
                }
            ]
        }
    ];

    constructor(props: Props) {
        super(props);
        CalendarScreenController.instance = this;

        this.receive = this.receive.bind(this);
        this.assignId = this.assignId.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            error: "",
            loading: false,
            errorDis: '',
            token: "",
            dashboard: "",
            open: false,
            edit: false,
            alert_open: false,
            delete_open: false,
            global_start_day: -60,
            global_end_day: 120,

            languageTab: 0,
            phases_attributes: this.phases_attributes_data,
            activityImg: "",
            activityId: 'none',

            input_details_attributes_key: "",
            input_details_attributes_value: "",
            input_attributes: [{ input_details_attributes_key: "", input_details_attributes_value: "" }, { input_details_attributes_key: "", input_details_attributes_value: "" }, { input_details_attributes_key: "", input_details_attributes_value: "" }],
            activity_profile_attributes: [],
            sub_activity_profile_attributes: {},
            phase_group_collection: [],
            group_id: "",
            form_loading: false,
            button_loader: false,
            img_aux: ''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
            });
        }
        // Customizable Area Start
        this.getGroups();
        this.getActivity();
        // Customizable Area End
    }

    openModal = () => {
        this.setState({
            phases_attributes: this.phases_attributes_data, delete_open: false, edit: false, open: true,
        });
    }

    openDeleteModal = (id: string) => {
        this.setState({ open: false, delete_open: true, edit: false, group_id: id });
    }

    assignId = (group_id: string) => {
        this.setState({ open: false, delete_open: false, edit: true, group_id, form_loading: true }, () => {
            this.getPhaseGroup();
        });

    }


    handleButtonCancel = () => {
        this.setState({
            open: false,
            alert_open: false,
            delete_open: false,
            edit: false,
            group_id: "", button_loader: false,
            languageTab: 0,
            input_attributes: [
                { input_details_attributes_key: "", input_details_attributes_value: "" },
                { input_details_attributes_key: "", input_details_attributes_value: "" },
                { input_details_attributes_key: "", input_details_attributes_value: "" }
            ]
        });
    }

    handleAlertCancel = () => {
        this.setState({ alert_open: false, delete_open: false , errorDis: '' });
    }

    handleDelete = () => {
        this.deletePhaseGroup();
        this.setState({ alert_open: false, delete_open: false });
    }

    parseImages = (img?: string): string | undefined => {
        if (!img) return undefined;
        return img;
    };

    assignLanguage = (e: any, languageTab: number) => {
        this.setState(prev => ({
            ...prev,
            languageTab
        }), () => this.clearInputAttributes());
    }

    updateInputAttributes(
        event: React.ChangeEvent<{
            name: string;
            value: unknown;
        }>,
        path: "input_details_attributes_key" | "input_details_attributes_value",
    ) {
        event.preventDefault();
        const value = event.target.value;

        

        this.setState(prev => {

            const input_attributes: Record<"input_details_attributes_key" | "input_details_attributes_value", string>[] = prev.input_attributes.map((item, item_int) => {

                if (item_int !== prev.languageTab && path === 'input_details_attributes_key') return item;
                return { ...item, [path]: value }
            });
            return {
                ...prev,
                input_attributes
            }
        })
    }

    clearInputAttributes() {
        this.setState(prev => ({
            ...prev,
            input_details_attributes_key: "",
            input_details_attributes_value: "",
            input_attributes: [
                { input_details_attributes_key: "", input_details_attributes_value: "" },
                { input_details_attributes_key: "", input_details_attributes_value: "" },
                { input_details_attributes_key: "", input_details_attributes_value: "" }
            ]
        }))
    }


    textChecker = (text: string): TChecker => {
        if (text.includes(".")) {
            text = text.split('').filter(char => char !== ".").join("");
        }

        if (text.includes("-")) {
            text = [text[0], ...text.substring(1, text.length).split("").filter(char => char !== "-")].join("");
        }

        return { check: Number.isInteger(+text), text };
    }

    addActivity = (int?: number) => {
        const activityDto = {
            "phase_activity_name": '',
            "phase_activity_id": 0,
            "start_day": 0,
            "end_day": 0,
            "sub_activity_progresses_attributes": [{
                phase_sub_activity_name: '',
                "phase_sub_activity_id": 0,
                "before_after_day": 0,
                "description": "",
                "is_completed": false,
                "input_details_attributes": []
            }]
        };
        const check = this.postValidation(this.state.phases_attributes);
        if (check.result  && check.validation_true ) {
            this.setState(prev => {
                const phases_attributes = prev.phases_attributes.map(phase => {
                    return {
                        ...phase,
                        phase_activity_progresses_attributes: [...phase.phase_activity_progresses_attributes, activityDto]
                    }
                });
    
                return {
                    ...prev,
                    phases_attributes
                };
            }, () => () => this.clearInputAttributes());
            return
        }

        this.setState({ alert_open: true, open: true });

        
    }

    addSubActivity(int: number) {
        const subActivityDto: TSubActivity = {
            phase_sub_activity_name: '',
            "phase_sub_activity_id": 0,
            "before_after_day": 0,
            "description": "",
            "is_completed": false,
            "input_details_attributes": []
        };
        const checkAddSubActivity = this.postValidation(this.state.phases_attributes);
        if (checkAddSubActivity.result && checkAddSubActivity.validation_true) {
            this.setState(prev => {
                const phases_attributes = prev.phases_attributes.map(phase => {
                    const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {
                        if (activity_index !== int) return activity;
    
                        return {
                            ...activity,
                            sub_activity_progresses_attributes: [...activity.sub_activity_progresses_attributes, subActivityDto]
                        }
                    })
    
                    return {
                        ...phase,
                        phase_activity_progresses_attributes
                    }
                });
    
                return {
                    ...prev,
                    phases_attributes
                };
            }, () => this.clearInputAttributes());
            return
        }
        this.setState({ alert_open: true, open: true });
        
    }

    auxillarySelectImage = ({ int, sub_index }: { int: number, sub_index: number }) => {
        this.setState({ img_aux: `${int}-${sub_index}` });
    };

    handleImageSelect = async (event: React.ChangeEvent<any>) => {
        const file: File = event.target.files[0]
        const [path_int, sub_path_int] = this.state.img_aux.split("-");

        if(file?.size >= configJSON.imageLimit_250){
           return  this.setState({ alert_open: true, open: true, errorDis: "Max allowed size is 250MB" });
        }

        const generateSubActivityData = ({ activity }: { activity: TActivity }) => {

            return activity.sub_activity_progresses_attributes.map((sub_path, sub_path_index) => {
                if (sub_path_index !== +sub_path_int) {
                    return sub_path;
                }

                return {
                    ...sub_path,
                    attachment: URL.createObjectURL(file),
                    file,
                };
            })
        }

        const generateActivityData = ({ phase }: { phase: TPhase }) => {

            return phase.phase_activity_progresses_attributes.map((activity, activity_index) => {
                if (activity_index !== +path_int) {
                    return activity;
                }

                return {
                    ...activity,
                    sub_activity_progresses_attributes: generateSubActivityData({ activity })
                };
            });
        }
                this.setState((prev) => {

                    const phases_attributes = prev.phases_attributes.map((phase) => {
                        return {
                            ...phase,
                            phase_activity_progresses_attributes: generateActivityData({ phase })
                        };
                    });

                    return {
                        ...prev,
                        phases_attributes,
                        img_aux: ""
                    };
                });
    };

    updatePhase = (
        event: React.ChangeEvent<{
            name: string;
            value: unknown;
        }>,
        path: keyof Omit<TPhase, "phase_activity_progresses_attributes" | "language">
    ) => {
        event.preventDefault();
        const value = event.target.value;

        this.setState((prev) => {
            const phases_attributes = prev.phases_attributes.map((phase, int) => {
                if (int !== this.state.languageTab && path === "name") {
                    return phase;
                }

                let text = typeof value === "string" ? value : "";
                const { text: _txt, check } = this.textChecker(text);

                const statement = text.length === 1 && text === "-" ? _txt : phase[path];

                let newVal = check ? _txt : statement;

                return {
                    ...phase,
                    [path]: (path === "name") ? value : newVal
                };
            });

            return {
                ...prev,
                phases_attributes
            };
        });
    }

    updateActivity = (
        event: React.ChangeEvent<{
            name?: string
            value: unknown;
        }>,
        path: keyof Omit<TActivity, "sub_activity_progresses_attributes" | "phase_activity_name" | "id">,
        path_int: number
    ) => {
        event.preventDefault();
        const value = event.target.value;
        let text = typeof value === "string" ? value : "";

        const generateSubActivityData = ({ activity }: { activity: TActivity }) => {

            const sub_activity_progresses_attributes = activity.sub_activity_progresses_attributes.map(sub_activity => {

                return { ...sub_activity, phase_sub_activity_id: "" }
            });

            return path === "phase_activity_id" ? sub_activity_progresses_attributes : activity.sub_activity_progresses_attributes
        }

        const flattenInput = (path_value: string | number) => {
            const { text: _txt, check } = this.textChecker(text);

            const statement = text.length === 1 && text === "-" ? _txt : path_value;

            let newVal = check ? _txt : statement;

            const processed_txt = path === "phase_activity_id" ? value : newVal;

            return processed_txt;

        }

        const generateActivityData = ({ phase }: { phase: TPhase }) => {
            return phase.phase_activity_progresses_attributes.map((activity, activity_index) => {

                if (activity_index !== path_int) {
                    return activity;
                }

                return {
                    ...activity,
                    [path]: flattenInput(activity[path] as string),
                    sub_activity_progresses_attributes: generateSubActivityData({ activity })
                };
            });

        }

        this.setState((prev) => {
            const phases_attributes = prev.phases_attributes.map((phase) => {
                return {
                    ...phase,
                    phase_activity_progresses_attributes: generateActivityData({ phase })
                };

            });

            return {
                ...prev,
                phases_attributes
            };
        });
    }

    updateSubActivity = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
        path: keyof Omit<TSubActivity, "input_details_attributes" | "phase_sub_activity_name" | "is_completed" | "attachment">,
        path_int: number,
        sub_path_int: number
    ) => {
        event.preventDefault();
        const value = event.target.value;
        const statement_finalizer = ({ sub_path, phase_int }: { sub_path: TSubActivity, phase_int: boolean }) => {

            let final_statement: string | unknown;

            switch (path) {
                case 'phase_sub_activity_id':
                    const statement = value === 'none' ? "" : value;
                    final_statement = statement;
                    break;
                case 'description':
                    final_statement = phase_int ? value : sub_path.description;
                    break;
                case 'before_after_day':
                    const text = typeof value === "string" ? value : "";

                    const { text: _txt, check } = this.textChecker(text);

                    const statement_text = text.length === 1 && text === "-" ? _txt : sub_path[path];

                    let newVal = check ? _txt : statement_text;

                    final_statement = newVal;
                    break;
                default:
                    final_statement = value;
                    break;
            }

            return { final_statement }
        }

        const generateSubActivityData = ({ activity, int }: { activity: TActivity, int: boolean }) => {
            return activity.sub_activity_progresses_attributes.map((sub_path, sub_path_index) => {
                if (sub_path_index !== sub_path_int) {
                    return sub_path;
                }

                const { final_statement } = statement_finalizer({ sub_path, phase_int: int })

                return {
                    ...sub_path,
                    [path]: final_statement,
                };
            });
        }

        this.setState((prev) => {
            const phases_attributes = prev.phases_attributes.map((phase, int) => {

                const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {
                    if (activity_index !== path_int) {
                        return activity;
                    }

                    return {
                        ...activity,
                        sub_activity_progresses_attributes: generateSubActivityData({ activity, int: prev.languageTab === int })
                    };
                });

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });

            return {
                ...prev,
                phases_attributes,
            };
        });
    }

    addInputField = (
        path_int: number,
        sub_path_int: number
    ) => {

        const generateSubActivityData = ({ activity, phase_number: int }: { activity: TActivity, phase_number: number }) => {

            return activity.sub_activity_progresses_attributes.map((sub_path, sub_path_index) => {

                if (sub_path_index !== sub_path_int) {
                    return sub_path;
                }

                const value = { key: this.state.input_attributes[int]?.input_details_attributes_key, value: this.state.input_attributes[int]?.input_details_attributes_value}

                const input_details_attributes = [...sub_path.input_details_attributes, value]

                return {
                    ...sub_path,
                    input_details_attributes
                };
            })
        };

        this.setState((prev) => {
            const phases_attributes = prev.phases_attributes.map((phase, int) => {

                const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {

                    if (activity_index !== path_int) {
                        return activity;
                    }

                    return {
                        ...activity,
                        sub_activity_progresses_attributes: generateSubActivityData({ activity, phase_number: int })
                    };
                });

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });

            return {
                ...prev,
                phases_attributes
            };
        }, () => {
            this.clearInputAttributes();
        });
    }

    removeInputField = (
        path_int: number,
        sub_path_int: number,
        item_int: number,
        rm_modal: Record<"open" | "edit", boolean>
    ) => {
        const resolveInputAttributesData = ({ sub_path }: { sub_path: TSubActivity }) => {
            let input_details_attributes: any[] = [];

            if (rm_modal.open) {
                input_details_attributes = sub_path.input_details_attributes.filter((_, key) => key !== item_int);
            }

            if (rm_modal.edit) {
                input_details_attributes = sub_path.input_details_attributes.map((input, input_key) => {
                    if (!input.id && input_key === item_int) return null
                    return {
                        ...input,
                        destroy: input_key !== item_int ? input.destroy : true
                    }
                }).filter(item => !!item);
            }

            return input_details_attributes;
        }

        this.setState((prev) => {
            const phases_attributes = prev.phases_attributes.map((phase, int) => {
                const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {
                    if (activity_index !== path_int) {
                        return activity;
                    }

                    const sub_activity_progresses_attributes = activity.sub_activity_progresses_attributes.map((sub_path, sub_path_index) => {
                        if (sub_path_index !== sub_path_int) {
                            return sub_path;
                        }

                        return {
                            ...sub_path,
                            input_details_attributes: resolveInputAttributesData({ sub_path })
                        };
                    })

                    return {
                        ...activity,
                        sub_activity_progresses_attributes
                    };
                });

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });

            return {
                ...prev,
                phases_attributes
            };
        }, () => {
            this.clearInputAttributes();
        });
    }

    updateInputField = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
        path: "key" | "value",
        path_int: number,
        sub_path_int: number,
        item_int: number
    ) => {
        event.preventDefault();
        const value = event.target.value;

        const generateInputData = ({ sub_path, phase_number: int }: { sub_path: TSubActivity, phase_number: number }) => {

            return sub_path.input_details_attributes.map((item, key) => {

                let text = typeof value === "string" ? value : 0 + "";

                if (text.includes(".")) {
                    text = text.split('').filter(char => char !== ".").join("");
                }

                const sub_text = item.value.split(" ")[0];

                let newVal = Number.isInteger(+text) ? text : sub_text;

                if (key !== item_int) return item;

                return {
                    ...item,
                    [path]: value
                }
            });

        }

        this.setState((prev) => {

            const phases_attributes = prev.phases_attributes.map((phase, int) => {

                if (int !== this.state.languageTab && path === "key") {
                    return phase;
                }

                const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {

                    if (activity_index !== path_int) {
                        return activity;
                    }

                    const sub_activity_progresses_attributes = activity.sub_activity_progresses_attributes.map((sub_path, sub_path_index) => {

                        if (sub_path_index !== sub_path_int) {
                            return sub_path;
                        }

                        return {
                            ...sub_path,
                            input_details_attributes: generateInputData({ sub_path, phase_number: int })
                        };
                    })

                    return {
                        ...activity,
                        sub_activity_progresses_attributes
                    };
                });

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });

            return {
                ...prev,
                phases_attributes
            };
        }, () => {
            this.clearInputAttributes();
        });
    }

    removeActivity = (path_int: number) => {
        this.setState(prev => {
            const phases_attributes = prev.phases_attributes.map((phase) => {
                const  phase_activity_progresses_attributes : any = phase.phase_activity_progresses_attributes.map((data : any ,activity_index : number) => {
                    if(activity_index === path_int){
                        data._destroy = 1;
                    }
                    return data;
                })

                if(phase_activity_progresses_attributes.filter((data : any) =>  !data._destroy).length === 0){
                    phase_activity_progresses_attributes.push({
                        "phase_activity_name": '',
                        "phase_activity_id": "",
                        "start_day": "",
                        "end_day": "",
                        "sub_activity_progresses_attributes": [{
                            phase_sub_activity_name: '',
                            "phase_sub_activity_id": "",
                            "before_after_day": "",
                            "description": "",
                            "is_completed": false,
                            "input_details_attributes": []
                        }]
                    })
                }

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });

            return {
                ...prev,
                phases_attributes
            };
        });
    }

    removeSubActivity = (path_int: number, sub_path_int: number) => {
        this.setState(prev => {
            const phases_attributes = prev.phases_attributes.map((phase) => {
                const phase_activity_progresses_attributes = phase.phase_activity_progresses_attributes.map((activity, activity_index) => {
                    if (activity_index !== path_int) {
                        return activity;
                    }

                    const sub_activity_progresses_attributes: any = activity.sub_activity_progresses_attributes.map((data : any , sub_path_index: number) =>  {
                        if(sub_path_index === sub_path_int ){ 
                            data._destroy = 1
                        } 
                        return data 
                    })

                    if(sub_activity_progresses_attributes.filter((data : any) =>  !data._destroy).length  === 0){
                        sub_activity_progresses_attributes.push({
                            "phase_sub_activity_name": '',
                            "phase_sub_activity_id": "",
                            "before_after_day": "",
                            "description": "",
                            "is_completed": false,
                            "input_details_attributes": [],
                        })
                    }

                    return {
                        ...activity,
                        sub_activity_progresses_attributes
                    };
                });

                return {
                    ...phase,
                    phase_activity_progresses_attributes
                };
            });            

            return {
                ...prev,
                phases_attributes
            };
        });
    }

    addPhaseModal = () => {
        const check = this.postValidation(this.state.phases_attributes);
        if (check.result && check.validation_true) {
            this.setState({ button_loader: true });
            this.addNewPhase();
            return
        }

        this.setState({ alert_open: true, open: true });
    }


    reseedPhases = (phases_attributes: any) => {
        this.setState((prev) => ({ ...prev, phases_attributes, group_id: "900" }));
    }

    updatePhaseModal = () => {
        const check = this.postValidation(this.state.phases_attributes);
        if (check.result && check.validation_true) {
            this.setState({ button_loader: true });
            this.patchPhase();
            return
        }

        this.setState({ alert_open: true, edit: true, open: false });
    }

    postValidation = (phases: TPhase[]) => {
        let result = true;
        let validation_true = true;
        let validation_document: any = {
            end_day: [],
            name: [],
            start_day: [],
            day_validation: Array(3).fill(validation_true)
        };

        const resolveInput = (input: string | number | undefined) => {
            const resolute = input == "0" ? true : Boolean(input);
            if (!resolute) {
                result = false;
            }
            return resolute;
        }

        const resolveDayValidation = ({ start_day, end_day }: { start_day: string | number, end_day: string | number }) => {

            if (+start_day >= +end_day) {

                validation_true = false;
                return false;
            }

            return true;
        }

        const generateDocumentPhase = ({ phase_group, phase_number: int }: { phase_group: TPhase, phase_number: number }) => {
            const litter: string[] = ["end_day", "start_day", 'name', "day_validation"];

            const values: Record<string, boolean> = {
                end_day: resolveInput(phase_group.end_day),
                start_day: resolveInput(phase_group.start_day),
                name: resolveInput(phase_group.name),
                day_validation: resolveDayValidation({ start_day: phase_group.start_day, end_day: phase_group.end_day })
            }

            litter.forEach(pass => {
                validation_document[pass][int] = values[pass]
            })
        }

        const generateDocumentActivity = ({ activity, activity_number, phase_number: int }: { activity: TActivity, activity_number: number, phase_number: number }) => {

            const litter: string[] = ["end_day", "start_day", 'phase_activity_id', "day_validation"];
            const values: Record<string, boolean> = {
                end_day: resolveInput(activity.end_day),
                start_day: resolveInput(activity.start_day),
                phase_activity_id: resolveInput(activity.phase_activity_id),
                day_validation: resolveDayValidation({ start_day: activity.start_day, end_day: activity.end_day })
            }

            litter.forEach(item => {

                validation_document[`activity_${activity_number}`] = !validation_document[`activity_${activity_number}`] ? {
                    [item]: []
                } : { ...validation_document[`activity_${activity_number}`], [item]: validation_document[`activity_${activity_number}`][item] ? [...validation_document[`activity_${activity_number}`][item]] : [] }

                validation_document[`activity_${activity_number}`][item][int] = values[item]
            });
        }

        const generateDocumentSubActivity = ({ sub_activity, activity_number, sub_activity_number, phase_number: int }: { sub_activity: TSubActivity, activity_number: number, sub_activity_number: number, phase_number: number }) => {
            const litter = ["attachment", "description", "before_after_day", 'phase_sub_activity_id'];
            const values: Record<string, boolean> = {
                attachment: resolveInput(sub_activity.attachment ? sub_activity.attachment : ""),
                description: resolveInput(sub_activity.description),
                before_after_day: resolveInput(sub_activity.before_after_day),
                phase_sub_activity_id: resolveInput(sub_activity.phase_sub_activity_id)
            }

            litter.forEach(item => {
                validation_document[`activity_${activity_number}&sub_${sub_activity_number}`] = !validation_document[`activity_${activity_number}&sub_${sub_activity_number}`] ? {
                    [item]: []
                } : { ...validation_document[`activity_${activity_number}&sub_${sub_activity_number}`], [item]: validation_document[`activity_${activity_number}&sub_${sub_activity_number}`][item] ? [...validation_document[`activity_${activity_number}&sub_${sub_activity_number}`][item]] : [] }

                validation_document[`activity_${activity_number}&sub_${sub_activity_number}`][item][int] = values[item]
            });

        }

        const generateDocumentInput = ({ input, activity_number, sub_activity_number, input_number, phase_number: int }: { input: Record<"key" | "value", string>, activity_number: number, sub_activity_number: number, input_number: number, phase_number: number }) => {

            const litter = ["key", "value"];
            const values: Record<string, boolean> = {
                key: resolveInput(input.key),
                value: resolveInput(input.value),
            }

            litter.forEach(item => {
                validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`] = !validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`] ? {
                    [item]: []
                } : { ...validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`], [item]: validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`][item] ? [...validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`][item]] : [] }

                validation_document[`activity_${activity_number}&sub_${sub_activity_number}&input_${input_number}`][item][int] = values[item]
            });

        }


        phases.forEach((phase_group, int) => {

            generateDocumentPhase({ phase_group, phase_number: int });

            phase_group.phase_activity_progresses_attributes.forEach((activity, activity_number) => {

                generateDocumentActivity({ activity, activity_number, phase_number: int })

                activity.sub_activity_progresses_attributes.forEach((sub_activity, sub_activity_number) => {

                    generateDocumentSubActivity({ sub_activity, sub_activity_number, activity_number, phase_number: int })

                    sub_activity.input_details_attributes.forEach((input, input_number) => {
                        generateDocumentInput({ input, input_number, activity_number, sub_activity_number, phase_number: int })
                    })
                });
            });

        });
        return { result, validation_document, validation_true };
    }

    attributesParserCreate = (phase: TPhase[]) => {
        const _phase = phase.slice().map((phase_block: Omit<TPhase, "id">) => {

            const phase_activity_progresses_attributes = phase_block.phase_activity_progresses_attributes.map((activity: Omit<TActivity, "id">) => {

                const sub_activity_progresses_attributes = activity.sub_activity_progresses_attributes.map((sub_activity: Omit<TSubActivity, "id">) => {
                    const input_details_attributes = sub_activity.input_details_attributes.map((input: Record<"key" | "value", string>) => {
                        return {
                            key: input.key,
                            value: input.value,
                        }
                    })

                    let activityObjectFileParser = sub_activity.file ? {
                        "phase_sub_activity_id": Number(sub_activity.phase_sub_activity_id),
                        "before_after_day": Number(sub_activity.before_after_day),
                        "description": sub_activity.description,
                        "is_completed": false,
                        "attachment": sub_activity.file,
                        input_details_attributes: sub_activity.input_details_attributes
                    } : {
                        "phase_sub_activity_id": Number(sub_activity.phase_sub_activity_id),
                        "before_after_day": Number(sub_activity.before_after_day),
                        "description": sub_activity.description,
                        "is_completed": false,
                        input_details_attributes
                    }

                    return activityObjectFileParser
                });

                return {
                    phase_activity_id: Number(activity.phase_activity_id),
                    start_day: Number(activity.start_day),
                    end_day: Number(activity.end_day),
                    sub_activity_progresses_attributes
                }
            })

            return {
                language: phase_block.language,
                name: phase_block.name.trim(),
                start_day: Number(phase_block.start_day),
                end_day: Number(phase_block.end_day),
                phase_activity_progresses_attributes
            }
        });
        return _phase
    }

    attributesParserPatch = (phase: TPhase[]) => {

        const resolveInputAttributesData = ({ data }: { data: TInputAttributes[] }) => {
            return data.map((input) => {
                if (input.destroy) return {
                    key: input.key,
                    value: input.value,
                    _destroy: Number(input.id),
                }

                return {
                    id: Number(input.id),
                    key: input.key,
                    value: input.value,
                }
            });
        };

        const _phase = phase.slice().map((phase_block: any) => {

            const phase_activity_progresses_attributes = phase_block.phase_activity_progresses_attributes.map((activity: TActivity) => {

                const sub_activity_progresses_attributes = activity.sub_activity_progresses_attributes.map((sub_activity: TSubActivity) => {

                    let activityObjectFileParser = sub_activity.file ? {
                        id: Number(sub_activity.id),
                        "phase_sub_activity_id": Number(sub_activity.phase_sub_activity_id),
                        "before_after_day": Number(sub_activity.before_after_day),
                        "description": sub_activity.description,
                        "is_completed": false,
                        "attachment": sub_activity.file,
                        "_destroy": sub_activity._destroy, 
                        input_details_attributes: resolveInputAttributesData({ data: sub_activity.input_details_attributes })
                    } : {
                        id: Number(sub_activity.id),
                        "phase_sub_activity_id": Number(sub_activity.phase_sub_activity_id),
                        "before_after_day": Number(sub_activity.before_after_day),
                        "description": sub_activity.description,
                        "is_completed": false,
                        "_destroy": sub_activity._destroy, 
                        input_details_attributes: resolveInputAttributesData({ data: sub_activity.input_details_attributes })
                    }

                    return activityObjectFileParser
                });

                return {
                    id: Number(activity.id),
                    phase_activity_id: Number(activity.phase_activity_id),
                    start_day: Number(activity.start_day),
                    end_day: Number(activity.end_day),
                    _destroy: activity._destroy,
                    sub_activity_progresses_attributes
                }
            })

            return {
                id: Number(phase_block.id),
                language: phase_block.language,
                name: phase_block.name.trim(),
                start_day: Number(phase_block.start_day),
                end_day: Number(phase_block.end_day),
                phase_activity_progresses_attributes
            }
        });

        return _phase;
    }

    addNewPhase(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.phaseCreateItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.calendarScreenDefaults.CREATE_PHASE_URL}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        const phases_attributes = this.attributesParserCreate(this.state.phases_attributes)
        const body = serialize({ phase_group: {phases_attributes} }, { indices: true});

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    patchPhase(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.phaseGroupPatchItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.calendarScreenDefaults.CREATE_PHASE_URL}/${this.state.group_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        const phases_attributes = this.attributesParserPatch(this.state.phases_attributes);
        const body = serialize({ phase_group: {phases_attributes} } , { indices: true })

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PATCH_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    getActivity(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.activityGetItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.calendarScreenDefaults.GET_ACTIVITY_URL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    getGroups(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.groupGetItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.calendarScreenDefaults.GET_SUBGROUPS_URL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    getPhaseGroup(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.phaseGroupGetItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.calendarScreenDefaults.ROOT_PHASE_URL}/${this.state.group_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    deletePhaseGroup(): boolean {
        // Customizable Area Start
        const header = {
            token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.phaseGroupDeleteItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.calendarScreenDefaults.ROOT_PHASE_URL}/${this.state.group_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DELETE_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        // Customizable Area End
        return true;
    }

    apiRequestCallGetActivityResponse = (response: TActivityAPIProfile[]) => {
        let sub_activity_profile_attributes: Record<string, TSubActivityProfile[]> = {};
        const activity_profile_attributes: TActivityProfile[] = response.map((activity: TActivityAPIProfile, activity_int: number) => {
            activity.attributes.phase_sub_activity_name.forEach((sub_activity_name: TSubActivityAPIProfile) => {


                const init = sub_activity_profile_attributes[activity.id] ? sub_activity_profile_attributes[activity.id] : []
                sub_activity_profile_attributes[activity.id] = [...init, {
                    id: sub_activity_name.id,
                    name_array: [sub_activity_name.name, sub_activity_name.name_gujarati, sub_activity_name.name_hindi],
                }]
            });

            return {
                id: activity.id,
                type: activity.type,
                activity_name_array: [activity.attributes.name, activity.attributes.name_gujarati, activity.attributes.name_hindi],
            };
        });

        this.setState(prev => {
            return {
                ...prev,
                activity_profile_attributes,
                sub_activity_profile_attributes,
                loading: false
            };
        });
    }

    apiRequestCallGroupGetItemResponse = (response: TPhaseGroup[]) => {
        let start_day = 0;
        let end_day = 0;
        const phase_group_collection: TPhaseProfile[] = response.map((phase_group: TPhaseGroup, int: number) => {
            start_day = phase_group.attributes.phase.data[0].attributes.start_day < start_day ? phase_group.attributes.phase.data[0].attributes.start_day : start_day;
            end_day = phase_group.attributes.phase.data[0].attributes.end_day > end_day ? phase_group.attributes.phase.data[0].attributes.end_day : end_day;
            return { ...phase_group.attributes.phase.data[0], phase_group_id: phase_group.id }
        });

        this.setState((prev) => ({ ...prev, phase_group_collection, global_start_day: start_day, global_end_day: end_day}));
    }

    apiRequestCallPhaseGroupGetItemResponse = (response: TPhaseGroup) => {
        const _phases_attributes = response.attributes.phase.data.map((phase: TPhaseProfile) => {

            let phase_activity_progresses_attributes: TActivity[] = phase.attributes.phase_activity_progresses.data.map((activity: TPhaseActivityProfile, activity_int) => {

                let sub_activity_progresses_attributes: TSubActivity[] = activity.attributes.phase_sub_activity_progresses.data.map((subactivity: TPhaseSubActivityProfile) => {

                    let input_details_attributes = subactivity.attributes.input_details.map(input => ({
                        id: input.id,
                        key: input.key,
                        value: input.value,
                        destroy: false,
                        _destroy: input.id
                    }));

                    let _subactivity: TSubActivity = {
                        "id": subactivity.id,
                        'attachment': typeof subactivity.attributes.attachment.url === 'string' ? subactivity.attributes.attachment.url : '',
                        'before_after_day': subactivity.attributes.before_after_day,
                        "description": subactivity.attributes.description,
                        'is_completed': subactivity.attributes.is_completed,
                        'phase_sub_activity_id': subactivity.attributes.phase_sub_activity_id,
                        'phase_sub_activity_name': subactivity.attributes.phase_sub_activity_progress_name,
                        input_details_attributes,
                    };

                    return _subactivity;
                });

                let _activity: TActivity = {
                    "id": activity.id,
                    "phase_activity_name": activity.attributes.phase_activity_name,
                    'end_day': activity.attributes.end_day,
                    'phase_activity_id': activity.attributes.phase_activity_id,
                    "start_day": activity.attributes.start_day,
                    sub_activity_progresses_attributes
                }
                return _activity
            });

            let _data: TPhase = {
                "id": phase.id,
                "language": phase.attributes.language,
                'end_day': phase.attributes.end_day,
                'name': phase.attributes.name,
                'start_day': phase.attributes.start_day,
                phase_activity_progresses_attributes
            };
            return _data;
        });

        let phases_attributes = [_phases_attributes[0], _phases_attributes[2], _phases_attributes[1]];

        this.setState((prev) => ({ ...prev, phases_attributes, form_loading: false }));
    }

    apiRequestCallPhaseCreateItemResponse = (response: TPhaseGroup) => {
        if (response.id) {
            this.setState(prev => {
                return { ...prev, button_loader: false }
            }, () => {
                this.getGroups();
            })

            this.handleButtonCancel();
        } else {
            this.setState({
                error: "Something went wrong, data not found",
                loading: false,
                form_loading: false,
                button_loader: false
            });
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const successResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (errorResponse) {
                this.setState({
                    error: "something went wrong!",
                    loading: false,
                    form_loading: false,
                    button_loader: false
                });

                return
            }

            if (apiRequestCallId === this.activityGetItemCallId) {
                this.activityRequestCall({ successResponse, errorResponse });
            }

            if (apiRequestCallId === this.groupGetItemCallId) {
                this.phaseGroupsRequestCall({ successResponse, errorResponse });
            }

            if (apiRequestCallId === this.phaseGroupGetItemCallId) {
                let data: TPhaseGroup = successResponse.data;
                this.apiRequestCallPhaseGroupGetItemResponse(data);
            }

            if (apiRequestCallId === this.phaseGroupPatchItemCallId) {
                this.patchPhaseGroupsRequestCall({ successResponse, errorResponse });
            }

            if (apiRequestCallId === this.phaseCreateItemCallId) {
                this.createPhaseGroupRequestCall({ successResponse, errorResponse });
            }

            if (apiRequestCallId === this.phaseGroupDeleteItemCallId) {
                this.deletePhaseGroupsRequestCall({ successResponse, errorResponse });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    activityRequestCall = ({ successResponse, errorResponse }: { successResponse: { data: TActivityAPIProfile[], errors: any[] }, errorResponse: any }) => {
        if (successResponse.errors) {
            const [error] = successResponse.errors
            this.setState({
                error: error.token,
                loading: false,
                form_loading: false,
                button_loader: false
            });

        } else {
            let data: TActivityAPIProfile[] = successResponse.data
            if (data[0]?.id) {
                this.apiRequestCallGetActivityResponse(data)
            } else {
                this.setState({
                    error: 'Something went wrong, data not found',
                    loading: false
                });
            }
        }
    }

    phaseGroupsRequestCall = ({ successResponse, errorResponse }: { successResponse: { data: TPhaseGroup[], errors: any[] }, errorResponse: any }) => {
        if (!successResponse.errors && successResponse.data) {
            this.apiRequestCallGroupGetItemResponse(successResponse.data);
        } else {
            if (errorResponse === undefined) {
                this.setState({
                    error: 'Something went wrong',
                    loading: false
                });
            }
        }
    }

    patchPhaseGroupsRequestCall = ({ successResponse, errorResponse }: { successResponse: { data: TPhaseGroup, errors: any[] }, errorResponse: any }) => {
        let data: TPhaseGroup = successResponse.data;
        if (successResponse.errors) {
            this.setState({
                error: Object.keys(successResponse.errors)[0],
                loading: false,
                form_loading: false,
                button_loader: false,
            });
        } else {
            if (successResponse.data?.id) {
                this.setState(prev => {
                    return { ...prev, group_id: data.id, button_loader: false, delete_open: false }
                }, () => {
                    this.getGroups();
                });
                this.handleButtonCancel();

            } else {
                this.setState({
                    error: "Something went wrong, data not found",
                    loading: false,
                    form_loading: false,
                    button_loader: false
                });
            }
        }
    }

    deletePhaseGroupsRequestCall = ({ successResponse, errorResponse }: { successResponse: Record<"deleted" | "errors", boolean | string[]>, errorResponse: any }) => {
        if (successResponse.deleted) {
            this.setState(prev => {
                return { ...prev, group_id: "" }
            }, () => this.getGroups())
        } else {
            this.setState({
                error: "something went wrong",
                loading: false,
                button_loader: false,
                form_loading: false,
            });
        }
    }

    createPhaseGroupRequestCall = ({ successResponse, errorResponse }: { successResponse: { data: TPhaseGroup, errors: any[] }, errorResponse: any }) => {
        if (!successResponse.errors && successResponse.data) {
            this.apiRequestCallPhaseCreateItemResponse(successResponse.data);
        } else {
            this.setState({
                error: 'Something went wrong',
                loading: false,
                button_loader: false,
                form_loading: false,
            });
        }
    }
    // Customizable Area End

}
