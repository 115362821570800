import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
// Customizable Area Start
import moment from "moment";

import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";
const userLocation = localStorage.getItem('userLocationFarmMapping');
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  feId: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  TokenValue: null | string;
  loggedInUserRole: string | null;
  isLoggedUserAdmin: boolean;

  currentFEData: any;
  currentFELoading: boolean;

  feListData: any[];
  feListDataLoading: boolean;
  fePageSize: any;
  feTableCurrentPage: number;
  totalFEsCount: any;

  totalAreaCotton: any;
  totalFECount: any;
  totalFarmerCount: any;
  totalCottonCount: any;
  seachInputValue: string;
  totalSearchedCount: any;
  totalVillagesCount: any;
  openDeleteModal: boolean;
  openDeleteFEModal: boolean;
  deleteUserData: any;
  openEditUserModal: boolean;
  userModal: boolean;
  selectedUser: string;
  seletedUserTypeAddUser: string;
  adminInitialValues: any;
  feInitialValues: any;
  selectedLocationArr: any[];
  farmerInitialValues: any;
  successAddAdmin: {};
  errorModalOpen: boolean;
  errorModalMsg: string;
  showEyeIconPassword: boolean;
  showEyeIconConfirmPassword: boolean;
  successAddFE: {};
  successUpdateFE: {};
  getLocationsListFEData: any;
  getMobileDeviceModelFEData: any;
  userLandTypeFarmer: any;
  getLandTypeFarmerData: any[];
  FElistData: any[];
  disableStateFarmer: boolean;
  getStateData: any[];
  getDistrictData: any[];
  getTalukaData: any[];
  getVillageData: any[];
  getSourceOfIrrigationData: any[];
  getOwnerListFarmerData: any[];
  getUnitOfMeasureFarmerData: any[];
  getSoilTextureFarmerData: any[];
  getTypeIrrigationListingData: any[];
  farmerRowData: any;
  selectedFEformData: any;
  successUpdateFarmer: {};
  farmerData: any[];
  farmImageFile: any;
  farmImageURL: any;
  farmerDataLoading: boolean;
  totalFarmers: any;
  totalFieldExecutives: any;
  totalAdmins: any;
  totalFarmDiary: any;
  successAddFarmer: {};
  farmerPageSize: any;
  seachFilterValue: string;
  farmerTableCurrentPage: number;
  filterStateValue: string;
  filterDistValue: string;
  filterAtoZValue: boolean;
  filterTalukaValue: string;
  filterVillageValue: string;
  filterZtoAValue: boolean;
  filterAtoZChecked: boolean;
  FeTableCurrentPage: number;
  filterZtoAChecked: boolean;
  adminTableCurrentPage: number;
  FePageSize: any;
  getEducationFarmerData: any;
  unitFarmAreaData: any[];
  DistanceInData: any[];
  successAddSuperAdmin: any;

  deletedVillagesList: any[];
  defaultAssignedVillages: any[];
  // ASSIGN VILLAGE FILTER INTERFACE
  openVillageFilter: boolean;
  expandedVF: any;
  anchorElVillageFilter: any;

  initialStatesVF: any[];
  initalDistrictsVF: any[];
  initialTalukasVF: any[];
  initialVillagesVF: any[];
  stateInputValueVF: string;
  districtInputValueVF: string;
  talukaInputValueVF: string;
  villageInputValueVF: string;
  selectedStateVF: any;
  selectedDistrictVF: any;
  selectedTalukaVF: any;
  selectedVillageVF: any;
  filteredStatesVF: any[];
  filteredDistrictsVF: any[];
  filteredTalukasVF: any[];
  filteredVillagesVF: any[];
  isAccordianErrorVF: any;
  isDisabledVF: any;
  // TABLE FILTER INTERFACE
  openTableFilterPopup: boolean;
  anchorElUserTableFilter: any;
  expanded: any;
  sortAscending: boolean,
  sortDescending: boolean,
  initialStates: any[],
  initalDistricts: any[],
  initialTalukas: any[],
  initialVillages: any[],
  savedSortAscending: boolean,
  savedSortDescending: boolean,
  savedIsDisabled: any,
  savedState: any,
  savedDistrict: any,
  savedTaluka: any,
  savedVillage: any,
  selectedState:
  {
    name: "Select State",
    id: "",
  },
  selectedDistrict: {
    name: "Select District",
    id: "",
  },
  selectedTaluka: {
    name: "Select Taluka",
    id: "",
  },
  selectedVillage: {
    name: "Select Village",
    id: "",
  },
  filteredStates: any[],
  filteredDistricts: any[],
  filteredTalukas: any[],
  filteredVillages: any[],
  stateInputValue: "",
  districtInputValue: "",
  talukaInputValue: "",
  villageInputValue: "",
  hideBadge: boolean
  filterHasError: boolean
  isDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
  },
  // SUCCESS MODAL INTERFACE
  successModalOpen: boolean;
  successModalMsg: string;
  // LOADING BTN INTERFACE
  addFarmerLoading: boolean;  
  addFELoading: boolean;
  addAdminLoading: boolean;  
  addSALoading: boolean;
  updateFarmerLoading: boolean;
  updateFELoading: boolean;
  deleteFarmerLoading:boolean;
  deleteFELoading:boolean;
  cropImageModal: boolean;
  croppedImage: any;
  feProfileImage: { icon: string };
  // CROP-MAPPING
  OpenFarmMapping: boolean;
  latlngHash: any;
  farmMappingURL: any;
  coordinates: any[];
  markerDefault: any;
  farmMappingFile: any;
  tempCoordinates: any[];
  userLocation: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FEConsoleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFEByIdApiCallId: string = "";
  getFEApiCallId: string = "";
  deleteUserApiCallId: string = "";
  deleteUserFEApiCallId: string = "";
  addAdminApiCallId: string = "";
  addFEApiCallId: string = "";
  updateFEApiCallId: string = "";
  getLocationsListFEApiCallId: string = "";
  getMobileDeviceModelFEApiCallId: string = "";
  getLandTypeFarmerApiCallId: string = "";
  getFElistApiCallId: string = "";
  getStateApiCallId: string = "";
  getDistrictApiCallId: string = "";
  getFilterVillageApiCallId: string = "";
  getFilterFeApiCallId: string = "";
  getFilterDistrictApiCallId: string = "";
  getFilterTalukaApiCallId: string = "";
  getTalukaApiCallId: string = "";
  getVillageApiCallId: string = "";
  getSourceOfIrrigationFarmerApiCallId: string = "";
  getOwnerListFarmerApiCallId: string = "";
  getUnitOfMeasureFarmerApiCallId: string = "";
  getSoilTextureFarmerApiCallId: string = "";
  getTypeIrrigationListingApiCallId: string = "";
  updateFarmerApiCallId: string = "";
  addFarmerApiCallId: string = "";
  getEducationFarmerApiCallId: string = "";
  getUnitFarmAreaApiCallId: string = "";
  getDistanceInApiCallId: string = "";
  addSuperAdminApiCallId: string = "";
  // ASSIGN VILLAGE FILTER STARTS
  getVillageFilterVillageData: string = "";
  getVillageFilterTalukaData: string = "";
  getVillageFilterDistrictData: string = "";
  getVillageFilterStateData: string = "";
  // USER TABLE FILTER START
  getStateDataApiCallId: string = "";
  getDistrictDataApiCallId: string = "";
  getTalukaDataApiCallId: string = "";
  getVillageDataApiCallId: string = "";
  // USER TABLE FILTER END
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      feId: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      TokenValue: localStorage.getItem("tokenn"),
      loggedInUserRole: localStorage.getItem("user_role"),
      isLoggedUserAdmin: false,

      currentFEData: {
        first_name: "",
        last_name: "",
        country_code: "",
        phone_number: "",
      },
      currentFELoading: false,

      feListData: [],
      feListDataLoading: true,
      fePageSize: 15,
      feTableCurrentPage: 0,
      totalFEsCount: "",
      totalAreaCotton: "",
      totalFECount: 0,
      totalFarmerCount: 0,
      totalCottonCount: 0,
      seachInputValue: "",
      totalSearchedCount: 0,
      totalVillagesCount: 0,
      openDeleteModal: false,
      openDeleteFEModal: false,
      deleteUserData: null,
      openEditUserModal: false,
      userModal: false,
      selectedUser: "farmer",
      seletedUserTypeAddUser: "Farmers",
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },
      selectedLocationArr: [],
      farmerRowData: {},
      successUpdateFarmer: {},
      successAddFarmer: {},
      farmerInitialValues: {
        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",
        stateFarmer: "",
        genderFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        farmnameFarmer: "",
        farmSurveyNumberFarmer: "",
        ownershipFarmer: "",
        totalFarmAreaFarmer: "",
        unitOfMeasureFarmer: "",
        horizontalDistanceFarmer: "",
        distanceInFarmer: "",
        motorInHpFarmer: "",
        depthOfPumpFarmer: "",
        soilTextureFarmer: "",
        landTypeFarmer: "",
        sourceOfIrrigationFarmer: "",
        typeOfIrrigationFarmer: "",
      },
      successAddAdmin: {},
      errorModalOpen: false,
      errorModalMsg: "",
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      successAddFE: {},
      successUpdateFE: {},
      getLocationsListFEData: [],
      getMobileDeviceModelFEData: [],
      userLandTypeFarmer: null,
      getLandTypeFarmerData: [],
      FElistData: [],
      disableStateFarmer: false,
      getStateData: [],
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
      getSourceOfIrrigationData: [],
      getOwnerListFarmerData: [],
      getUnitOfMeasureFarmerData: [],
      getSoilTextureFarmerData: [],
      getTypeIrrigationListingData: [],
      selectedFEformData: null,
      farmerData: [],
      farmImageFile: null,
      farmImageURL: null,
      farmerDataLoading: true,
      totalFarmers: 0,
      totalFieldExecutives: "",
      totalAdmins: "",
      totalFarmDiary: "",
      farmerPageSize: 15,
      seachFilterValue: "",
      farmerTableCurrentPage: 0,
      filterStateValue: "",
      filterAtoZValue: false,
      filterTalukaValue: "",
      filterVillageValue: "",
      filterZtoAValue: false,
      filterAtoZChecked: false,
      FeTableCurrentPage: 0,
      filterZtoAChecked: false,
      adminTableCurrentPage: 0,
      FePageSize: 15,
      filterDistValue: "",
      getEducationFarmerData: [],
      unitFarmAreaData: [],
      DistanceInData: [],
      successAddSuperAdmin: null,
      deletedVillagesList: [],
      defaultAssignedVillages: [],

      // ASSIGN VILLAGE FILTER STATES
      openVillageFilter: false,
      anchorElVillageFilter: null,
      expandedVF: false,
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      initialStatesVF: [],
      initalDistrictsVF: [],
      initialTalukasVF: [],
      initialVillagesVF: [],
      filteredStatesVF: [],
      filteredDistrictsVF: [],
      filteredTalukasVF: [],
      filteredVillagesVF: [],
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      // TABLE STATES STATES
      anchorElUserTableFilter: null,
      openTableFilterPopup: false,
      expanded: false,
      filterHasError: false,
      hideBadge: true,
      sortAscending: false,
      sortDescending: false,
      initialStates: [],
      initalDistricts: [],
      initialTalukas: [],
      initialVillages: [],
      savedSortAscending: false,
      savedSortDescending: false,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      filteredStates: [],
      filteredDistricts: [],
      filteredTalukas: [],
      filteredVillages: [],
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      // SUCCESS MODAL INTERFACE
      successModalOpen: false,
      successModalMsg: "",
      // LOADING BTN INTERFACE
      addFarmerLoading: false,
      addFELoading: false,
      addAdminLoading: false,
      addSALoading: false,
      updateFarmerLoading: false,
      updateFELoading: false,
      deleteFarmerLoading: false,
      deleteFELoading:false,
      cropImageModal: false,
      croppedImage: "",
      feProfileImage: { icon: "" },
      // CROP-MAPPING
      OpenFarmMapping: false,
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],      
      tempCoordinates:[],
      markerDefault: null,
      farmMappingFile: null,
      userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // web events

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestId && response) {
        this.setAssignVillageFilterAPIResponses(apiRequestId, response)
        this.setUserTableFilterAPIResponses(apiRequestId, response)
        switch (apiRequestId) {
          case this.getFEByIdApiCallId:
            this.getFEByIdApiCallIdResp(response);
            break;
          case this.getFEApiCallId:
            this.getFEApiCallIdResp(response);
            break;
          case this.deleteUserApiCallId:
            this.deleteUserApiCallIdResp(response);
            break;
          case this.deleteUserFEApiCallId:
            this.deleteUserFEApiCallIdResp(response);
            break;
          case this.addAdminApiCallId:
            this.addAdminApiCallIdResp(response);
            break;
          case this.addFEApiCallId:
            this.addFEApiCallIdResp(response);
            break;
          case this.updateFEApiCallId:
            this.updateFEApiCallIdResp(response);
            break;
          case this.getLocationsListFEApiCallId:
            this.getLocationsListFEApiCallIdResp(response);
            break;
          case this.getMobileDeviceModelFEApiCallId:
            this.getMobileDeviceModelFEApiCallIdResp(response);
            break;
          case this.getLandTypeFarmerApiCallId:
            this.getLandTypeFarmerApiCallIdResp(response);
            break;
          case this.getFElistApiCallId:
            this.getFElistApiCallIdResp(response);
            break;
          case this.getStateApiCallId:
            this.getStateApiCallIdResp(response);
            break;
          case this.getDistrictApiCallId:
            this.getDistrictApiCallIdResp(response);
            break;
          case this.getTalukaApiCallId:
            this.getTalukaApiCallIdResp(response);
            break;
          case this.getVillageApiCallId:
            this.getVillageApiCallIdResp(response);
            break;
          case this.getSourceOfIrrigationFarmerApiCallId:
            this.getSourceOfIrrigationFarmerApiCallIdResp(response);
            break;
          case this.getOwnerListFarmerApiCallId:
            this.getOwnerListFarmerApiCallIdResp(response);
            break;
          case this.getUnitOfMeasureFarmerApiCallId:
            this.getUnitOfMeasureFarmerApiCallIdResp(response);
            break;
          case this.getSoilTextureFarmerApiCallId:
            this.getSoilTextureFarmerApiCallIdResp(response);
            break;
          case this.getTypeIrrigationListingApiCallId:
            this.getTypeIrrigationListingApiCallIdResp(response);
            break;
          case this.updateFarmerApiCallId:
            this.updateFarmerApiCallIdResp(response);
            break;
          case this.addFarmerApiCallId:
            this.addFarmerApiCallIdResp(response);
            break;
          case this.getEducationFarmerApiCallId:
            this.getEducationFarmerApiCallIdResp(response);
            break;
          case this.getUnitFarmAreaApiCallId:
            this.getUnitFarmAreaApiCallIdResp(response);
            break;
          case this.getDistanceInApiCallId:
            this.getDistanceInApiCallIdResp(response);
            break;
          case this.addSuperAdminApiCallId:
            this.addSuperAdminApiCallIdResp(response);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleCloseCropModalFE = () => {
    this.setState({ cropImageModal: false })
  }

  handleSubmitFEProgfileImage = (cropperRef: any) => {
    let croppedFEImage = cropperRef.current?.cropper.getCroppedCanvas();
    croppedFEImage.toBlob((blob: any) => {
        const file = new File([blob], "FEProfile.png", { type: 'image/png' });
        this.setState({ croppedImage: file, cropImageModal: false }, () => {
            this.updateFEAvatar(file);
        })
    });
}

  handleFEProfileImageUpload = async (event: any) => {
    event.persist();
    const selectedFile = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.setState(prev => {
        return { feProfileImage: { icon: reader.result as string }, cropImageModal: true }
      });
    };
    reader.readAsDataURL(selectedFile);
    if (event.currentTarget) {
      event.currentTarget.value = '';
    }
  }

  onProfileImageUpload = (event: any) => {
    event.currentTarget.files.length && this.handleFEProfileImageUpload(event)
  }

  updateFEAvatar = async (avatar: any) => {
    this.setState({ updateFELoading: true })
    const feId = window.location.pathname.split("/")[2];

    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFEApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${feId}?role_fe=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();

    reqFormdata.append("data[attributes][avatar]", avatar);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // ASSIGN-VILLAGE FILTER RESPONSE FUNCTIONS
  setAssignVillageFilterAPIResponses = (apiRequestId: any, responseJsonData: any) => {
    switch (apiRequestId) {
      case this.getVillageFilterVillageData:
        this.getAssignVillageFilterVillageDataRes(responseJsonData);
        break;
      case this.getVillageFilterTalukaData:
        this.getAssignVillageFilterTalukaDataRes(responseJsonData);
        break;
      case this.getVillageFilterDistrictData:
        this.getAssignVillageFilterDistrictDataRes(responseJsonData);
        break;
      case this.getVillageFilterStateData:
        this.getAssignVillageFilterStateDataRes(responseJsonData);
        break;
    }
  };
  getAssignVillageFilterVillageDataRes = (responseJsonData: any[]) => {
    if (responseJsonData) {
      let villageList: any[] = [];

      if (responseJsonData?.length > 0) {
        if (this.state.selectedLocationArr.length > 0) {
          villageList = responseJsonData.filter((el) => {
            return !this.state.selectedLocationArr.find((element) => {
              return element.id === el.id;
            });
          });
        } else {
          villageList = responseJsonData;
        }
        this.setState({
          initialVillagesVF: villageList,
          filteredVillagesVF: villageList,
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      } else {
        this.setState({
          initialVillagesVF: [],
          filteredVillagesVF: [],
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      }
    }
  };
  getAssignVillageFilterTalukaDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initialTalukasVF: respJson,
        filteredTalukasVF: respJson,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    } else {
      this.setState({
        initialTalukasVF: [],
        filteredTalukasVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    }
  };
  getAssignVillageFilterDistrictDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initalDistrictsVF: respJson,
        filteredDistrictsVF: respJson,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    } else {
      this.setState({
        initalDistrictsVF: [],
        filteredDistrictsVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    }
  };
  getAssignVillageFilterStateDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initialStatesVF: respJson,
        filteredStatesVF: respJson,
      });
    } else {
      this.setState({
        initialStatesVF: [],
        filteredStatesVF: [],
      });
    }
  };
  // USER FILTER RESPONSE FUNCTIONS
  setUserTableFilterAPIResponses = (apiRequestId: any, responseJsonData: any) => {
    switch (apiRequestId) {
      case this.getStateDataApiCallId:
        this.getUserTableFilterStateDataRes(responseJsonData);
        break;
      case this.getDistrictDataApiCallId:
        this.getUserTableFilterDistrictDataRes(responseJsonData);
        break;
      case this.getTalukaDataApiCallId:
        this.getUserTableFilterTalukaDataRes(responseJsonData);
        break;
      case this.getVillageDataApiCallId:
        this.getUserTableFilterVillageDataRes(responseJsonData);
        break;
    }
  }
  getUserTableFilterStateDataRes = (responseJsonData: any) => {
    if (responseJsonData?.length) {
      this.setState({
        initialStates: responseJsonData,
        filteredStates: responseJsonData
      })
    } else {
      this.setState({
        initialStates: [],
        filteredStates: []
      })
    }

  }
  getUserTableFilterDistrictDataRes = (responseJsonData: any) => {
    if (responseJsonData?.length) {
      this.setState({
        initalDistricts: responseJsonData,
        filteredDistricts: responseJsonData,
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      })
    } else {
      this.setState({
        initalDistricts: [],
        filteredDistricts: [],
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      })
    }
  }
  getUserTableFilterTalukaDataRes = (responseJsonData: any) => {
    if (responseJsonData?.length) {
      this.setState({
        initialTalukas: responseJsonData,
        filteredTalukas: responseJsonData,
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      })
    } else {
      this.setState({
        initialTalukas: [],
        filteredTalukas: [],
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      })
    }
  }
  getUserTableFilterVillageDataRes = (responseJsonData: any) => {
    if (responseJsonData?.length) {
      this.setState({
        initialVillages: responseJsonData,
        filteredVillages: responseJsonData,
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      })
    } else {
      this.setState({
        initialVillages: [],
        filteredVillages: [],
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      })
    }
  }
  setUserAdminRole = () => {
    if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.ADMIN) {
        this.setState({ isLoggedUserAdmin: true });
    } else if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.SUPER_ADMIN) {
        this.setState({ isLoggedUserAdmin: false });
    } else {
        this.setState({ isLoggedUserAdmin: true });
    }
};
  isLandtypeIrrigation = () => {
    return this.state.userLandTypeFarmer?.label === "Irrigation" ? true : false;
  };

  getFElistApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ FElistData: responseJson });
    } else {
      this.setState({ FElistData: [] });
    }
  };

  deleteUserApiCallIdResp = (responseJson: any) => {
    if (responseJson?.account_user) {
      const feId = window.location.pathname.split("/")[2];
      this.fetchFEsListData(feId);
      this.fetchFEByIdData(feId);
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.DELETE_FARMER
      })
      this.setState({ deleteFarmerLoading: false })
    } else if (responseJson?.data?.base) {
      this.setState({
        errorModalMsg: responseJson?.data?.base[0],
        errorModalOpen: true
      })
      this.setState({ deleteFarmerLoading: false })
    } else {
      this.setState({
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        errorModalOpen: true
      })
      this.setState({ deleteFarmerLoading: false })
    }
    this.handleClose()
  };

  deleteUserFEApiCallIdResp = (responseJson: any) => {
    if (responseJson?.account_user) {
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.DELETE_FE
      })
      this.setState({ deleteFELoading: false })
      window.location.replace("/users");    
    } else if (responseJson?.data?.base) {
      this.setState({
        errorModalMsg: responseJson?.data?.base[0],
        errorModalOpen: true
      })
      this.setState({ deleteFELoading: false })
    } else {
      this.setState({
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        errorModalOpen: true
      })
      this.setState({ deleteFELoading: false })
    }
    this.handleClose()
  };

  addSuperAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({
        successAddSuperAdmin: responseJson.data,
        userModal: false,
      });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_SA
      })
      this.setState({ addSALoading: false })
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addSALoading: false })
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addSALoading: false })
      }
    }
  };

  addAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({ successAddAdmin: responseJson.data });
      this.setState({ userModal: false });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_ADMIN
      })
      this.setState({ addAdminLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addAdminLoading: false })
      }
    }
  };

  addFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({ successAddFE: responseJson.data });
      this.setState({ userModal: false });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FE
      })
      this.setState({ addFELoading: false })
    } else {
      if (responseJson?.errors) {
        let newMsg = "";
        let errorMsg: string = responseJson.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responseJson?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorModalOpen: true,
          errorModalMsg: newMsg,
        });
        this.setState({ addFELoading: false })
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addFELoading: false })
      }
    }
  };

  updateFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      if (responseJson.data) {
        this.setState({ successUpdateFE: responseJson.data });
        const feId = window.location.pathname.split("/")[2];
        this.fetchFEByIdData(feId);
        this.fetchFEsListData(feId)
        this.handleClose();
        this.setState({
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_FE
        })
        this.setState({ updateFELoading: false })
      }
    } else {
      if (responseJson?.errors) {
        let newMsg = "";
        let errorMsg: string = responseJson.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responseJson?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorModalOpen: true,
          errorModalMsg: newMsg,
        });
        this.setState({ updateFELoading: false })
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateFELoading: false })
      }
    }
  };

  getStateApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({
        getStateData: responseJson,
      });
    }
  };

  getDistrictApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getDistrictData: responseJson });
    }
  };

  getTalukaApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getTalukaData: responseJson });
    }
  };

  getVillageApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getVillageData: responseJson });
    }
  };

  getOwnerListFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getOwnerListFarmerData: responseJson });
    }
  };

  getUnitOfMeasureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getUnitOfMeasureFarmerData: responseJson });
    }
  };

  getSoilTextureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getSoilTextureFarmerData: responseJson });
    } else {
      this.setState({ getSoilTextureFarmerData: [] });
    }
  };

  getTypeIrrigationListingApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getTypeIrrigationListingData: responseJson });
    }
  };

  updateFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      const feId = window.location.pathname.split("/")[2];
      this.fetchFEsListData(feId);
      this.setState({
        successUpdateFarmer: responseJson.data,
        selectedLocationArr: [],
      });
      this.handleClose();
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.UPDATE_FARMER
      })
      this.setState({ updateFarmerLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ updateFarmerLoading: false })
      }
    }
  };

  addFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.setState({
        successAddFarmer: responseJson.data,
        userModal: false,
      });
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FARMER
      })
      this.setState({ addFarmerLoading: false })
    } else {
      if (responseJson.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addFarmerLoading: false })
      }
    }
  };

  getDistanceInApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ DistanceInData: responseJson });
    } else {
      this.setState({ DistanceInData: [] });
    }
  };

  getEducationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getEducationFarmerData: responseJson });
    }
  };

  getFEByIdApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      if (responseJson.data) {
        this.setState({
          currentFELoading: false,
          currentFEData: responseJson.data?.attributes,
          totalFarmerCount: responseJson?.meta?.total_number_of_farmers,
          totalVillagesCount: responseJson?.meta?.total_number_of_villages,
        });
      } else {
        window.location.replace("/users");
        this.setState({
          currentFELoading: false,
          currentFEData: {
            first_name: "",
            last_name: "",
            country_code: "",
            phone_number: "",
          },
        });
      }
    }
  };

  getFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      if (responseJson.data) {
        const isFilterNotApplied: boolean = this.checkFilterError()
        if (isFilterNotApplied) {
          this.setState({
            hideBadge: true
          })
        } else {
          this.setState({
            hideBadge: false
          })
        }
        this.setState({
          feListDataLoading: false,
          feListData: responseJson.data,
          totalSearchedCount: responseJson?.meta?.total_searched_count,
          totalFECount: responseJson?.meta?.fe_count,
          totalCottonCount: responseJson?.meta?.total_crop_area_of_cotton,
        }, () => {
          this.handleCloseTableFilterPopup()
        });
      } else {
        this.setState({ feListDataLoading: false });
        this.setState({ feListData: [] });
      }
    }
  };

  getSourceOfIrrigationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getSourceOfIrrigationData: responseJson });
    }
  };

  getUnitFarmAreaApiCallIdResp = (responseJson: any) => {
    if (responseJson) {
      this.setState({ unitFarmAreaData: responseJson });
    } else {
      this.setState({ unitFarmAreaData: [] });
    }
  };

 // HANDLE FARM-MAPPING FUNCTION
  closeFarmMappingModal = () => { this.setState({ OpenFarmMapping: false }) }

  onSaveFarmMapping = (latlngHash: any, file: any,
    url: string, savedCoordinates: any[]) => {
    this.setState({
      latlngHash: latlngHash, OpenFarmMapping: false,
      farmMappingURL: url, farmMappingFile: file, tempCoordinates: savedCoordinates
    })
  }

  getCurrentLocation = () => {
    const success = (pos: any) => {

      this.setState({
        userLocation: {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }
      }, () => {
        localStorage.setItem('userLocationFarmMapping',
          JSON.stringify(this.state.userLocation))
      })
    }

    const error = (error: any) => {

      this.setState({ userLocation: null })
    }
    if (navigator && navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(success, error);
    }
}

setcoordinates = (data: any) => {

  // get string of latitude_longitude

  let latLongStringData = data.attributes.land_details[0]?.attributes.latitude_longitude

  if (latLongStringData && latLongStringData?.length > 0) {

    latLongStringData = JSON.parse(latLongStringData);
    // convert string to array of object
    let coordinatesArrayTemp = [];

    for (let i = 0; i < Object.keys(latLongStringData).length; i++) {
      coordinatesArrayTemp.push(latLongStringData[i]);
    }
    // re-change key names of array
    let CoordinatesArrFinalData = [];

    if (coordinatesArrayTemp.length > 0) {

      // Loop through each object in the original array and modify the keys
      for (const coordinate of coordinatesArrayTemp) {
        const modifiedCoord = {
          "lat": coordinate.latitude,
          "lng": coordinate.longitude
        };

        CoordinatesArrFinalData.push(modifiedCoord);
      }
    }
    if (CoordinatesArrFinalData.length > 0) {

      const centroidTemp = getPolygonCentroid(CoordinatesArrFinalData)
      this.setState({ 
        markerDefault: centroidTemp })
    }
    return CoordinatesArrFinalData;
  } else {
    return []
  }

}
  async componentDidMount(): Promise<void> {
    this.setUserAdminRole();
    const feId = window.location.pathname.split("/")[2];
    this.fetchFEsListData(feId);
    this.fetchFEByIdData(feId);
    this.fetchMobileDeviceModelFE()
    this.fetchStates();
    this.fetchEducationFarmer();
    this.fetchOwnerListFarmer();
    this.fetchUnitOfMeasureFarmer();
    this.fetchLandTypeFarmer();
    this.fetchSoilTextureFarmer();
    this.fetchSourceOfIrrigationFarmer();
    this.fetchTypeIrrigation();
    this.fetchLocationsListFE();
    this.fetchMobileDeviceModelFE();
    this.fetchUnitFarmAreaUrlData();
    this.fetchDistnaceInData();
    this.fetchFElist();
    // Assign Village
    this.getStatesVF()
    // User Table
    this.getStatesUT()
    this.getDistrictsUT()
    this.getTalukasUT()
    this.getVillagesUT()
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    const newFEId = window.location.pathname.split("/")[2];
    if (
      prevState.fePageSize !== this.state.fePageSize ||
      prevState.feTableCurrentPage !== this.state.feTableCurrentPage ||
      prevState.seachInputValue !== this.state.seachInputValue
    ) {
      this.fetchFEByIdData(newFEId);
      this.fetchFEsListData(newFEId);
    }
  }

  onFeLocationsChange = (event: any, values: any) => {
    this.setState({
      selectedLocationArr: values,
    });
  };
  assignVillagePlaceholder = () => {
    return this.state.selectedLocationArr.length === 0
      ? "Search by village name"
      : "";
  };
  handleVillageDelete = (event: any, village: any) => {
    const BEvillageObject: any = this.state.defaultAssignedVillages.find(
      (item: any) => String(item.attributes.village_id) === String(village.id)
    );
    if (BEvillageObject) {
      this.setState({
        deletedVillagesList: [
          ...this.state.deletedVillagesList,
          {
            id: BEvillageObject?.id,
            _destroy: true,
            village_id: BEvillageObject?.attributes.village_id,
          },
        ],
      });
    }
    const { selectedLocationArr } = this.state;
    const updatedOptions = selectedLocationArr?.filter(
      (option: any) => option !== village
    );

    this.setState({ selectedLocationArr: updatedOptions });
  };

  fetchFEByIdData = (feId: string) => {
    this.setState({
      currentFEData: {
        first_name: "",
        last_name: "",
        country_code: "",
        phone_number: "",
      },
      currentFELoading: true,
    });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}/${feId}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFEByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchFEsListData = (feId: string) => {
    this.setState({ feListData: [], feListDataLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.fePageSize) * Number(this.state.feTableCurrentPage + 1)
      }&page=${1}&search=${this.state.seachInputValue}&state_id=${this.state.selectedState.id}&district_id=${this.state.selectedDistrict.id}&taluka_id=${this.state.selectedTaluka.id}&village_id=${this.state.selectedVillage.id}&fe_user_id=${feId}`;

    if (this.state.sortAscending) {
      endpoint = endpoint + `&sort_by_a_to_z=${true}`
    }
    else if (this.state.sortDescending) {
      endpoint = endpoint + `&sort_by_z_to_a=${true}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    this.getFEApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFEDelete = () => {
    this.setState({
      openDeleteFEModal: true,
    });
  };

  checkLandType = (id: any)=> {
    if (id && this.state.getLandTypeFarmerData.length) {
      const matchedObj = this.state.getLandTypeFarmerData?.find(
        (ech: any) => ech.id === Number(id)
      );
      this.setState({ userLandTypeFarmer: matchedObj });
    } else {
      this.setState({ userLandTypeFarmer: null });
    }
  }

  getLandTypeFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getLandTypeFarmerData: responseJson });
    }
  };

  landTypeFarmerStatus = (data: any) => {
    if (
      data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes?.land_type_id &&
      data.attributes.land_details[0]?.attributes?.land_type_id.length &&
      data.attributes.land_details[0]?.attributes?.land_type_id
    ) {
      this.checkLandType(
        data.attributes.land_details[0].attributes.land_type_id[0]?.id
      );
      return data.attributes.land_details[0].attributes.land_type_id[0]?.id;
    } else {
      return "";
    }
  };

  handleFarmerDelete = (data: any) => {
    this.setState({
      deleteUserData: data,
      openDeleteModal: true,
    });
  };

  showUserModal() {
    this.setState({
      openEditUserModal: false,
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        userLandTypeFarmer: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },
      selectedLocationArr: [],
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      farmerInitialValues: {
        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",
        stateFarmer: "",
        genderFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        farmnameFarmer: "",
        farmSurveyNumberFarmer: "",
        ownershipFarmer: "",
        totalFarmAreaFarmer: "",
        unitOfMeasureFarmer: "",
        horizontalDistanceFarmer: "",
        distanceInFarmer: "",
        motorInHpFarmer: "",
        depthOfPumpFarmer: "",
        soilTextureFarmer: "",
        landTypeFarmer: "",
        sourceOfIrrigationFarmer: "",
        typeOfIrrigationFarmer: "",
      },
      userModal: true,
    });
  }

  distIDStatus = (data: any) => {
    return data.attributes.district_id && data.attributes.district_id.length
      ? data.attributes.district_id[0]?.id
      : "";
  };

  talukaIDStatus = (data: any) => {
    return data.attributes.taluka_id && data.attributes.taluka_id.length
      ? data.attributes.taluka_id[0]?.id
      : "";
  };

  VillageIDStatus = (data: any) => {
    return data.attributes.village_id && data.attributes.village_id.length
      ? data.attributes.village_id[0]?.id
      : "";
  };

  handleAssignFEchange = (event: any, value: any) => {
    this.setState({ selectedFEformData: value });
  };

  handleClose = () => {
    this.setState({
      farmImageFile: null,
      farmImageURL: null,
      openDeleteModal: false,
      deleteUserData: null,
      selectedFEformData: null,
      openDeleteFEModal: false,
      userModal: false,
      disableStateFarmer: false,
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
      // handle admin form states
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      // handle FE form states
      selectedLocationArr: [],
      defaultAssignedVillages: [],
      deletedVillagesList: [],
       // crop mapping
       farmMappingURL: null,
       coordinates: [],
       markerDefault: null,
       farmMappingFile: null,
       latlngHash: null,
       OpenFarmMapping: false,
       tempCoordinates: [],
    });
    switch (this.state.selectedUser) {
      case "farmer":
        this.setState({ seletedUserTypeAddUser: "Farmers" });
        break;
      case "fieldExecutive":
        this.setState({ seletedUserTypeAddUser: "Farmers" });
        break;
      case "admin":
        this.setState({ seletedUserTypeAddUser: "Admin" });
        break;
    }
  };

  editModalStatus = () => {
    return this.state.openEditUserModal === true ? true : false;
  };

  updateSeletedUserTypeAddUser = (userRole: string) => {
    this.setState({ seletedUserTypeAddUser: userRole });
  };

  handleErrorModalClose = () => {
    this.setState({ errorModalOpen: false, errorModalMsg: "" });
  };

  getPasswordType = () => {
    return this.state.showEyeIconPassword ? "password" : "text";
  };

  getConfirmPasswordType = () => {
    return this.state.showEyeIconConfirmPassword ? "password" : "text";
  };

  getLocationsListFEApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getLocationsListFEData: responseJson });
    } else {
      this.setState({ getLocationsListFEData: [] });
    }
  };

  getMobileDeviceModelFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getMobileDeviceModelFEData: responseJson });
    }
  };
  handleCloseSuccessModal = () => {
    this.setState({
      successModalOpen: false,
      successModalMsg: ""
    })
  }

  // ASSIGN VILLAGE FILTER FUNCTIONS
  showVillageFilterModal = () => {
    this.setState({ openVillageFilter: true });
  }
  anchorCurrentTarget = (event: any) => {
    this.setState({
      anchorElVillageFilter: event.currentTarget,
    });
  };
  handleChangePanelVF = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expandedVF: newExpanded ? panel : false,
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: this.state.initialStatesVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredVillagesVF: this.state.initialVillagesVF,
    });
  };
  opacityTalukaCalculatorVF = () => {
    if (this.state.selectedTalukaVF.name === "Select Taluka") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityVillageCalculatorVF = () => {
    if (this.state.selectedVillageVF.name === "Select Village") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityStateCalculatorVF = () => {
    if (this.state.selectedStateVF.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityDistrictCalculatorVF = () => {
    if (this.state.selectedDistrictVF.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }
  closeVillageFilterVF = () => {
    this.setState({
      openVillageFilter: false,
      expandedVF: false,
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    });
  }
  handleSubmitAccordionVF = () => {
    const result: boolean = this.checkAccordianError();
    if (result === true) {
      this.setState({
        expandedVF: false,
      });
    } else {
      const FilterObj = this.state.getLocationsListFEData.find(
        (item: any) => item.id === this.state.selectedVillageVF.id
      );
      let newVillageValue = [...this.state.selectedLocationArr];
      newVillageValue.push(FilterObj);
      this.setState(
        {
          selectedLocationArr: newVillageValue,
          openVillageFilter: false,
        },
        () => {
          this.closeVillageFilterVF();
        }
      );
    }
  }
  handleVillageFilterClearVF = () => {
    this.setState({
      expandedVF: false,
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: this.state.initialStatesVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredVillagesVF: this.state.initialVillagesVF,
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
    });
  }
  handleTalukaSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialTalukasVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukasVF;
    this.setState({
      filteredTalukasVF: filteredArray,
    });
  }
  handleVillageSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialVillagesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillagesVF;
    this.setState({
      filteredVillagesVF: filteredArray,
    });
  }
  handleStateSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialStatesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStatesVF;
    this.setState({
      filteredStatesVF: filteredArray,
    });
  }
  handleDistrictSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initalDistrictsVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistrictsVF;
    this.setState({
      filteredDistrictsVF: filteredArray,
    });
  }
  handleChangeTalukaFieldVF = (talukaData: any) => {
    this.setState(
      {
        selectedTalukaVF: talukaData,
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
        this.getVillagesVF();
      }
    );
  }
  handleChangeVillageFieldVF = (villageData: any) => {
    this.setState(
      {
        selectedVillageVF: villageData,
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
      }
    );
  }
  handleChangeStateFieldVF = (stateData: any) => {
    this.setState(
      {
        selectedStateVF: stateData,
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
        this.getDistrictsVF();
      }
    );
  }
  handleChangeDistrictFieldVF = (districtData: any) => {
    this.setState(
      {
        selectedDistrictVF: districtData,
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      }, () => {
        this.checkAccordianError();
        this.getTalukasVF();
      }
    );
  }

  checkAccordianError = () => {
    let isStateValueEmpty = false;
    let isDistrictValueEmpty = false;
    let isTalukaValueEmpty = false;
    let isVillageValueEmpty = false;

    if (
      this.state.selectedStateVF.id === "" || this.state.selectedDistrictVF.id === "" ||
      this.state.selectedTalukaVF.id === "" || this.state.selectedVillageVF.id === ""
    ) {
      if (this.state.selectedStateVF.id === "") {
        isStateValueEmpty = true;
      }
      if (this.state.selectedDistrictVF.id === "") {
        isDistrictValueEmpty = true;
      }
      if (this.state.selectedTalukaVF.id === "") {
        isTalukaValueEmpty = true;
      }
      if (this.state.selectedVillageVF.id === "") {
        isVillageValueEmpty = true;
      }
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateValueEmpty,
          district: isDistrictValueEmpty,
          taluka: isTalukaValueEmpty,
          village: isVillageValueEmpty,
        },
      });
      return true;
    } else {
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateValueEmpty,
          district: isDistrictValueEmpty,
          taluka: isTalukaValueEmpty,
          village: isVillageValueEmpty,
        },
      });
      return false;
    }
  }


  // TABLE FILTER FUNCTIONS STARTS
  handleChangeZtoA = () => {
    this.setState({
      sortDescending: !this.state.sortDescending,
      sortAscending: false
    });
  }
  handleChangeAtoZ = () => {
    this.setState({
      sortAscending: !this.state.sortAscending,
      sortDescending: false
    });
  }
  handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
    });
  };
  opacityDistrictCalculator = () => {
    if (this.state.selectedDistrict.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityStateCalculator = () => {
    if (this.state.selectedState.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityVillageCalculator = () => {
    if (this.state.selectedVillage.name === "Select Village") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityTalukaCalculator = () => {
    if (this.state.selectedTaluka.name === "Select Taluka") {
      return 0.48
    }
    else {
      return 1
    }
  }
  handleTalukaSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValue: e?.target?.value });
    const TalukafilteredArray = query
      ? this.state.initialTalukas.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukas;
    this.setState({
      filteredTalukas: TalukafilteredArray,
    });
  }
  handleStateSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValue: e?.target?.value });
    const StatefilteredArray = query
      ? this.state.initialStates.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStates;
    this.setState({
      filteredStates: StatefilteredArray,
    });
  }
  handleVillageSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValue: e?.target?.value });
    const VillagefilteredArray = query
      ? this.state.initialVillages.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillages;
    this.setState({
      filteredVillages: VillagefilteredArray,
    });
  }
  handleDistrictSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValue: e?.target?.value });
    const DistrictfilteredArray = query
      ? this.state.initalDistricts.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistricts;
    this.setState({
      filteredDistricts: DistrictfilteredArray,
    });
  }
  handleCloseTableFilterPopup = () => {
    this.setState({
      openTableFilterPopup: false,
      isDisabled: this.state.savedIsDisabled,
      selectedState: this.state.savedState,
      selectedDistrict: this.state.savedDistrict,
      selectedTaluka: this.state.savedTaluka,
      selectedVillage: this.state.savedVillage,
      expanded: false,
      sortAscending: this.state.savedSortAscending,
      sortDescending: this.state.savedSortDescending,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      filterHasError: false
    })
  }
  handleVillageFilterClear = () => {
    this.setState({
      openTableFilterPopup: false,
      expanded: false,
      anchorElUserTableFilter: null,
      sortDescending: false,
      sortAscending: false,
      filteredVillages: this.state.initialVillages,
      filteredTalukas: this.state.initialTalukas,
      filteredDistricts: this.state.initalDistricts,
      filteredStates: this.state.initialStates,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      savedSortDescending: false,
      savedSortAscending: false,
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      filterHasError: false,
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    }, () => {
      const feId = window.location.pathname.split("/")[2];
      this.fetchFEsListData(feId);
      this.getDistrictsUT()
      this.getTalukasUT()
      this.getVillagesUT()
    })
  };
  handleSubmitFilter = () => {
    const isError = this.checkFilterError()
    if (!isError) {
      this.setState({
        expanded: false,
        savedIsDisabled: this.state.isDisabled,
        savedSortAscending: this.state.sortAscending,
        savedSortDescending: this.state.sortDescending,
        savedState: this.state.selectedState,
        savedDistrict: this.state.selectedDistrict,
        savedTaluka: this.state.selectedTaluka,
        savedVillage: this.state.selectedVillage,
      }, () => {
        const feId = window.location.pathname.split("/")[2];
        this.fetchFEsListData(feId);
      });
    } else {
      return false
    }
  }
  handleChangeDistrictField = (districtData: any) => {
    this.setState({
      selectedDistrict: districtData,
      expanded: false,
    }, () => {
      this.getTalukasUT()
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", "district", "taluka", "", "")

  }
  handleChangeStateField = (stateData: any) => {
    this.setState({
      selectedState: stateData,
      expanded: false,
    }, () => {
      this.getDistrictsUT()
    });
    this.handleChangeField("", "state", "district", "", "")
  }
  handleChangeVillageField = (villageData: any) => {
    this.setState({
      selectedVillage: villageData,
      expanded: false,
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", "village", "fe")
  }
  handleChangeTalukaField = (talukaData: any) => {
    this.setState({
      selectedTaluka: talukaData,
      expanded: false,
    }, () => {
      this.getVillagesUT()
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", "taluka", "village", "")
  }
  handleChangeField = (key1: any, key2: any, key3: any, key4: any, key5?: any) => {
    const isDisabledInitialValues = {
      state: true,
      district: true,
      taluka: true,
      village: true,
    }
    this.setState({
      isDisabled: {
        ...isDisabledInitialValues,
        ...(key4 && { [key4]: false }),
        ...(key3 && { [key3]: false }),
        ...(key2 && { [key2]: false }),
        ...(key1 && { [key1]: false }),
      }
    })

  }
  checkFilterError = () => {
    if (this.state.selectedState.id !== "" ||
      this.state.selectedDistrict.id !== "" ||
      this.state.selectedTaluka.id !== "" ||
      this.state.selectedVillage.id !== "" ||
      this.state.sortAscending ||
      this.state.sortDescending
    ) {
      this.setState({ filterHasError: false })
      return false;
    } else {
      this.setState({ filterHasError: true })
      return true;
    }
  }

  // TABLE FILTER FUNCTIONS ENDS
  fetchMobileDeviceModelFE = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getMobileDeviceModelFEApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMobileDeviceModelFEAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteUser = () => {
    this.setState({ deleteFarmerLoading: true })
    let userId = this.state.deleteUserData?.id;
    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      this.deleteUserApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteUserApiEndPoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.userNotFound,
      });
      this.setState({ deleteFarmerLoading: false })
      return false;
    }
  };

  deleteFEUser = () => {
    this.setState({ deleteFELoading: true })
    let userId = window.location.pathname.split("/")[2];
    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      this.deleteUserFEApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteUserApiEndPoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.userNotFound,
      });
      this.setState({ deleteFELoading: false })
      return false;
    }
  };

  addAdmin = (adminBody: any) => {
    this.setState({ addAdminLoading: true })
    const dob = moment(adminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAdminAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: adminBody.firstnameAdmin,
          last_name: adminBody.lastnameAdmin,
          email: adminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + adminBody.mobileNumberAdmin,
          user_name: adminBody.usernameAdmin,
          password: adminBody.passwordAdmin,
          password_confirmation: adminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addSuperAdmin = (superAdminBody: any) => {
    this.setState({ addSALoading: true })
    const dob = moment(superAdminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSuperAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_super_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: superAdminBody.firstnameAdmin,
          last_name: superAdminBody.lastnameAdmin,
          email: superAdminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + superAdminBody.mobileNumberAdmin,
          user_name: superAdminBody.usernameAdmin,
          password: superAdminBody.passwordAdmin,
          password_confirmation: superAdminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFeEditClick = (data: any) => {
    const feDoB = moment(data.date_of_birth);
    const mobileDevice = data?.mobile_type_id[0].id || "";
    let tempArr: any = [];
    this.state.getLocationsListFEData.forEach((each: any) => {
      if (data.accounts_villages?.length) {
        data.accounts_villages.forEach((item: any) => {
          if (String(each.id) === String(item.attributes.village_id)) {
            tempArr.push(each);
          }
        });
      }
    });
    this.setState({
      selectedLocationArr: tempArr,
      defaultAssignedVillages: data.accounts_villages
    });
    this.setState({
      openEditUserModal: true,
      seletedUserTypeAddUser: "FieldExecutive",
      feInitialValues: {
        firstnameFE: data.first_name,
        lastnameFE: data.last_name,
        mobileNumberFE: data.phone_number,
        locationFE: data.location,
        dateOfBirthFE: feDoB,
        emailAddressFE: data.email,
        usernameFE: data.user_name,
        mobileDeviceModelFE: mobileDevice,
      },
      userModal: true,
    });
  };

  addFieldExecutive = (feBody: any) => {
    this.setState({ addFELoading: true })
    const dobFe = moment(feBody.dateOfBirthFE).format("DD/MM/YYYY");

    let feLocationsArr: any[] = [];
    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        feLocationsArr.push({ village_id: each.id });
      });
    } else {
      feLocationsArr = [];
    }
    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFEApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_fe=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feBody.firstnameFE,
          last_name: feBody.lastnameFE,
          phone_number: feBody.mobileNumberFE,
          location: feBody.locationFE,
          accounts_villages_attributes: feLocationsArr,
          date_of_birth: dobFe,
          email: feBody.emailAddressFE,
          user_name: feBody.usernameFE,
          mobile_type_id: feBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feBody.mobileNumberFE,
        },
      }
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateFieldExecutive = (feNewBody: any) => {
    this.setState({ updateFELoading: true })
    const feId = window.location.pathname.split("/")[2];
    const dobFe = moment(feNewBody.dateOfBirthFE).format("DD/MM/YYYY");
    let feNewLocationsArr: any[] = [];

    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        const isAssignedVillageIsNew: boolean = !this.state.defaultAssignedVillages?.some(
          (item) => item.attributes.village_id === each.id
        )
        if (isAssignedVillageIsNew) {
          feNewLocationsArr.push({ village_id: each.id });
        }
      });
    } else {
      feNewLocationsArr = [];
    }
    const updatedDeleteArray = this.state.deletedVillagesList?.map((item) => {
      for (const element of this.state.selectedLocationArr) {
        if (element.id === item.village_id) {
          item._destroy = false;
        }
      }
      return item;
    });

    const finalUpdatedVillageList = feNewLocationsArr.concat(
      updatedDeleteArray
    );

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFEApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${feId}?role_fe=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feNewBody.firstnameFE,
          last_name: feNewBody.lastnameFE,
          phone_number: feNewBody.mobileNumberFE,
          location: feNewBody.locationFE,
          accounts_villages_attributes: finalUpdatedVillageList,
          date_of_birth: dobFe,
          email: feNewBody.emailAddressFE,
          user_name: feNewBody.usernameFE,
          mobile_type_id: feNewBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feNewBody.mobileNumberFE,
        },
      },
      assign_farmer_ids: [],
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDistrictFarmerValue = (values: any) => {
    return this.state.getDistrictData?.length ? values?.districtFarmer : "";
  };

  getVillageFarmerValue = (values: any) => {
    return this.state.getVillageData?.length ? values?.villageFarmer : "";
  };

  getTalukaFarmerValue = (values: any) => {
    return this.state.getTalukaData?.length ? values?.talukaFarmer : "";
  };

  disableDistrictFarmerValue = () => {
    return this.state.getStateData.length && this.state.getDistrictData.length
      ? false
      : true;
  };
  disableTalukaFarmerValue = () => {
    return this.state.getDistrictData.length && this.state.getTalukaData.length
      ? false
      : true;
  };

  disableVillageFarmerValue = () => {
    return this.state.getTalukaData.length && this.state.getVillageData.length
      ? false
      : true;
  };

  keyDown = (evt: any) =>
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
  keyDownInt = (evt: any) =>
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();

  parseImages = (img?: string): string | undefined => {
    if (!img) return undefined;
    return img;
  };
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  async handleImageUpload(event: any) {
    const selectedFile = event.target.files[0];
    const urlImage = await this.getBase64(selectedFile);
    this.setState({ farmImageURL: urlImage, farmImageFile: selectedFile });
  }
  fetchUnitFarmAreaUrlData = () => {
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getUnitFarmAreaApiCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UnitFarmAreaUrl
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
  fetchStates = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getStateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchDistrict = (stateId: string | number) => {
    this.setState({
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
    });
    if (!stateId || stateId === "") {
      this.setState({
        getDistrictData: [],
        getTalukaData: [],
        getVillageData: [],
      });
      return false;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getDistrictApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictAPiEndPoint}?state_id=${stateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchTaluka = (districtId: string | number) => {
    this.setState({ getTalukaData: [], getVillageData: [] });

    if (!districtId || districtId === "") {
      this.setState({ getTalukaData: [], getVillageData: [] });
      return false;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getTalukaApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaAPiEndPoint}?district_id=${districtId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchVillage = (villageId: string | number) => {
    this.setState({ getVillageData: [], });

    if (!villageId) {
      this.setState({ getVillageData: [], });
      return false;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getVillageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}?taluka_id=${villageId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchOwnerListFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getOwnerListFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOwnerListFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchUnitOfMeasureFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getUnitOfMeasureFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUnitOfMeasureFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchLandTypeFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getLandTypeFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandTypeFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSoilTextureFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getSoilTextureFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoilTextureFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSourceOfIrrigationFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getSourceOfIrrigationFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSourceOfIrrigationFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchTypeIrrigation = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getTypeIrrigationListingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTypeIrrigationListingAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  stateIDStatus = (data: any) => {
    return data.attributes.state_id?.length
      ? data.attributes.state_id[0]?.id
      : "";
  };
  educationFarmerStatus = (data: any) => {
    return data.attributes.highest_education_id &&
      data.attributes.highest_education_id.length
      ? data?.attributes?.highest_education_id[0]?.id
      : "";
  };
  farmnameFarmerStatus = (data: any) => {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.name) ||
      ""
    );
  };
  fetchEducationFarmer = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getEducationFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationFarmerAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  farmSurveyNumberFarmerStatus = (data: any) => {
    return data.attributes.land_details
      ? data.attributes.land_details[0]?.attributes.survey_number
      : "";
  };
  ownershipFarmerStatus = (data: any) => {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.ownership_of_land_id?.id
      : "";
  };
  totalFarmAreaFarmerStatus = (data: any) => {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.farm_area) ||
      ""
    );
  };
  unitOfMeasureFarmerStatus = (data: any) => {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.unit_farm_area_id
      ? data.attributes.land_details[0]?.attributes.unit_farm_area_id?.id
      : "";
  };
  horizontalDistanceFarmerStatus = (data: any) => {
    return data.attributes.land_details && data.attributes.land_details.length
      ? data.attributes.land_details[0]?.attributes.horizontal_distance
      : "";
  };
  distanceInFarmerStatus = (data: any) => {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.distance_in_id
      ? data.attributes.land_details[0]?.attributes.distance_in_id?.id
      : "";
  };
  motorInHpFarmerStatus = (data: any) => {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.motor_horse_power) ||
      ""
    );
  };
  depthOfPumpFarmerStatus = (data: any) => {
    return (
      (data.attributes.land_details &&
        data.attributes.land_details[0]?.attributes.pump_depth) ||
      ""
    );
  };
  soilTextureFarmerStatus = (data: any) => {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.soil_texture_id &&
      data.attributes.land_details[0]?.attributes.soil_texture_id.length &&
      data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.soil_texture_id[0]?.id
      : "";
  };
  sourceOfIrrigationFarmerStatus = (data: any) => {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
        .length &&
      data.attributes.land_details[0]?.attributes?.source_irrigation_id
      ? data.attributes.land_details[0].attributes.source_irrigation_id[0]?.id
      : "";
  };
  typeOfIrrigationFarmerStatus = (data: any) => {
    return data.attributes.land_details &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id.length &&
      data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      ? data.attributes.land_details[0]?.attributes.type_irrigation_id[0]?.id
      : "";
  };
  handleFarmerEditClick = async (data: any) => {
    let setFE = null;
    let feId = data.attributes.assigned_fe_id;
    if (feId) {
      setFE = this.state.FElistData?.find((item: any) => item.id === feId);
    }
    const farmerDoB = moment(data.attributes?.date_of_birth);
    const stateID = this.stateIDStatus(data);
    if (stateID) {
      this.setState({ disableStateFarmer: true });
    } else {
      this.setState({ disableStateFarmer: false });
    }
    await this.fetchDistrict(stateID);
    const distID = this.distIDStatus(data);
    await this.fetchTaluka(distID);
    const talukaId = this.talukaIDStatus(data);
    await this.fetchVillage(talukaId);
    const villageID = this.VillageIDStatus(data);

    this.setState({
      openEditUserModal: true,
      seletedUserTypeAddUser: "Farmers",
      farmerRowData: data,
      farmerInitialValues: {
        firstnameFarmer: data.attributes.first_name,
        middlenameFarmer: data.attributes.middle_name,
        surnameFarmer: data.attributes.last_name,
        mobileNumberFarmer: data.attributes.phone_number,
        adharcardNumberFarmer: data.attributes.aadhaar_number,
        dateOfBirthFarmer: farmerDoB,
        totalFamilyMemberFarmer: data?.attributes?.total_family_members,
        educationFarmer: this.educationFarmerStatus(data),
        stateFarmer: stateID,
        genderFarmer: data.attributes.gender,
        districtFarmer: distID,
        talukaFarmer: talukaId,
        villageFarmer: villageID,
        farmnameFarmer: this.farmnameFarmerStatus(data),
        farmSurveyNumberFarmer: this.farmSurveyNumberFarmerStatus(data),
        ownershipFarmer: this.ownershipFarmerStatus(data),
        totalFarmAreaFarmer: this.totalFarmAreaFarmerStatus(data),
        unitOfMeasureFarmer: this.unitOfMeasureFarmerStatus(data),
        horizontalDistanceFarmer: this.horizontalDistanceFarmerStatus(data),
        distanceInFarmer: this.distanceInFarmerStatus(data),
        motorInHpFarmer: this.motorInHpFarmerStatus(data),
        depthOfPumpFarmer: this.depthOfPumpFarmerStatus(data),
        soilTextureFarmer: this.soilTextureFarmerStatus(data),
        landTypeFarmer: this.landTypeFarmerStatus(data),
        sourceOfIrrigationFarmer: this.sourceOfIrrigationFarmerStatus(data),
        typeOfIrrigationFarmer: this.typeOfIrrigationFarmerStatus(data),
      },
      selectedFEformData: setFE,
      farmMappingURL: data.attributes.land_details[0]?.attributes.farm_mapping_image?.url,
      coordinates: this.setcoordinates(data),
      tempCoordinates: this.setcoordinates(data),
      farmImageURL: data.attributes.land_details[0]?.attributes.farm_image?.url,
      farmImageFile: null,      
      userModal: true,
    });
  };

  addFarmer = (farmerBody: any) => {
    this.setState({ addFarmerLoading: true })
    const dobFarmer = moment(farmerBody.dateOfBirthFarmer).format("DD/MM/YYYY");
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;

    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_farmer=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", dobFarmer);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );

    if (this.state.latlngHash) 
    {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][latitude_longitude]",
        this.state.latlngHash
      );
    }
    if (this.state.farmImageFile) {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][farm_image]",
        this.state.farmImageFile
      );
    }    
    if (this.state.farmMappingFile) 
    {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][farm_mapping_image]", this.state.farmMappingFile) }
    
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][name]",
      farmerBody.farmnameFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][survey_number]",
      farmerBody.farmSurveyNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][ownership_of_land_id]",
      farmerBody.ownershipFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area]",
      farmerBody.totalFarmAreaFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
      farmerBody.totalFarmAreaFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][unit_farm_area_id]",
      farmerBody.unitOfMeasureFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][horizontal_distance]",
      farmerBody.horizontalDistanceFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][distance_in_id]",
      farmerBody.distanceInFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][motor_horse_power]",
      farmerBody.motorInHpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][pump_depth]",
      farmerBody.depthOfPumpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][land_type_id]",
      farmerBody.landTypeFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][source_irrigation_id]",
      farmerBody.sourceOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][type_irrigation_id]",
      farmerBody.typeOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][soil_texture_id]",
      farmerBody.soilTextureFarmer
    ); //extra
    if (this.state.selectedFEformData) {
      reqFormdata.append("data[attributes][assigned_fe_id]", FEid);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateFarmer = (farmerBody: any) => {
    this.setState({ updateFarmerLoading: true })
    const farmerId = this.state.farmerRowData.id;
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;
    const landDetailsId =
      this.state.farmerRowData.attributes.land_detail_id[0] || "";
    const farmerNewdob = moment(farmerBody.dateOfBirthFarmer).format(
      "DD/MM/YYYY"
    );
    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${farmerId}?role_farmer=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", farmerNewdob);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    if (this.state.farmImageFile) {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][farm_image]",
        this.state.farmImageFile
      );
    }
    // farm_mapping_image
    if (this.state.latlngHash) 
    {
      reqFormdata.append(
        "data[attributes][land_details_attributes[0]][latitude_longitude]",
        this.state.latlngHash
      );
    }
    if (this.state.farmMappingFile)  {
      reqFormdata.append( "data[attributes][land_details_attributes[0]][farm_mapping_image]", this.state.farmMappingFile
      )}   
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][id]",
      landDetailsId
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes[0]][name]",
      farmerBody.farmnameFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][survey_number]",
      farmerBody.farmSurveyNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][ownership_of_land_id]",
      farmerBody.ownershipFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area]",
      farmerBody.totalFarmAreaFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
      farmerBody.totalFarmAreaFarmer
    ); //extra
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][unit_farm_area_id]",
      farmerBody.unitOfMeasureFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][horizontal_distance]",
      farmerBody.horizontalDistanceFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][distance_in_id]",
      farmerBody.distanceInFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][motor_horse_power]",
      farmerBody.motorInHpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][pump_depth]",
      farmerBody.depthOfPumpFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][land_type_id]",
      farmerBody.landTypeFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][source_irrigation_id]",
      farmerBody.sourceOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][type_irrigation_id]",
      farmerBody.typeOfIrrigationFarmer
    );
    reqFormdata.append(
      "data[attributes][land_details_attributes][0][soil_texture_id]",
      farmerBody.soilTextureFarmer
    ); //extra

    reqFormdata.append("data[attributes][assigned_fe_id]", FEid);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchFElist = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFElistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFEListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchDistnaceInData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getDistanceInApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDistanceInAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchLocationsListFE = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getLocationsListFEApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS
  getStatesVF = () => {
    this.setState({      
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },      
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      isDisabledVF: {
        ...this.state.isDisabledVF,
        district: true,
        village: true,
        taluka: true,
      },
    });
   
    const reqApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageFilterStateData = reqApiMsg.messageId;

    reqApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue,
    };
    reqApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
  }
  getDistrictsVF = () => {
    let params = this.state.selectedStateVF?.id;
    if (this.state.selectedStateVF?.id) {
      this.setState({
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: true,
          village: true,
          taluka: true,
        },        
      });

      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterDistrictData = reqApiMsg.messageId;
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getDistrictList}?state_id=${params}`
      );
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }
  getTalukasVF = () => {
    let params = this.state.selectedDistrictVF?.id;
    if (this.state.selectedDistrictVF?.id) {
      this.setState({        
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: true,
          village: true,
        },
      });
      
      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterTalukaData = reqApiMsg.messageId;

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTalukaList}?district_id=${params}`
      );
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };
      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }
  getVillagesVF = () => {
    let params = this.state.selectedTalukaVF?.id;
    if (this.state.selectedTalukaVF?.id) {
      this.setState({
        isDisabledVF: {
          ...this.state.isDisabledVF,
          village: true,
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
      });
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const reqApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterVillageData = reqApiMsg.messageId;

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVillageList}?taluka_id=${params}`
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqApiMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    } else {
      return false;
    }
  }
  // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS

  // USER TABLE FILTER STARTS
  getVillagesUT() {
    let params = this.state.selectedTaluka?.id
    this.setState({
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        village: true,
      }
    })

    const resApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageDataApiCallId = resApiMsg.messageId;

    resApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageList}?taluka_id=${params}`
    );
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };
    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(resApiMsg.id, resApiMsg);
  }
  getTalukasUT() {
    let params = this.state.selectedDistrict?.id
    this.setState({
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        taluka: true,
        village: true,
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const resApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaDataApiCallId = resApiMsg.messageId;

    resApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaList}?district_id=${params}`
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(resApiMsg.id, resApiMsg);
  }
  getDistrictsUT() {
    let params = this.state.selectedState?.id
    this.setState({
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        district: true,
        village: true,
        taluka: true,
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
    });
    const resApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistrictDataApiCallId = resApiMsg.messageId;

    resApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictList}?state_id=${params}`
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };
    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(resApiMsg.id, resApiMsg);
  }
  getStatesUT() {
    this.setState({
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        district: true,
        village: true,
        taluka: true,
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
    });
    const headers = {
      token: this.state.TokenValue,
      "Content-Type": configJSON.validationApiContentType,
    };

    const resApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateDataApiCallId = resApiMsg.messageId;

    resApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    resApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(resApiMsg.id, resApiMsg);
  }
  // USER TABLE FILTER ENDS
  // Customizable Area End
}
