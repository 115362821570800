import React from 'react'

import {CircularProgress , makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    loader :{
        "text-align": "center",
        marginTop: "10px",
    }
})) 

const Loader = ({error}: {error?: string}) =>{ 
    const classes = useStyles();
    return (
    <div className={classes.loader}>
      {error || <CircularProgress color="secondary" />}
    </div>
  )}


export default Loader