Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";

exports.exampleAPiMethod = "POST";
exports.apiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";

exports.btnExampleTitle = "CLICK ME";
exports.surveyShareLink = "https://co:farm/survey/";
exports.surveyDetailsShareLink = "https://co:farm/surveydetails/";
//Surveys  
exports.SURVEYS_LIST = "admin/bx_block_admin_farm_dairy/surveys";
exports.GET_ALL_QUESTION = "admin/bx_block_admin_farm_dairy/surveys/all_question_types";
exports.CREATE_SURVEY =  "/admin/bx_block_admin_farm_dairy/surveys";
exports.editSurvey = (id) => `/admin/bx_block_admin_farm_dairy/surveys/${id}`;
exports.DELETE_SURVEY_DESCRIPTION = "By deleting survey from here it will be removed from all the users. Are you sure? You want to delete this Survey?"
exports.changeSurveysState = (id)  =>  `/admin/bx_block_admin_farm_dairy/surveys/${id}/enable_disable_survey`;
exports.SURVEYS_RESPONSE = "/admin/bx_block_admin_farm_dairy/surveys/survey_user_response?survey_id=";
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.DELETE_METHOD_TYPE = "DELETE";

//inital
exports.optionInitialValue = { value: "", value_hindi: "", value_gujrati: ""}

// Customizable Area End