Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LinkShare";
exports.labelBodyText = "LinkShare Body";
exports.apiContentType = 'application/json';

exports.btnExampleTitle = "CLICK ME";
exports.getChatListApiEndPoint = 'admin/bx_block_admin_chat/admin_chats';
exports.sendMessageApiEndPoint = 'admin/bx_block_admin_chat/chat_messages';
exports.perPageUserList = 10;
// Customizable Area End