
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    landType: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isExpanded: boolean | string;
    isShare: boolean,
    linkShare:string
    messageHeading:string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserFarmDiaryController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isExpanded: false,
            isShare: false,
            linkShare:'',
            messageHeading:''
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    // Customizable Area Start
    handleAccordionChange = (panel: any) => (
        event: any, isExpanded: boolean
    ) => {
        this.setState({
            isExpanded: isExpanded ? panel : false
        })
    };

    handleShareLink =(farmObj?:any) => { 
        const shareLink = configJSON.farmDairyShareLink + farmObj?.type + '/' + farmObj?.id + '/' + farmObj?.attributes?.account_id
        const heading = farmObj?.type
        this.setState({
            isShare: !this.state.isShare,
            linkShare: shareLink,
            messageHeading: heading
        })   
        }

    // Customizable Area End
}
