import React, { ReactElement } from "react";
import {
    Badge,
    Box,
    Button,
    Card,
    CardMedia,
    CardHeader,
    CardContent,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    Divider,
    Input,
    InputBase,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Modal,
    Tabs,
    Tab,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
    Add,
    AddCircleOutline,
    Edit,
    RemoveCircleOutline,
    PhotoCamera,
    Delete,
    Close,
} from "@material-ui/icons";
import "./calendar.web.css";
import CalendarScreenController, { Props, configJSON, TPhaseProfile, TActivity, TRange, TResult } from "./AdminConsoleCalendarScreenController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { isImage } from "../../../components/src/AdminConsoleHelper";

const { calendarScreenDefaults: screenDefaults } = configJSON;

// Customizable Area Start        
import { ceilingValueWithBase10,floorValueWithBase10 } from "../../../components/src/helper";
const colorGroup: Record<"bg" | "bl", string>[] = [
    { bg: "#E6DECB", bl: "#9B824B" },
    { bg: "#DBDEC9", bl: "#A9AF7E" },
    { bg: "#C0E2D5", bl: "#67B99A" },
    { bg: "#C6EBCE", bl: "#59C570" },
    { bg: "#DFE8C4", bl: "#9EB94B" },
    { bg: "#E8E9BF", bl: "#BABD42" },
    { bg: "#FFE8DB", bl: "#FFA570" },
    { bg: "#FFEACD", bl: "#FBC26E" },
];
// Customizable Area End

export default class CalendarScreen extends CalendarScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    ImageFragment = ({ attachment, file, int, index }: { attachment?: string, file: any, int: number, index: number }) => {
        const imgPartial = file ? attachment : this.parseImages(attachment);

        const  isImg = file?.type?.includes('image') || isImage(attachment || "")

        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={styles.hide}
                                id="edit-icon-badge-file"
                                onChange={(e) => this.handleImageSelect(e)}
                                data-testid={"modal_image_handle_select"}
                                accept="image/*,video/*"
                            />
                            <label htmlFor="edit-icon-badge-file">
                                <IconButton
                                    data-testid={"modal_image_fragment_select"}
                                    component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={styles.badgeButton}
                                    onClick={() => this.auxillarySelectImage({ int, sub_index: index })}
                                >
                                    <Edit fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined" style={styles.badgeCardRoot}>
                        { isImg ? <CardMedia
                            component="img"
                            image={imgPartial}
                            src={attachment}
                            alt="cover-images"
                        />
                            :
                            <video src={imgPartial} width="100%" height="100%"  controls />
                        }
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={styles.formImageContainer}>
                    <input
                        accept="image/*,video/*"
                        type="file"
                        style={styles.hide}
                        id="icon-button-file"
                        onChange={(e) => this.handleImageSelect(e)}
                        data-testid={"modal_image_handle_select"}
                    />
                    <label htmlFor="icon-button-file" style={{ display: "flex", alignItems: "center" }}>
                        <IconButton data-testid={"modal_image_fragment_select"} aria-label="upload image" component={"span"} onClick={() => this.auxillarySelectImage({ int, sub_index: index })}>
                            <PhotoCamera style={styles.photoCamera} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };

    secondaryActivity = ({ phase, int }: { phase: TActivity, int: number }) => {

        return phase.sub_activity_progresses_attributes.map((sub_phase: any, index) => {

            if (sub_phase._destroy) {
                return <></>
            }

            const subActivityId = sub_phase.phase_sub_activity_id ? sub_phase.phase_sub_activity_id : 'none';

            return (
                <React.Fragment key={index} >
                    <Box style={{...styles.secondaryRootBoxContainer, ...{flexDirection: "column"}}}>
                        <Box style={{display: 'flex'}}>
                            <Typography
                                variant="subtitle2"
                                className="calendarFieldLabel"
                                style={
                                   { ...styles.modalCardContentEditModalTypography,
                                    ...{'width': '50%'}}
                                }
                                >
                                Sub-Activity Name
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className="calendarFieldLabel"
                                style={
                                    { ...styles.modalCardContentEditModalTypography,
                                     ...{'width': '50%'}}
                                 }
                                >
                                Days before/after sowing
                            </Typography>
                        </Box>
                        <Box style={{width: '100%', display: 'flex', gap: '0.5rem'}}>
                            <FormControl
                                fullWidth
                                style={{ ...styles.modalFormControl }}
                            >
                                <InputLabel htmlFor="my-input" />
                                <Select
                                    data-testid={"modal_select_sub_activity_id"}
                                    labelId="demo-customized-select-label"
                                    id="demo-customized-select"
                                    value={subActivityId}
                                    onChange={(e) => this.updateSubActivity(e, 'phase_sub_activity_id', int, index)}
                                    name="phase_sub_activity_name"
                                    input={
                                        <InputBase
                                            placeholder="Enter name of sub-activity"
                                            fullWidth
                                        />
                                    }
                                    style={styles.modalSelectField}
                                >
                                    <MenuItem value={'none'}>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        this.state.sub_activity_profile_attributes[phase.phase_activity_id]?.map((item) => (
                                              <MenuItem key={item.id} value={item.id}>{item.name_array[this.state.languageTab]}</MenuItem>
                                            )
                                        )
                                    }
                                </Select>

                                <FormHelperText
                                    id="my-helper-text"
                                    style={styles.modalFormHelper}
                                >
                                </FormHelperText>
                            </FormControl>
                            
                            <Typography style={{...styles.toTypography, ...{visibility: 'hidden'}}}>
                            to
                            </Typography>

                            <FormControl
                                        fullWidth
                                        style={styles.modalFormControl}
                                    >
                                        <InputLabel htmlFor="my-input" />
                                        <input
                                            data-testid={"modal_input_before_after_day"}
                                            style={styles.modalTextField}
                                            placeholder="Start day"
                                            value={sub_phase.before_after_day}
                                            name="before_after_day"
                                            onChange={(e) => this.updateSubActivity(e, 'before_after_day', int, index)}
                                        />

                                        <FormHelperText
                                            id="my-helper-text"
                                            style={styles.modalFormHelper}
                                        >
                                        </FormHelperText>
                            </FormControl>

                            <Box style={{...styles.buttonContainer, ...{display: 'flex'}}}>
                                <Delete data-testid={"modal_remove_sub_activity_button"} htmlColor={"#B3261E"} onClick={() => this.removeSubActivity(int, index)} style={{ cursor: "pointer" }} />
                            </Box>
                        </Box>
                    </Box>

                    {/* Upload Image */}
                    <Box style={styles.secondaryUploadImageBox}>
                        <Box component="form" style={styles.secondaryRootBox}>
                            <Typography
                                variant="subtitle2"
                                className="calendarFieldLabel"
                                style={styles.modalCardContentEditModalTypography}
                            >
                                Upload Image/Video
                            </Typography>
                            <FormControl
                                fullWidth
                                style={styles.modalFormControl}
                            >
                                <InputLabel htmlFor="my-input" />
                                {this.ImageFragment({ attachment: sub_phase.attachment, file: sub_phase.file, int, index })}

                                <FormHelperText
                                    id="my-helper-text"
                                    style={styles.modalFormHelper}
                                >
                                </FormHelperText>
                            </FormControl>
                        </Box>

                        <Box component="form" style={styles.secondaryRootDescriptionContainer}>
                            <Typography
                                variant="subtitle2"
                                className="calendarFieldLabel"
                                style={styles.modalCardContentEditModalTypography}
                            >
                                Description
                            </Typography>
                            <FormControl
                                fullWidth
                                style={styles.modalFormControl}
                            >
                                <InputLabel htmlFor="my-input" />
                                <textarea
                                    data-testid={"modal_text_area_update_description"}
                                    style={styles.modalTextArea}
                                    placeholder="Enter description here..."
                                    rows={4}
                                    value={sub_phase.description}
                                    name={"description"}
                                    onChange={(e) => this.updateSubActivity(e, 'description', int, index)}
                                />

                                <FormHelperText
                                    id="my-helper-text"
                                    style={styles.modalFormHelper}
                                >
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Box >
                    {/* Upload Image */}

                    {/* Input Details */}
                    <Box>
                        {sub_phase.input_details_attributes?.map((input: any, input_key: number) => {
                            
                            return !input._destroy && (
                                <React.Fragment key={input_key}>
                                    <Box component="form" style={styles.secondaryInputDetailsAttributesContainer}>
                                        <Box style={styles.secondaryRootBox}>
                                            <FormControl
                                                fullWidth
                                                style={styles.modalFormControl}
                                            >
                                                <InputLabel htmlFor="my-input" />
                                                <input
                                                    data-testid={"modal_update_input_key"}
                                                    style={styles.modalTextField}
                                                    value={input.key}
                                                    name="activityInputDetails"
                                                    onChange={(e) => this.updateInputField(e, "key", int, index, input_key)}
                                                />

                                                <FormHelperText
                                                    id="my-helper-text"
                                                    style={styles.modalFormHelper}
                                                >
                                                </FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box style={{ width: "50%", display: 'flex', gap: "1rem" }}>
                                            <Box style={{ display: "flex", flexGrow: 1 }}>
                                                <FormControl
                                                    fullWidth
                                                    style={styles.modalFormControl}
                                                >
                                                    <InputLabel htmlFor="my-input" />
                                                    <input
                                                        data-testid={"modal_update_input_value"}
                                                        style={styles.modalTextField}
                                                        value={input.value}
                                                        name="activityQuantity"
                                                        onChange={(e) => this.updateInputField(e, "value", int, index, input_key)}
                                                    />

                                                    <FormHelperText
                                                        id="my-helper-text"
                                                        style={styles.modalFormHelper}
                                                    >
                                                    </FormHelperText>
                                                </FormControl>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <RemoveCircleOutline data-testid={"modal_remove_input_field"} onClick={() => this.removeInputField(int, index, input_key, { open: this.state.open, edit: this.state.edit })} htmlColor="rgba(28, 35, 36, .64)" style={{ cursor: "pointer" }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            );
                        })}

                        <Box component="form" className="secondaryInputDetails">

                            <Box className="widthItem">
                                <Typography variant="subtitle2"
                                    className="calendarFieldLabel"
                                    style={styles.modalCardContentEditModalTypography}>Input Details</Typography>
                                <FormControl
                                    fullWidth
                                    style={styles.modalFormControl}
                                >
                                    <InputLabel htmlFor="my-input" />
                                    <input
                                        data-testid={"modal_update_input_details_attributes_key"}
                                        style={styles.modalTextField}
                                        placeholder="Input Details"
                                        value={this.state.input_attributes[this.state.languageTab].input_details_attributes_key}
                                        name="input_details_attributes_key"
                                        onChange={(e) => this.updateInputAttributes(e, 'input_details_attributes_key')}
                                    />

                                    <FormHelperText
                                        id="my-helper-text"
                                        style={styles.modalFormHelper}
                                    >
                                    </FormHelperText>
                                </FormControl>
                            </Box>

                            <Box className="secondaryInputDetailsAlt">
                                <Typography variant="subtitle2"
                                    className="calendarFieldLabel"
                                    style={styles.modalCardContentEditModalTypography}>Quantity</Typography>
                                    <Box  style={{ display: 'flex', flexGrow: 1 }}>
                                <Box style={{ display: 'flex', flexGrow: 1 }}>
                                    <FormControl
                                        fullWidth
                                        style={styles.modalFormControl}
                                    >
                                        <InputLabel htmlFor="my-input" />
                                        <input
                                            data-testid={"modal_update_input_details_attributes_value"}
                                            style={styles.modalTextField}
                                            placeholder="Quantity"
                                            value={this.state.input_attributes[this.state.languageTab].input_details_attributes_value}
                                            name="input_details_attributes_value"
                                            onChange={(e) => this.updateInputAttributes(e, 'input_details_attributes_value')}
                                        />

                                        <FormHelperText
                                            id="my-helper-text"
                                            style={styles.modalFormHelper}
                                        >
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                                <Box style={{ display: 'flex', alignItems: "center",margin:"0px 5px" }}>
                                    <AddCircleOutline data-testid={'modal_add_input_field'} fontSize='medium' onClick={() => this.addInputField(int, index)} htmlColor={"rgba(28, 35, 36, .64)"} style={{ cursor: "pointer" }} />
                                </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* Input Details */}
                    {index !== phase.sub_activity_progresses_attributes.length - 1 ? <Divider light={true} variant='fullWidth' style={{ color: "#F0EBE0", marginBlockEnd: "1rem" }} /> : null}
                </React.Fragment >
            );
        })
    };

    // Customizable Area Start
    renderDaysBG = () => {
        let firstArray: number[] = []

        let start_day = this.state.global_start_day < 0 ? ceilingValueWithBase10(this.state.global_start_day): floorValueWithBase10(this.state.global_start_day)
        let end_day = ceilingValueWithBase10(this.state.global_end_day)

        for (let i = start_day; i <= end_day; i = i + 10) {
            firstArray = [...firstArray, +i];            
        }
        return(
            <Box style={styles.globalCalendarContainer} className="globalCalendarContainerResponsive" data-testid={"global_calendar_container"}>
                <Divider light={true} variant='fullWidth' style={styles.calendarRootDivider} />
                <Box style={{ position: "relative",left:"-4.5%"}} className="daysContainerBGWrapper">

                    {firstArray?.map((day: number) => (
                        <Container>
                            <Badge
                                className="daysBGbadge"
                                style={{
                                    right: 15,
                                    bottom: 20
                                }}
                                badgeContent={
                                    <Typography style={styles.calendarBaseTypography}>
                                        {day}
                                    </Typography>
                                }
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                                overlap="rectangular"
                            ></Badge>
                            <Badge
                                overlap="rectangular"
                                style={{
                                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cline x1='0' y1='0' x2='100' y2='0' stroke='rgba(228, 221, 201, 1)' stroke-dasharray='1 2' stroke-width='3' vector-effect='non-scaling-stroke'%3E%3C/line%3E%3C/svg%3E")`,
                                    width: "100%",
                                    height: "2.5rem",
                                }}
                            ></Badge>
                        </Container>
                    ))}
                </Box>
            </Box>
        )
           
    }

    phaseTextViews = (phase: any,colors:any) => {
        let day_difference = phase.attributes.end_day - phase.attributes.start_day;        
        let paddingTop = 0
        let pre_sowing = phase.attributes.start_day < 0;
        let pre_sowing_days = pre_sowing ? "Days before Sowing" : " Days after Sowing";
        let presowing_satement = <React.Fragment>
            {pre_sowing && <><strong>{phase.attributes.start_day * -1}</strong></>}
            {!pre_sowing && <><strong>{phase.attributes.start_day} - {phase.attributes.end_day}</strong></>}
        </React.Fragment>;
        let group_id = phase.phase_group_id;

        // double line day_difference > 40
        const view1 = () => {
            return (
                <>
                    <Box style={{width:"inherit"}}>
                        <Box style={{
                            paddingTop: `${paddingTop}px`,
                            paddingInlineStart: "1.5rem",
                            paddingInlineEnd: "1.5rem",
                            width: "100%"
                        }}>
                            <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: '.5rem'
                            }}>
                                <Typography style={{
                                    fontFamily: screenDefaults.fontFamily,
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "18px",
                                    color: "#1C2324",
                                }} noWrap>
                                    {phase.attributes.name}
                                </Typography>

                                <Box style={{
                                    display: "flex"
                                }}>
                                    <Typography noWrap style={{
                                        fontFamily: screenDefaults.fontFamily,
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "#1C2324",
                                        opacity: 0.8
                                    }}>
                                        {presowing_satement}
                                        {/* 12 */}
                                    </Typography>
                                    <Typography noWrap style={{
                                        fontFamily: screenDefaults.fontFamily,
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: "#1C2324",
                                        opacity: 0.8,
                                        textAlign: "center",
                                        marginLeft: "0.5rem"
                                    }}>
                                        {pre_sowing_days}
                                        {/* Days After Sowing */}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop:"1rem" }}>
                                <Box style={{ height: "100%", width: "100%" }}>
                                    <Divider light={true} variant='fullWidth' style={styles.middleLogicDivider} />
                                </Box>

                                <Box style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <Typography noWrap style={{
                                        fontFamily: screenDefaults.fontFamily,
                                        fontStyle: "normal",
                                        fontSize: "1rem",
                                        alignSelf: "center",
                                        color: "#006A77",
                                        opacity: 0.8
                                    }}>
                                        <strong>{phase.attributes.phase_activity_progresses}</strong> Activities
                                    </Typography>
                                    <Box style={{ display: "flex", gap: "1rem" }}>
                                        <IconButton
                                            data-testid={"edit_modal_button"}
                                            onClick={() => this.assignId(group_id)}
                                            style={styles.calendarIconButton}>
                                            <Edit fontSize="small" htmlColor="#EB8F06" />
                                        </IconButton>
                                        <IconButton
                                            data-testid={"delete_modal_button"}
                                            onClick={() => this.openDeleteModal(group_id)}
                                            style={styles.calendarIconButton}>
                                            <Delete fontSize="small" htmlColor="#B3261E" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }
        // double line 20<= day_difference < 40
        const view2 = () => {
            return (
                <>
                    <Box style={{width:"inherit"}}>
                        <Box style={{
                            paddingTop: `${paddingTop}px`,
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            paddingInlineStart: "1.5rem",
                            paddingInlineEnd: "1.5rem",
                            justifyContent: 'space-around'
                        }}>
                            <Box style={{
                                display: "flex",
                                alignItems: "flex-end"
                            }}>
                                <Typography style={{
                                    fontFamily: screenDefaults.fontFamily,
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "18px",
                                    color: "#1C2324",
                                }} noWrap>
                                    {phase.attributes.name}
                                </Typography>
                            </Box>
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <Box style={{ display: "flex", gap: "1rem", justifyContent: "center", alignItems: "center" }}>
                                    <Box style={{ paddingInlineEnd: "1rem", borderInlineEnd: `1px solid ${colors.bl}`, gap: '.1rem', display: 'flex' }}>
                                        <Typography noWrap style={{
                                            fontFamily: screenDefaults.fontFamily,
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            opacity: 0.8
                                        }}>
                                            {presowing_satement}
                                        </Typography>
                                        <Typography noWrap style={{
                                            fontFamily: screenDefaults.fontFamily,
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            opacity: 0.8,
                                            textAlign: "center",
                                            marginLeft: "0.5rem"
                                        }}>
                                            {pre_sowing_days}
                                        </Typography>
                                    </Box>

                                    <Typography noWrap style={{
                                        fontFamily: screenDefaults.fontFamily,
                                        fontStyle: "normal",
                                        fontSize: "1rem",
                                        color: "#006A77",
                                        opacity: 0.8
                                    }}>
                                        <strong>{phase.attributes.phase_activity_progresses}</strong> Activities

                                    </Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", paddingInlineStart: "1rem" }}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            gap: "1rem"
                                        }}
                                    >
                                        <IconButton data-testid={"edit_modal_button"} onClick={() => this.assignId(group_id)} style={styles.calendarIconButton}> <Edit fontSize="small" htmlColor="#EB8F06" /></IconButton>
                                        <IconButton data-testid={"delete_modal_button"} onClick={() => this.openDeleteModal(group_id)} style={styles.calendarIconButton}> <Delete fontSize="small" htmlColor="#B3261E" /></IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }
        // single line day_difference < 20
        const view3 = () => {
            return (
                <>
                    <Box style={{width:"inherit"}}>
                        <Box style={{
                            paddingTop: `${paddingTop}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingInlineStart: "1.5rem",
                            paddingInlineEnd: "1.5rem",
                            width: "100%"
                        }}>
                            <Box style={{
                                display: "flex",
                                gap: '1rem'
                            }}>
                                <Box style={{
                                    display: "flex",
                                    gap: '1rem',
                                    borderInlineEnd: `1px solid ${colors.bl}`,
                                    paddingInlineEnd: "1rem",
                                    alignItems: "baseline"
                                }}>
                                    <Typography style={{
                                        fontFamily: screenDefaults.fontFamily,
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "18px",
                                        lineHeight: "28px",
                                        color: "#1C2324",
                                    }}
                                        noWrap>
                                        {phase.attributes.name}
                                    </Typography>
                                    <span style={{ display: "flex", gap: ".5rem" }}>
                                        <Typography noWrap style={{
                                            fontFamily: screenDefaults.fontFamily,
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            opacity: 0.8
                                        }}>
                                            {presowing_satement}
                                        </Typography>
                                        <Typography noWrap style={{
                                            fontFamily: screenDefaults.fontFamily,
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            opacity: 0.8
                                        }}>
                                            {pre_sowing_days}

                                        </Typography>
                                    </span>
                                </Box>

                                <Typography noWrap style={{
                                    fontFamily: screenDefaults.fontFamily,
                                    fontStyle: "normal",
                                    fontSize: "1rem",
                                    alignSelf: "center",
                                    color: "#006A77",
                                    opacity: 0.8
                                }}>
                                    <strong>{phase.attributes.phase_activity_progresses}</strong> Activities

                                </Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", paddingInlineStart: "1rem", alignItems: "center" }}>
                                <Box
                                    style={{
                                        display: "flex",
                                        gap: "1rem"
                                    }}
                                >
                                    <IconButton data-testid={"edit_modal_button"} onClick={() => this.assignId(group_id)} style={styles.calendarIconButton}> <Edit fontSize="small" htmlColor="#EB8F06" style={{ cursor: "pointer" }} /></IconButton>
                                    <IconButton data-testid={"delete_modal_button"} onClick={() => this.openDeleteModal(group_id)} style={styles.calendarIconButton}> <Delete fontSize="small" htmlColor="#B3261E" style={{ cursor: "pointer" }} /></IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }

        const returnPhaseView = () => {
            if (day_difference < 20) {
                return view3
            } else if (day_difference >= 20) {
                if (day_difference > 40) {
                    return view1
                } else {
                    return view2
                }
            }
        }
        return (
            <>
                <Box>
                    {returnPhaseView()}
                </Box>
            </>
        )
    }

    renderPhases = () => {
        return (
            <>
                <Box style={styles.phaseDiv}>
                    {this.state.phase_group_collection?.map((phase, index) => {

                        // calculate day-difference and set phase height
                        const day_difference = phase.attributes.end_day - phase.attributes.start_day
                        const phaseHeight = day_difference * 4

                        // calculate color using index
                        const phaseColor = colorGroup[index % 8]

                        // calculate top from global start
                        const start_day = this.state.global_start_day < 0 ? ceilingValueWithBase10(this.state.global_start_day) : floorValueWithBase10(this.state.global_start_day)
                        const phaseStartDayDifference = phase.attributes.start_day - start_day
                        const phaseTop = phaseStartDayDifference * 4

                        // prepare inline style object
                        const phaseBoxStyle = {
                            position: "absolute",
                            top: `${phaseTop}px`,
                            borderInlineStart: `12px solid ${phaseColor.bl}`,
                            background: `${phaseColor.bg}`,
                            height: `${phaseHeight}px`,                            
                            borderRadius: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            transition: 'z-index 0.3s, transform 0.3s, box-shadow 0.3s', // Add transitions
                            zIndex: 1, // Set the initial z-index
                            transform: 'scale(1)', // Initial transform (no scale)
                            boxShadow: 'none', // Initial box shadow                                                      
                            '&:hover': {
                                zIndex: 1000, // Set the z-index on hover,
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)', // Add a box shadow on hover
                            },
                        } as React.CSSProperties

                        return (
                            <>
                                <Box sx={phaseBoxStyle} className="phaseBoxStyleDiv">
                                    {this.phaseTextViews(phase, phaseColor)}
                                </Box>
                            </>
                        )
                    })}
                </Box>
            </>
        )
    }
    // Customizable Area End
    render() {
        const { navigation, id } = this.props;
        const _this = this;

        const TabUI: ReactElement[] = ["english", "gujarati", "hindi"].map((tab, int) => { 
            return (
                <Tab className="languageTabClass" key={tab} label={tab} style={{ ...styles.languageTabs, color: this.state.languageTab === int ? "#DF5200" : "#1C2324", }} />
            );
        })


        const primaryActivity = this.state.phases_attributes[this.state.languageTab].phase_activity_progresses_attributes.map((phase: any, int) => {

            if(phase._destroy){
                return <></>
            }

            const activityId = phase.phase_activity_id ? phase.phase_activity_id : 'none';

            return (
                <React.Fragment key={int} >
                    <Box style={styles.primaryRoot} className="primaryRootBoxParent">
                        <Box className="primaryRootBox">
                            <Box component="form" className="widthItem">
                                <Typography
                                    variant="subtitle2"
                                    style={
                                        styles.modalCardContentEditModalTypography
                                    }
                                    className="calendarFieldLabel"
                                >
                                    Activity Name
                                </Typography>
                                <FormControl
                                    fullWidth
                                    style={styles.modalFormControl}
                                >
                                    <InputLabel htmlFor="my-input" />
                                    <Select
                                        data-testid={"modal_select_activity_id"}
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={activityId}
                                        defaultValue={"none"}
                                        onChange={(e) => this.updateActivity(e, 'phase_activity_id', int)}
                                        name="phase_activity_name"
                                        input={
                                            <InputBase
                                                placeholder="Enter name of Activity"
                                                autoFocus={false}
                                                onFocus={(e) => e.preventDefault()}
                                            />
                                        }
                                        style={styles.modalSelectField}
                                    >
                                        <MenuItem value={'none'}>
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            this.state.activity_profile_attributes.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>{item.activity_name_array[this.state.languageTab]}</MenuItem>
                                                );
                                            })
                                        }
                                    </Select>

                                    <FormHelperText
                                        id="my-helper-text"
                                        style={styles.modalFormHelper}
                                    >
                                    </FormHelperText>
                                </FormControl>
                            </Box>

                            <Box className="primaryRootBoxAlt">
                                <Box style={styles.containerPrimaryDaysBeforeSowing}>
                                    <Typography
                                        variant="subtitle2"
                                        style={
                                            styles.modalCardContentEditModalTypography
                                        }
                                        className="calendarFieldLabel"
                                    >
                                        Days before/after sowing
                                    </Typography>
                                    <Box style={{ display: "flex", gap: ".5rem" }}>
                                        <FormControl
                                            fullWidth
                                            style={styles.modalFormControl}
                                        >
                                            <InputLabel htmlFor="my-input" />
                                            <input
                                                data-testid={"modal_input_start_day"}
                                                style={styles.modalTextField}
                                                placeholder="Start day"
                                                value={phase.start_day}
                                                name="start_day"
                                                onChange={(e) => this.updateActivity(e, 'start_day', int)}
                                            />

                                            <FormHelperText
                                                id="my-helper-text"
                                                style={styles.modalFormHelper}
                                            >
                                            </FormHelperText>
                                        </FormControl>

                                        <Typography style={styles.toTypography}>
                                            to
                                        </Typography>

                                        <FormControl
                                            fullWidth
                                            style={styles.modalFormControl}
                                        >
                                            <InputLabel htmlFor="my-input" />
                                            <input
                                                data-testid={"modal_input_update_phase_end_day"}
                                                style={styles.modalTextField}
                                                placeholder="End day"
                                                value={phase.end_day}
                                                name="phase_end_day"
                                                onChange={(e) => this.updateActivity(e, 'end_day', int)}
                                            />

                                            <FormHelperText
                                                id="my-helper-text"
                                                style={styles.modalFormHelper}
                                            >
                                            </FormHelperText>
                                        </FormControl>

                                        <Box style={styles.primaryDeleteContainer}>
                                            <Delete data-testid={"modal_remove_activity_button"} onClick={() => this.removeActivity(int)} htmlColor={"#B3261E"} style={{ "cursor": "pointer" }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        {/* Activity Section */}
                        <Divider light={true} variant='fullWidth' style={styles.primaryRootDivider} />
                        {/* Sub-Activity Section */}
                        <Box style={styles.secondaryRootContainer} className="calendarSecondaryRootContainer">
                            {this.secondaryActivity({ phase, int })}
                            <Box>
                                <Box style={styles.buttonContainer}>
                                    <Button
                                        component="span"
                                        aria-label="add_sub_activity_button"
                                        data-testid={"modal_add_sub_activity_button"}
                                        style={styles.buttonComponent}
                                        onClick={() => this.addSubActivity(int)}
                                    >
                                        <Add fontSize="medium" htmlColor={"rgba(28, 35, 36, .64)"} />
                                        <Typography style={styles.buttonTxt}>
                                            {screenDefaults.addSubActivityText}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                        {/* Sub-Activity Section */}
                        <Divider light={true} variant='fullWidth' style={styles.primaryRootDivider} />
                    </Box>
                </React.Fragment>
            );
        });

        return (
            // Customizable Area Start
            <NavigationMenu navigation={navigation} id={id} >
                <Grid>
                    <Grid item>
                        <Grid item container style={styles.rootGrid}>
                            <Box style={styles.rootGridContainer}>
                                <Typography variant='h4' style={styles.heading} className="headingResponsive">{screenDefaults.heading1}</Typography>
                                <Box style={styles.buttonContainer}>
                                    <Button
                                        component="span"
                                        aria-label="edit-profile"
                                        disableRipple={true}
                                        style={styles.addButtonComponent}
                                        onClick={this.openModal}
                                        data-testid={'primary_modal_button'}
                                    >
                                        <Add />
                                        <Typography style={{ ...styles.buttonTxt, color: "#FFF", marginInlineStart: ".3rem", opacity: 1 }}>
                                            {screenDefaults.buttonText}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={styles.calendarGrid} className="calendarGridWrapper">
                            <Box>
                            <Typography style={{
                                fontSize: "12px",
                                textAlign: "left",
                                color: "#1C2324",
                                fontWeight: 500,
                                opacity: 0.64,
                            }} >DAYS</Typography>
                            <Box style={{ paddingInline: "2.25rem", marginBlockStart: "1rem" }}>
                                {this.renderDaysBG()}
                                <Box style={{ position: "absolute", marginLeft: "2px", marginTop: "8px" }}>
                                    {this.renderPhases()}
                                </Box>
                            </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={2}>
                                <Grid item>
                                    <Modal
                                        open={this.state.open}
                                        onClose={this.handleButtonCancel}
                                        aria-labelledby="modal-calendar-title"
                                        aria-describedby="modal-calendar-description"
                                        style={styles.modalRoot}
                                        disableScrollLock={true}
                                        BackdropProps={{
                                            style: styles.modalBackdropProps,
                                        }}
                                    >
                                        <Card variant="outlined" style={styles.modalCardRoot} className="modalContentWrapper">
                                            <CardHeader
                                                style={styles.modalCardHeader}
                                                disableTypography={true}
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={this.handleButtonCancel}
                                                        data-testid={"modal_close_button"}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                }
                                                title={
                                                    <Typography style={styles.modalHeaderTypography}>
                                                        {screenDefaults.modalCalendarHeader}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent style={{ paddingInline: 0, paddingBlockStart: '.5rem' }}>
                                                <Box style={{ paddingInline: "2rem" }}>
                                                    <StyledTabs
                                                        value={this.state.languageTab}
                                                        onChange={this.assignLanguage}
                                                        variant="fullWidth"
                                                        className="customFullWidth"
                                                        data-testid={"modal_assign_language_tab"}
                                                    >
                                                        {TabUI}
                                                    </StyledTabs>
                                                </Box>

                                                <Box style={{ borderBlockEnd: "2px solid #F0EBE0", paddingBlock: "1.5rem" }}>
                                                    <Box className="displayItem">

                                                        <Box className="widthItem">
                                                            <Typography
                                                                variant="subtitle2"
                                                                style={
                                                                    styles.modalCardContentEditModalTypography
                                                                }
                                                                className="calendarFieldLabel"
                                                            >
                                                                Phase Name
                                                            </Typography>
                                                            <FormControl
                                                                fullWidth
                                                                style={styles.modalFormControl}
                                                            >
                                                                <InputLabel htmlFor="my-input" />
                                                                <input
                                                                    style={styles.modalTextField}
                                                                    placeholder="Enter name of Phase"
                                                                    value={this.state.phases_attributes[this.state.languageTab].name}
                                                                    name="phase_name"
                                                                    onChange={(e) => this.updatePhase(e, 'name')}
                                                                    data-testid={"modal_input_phase_name"}
                                                                />

                                                                <FormHelperText
                                                                    id="my-helper-text"
                                                                    style={styles.modalFormHelper}
                                                                >
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </Box>

                                                        <Box className="widthItem">
                                                            <Typography
                                                                variant="subtitle2"
                                                                style={
                                                                    styles.modalCardContentEditModalTypography
                                                                }
                                                                className="calendarFieldLabel"
                                                            >
                                                                Days before/after sowing
                                                            </Typography>
                                                            <Box style={{
                                                                width: "100%", display: "flex",
                                                                gap: ".5rem",
                                                            }}>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={styles.modalFormControl
                                                                    }
                                                                >
                                                                    <InputLabel htmlFor="my-input" />
                                                                    <input
                                                                        data-testid={"modal_phase_update_start_day"}
                                                                        style={styles.modalTextField}
                                                                        placeholder="Start day"
                                                                        value={this.state.phases_attributes[this.state.languageTab].start_day}
                                                                        name="start_day"
                                                                        onChange={(e) => this.updatePhase(e, 'start_day')}
                                                                    />

                                                                    <FormHelperText
                                                                        id="my-helper-text"
                                                                        style={styles.modalFormHelper}
                                                                    >
                                                                    </FormHelperText>
                                                                </FormControl>

                                                                <Typography style={styles.toTypography}>
                                                                    to
                                                                </Typography>

                                                                <FormControl
                                                                    fullWidth
                                                                    style={styles.modalFormControl}
                                                                >
                                                                    <InputLabel htmlFor="my-input" />
                                                                    <input
                                                                        data-testid={"modal_phase_update_end_day"}
                                                                        style={styles.modalTextField}
                                                                        placeholder="End day"
                                                                        value={this.state.phases_attributes[this.state.languageTab].end_day}
                                                                        name="end_day"
                                                                        onChange={(e) => this.updatePhase(e, 'end_day')}
                                                                    />

                                                                    <FormHelperText
                                                                        id="my-helper-text"
                                                                        style={styles.modalFormHelper}
                                                                    >
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Box>

                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {primaryActivity}
                                                <Box style={styles.addButtonContainer}>
                                                    <Button
                                                        component="span"
                                                        aria-label="edit-profile"
                                                        data-testid={"modal_add_activity_button"}
                                                        style={{
                                                            ...styles.buttonComponent,
                                                            backgroundColor: "#F0EBE0",
                                                            border: "1px solid #E4DDC9",
                                                        }}
                                                        onClick={() => this.addActivity()}
                                                    >
                                                        <Add fontSize="medium" htmlColor={"rgba(28, 35, 36, .64)"} />
                                                        <Typography style={styles.buttonTxt}>
                                                            {screenDefaults.addActivityText}
                                                        </Typography>
                                                    </Button>

                                                    <Button
                                                        component="span"
                                                        aria-label="edit-profile"
                                                        data-testid={"modal_add_phase_button"}
                                                        disableRipple={true}
                                                        style={{ ...styles.buttonComponent, ...styles.buttonComponentAddNewPhase }}
                                                        onClick={this.addPhaseModal}
                                                        disabled={this.state.button_loader}
                                                    >
                                                        {
                                                            !this.state.button_loader ? <Typography style={{ ...styles.buttonTxt, ...styles.addButtonTxt }}>
                                                                {screenDefaults.addButtonText}
                                                            </Typography> : <CircularProgress size={28} style={{ color: "#fff" }} />
                                                        }
                                                    </Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Modal>
                                </Grid>
                                <Grid item>
                                    <Modal
                                        open={this.state.edit}
                                        onClose={this.handleButtonCancel}
                                        aria-labelledby="modal-calendar-title"
                                        aria-describedby="modal-calendar-description"
                                        style={styles.modalRoot}
                                        disableScrollLock={true}
                                        BackdropProps={{
                                            style: styles.modalBackdropProps,
                                        }}
                                    >
                                        <Card variant="outlined" style={styles.modalCardRoot} className="modalContentWrapper">
                                            <CardHeader
                                                style={styles.modalCardHeader}
                                                disableTypography={true}
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={this.handleButtonCancel}
                                                        data-testid={"modal_close_button"}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                }
                                                title={
                                                    <Typography style={styles.modalHeaderTypography}>
                                                        {screenDefaults.modalHeaderTextEdit}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent style={styles.cardContentContainer}>
                                                {
                                                    this.state.form_loading ?
                                                        <Box style={styles.loadingContainer}>
                                                            <CircularProgress size={"4rem"} style={{ color: "#ccc" }} />
                                                        </Box>
                                                        :
                                                        <>
                                                            <Box style={styles.styledTabsContainer} >
                                                                <StyledTabs
                                                                    value={this.state.languageTab}
                                                                    onChange={this.assignLanguage}
                                                                    variant="fullWidth"
                                                                    data-testid={"modal_assign_language_tab"}
                                                                >
                                                                    {TabUI}
                                                                </StyledTabs>
                                                            </Box>
                                                            <Box style={{ borderBlockEnd: "2px solid #F0EBE0", paddingBlock: "1.5rem" }}>
                                                                <Box style={{ paddingInline: "2rem", display: "flex", gap: "1rem", }}>

                                                                    <Box style={styles.secondaryRootBox}>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            style={
                                                                                styles.modalCardContentEditModalTypography
                                                                            }
                                                                            className="calendarFieldLabel"
                                                                        >
                                                                            Phase Name
                                                                        </Typography>
                                                                        <FormControl
                                                                            fullWidth
                                                                            style={styles.modalFormControl}
                                                                        >
                                                                            <InputLabel htmlFor="my-input" />
                                                                            <input
                                                                                data-testid={"modal_input_phase_name"}
                                                                                style={styles.modalTextField}
                                                                                placeholder="Enter name of Phase"
                                                                                value={this.state.phases_attributes[this.state.languageTab].name}
                                                                                name="name"
                                                                                onChange={(e) => this.updatePhase(e, 'name')}
                                                                            />

                                                                            <FormHelperText
                                                                                id="my-helper-text"
                                                                                style={styles.modalFormHelper}
                                                                            >
                                                                            </FormHelperText>
                                                                        </FormControl>
                                                                    </Box>

                                                                    <Box style={styles.secondaryRootBox}>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            style={
                                                                                styles.modalCardContentEditModalTypography
                                                                            }
                                                                            className="calendarFieldLabel"
                                                                        >
                                                                            Days before/after sowing
                                                                        </Typography>
                                                                        <Box style={{ width: "100%", display: "flex", gap: "1rem" }}>
                                                                            <FormControl
                                                                                fullWidth
                                                                                style={{ ...styles.modalFormControl }}
                                                                            >
                                                                                <InputLabel htmlFor="my-input" />
                                                                                <input
                                                                                    data-testid={"modal_phase_update_start_day"}
                                                                                    style={styles.modalTextField}
                                                                                    placeholder="Start day"
                                                                                    value={this.state.phases_attributes[this.state.languageTab].start_day}
                                                                                    name="start_day"
                                                                                    onChange={(e) => this.updatePhase(e, 'start_day')}
                                                                                />

                                                                                <FormHelperText
                                                                                    id="my-helper-text"
                                                                                    style={styles.modalFormHelper}
                                                                                >
                                                                                </FormHelperText>
                                                                            </FormControl>

                                                                            <Typography style={styles.toTypography}>
                                                                                to
                                                                            </Typography>

                                                                            <FormControl
                                                                                fullWidth
                                                                                style={styles.modalFormControl}
                                                                            >
                                                                                <InputLabel htmlFor="my-input" />
                                                                                <input
                                                                                    data-testid={"modal_phase_update_end_day"}
                                                                                    style={styles.modalTextField}
                                                                                    placeholder="End day"
                                                                                    value={this.state.phases_attributes[this.state.languageTab].end_day}
                                                                                    name="end_day"
                                                                                    onChange={(e) => this.updatePhase(e, 'end_day')}
                                                                                />

                                                                                <FormHelperText
                                                                                    id="my-helper-text"
                                                                                    style={styles.modalFormHelper}
                                                                                >
                                                                                </FormHelperText>
                                                                            </FormControl>
                                                                        </Box>

                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            {primaryActivity}
                                                            <Box style={styles.addButtonContainer}>
                                                                <Button
                                                                    data-testid={"modal_add_activity_button"}
                                                                    component="span"
                                                                    aria-label="edit-profile"
                                                                    style={{
                                                                        ...styles.buttonComponent,
                                                                        backgroundColor: "#F0EBE0",
                                                                        border: "1px solid #E4DDC9",
                                                                    }}
                                                                    onClick={() => this.addActivity()}
                                                                >
                                                                    <Add fontSize="medium" htmlColor={"rgba(28, 35, 36, .64)"} />
                                                                    <Typography style={styles.buttonTxt}>
                                                                        {screenDefaults.addActivityText}
                                                                    </Typography>
                                                                </Button>

                                                                <Button
                                                                    data-testid={"modal_update_phase_button"}
                                                                    component="span"
                                                                    aria-label="edit-profile"
                                                                    disableRipple={true}
                                                                    style={{ ...styles.buttonComponent, ...styles.buttonComponentAddNewPhase }}
                                                                    onClick={this.updatePhaseModal}
                                                                    disabled={this.state.button_loader}
                                                                >
                                                                    {
                                                                        !this.state.button_loader ? <Typography style={{ ...styles.buttonTxt, ...styles.addButtonTxt }}>
                                                                            {screenDefaults.editButtonText}
                                                                        </Typography> : <CircularProgress size={28} style={{ color: "#fff" }} />
                                                                    }
                                                                </Button>
                                                            </Box>
                                                        </>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Modal>
                                </Grid>
                                <Grid item>
                                    <Modal
                                        open={this.state.alert_open}
                                        onClose={this.handleAlertCancel}
                                        aria-labelledby="modal-calendar-alert-validation"
                                        aria-describedby="modal-calendar-alert"
                                        style={styles.secondaryModalRoot}
                                        disableScrollLock={true}
                                        BackdropProps={{
                                            style: styles.modalBackdropProps,
                                        }}
                                    >
                                        <Card
                                            variant="outlined"
                                            style={styles.modalCardRootSuccess}
                                            className="activityErrorMessageModal"
                                        >
                                            <CardContent style={styles.secondaryModalCardContent}>
                                                <Container
                                                    style={styles.secondaryModalContentContainer}
                                                >
                                                    <Typography align="center" style={{
                                                        fontFamily: screenDefaults.fontFamily,
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        fontSize: "24px",
                                                        lineHeight: "36px",
                                                        color: "#1C2324",
                                                    }}>
                                                        Error
                                                    </Typography>

                                                    <Box style={styles.modalImageBox}>
                                                        <Typography align="center" style={{
                                                            fontFamily: screenDefaults.fontFamily,
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            fontSize: "18px",
                                                            lineHeight: "28px",
                                                            color: "#1C2324",
                                                            textAlign: "center"
                                                        }}>
                                                            {this.state.errorDis || "Activity data is incomplete. Please add data for other languages as well to save activity."}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={styles.modalSucessTextContainer}>
                                                        <Button
                                                            fullWidth
                                                            style={styles.modalGoBackButton}
                                                            onClick={this.handleAlertCancel}
                                                            data-testid={"modal_alert_cancel_button"}
                                                        >
                                                            <strong>GO BACK</strong>
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            style={styles.modalCardCancelButton}
                                                            onClick={this.handleAlertCancel}
                                                            data-testid={"modal_alert_cancel_button"}
                                                        >
                                                            <strong>CANCEL</strong>
                                                        </Button>
                                                    </Box>
                                                </Container>
                                            </CardContent>
                                        </Card>
                                    </Modal>
                                </Grid>
                                <Grid item>
                                    <Modal
                                        open={this.state.delete_open}
                                        onClose={this.handleButtonCancel}
                                        aria-labelledby="modal-calendar-alert-validation"
                                        aria-describedby="modal-calendar-alert"
                                        style={styles.secondaryModalRoot}
                                        disableScrollLock={true}
                                        BackdropProps={{
                                            style: styles.modalBackdropProps,
                                        }}
                                    >
                                        <Card
                                            variant="outlined"
                                            style={styles.modalCardRootSuccess}
                                        >
                                            <CardContent style={styles.secondaryModalCardContent}>
                                                <Container
                                                    style={styles.secondaryModalContentContainer}
                                                >
                                                    <Typography align="center" style={{
                                                        fontFamily: screenDefaults.fontFamily,
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        fontSize: "24px",
                                                        lineHeight: "36px",
                                                        color: "#1C2324",
                                                    }}>
                                                        Delete Phase
                                                    </Typography>

                                                    <Box style={styles.modalImageBox}>
                                                        <Typography align="center" style={{
                                                            fontFamily: screenDefaults.fontFamily,
                                                            fontStyle: "normal",
                                                            fontWeight: 400,
                                                            fontSize: "18px",
                                                            lineHeight: "28px",
                                                            color: "#1C2324",
                                                            textAlign: "center"
                                                        }}>
                                                            {"By deleting phase all the activities, sub-activities and it's content are also deleted. Are you sure? You want to delete this Phase?"}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={styles.modalSucessTextContainer}>
                                                        <Button
                                                            fullWidth
                                                            style={styles.modalGoBackButton}
                                                            onClick={this.handleDelete}
                                                            data-testid={"modal_delete_button"}
                                                        >
                                                            <strong>DELETE</strong>
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            style={styles.modalCardCancelButton}
                                                            onClick={this.handleButtonCancel}
                                                            data-testid={"modal_cancel_button"}
                                                        >
                                                            <strong>CANCEL</strong>
                                                        </Button>
                                                    </Box>
                                                </Container>
                                            </CardContent>
                                        </Card>
                                    </Modal>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid >
                </Grid >
            </NavigationMenu >
            // Customizable Area End
        );
    }
}

const StyledTabs = styled(Tabs)(() => ({
    color: "#1C2324",
    "&.customFullWidth .MuiTab-fullWidth": {
        '@media (max-width: 600px)': {
            flexBasis: "33.33%"
        }
    },
    "& .MuiTabs-indicator": {
        width: "100%",
        height: "2px",
        backgroundColor: '#DF5200',
    }
}));

const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        right: "24px",
        top: "24px",
        width: "25px",
        height: "20px",
        padding: ".8rem",
    },
}));


// Customizable Area Start
const styles = {
    rootGrid: {
        flexGrow: 1,
        width: "100%"
    } as React.CSSProperties,

    rootGridContainer: { display: "flex", justifyContent: "space-between", width: "100%", marginBlockEnd: "2rem",flexWrap:"wrap" } as React.CSSProperties,

    calendarGrid: {
        width: "100%",
        overflowX: "auto",
    } as React.CSSProperties,

    globalCalendarContainer: {
        borderInlineStart: "2px solid #E4DDC9",
        width: "750px",
        position: "absolute",
        "@media (max-width: 900px)": {
            width: "75% !important"
        }
    } as React.CSSProperties,

    heading: {
        fontWeight: 600,
        fontFamily: screenDefaults,
        fontStyle: "normal",
        fontSize: "32px",
        lineHeight: "48px",
        color: "#1C2324",
        marginInlineStart: '1.5rem'
    } as React.CSSProperties,

    buttonContainer: {
        alignSelf: "center"

    } as React.CSSProperties,

    addButtonContainer: {
        alignSelf: "center",
        paddingBlock: "0.5rem 0",
        paddingInline: "2rem",
        display: "flex",
        flexDirection: "column",        
    } as React.CSSProperties,

    buttonComponent: {
        borderRadius: "1.85rem",
        paddingBlock: ".875rem",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        width: "100%",
        margin:"1rem 0rem"
    } as React.CSSProperties,

    addButtonComponent: {
        borderRadius: "1.85rem",
        paddingBlock: ".875rem",
        border: "1px solid #F0EBE0",
        width: "100%",
        backgroundColor: "#DF5200",
        color: "#FFF",
        paddingInline: "1.5rem"
    },

    buttonComponentAddNewPhase: {
        backgroundColor: "#DF5200"
    } as React.CSSProperties,

    buttonTxt: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "28px",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#1C2324",
        opacity: 0.64,
    } as React.CSSProperties,

    addButtonTxt: {
        color: "#fff",
        opacity: 1
    } as React.CSSProperties,

    modalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        paddingBlock: "2rem", //
        backdropFilter: "blur(5px)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        width: '100vw',
    } as React.CSSProperties,

    modalCardRoot: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "712px",
        height: "90%",
        fontFamily: screenDefaults.fontFamily,
        fontSize: "1rem",
        overflowY: "scroll",
    } as React.CSSProperties,

    modalBackdropProps: {
        backgroundColor: "rgba(0, 0, 0, .7)",
        backdropFilter: "blur(10px)",
    } as React.CSSProperties,

    modalCardHeader: {
        paddingInline: "2rem",
        paddingBlockEnd: 0,
    },

    modalHeaderTypography: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324"
    },

    modalCardContentEditModalTypography: {
        fontFamily: screenDefaults.fontFamily,
        paddingBlockEnd: 1,
        fontSize: ".875rem",
        fontWeight: 400,
        color: "#1C2324",
        opacity: 0.64,
        marginBottom:"8px"
    } as React.CSSProperties,

    modalFormControl: {
        height: "100%"
    } as React.CSSProperties,

    modalTextField: {
        fontFamily: screenDefaults.fontFamily,
        borderRadius: "0.75rem",
        paddingBlock: "1rem",
        paddingInline: "1.13rem",
        fontSize: "1rem",
        backgroundColor: '#FCFAF7',
        border: "1px solid #F0EBE0",
        '::placeholder': {
            color: "red",
            fontWeight: 300
        },
    } as React.CSSProperties,
    displayItem: {
        paddingInline: "2rem",
        display: "flex",
        gap: "1rem",
        border: "1px solid red",
        '@media (max-width: 600px)': {
            flexDirection: "column !important",
        },
    } as React.CSSProperties,
    widthItem: {
        width: "50%",
        "@media (max-width: 600px)": {
            width: "100% !important"
        }
    } as React.CSSProperties,
    modalSelectField: {
        borderRadius: "0.75rem",
        paddingBlock: "0.6rem",
        paddingInline: "1.1rem",
        fontSize: "1rem",
        backgroundColor: '#FCFAF7',
        border: "1px solid #F0EBE0",
        width: "100%",
        overflow: "hidden"
    } as React.CSSProperties,

    modalTextArea: {
        fontFamily: screenDefaults.fontFamily,
        backgroundColor: "#FCFAF7",
        paddingBlock: ".5rem",
        paddingInline: ".7rem",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        height: "189px",
        resize: "none",
    } as React.CSSProperties,

    modalFormHelper: {
        paddingBlock: "0",
        paddingInline: "0",
    } as React.CSSProperties,
    hide: {
        display: "none"
    },

    badgeCardRoot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        height: '189px'
    },

    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "0.375rem",
    },

    formImageContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        width: "100%",
        height: 189
    },

    primaryRoot: {
        paddingInline: "2rem", display: "flex", flexDirection: "column", gap: "1rem", paddingBlockStart: "1.5rem"
    } as React.CSSProperties,

    primaryRootBox: { display: "flex", gap: "1rem" },

    primaryRootBoxAlt: { width: "50%", display: 'flex', gap: '1rem' },

    primaryRootDivider: { borderBlockStart: "1px solid #F0EBE0", color: "#F0EBE0" },
    middleLogicDivider: { color: "#DBCFB3" },
    calendarRootDivider: { borderBlockStart: "8px solid #fff", color: "#FFF" },

    containerPrimaryDaysBeforeSowing: { display: "flex", flexDirection: "column", width: "100%" } as React.CSSProperties,

    toTypography: { alignSelf: "center", color: "#1C2324", opacity: 0.64 } as React.CSSProperties,

    primaryDeleteContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0
    },

    secondaryRootContainer: { paddingInline: ".8rem", paddingInlineEnd: "1.1rem", paddingBlockEnd: "1rem" },
    secondaryRootBoxContainer: { display: "flex", width: "100%" },
    secondaryRootBox: { width: "50%" },
    secondaryRootBoxAlt: { width: "50%", display: 'flex', gap: "1rem", justifyContent: "space-between" },
    secondaryUploadImageBox: { display: 'flex', gap: "1rem", paddingBlockStart: '1rem', marginBottom:"1rem" },
    secondaryInputDetails: { display: "flex", gap: "1rem", alignItems: "center", marginBlockEnd: ".5rem" },
    secondaryInputDetailsAlt: { width: "50%", display: 'flex', gap: "1rem", justifyContent: "space-between" },
    secondaryInputDetailsContainer: { display: "flex", gap: "1rem", alignItems: "center" },
    secondaryInputDetailsAttributesContainer: { display: "flex", gap: "1rem", marginTop:"0.5rem" },
    secondaryRootDescriptionContainer: { width: "50%", height: "100%", gap: '.5rem' },

    cardContentContainer: {
        paddingInline: 0, paddingBlockStart: '.5rem',
        height: "100%",
    } as React.CSSProperties,

    styledTabsContainer: { paddingInline: "2rem" },
    languageTabs: {
        fontFamily: screenDefaults.fontFamily,
        fontSize: "1.2rem",
        fontWeight: 500,
        lineHeight: "28px",
        borderBlockEnd: "2px solid rgba(28, 35, 36, .12)",
    },

    secondaryModalRoot: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        backdropFilter: "blur(5px)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        height: "100%"
    } as React.CSSProperties,

    modalCardRootSuccess: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 528,
        height: 360,
        fontSize: "16px",
        display: "flex",
        "flexDirection": "column",
        justifyContent: "space-between",
        borderRadius: "12px",
    } as React.CSSProperties,

    modalGoBackButton: {
        width: "142px",
        backgroundColor: "#B3261E",
        color: "#fff",
        borderRadius: "28px",
        "textTransform": "none",
        fontWeight: 500,
        fontSize:'1.125rem',
        paddingBlock: "0.875rem"
    } as React.CSSProperties,

    modalCardCancelButton: {
        width: "142px",
        backgroundColor: "#E4DDC9",
        borderRadius: "28px",
        "textTransform": "none",
        fontWeight: 500,
        fontSize:'1.125rem',
        color:"#1C2324"
    } as React.CSSProperties,

    secondaryModalCardContent: {
        padding: '3.5rem 0rem',
        height: "100%"
    },

    secondaryModalContentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        fontSize: "16px",
        alignItems: "center",
        height: "100%",
        width: "100%"
    } as React.CSSProperties,

    modalImageBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: "2rem",
    } as React.CSSProperties,

    modalSucessTextContainer: {
        display: "flex",
        gap: "2.5em",
        fontSize: "16px"
    } as React.CSSProperties,

    loadingContainer: {
        height: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,

    font: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "28px",
        lineHeight: "42px",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#006A77",
        position: "relative",
        "&::after": {
            content: "' '",
            width: "1px",
            position: "absolute",
            top: "15px",
            bottom: "15px",
            left: "-10px",
            background:
                "linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)",
        },
    } as React.CSSProperties,

    calendarBaseTypography: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "22px",
        textTransform: "uppercase",
        color: "#1C2324",
        opacity: 0.8,
    } as React.CSSProperties,

    calendarIconButton: {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
        width: "2rem",
        height: "2rem"
    },
    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: .5
    },
    phaseDiv: {
        position:"relative"
    } as React.CSSProperties,
};
// Customizable Area End