import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    InputBase,
    IconButton
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./Dropdown.web.css"
import DropdownAccordionSection from "../../../../components/src/DropdownAccordionSection.web"
import SubDropdownAccordionSection from "../../../../components/src/SubDropdownAccordionSection.web";

// Customizable Area End

import DropdownController, { Props } from "./DropdownController";
import NavigationMenu from "../../../dashboard/src/NavigationMenu.web";
import { configJSON } from "../../../dashboard/src/NavigationMenuController";

const { navigationScreenDefaults: screenDefaults } = configJSON;

export default class Dropdown extends DropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation, id } = this.props;
        const { stateList, districtList, talukaList, villageList, selectedData, section1InputsEnglish, section2InputsHindi, section3InputsGujrati } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu navigation={navigation} id={id}>
                    <Grid container spacing={2} className="news_and_events_container">
                        <Grid item md={4} lg={4} sm={12} xs={12}>
                            <Typography style={styles.dropDownTitle}>{this.state.dropDownTitle}</Typography>
                            {this.state.dropDownData.length > 0 ?
                                <Box>
                                    {this.state.dropdownFilteredData?.map((data: any, index: number) => (
                                        <DropdownAccordionSection
                                            data={data}
                                            expandTab={(index === this.state.selectedId)}
                                            handleAccordion={this.handleAccordion}
                                            id={index}
                                            selectedData={this.state.selectedData}
                                            handleSelectData={this.handleSelectData}
                                        />
                                    )
                                    )}
                                </Box>
                                :
                                <Typography style={styles.noDataFoundText}>{this.state.dropdownAPILoading ? "" : "No Data Found!"}</Typography>}
                        </Grid>
                        <Grid item md={8} lg={8} sm={12} xs={12}>
                            <Box style={styles.selectedBox}>
                                <Box style={styles.dropDownRightContainer}>
                                    <Typography style={styles.selectedHeader} className="selectedHeaderStyle">
                                        {Object.keys(selectedData).length > 0 ? selectedData.name : "Select Dropdown"}
                                    </Typography>
                                    { selectedData?.dropdownData?.length > 0 &&  <Box style={styles.searchAppBar} className="searchAppBarStyle">
                                        <Box style={styles.searchIconContainer}>
                                            <Search style={styles.searchIcon} fontSize="medium" />
                                        </Box>
                                        <InputBase
                                            placeholder="Search here..."
                                            inputProps={{ "aria-label": "search" }}
                                            style={styles.searchInputNative}
                                            id="searchInputField"
                                            classes={{ input: "searchInput" }}
                                            value={this.state.searchInput}
                                            onChange={(event) => this.updateInput(event.target.value)}
                                        />
                                    { this.state.searchInput &&  <IconButton onClick={() => this.updateInput("")} id="close-btn">
                                         <Close />
                                    </IconButton>  }                
                                    </Box>}
                                </Box>
                                
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            {/* Render the first half of dropdownData */}
                                            {selectedData?.dropdownData?.slice(0, Math.ceil(selectedData.dropdownData.length / 2)).filter((item: any) =>  !this.state.searchInput || Object.keys(item)[0].toLowerCase().includes(this.state.searchInput.toLocaleLowerCase()) ).map((item: any, index: any) => (
                                                <SubDropdownAccordionSection
                                                    loadingIndicator={this.state.loading}
                                                    handleErrorClear={this.handleErrorClear}
                                                    getSpecificList={this.getSpecificList}
                                                    errorType={this.state.errorType}
                                                    error={this.state.error}
                                                    key={index}
                                                    handleSelectData={this.addDropDownData}
                                                    data={item}
                                                    expandTab={(index === this.state.selectedSubId)}
                                                    handleAccordion={this.handleSubAccordion}
                                                    id={index}
                                                    handleInputChange={this.handleInputChange}
                                                    handleAddInput={this.handleAddInput}
                                                    handleRemoveInput={this.handleRemoveInput}
                                                    section1InputsEnglish={section1InputsEnglish}
                                                    resetSelectionInput={this.handleResetSection}
                                                    section2InputsHindi={section2InputsHindi}
                                                    section3InputsGujrati={section3InputsGujrati}
                                                    stateList={stateList}
                                                    districtList={districtList}
                                                    talukaList={talukaList}
                                                    villageList={villageList}
                                                    specificDropDownData={this.state.specificDropDownData}
                                                />
                                            ))}
                                            {
                                                !selectedData?.dropdownData?.filter((item: any) => Object.keys(item)[0].toLowerCase().includes(this.state.searchInput.toLocaleLowerCase())).length && !!this.state.searchInput && 
                                                <Typography>
                                                    No Data Found
                                                    </Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {/* Render the second half of dropdownData */}
                                            {selectedData?.dropdownData?.slice(Math.ceil(selectedData.dropdownData.length / 2))?.filter((item: any) =>  !this.state.searchInput || Object.keys(item)[0].toLowerCase().includes(this.state.searchInput.toLocaleLowerCase())).map((item: any, index: any) => (
                                                <SubDropdownAccordionSection
                                                    loadingIndicator={this.state.loading}
                                                    getSpecificList={this.getSpecificList}
                                                    handleErrorClear={this.handleErrorClear}
                                                    errorType={this.state.errorType}
                                                    error={this.state.error}
                                                    key={index + Math.ceil(selectedData.dropdownData.length / 2)} // Offset key by the length of the first half
                                                    handleSelectData={this.addDropDownData}
                                                    data={item}
                                                    expandTab={(index + Math.ceil(selectedData.dropdownData.length / 2) === this.state.selectedSubId)}
                                                    handleAccordion={this.handleSubAccordion}
                                                    id={index + Math.ceil(selectedData.dropdownData.length / 2)} // Offset id by the length of the first half
                                                    handleInputChange={this.handleInputChange}
                                                    handleAddInput={this.handleAddInput}
                                                    handleRemoveInput={this.handleRemoveInput}
                                                    section1InputsEnglish={section1InputsEnglish}
                                                    resetSelectionInput={this.handleResetSection}
                                                    section2InputsHindi={section2InputsHindi}
                                                    section3InputsGujrati={section3InputsGujrati}
                                                    stateList={stateList}
                                                    districtList={districtList}
                                                    talukaList={talukaList}
                                                    villageList={villageList}
                                                    specificDropDownData={this.state.specificDropDownData}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </NavigationMenu>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#DF5200",
            contrastText: "#fff"
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },

    },
    typography: {
        fontFamily: 'IBM Plex Sans',
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    }
});
const styles: any = {
    selectedBox: {
        boxShadow: '2px 8px 32px 4px rgba(0, 0, 0, 0.06)',
        borderRadius: '12px',
        padding: '1rem',
        marginBottom: "24px",
        height: "100%"
    },
    selectedHeader: {
        fontSize: '18px',
        fontWeight: 700,
    },
    dropDownTitle: {
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: '3.3vw',
        marginBottom: '35px'
    },
    noDataFoundText: {
        textAlign: 'center',
        padding: '1rem'
    },
    dropDownRightContainer: {
        marginBottom: "4%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchAppBar: {
        background: "#FCFAF7",
        border: "1px solid #F0EBE0",
        fontSize: "1rem",
        borderRadius: "28px",
        display: "flex",
        width: "50%",
        height: "40px",
        alignItems: "center"
    },

    searchIconContainer: {
        padding: "1rem",
        paddingInlineEnd: "0.5rem",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    searchIcon: {
        color: "#1C2324",
        opacity: 0.48
    },

    searchInputNative: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        padding: 0,
        lineHeight: "1.5rem",
        width: "100%"
    },
};

// Customizable Area End
