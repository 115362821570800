import React from "react";

import OfflineBrowsingContext from "./OfflineBrowsingContext";

import OfflineBrowsingProviderController, {
  Props,
  configJSON,
} from "./OfflineBrowsingProviderController";

class OfflineBrowsingProvider extends OfflineBrowsingProviderController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <OfflineBrowsingContext.Provider
        value={{
          isConnected: this.state.isConnected,
          offlineQueue: this.state.offlineQueue,
          offlineState: this.state.offlineState,
          sendNetworkSafeApiRequest: this.sendNetworkSafeApiRequest,
          setNetworkSafeState: this.setNetworkSafeState,
          getNetworkSafeState: this.getNetworkSafeState,
        }}
      >
        {this.props.children}
      </OfflineBrowsingContext.Provider>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End

export { OfflineBrowsingProvider };
