import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Select, FormControl, MenuItem, Grid, List, Checkbox, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction,
  IconButton, Box, TextField, Radio , Tooltip, InputAdornment
} from '@material-ui/core';
import { Clear, RemoveCircleOutlineOutlined, WatchLater, DateRange, KeyboardArrowDown } from '@material-ui/icons';

const optionInitialValue = { value: "", value_hindi: "", value_gujrati: ""};

type ValuesType =  {
  id?: string,
  question: string,
  question_hindi: string,
  question_gujrati: string,
  question_type_id: number | string,
  question_options_attributes: Array<{ id?: string, value: string, value_hindi: string, value_gujrati: string, _destroy?: string }>
}

interface CreateQuestionProps {
  values: ValuesType,
  index: number,
  currentTab: number,
  setFieldValue: (name: string, values: any) => void;
  removeField: (index: number) => void;
  key: string;
  allQuestion: Array<{ id: number, question_type: string }> | null;
  isOption: boolean | undefined;
  errors: {
    question: string,
    question_hindi: string,
    question_gujrati: string,
  } | any
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectSection: {
    '& .MuiSelect-select': {
      background: '#F0EBE0',
      borderRadius: '10px',
    },
  },
  listItemSection: {
    padding: 0,
  },
  boxWraperForServey: {
    display: 'flex',
    margin: '10px 0',
  },
  boxWraperForServeyInput: {
    flexGrow: 1,
    padding: '10px',
    borderRadius: '10px 15px',
    background: '#FCFAF7',
    border: '1px solid #F0EBE0',
    width:"100px"
  },
  surveyInput: {
    marginBottom: '10px',
    borderBottom: '.5px solid #F0EBE0',
    '&:before':{
      border: 0,
    },
  },
  removeBtn: {
    color: '#1C2324',
    opacity: "64%"
  },
  removeField: {
    padding: '10px 10px'
  },
  emptyField: {
    color: "#1C2324",
    opacity: 0.5
  },
  w100: {
    width: '100%',
  },
  textAreaField:{
     '& .MuiFormControl-root':{
      width: '100%',
     }
  },
  option:{
    "& span":{
      maxWidth: "75%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap" as const
    }
  },
  mt8:{
    marginTop: '8px',
  },
  textArea:{
    width: '100%',
    "& div": {
      padding: '17px 12px !important'
    }
  },
  ".MuiMenuItem-root": {
    background: '#F0EBE0',
  },
  errorTextShow:{
    fontSize: '0.75rem',
    fontWeight: 300,
    color: 'red',
    opacity:0.7,
  },
  op5:{
    opacity: '0.5',
  },
  placeHolder:{
    fontSize: "14px",
  }
}));

const CreateQuestion = (props: CreateQuestionProps) => {
  const { values, setFieldValue, currentTab, index, removeField, key, allQuestion, errors, isOption } = props
  const [ids, setIds] = useState<number[]>([]);
  const [edit, setEdited] = useState({
    indexEdit: -2,
    value: ""
  });
  const basekey = `fieldArray.${index}.`;
  const classes = useStyles();

  useEffect(() => {
    if (allQuestion) {
      const idsOption = allQuestion
      .filter(data => ['Check', 'Radio', 'Dropdown'].some(type => data.question_type.includes(type)))
      .map(data => data.id);
      setIds(idsOption);
    }
  }, [allQuestion])

  const handleEventsFormValue = (keys: Omit<ValuesType, "question_type_id" | "question_options_attributes">) => {
    if (currentTab === 1) return keys.question_gujrati;
    if (currentTab === 2) return keys.question_hindi;
    return keys.question;
  }

  const handleOption = (idx: number) => {
    if (currentTab === 1) return values.question_options_attributes[idx].value_gujrati;
    if (currentTab === 2) return values.question_options_attributes[idx].value_hindi;
    return values.question_options_attributes[idx].value;
  }

  const errorOption = (idx: number) => {
     if(errors?.question_options_attributes && errors.question_options_attributes[idx]) {
       let errorData  = ""
      if (currentTab === 1) errorData = errors?.question_options_attributes[idx]?.value_gujrati;
      else if (currentTab === 2) errorData = errors?.question_options_attributes[idx]?.value_hindi;
      else errorData  = errors?.question_options_attributes[idx]?.value;
      return <span style={{color: '#ff0000',fontSize:'12px',fontWeight:400 , opacity : 1}}>{errorData}</span>
     }
    return  null
  }

  const handleEventTextFieldChange = (value: string) => {
    let key = "question"
    if (currentTab === 1) key = "question_gujrati";
    else if (currentTab === 2) key = "question_hindi";
    setFieldValue(`${basekey}${key}`, value);
  }

  const onSubmit = (value: { key: string } | boolean) => {
    if (edit.value && (typeof value === "boolean" || value?.key === 'Enter')) {
      const temp = [...values.question_options_attributes];

      switch (currentTab) {
        case 1:
          temp[edit.indexEdit].value_gujrati = edit.value;
          break;
        case 2:
          temp[edit.indexEdit].value_hindi = edit.value;
          break;
        default:
          temp[edit.indexEdit].value = edit.value;
          break;
      }

      if (temp.length === edit.indexEdit + 1) {
        temp.push(JSON.parse(JSON.stringify(optionInitialValue)));
      }

      setFieldValue(`${basekey}question_options_attributes`, temp);
      setEdited({ indexEdit: -1, value: "" });
    }
  };

  const deleteOption = (idx: number) => {

    if(values.question_options_attributes[idx].id){
      const temp = values.question_options_attributes;
      temp[idx]._destroy = "1";
      setFieldValue(`${basekey}question_options_attributes`, temp);
      return ;
    }

    if (values.question_options_attributes[idx].value || values.question_options_attributes[idx].value_gujrati || values.question_options_attributes[idx].value_hindi) {
      setFieldValue(`${basekey}question_options_attributes`, values.question_options_attributes.filter((_, _filterIdx) => _filterIdx !== idx) as any);
    }
  }

  const getSelectField = () => allQuestion?.find(data => data.id === values.question_type_id);

  const TypeSection = () => {
    const selectedField = getSelectField();

    if (selectedField?.question_type.includes("Check")) {
      return (
        <ListItemIcon >
          <Checkbox
            edge="start"
            disabled
          />
        </ListItemIcon>
      )
    }
    if (selectedField?.question_type.includes("Radio")) {
      return (
        <ListItemIcon >
          <Radio
            disabled
          />
        </ListItemIcon>
      )
    }
    if (selectedField?.question_type.includes("Text")) {
      return (
        <ListItemIcon className={classes.w100} >
          <TextField
            multiline
            maxRows={4}
          />
        </ListItemIcon>
      )
    }
    return null;
  }

  const SectionType = () => {
    const selectedField = getSelectField();

    if (selectedField?.question_type.includes("Text")) {
      return (
        <ListItemIcon className={`${classes.w100} ${classes.textAreaField} `} >
          <TextField
            multiline
            maxRows={4}
            variant='outlined'
            placeholder='User will write answer here....'
            disabled
          />
        </ListItemIcon>
      )
    }
    if (selectedField?.question_type.includes("Numeric")) {
      return (
        <ListItemIcon className={classes.w100} >
          <TextField
            type='number'
            variant='outlined'
            fullWidth
            placeholder='User will write number here....'
            disabled
          />
        </ListItemIcon>
      )
    }
    if (selectedField?.question_type.includes("Date")) {
      return (
        <ListItemIcon >
         <TextField
          defaultValue="DD/MM/YYYY"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><DateRange /></InputAdornment>,
          }}
          variant="outlined"
        />
        </ListItemIcon>
      )
    }
    if (selectedField?.question_type.includes("Time")) {
      return (
        <ListItemIcon >
           <TextField
          id="filled-start-adornment"
          defaultValue="Time"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><WatchLater /></InputAdornment>,
          }}
          variant="outlined"
        />
        </ListItemIcon>
      )
    }
    return null;
  }

  return (
    <Box className={classes.boxWraperForServey} key={key}>
      <Box className={classes.boxWraperForServeyInput}>
        <FormControl fullWidth>
          <TextField
            placeholder="Write question here..."
            type="text"
            id="standard-basic"
            InputProps={{
              className: classes.surveyInput,
            }}
            color="secondary"
            style={{ border: '0px' }}
            value={handleEventsFormValue(values)}
            helperText={errors && handleEventsFormValue(errors)}
            error={!!errors}
            onChange={(event) => handleEventTextFieldChange(event.target.value)}
          />
        </FormControl>
        <Grid container spacing={3} alignItems={isOption ? 'flex-start' : 'center' }>
          <Grid item xs={12} md={5}>
            {allQuestion && 
            <>
            <FormControl variant="outlined" className={classes.formControl} >
              <Select
                value={values.question_type_id}
                onChange={(event) => setFieldValue(`${basekey}question_type_id`, event.target.value as string)}
                className={`${classes.selectSection} ${values.question_type_id === 'none' ?  classes.op5 : ''}`}
                id="select-type"
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem value="none" >
                    <span className={classes.placeHolder}> Choose answer type</span>
                </MenuItem>
                {
                  allQuestion.map((data, indexQuestion) => <MenuItem key={`question-question_options_attributes-${indexQuestion}`} value={data.id}>{data.question_type}</MenuItem>)
                }
              </Select>
            </FormControl>
            { !!errors?.question_type_id && <div className={classes.errorTextShow} >Please select the type.</div>}
            </>}
          </Grid>
          <Grid item xs={12} md={7}>
            {( typeof(values.question_type_id) !== 'string'  && ids.includes(values.question_type_id))
              ?
              <List>
                {values.question_options_attributes.map((option, indexOption) => (
                     !option._destroy && <ListItem role={undefined} className={classes.listItemSection} key={`list-section-${option.id}-${index}`}>
                    <TypeSection />
                    {edit.indexEdit === indexOption ?
                      <TextField id="standard-basic" value={edit.value} onChange={(event) => setEdited({ ...edit, value: event.target.value })} onKeyUp={onSubmit} inputRef={input => input && input.focus()} onBlur={() => setEdited({ indexEdit: -2 , value: "" })} /> :
                      <Tooltip title={handleOption(indexOption) || ""}>
                      <ListItemText className={handleOption(indexOption) ? classes.option : classes.emptyField} id={`option-${index}`} primary={handleOption(indexOption) || "Add Option"} secondary={errorOption(indexOption)}  onClick={() => setEdited({ indexEdit: indexOption, value: handleOption(indexOption) })} />
                      </Tooltip>
                    }
                    { edit.indexEdit !== indexOption &&  <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments" onClick={() => deleteOption(indexOption)} >
                        <Clear />
                      </IconButton>
                    </ListItemSecondaryAction>}
                  </ListItem>

                ))}
              </List>
              :
              <SectionType />
            }
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.removeField} id="remove-btn" onClick={() => removeField(index)}>
        <RemoveCircleOutlineOutlined className={classes.removeBtn} />
      </Box>
    </Box>)
}

export default CreateQuestion