import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import moment from "moment";

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop:'10px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px", 
      textTransform: 'uppercase',
      fontStyle: "normal"  
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px",
      textTransform:'capitalize'
    },
    body1: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop:'5px',
      paddingBottom:'10px'
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});


interface SurveyDetailsContent {
  handleShareLinkPopup: (linkType?: string,linkId?:any,accountId?:any,type?:any) => void;
  isLinkPopup: boolean;
  surveyObj: any
}

const SurveyDetailsChatContent = (props: SurveyDetailsContent) => {
  const { handleShareLinkPopup, isLinkPopup,surveyObj } = props

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px"},
        }}
      >
         <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <DialogTitle className="modalDialogTitle">
                <Typography variant="subtitle1">
                  {"Survey Response"}
                </Typography>
              </DialogTitle>
            </div>
            <div style={{ marginRight: 20, color: '#1C2324' }}>
              <IconButton
                aria-label="close"
                data-testid="modalCloseBtnTestid"
                onClick={(e)=>{
                const linkType = 'surveydetails'
                handleShareLinkPopup(linkType)
              }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        <DialogContent dividers className="modalBodyWrapper">
          <Box style={{ width: '480px', borderRadius: '12px', boxSizing: 'border-box', background:'#F0EBE0',marginLeft:'22px', marginBottom:'15px' }}>
          {Array.isArray(surveyObj) && surveyObj?.map((data, index) =>
          <div key={`user-response-${data.id}-${index}`}>
           <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
           <Typography variant="subtitle2"> 
           {data.attributes.account_id}
            </Typography>
            <Typography variant="body1"> 
            {moment(data?.attributes.created_at).format('D MMMM YYYY')}
            </Typography>
           </div>            
           <Box style={classes.detailSection} >
            {
              Object.keys(data.attributes.response).map((item, index) => <div key={`question-${index}`} >
              <Typography style={classes.questionAccordion}>
                {item}
              </Typography>
                {typeof data.attributes.response[item] === "string" ? (
                  <Typography style={classes.answer}>
                    {data.attributes.response[item]}
                  </Typography>
                ) : (
                  (data.attributes.response[item] as string[])?.map((items, index) => (
                    <Typography style={classes.answer} key={`checkbox-${index}`}>
                      {items}
                    </Typography>
                  ))
                )}
            </div>
            )}
            </Box>
            </div>
            )}
          </Box>         
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start
const classes = {
  questionAccordion: {
    background: '#F0EBE0',
    borderRadius: '7px',
    fontSize: '15px',
    width: '100%',
    lineHeight: '25px',
    fontStyle: "normal",
    wordWrap: 'break-word' as const   
  },
  answer:{
    fontStyle:'normal',
    wordWrap: 'break-word' as const   
  },
  detailSection: {
    display: "flex",
    flexDirection: "column" as const,
    gap: '10px'
  },
};

// Customizable Area End

export default SurveyDetailsChatContent;