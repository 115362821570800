import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SchemaAdminFormADD, SchemaAdminFormEDIT, debounce, getBase64 } from "../../../components/src/helper";

export const configJSON = require("./config");
// Customizable Area Start
import moment from "moment";
const userLocation = localStorage.getItem('userLocationFarmMapping');
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  adminId: string;
  TokenValue: null | string;
  loggedInUserRole: string | null;
  isLoggedUserAdmin: boolean;

  currentAdminData: any;
  currentAdminID: any;
  currentAdminLoading: boolean;

  // PROFILE CARDS STATES INTERFACE
  totalFarmerCount: string | number;
  totalFECount: string | number;
  totalAreaCotton: any;

  // FE TABLE STATES INTERFACE
  FEListData: any[];
  FEListDataLoading: boolean;
  FEPageSize: number;
  FETableCurrentPage: number;

  searchFEbyNameValue: string;
  totalSearchedCount: number;


  // POPUP STATES INTERFACE  
  openAdminDeletePopup: boolean;
  openFEDeletePopup: boolean;
  deleteFEUserData: any;
  errorPopupOpen: boolean;
  errorPopupMsg: string;


  // ADD-USER MODAL INTERFACE
  userModal: boolean,
  openEditUserModal: boolean,
  selectedUserTable: string,
  seletedUserTypeAddUser: string;

  // ADMIN FORM INTERFACE 
  adminInitialValues: any;
  showEyeIconPassword: boolean;
  showEyeIconConfirmPassword: boolean;

  // FE FORM INTERFACE
  feInitialValues: any;
  selectedLocationArr: any[];
  getMobileDeviceModelFEData: any[];
  AllVillageListData: any[];

  deletedVillagesList: any[];
  defaultAssignedVillages: any[];
  feRowData: any;

  // FARMER FORM INTERFACE
  farmerInitialValues: any;
  farmImageFile: any;
  farmImageURL: any;
  userLandTypeFarmer: any;
  selectedFEformData: any;
  getEducationFarmerData: any[];
  getFarmerStateData: any[];
  getFarmerDistrictData: any[];
  getFarmerTalukaData: any[];
  getFarmerVillageData: any[];
  getOwnerListFarmerData: any[];
  getUnitOfMeasureFarmerData: any[];
  DistanceInData: any[];
  getLandTypeFarmerData: any[];
  getSoilTextureFarmerData: any[];
  getSourceOfIrrigationData: any[];
  getTypeIrrigationListingData: any[];
  FElistData: any[];

  // TABLE FILTER INTERFACE
  anchorEl: any;
  openTableFilterPopup: boolean;
  expanded: any;
  savedSortAscending: boolean,
  savedSortDescending: boolean,
  savedIsDisabled: any,
  savedState: any,
  savedDistrict: any,
  savedTaluka: any,
  savedVillage: any,
  sortAscending: boolean,
  sortDescending: boolean,
  initialStates: any[],
  initalDistricts: any[],
  initialTalukas: any[],
  initialVillages: any[],
  filteredStates: any[],
  filteredDistricts: any[],
  filteredTalukas: any[],
  filteredVillages: any[],
  selectedState:
  {
    name: "Select State",
    id: "",
  },
  selectedDistrict: {
    name: "Select District",
    id: "",
  },
  selectedTaluka: {
    name: "Select Taluka",
    id: "",
  },
  selectedVillage: {
    name: "Select Village",
    id: "",
  },
  stateInputValue: "",
  districtInputValue: "",
  talukaInputValue: "",
  villageInputValue: "",
  isDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
  },
  filterHasError: boolean
  hideBadge: boolean

  // ASSIGN VILLAGE FILTER INTERFACE
  openVillageFilter: boolean;
  anchorElVillageFilter: any;
  expandedVF: any;
  initialStatesVF: any[];
  initalDistrictsVF: any[];
  initialTalukasVF: any[];
  initialVillagesVF: any[];
  selectedStateVF: any;
  selectedDistrictVF: any;
  selectedTalukaVF: any;
  selectedVillageVF: any;
  stateInputValueVF: string;
  districtInputValueVF: string;
  talukaInputValueVF: string;
  villageInputValueVF: string;
  filteredStatesVF: any[];
  filteredDistrictsVF: any[];
  filteredTalukasVF: any[];
  filteredVillagesVF: any[];
  isDisabledVF: any;
  isAccordianErrorVF: any;
  // SUCCESS MODAL INTERFACE
  successModalOpen: boolean;
  successModalMsg: string;
  // LOADING BTN INTERFACE
  addFarmerLoading: boolean;
  addFELoading: boolean;
  addAdminLoading: boolean;
  addSALoading: boolean;
  updateFELoading: boolean;
  updateAdminLoading: boolean;
  deleteFELoading: boolean;
  deleteAdminLoading: boolean;
  cropImageModal: boolean;
  croppedImage: any;
  adminProfileImage: { icon: string }
  // CROP-MAPPING
  OpenFarmMapping: boolean
  latlngHash: any
  farmMappingURL: any;
  coordinates: any[];
  markerDefault: any;
  farmMappingFile: any;
  tempCoordinates: any[];
  userLocation: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminByIdApiCallId: string = "";
  getFEListApiCallId: string = "";
  deleteAdminUserApiCallId: string = "";
  deleteFEUserApiCallId: string = "";
  // ADD USER FORM - ADMIN
  addAdminApiCallId: string = "";
  updateAdminApiCallId: string = "";
  addSuperAdminApiCallId: string = "";
  // ADD USER FORM - FE
  getMobileDeviceModelFEApiCallId: string = "";
  getAllVillageListApiCallId: string = "";
  addFEApiCallId: string = "";
  updateFEApiCallId: string = "";
  // ADD USER FORM - FARMER
  getFarmerFormStateApiCallId: string = "";
  getFarmerFormDistrictApiCallId: string = "";
  getFarmerFormTalukaApiCallId: string = "";
  getFarmerFormVillageApiCallId: string = "";
  getEducationFarmerApiCallId: string = "";
  getOwnerListFarmerApiCallId: string = "";
  getUnitOfMeasureFarmerApiCallId: string = "";
  getDistanceInApiCallId: string = "";
  getSoilTextureFarmerApiCallId: string = "";
  getLandTypeFarmerApiCallId: string = "";
  getSourceOfIrrigationFarmerApiCallId: string = "";
  getTypeIrrigationListingApiCallId: string = "";
  getFElistFarmerApiCallId: string = "";
  addFarmerUserApiCallId: string = "";

  // USER TABLE FILTER START
  getStateDataApiCallId: string = "";
  getDistrictDataApiCallId: string = "";
  getTalukaDataApiCallId: string = "";
  getVillageDataApiCallId: string = "";
  // USER TABLE FILTER END
  // ASSIGN VILLAGE FILTER STARTS
  getVillageFilterVillageData: string = "";
  getVillageFilterTalukaData: string = "";
  getVillageFilterDistrictData: string = "";
  getVillageFilterStateData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adminId: "",
      TokenValue: localStorage.getItem("tokenn"),
      loggedInUserRole: localStorage.getItem("user_role"),
      isLoggedUserAdmin: true,

      currentAdminData: {
        first_name: "",
        last_name: "",
        dob: null,
      },
      currentAdminID: "",
      currentAdminLoading: false,

      // PROFILE CARDS STATES
      totalFarmerCount: 0,
      totalFECount: 0,
      totalAreaCotton: "",

      // FE TABLE STATES
      FEListData: [],
      FEListDataLoading: true,
      FEPageSize: 15,
      FETableCurrentPage: 0,

      searchFEbyNameValue: "",
      totalSearchedCount: 0,

      // POPUP STATES      
      openAdminDeletePopup: false,
      openFEDeletePopup: false,
      deleteFEUserData: null,
      errorPopupOpen: false,
      errorPopupMsg: "",

      // ADD-USER MODAL STATES 
      userModal: false,
      openEditUserModal: false,
      selectedUserTable: "farmer",
      seletedUserTypeAddUser: "Farmers",

      // ADMIN FORM STATES
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,

      // FE FORM STATES
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },
      selectedLocationArr: [],
      getMobileDeviceModelFEData: [],
      AllVillageListData: [],

      deletedVillagesList: [],
      defaultAssignedVillages: [],
      feRowData: {},

      // FARMER FORM STATES
      farmerInitialValues: {
        typeOfIrrigationFarmer: "",
        sourceOfIrrigationFarmer: "",
        landTypeFarmer: "",
        soilTextureFarmer: "",
        depthOfPumpFarmer: "",
        motorInHpFarmer: "",
        distanceInFarmer: "",
        horizontalDistanceFarmer: "",
        unitOfMeasureFarmer: "",
        totalFarmAreaFarmer: "",
        ownershipFarmer: "",
        farmSurveyNumberFarmer: "",
        mobileNumberFarmer: "",
        surnameFarmer: "",
        middlenameFarmer: "",
        firstnameFarmer: "",
        farmnameFarmer: "",
        villageFarmer: "",
        talukaFarmer: "",
        districtFarmer: "",
        genderFarmer: "",
        stateFarmer: "",
        educationFarmer: "",
        totalFamilyMemberFarmer: "",
        dateOfBirthFarmer: null,
        adharcardNumberFarmer: "",
      },
      farmImageFile: null,
      farmImageURL: null,
      userLandTypeFarmer: null,
      selectedFEformData: null,
      getEducationFarmerData: [],
      getFarmerStateData: [],
      getFarmerDistrictData: [],
      getFarmerTalukaData: [],
      getFarmerVillageData: [],
      getOwnerListFarmerData: [],
      getUnitOfMeasureFarmerData: [],
      DistanceInData: [],
      getLandTypeFarmerData: [],
      getSoilTextureFarmerData: [],
      getSourceOfIrrigationData: [],
      getTypeIrrigationListingData: [],
      FElistData: [],

      // TABLE STATES STATES
      anchorEl: null,
      openTableFilterPopup: false,
      expanded: false,

      savedSortAscending: false,
      savedSortDescending: false,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      sortAscending: false,
      sortDescending: false,

      initialStates: [],
      initalDistricts: [],
      initialTalukas: [],
      initialVillages: [],
      filteredStates: [],
      filteredDistricts: [],
      filteredTalukas: [],
      filteredVillages: [],
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      filterHasError: false,
      hideBadge: true,

      // ASSIGN VILLAGE FILTER STATES
      openVillageFilter: false,
      anchorElVillageFilter: null,
      expandedVF: false,
      initialStatesVF: [],
      initalDistrictsVF: [],
      initialTalukasVF: [],
      initialVillagesVF: [],
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: [],
      filteredDistrictsVF: [],
      filteredTalukasVF: [],
      filteredVillagesVF: [],
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      // SUCCESS MODAL INTERFACE
      successModalOpen: false,
      successModalMsg: "",
      // LOADING BTN INTERFACE
      addFarmerLoading: false,
      addFELoading: false,
      addAdminLoading: false,
      addSALoading: false,
      updateFELoading: false,
      updateAdminLoading: false,
      deleteFELoading: false,
      deleteAdminLoading: false,
      cropImageModal: false,
      croppedImage: "",
      adminProfileImage: { icon: "" },
      // CROP-MAPPING
      OpenFarmMapping: false,
      coordinates: [],
      farmMappingFile: null,
      tempCoordinates:[],
      latlngHash: null,
      farmMappingURL: null,      
      userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      markerDefault: null,            
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.setFarmerFormResponses(apiRequestCallId, responseJson)
        this.setVillageFilterAPIResponses(apiRequestCallId, responseJson)
        switch (apiRequestCallId) {
          case this.getAdminByIdApiCallId:
            this.getAdminByIdApiCallIdResp(responseJson);
            break;
          case this.getFEListApiCallId:
            this.getFEListApiCallIdResp(responseJson);
            break;
          case this.deleteFEUserApiCallId:
            this.deleteFEUserApiCallIdResp(responseJson)
            break;
          case this.addAdminApiCallId:
            this.addAdminApiCallIdResp(responseJson);
            break;
          case this.updateAdminApiCallId:
            this.updateAdminApiCallIdResp(responseJson);
            break;
          case this.addSuperAdminApiCallId:
            this.addSuperAdminApiCallIdResp(responseJson);
            break;
          case this.deleteAdminUserApiCallId:
            this.deleteAdminUserApiCallIdResp(responseJson)
            break;

          // FE FORM APIS
          case this.getMobileDeviceModelFEApiCallId:
            this.getMobileDeviceModelFEApiCallIdResp(responseJson);
            break;
          case this.getAllVillageListApiCallId:
            this.getAllVillageListApiCallIdResp(responseJson);
            break;
          case this.addFEApiCallId:
            this.addFEApiCallIdResp(responseJson);
            break;
          case this.updateFEApiCallId:
            this.updateFEApiCallIdResp(responseJson);
            break;

          // ASSIGN VILLAGE FiLTER STARTS
          case this.getStateDataApiCallId:
            this.getStateDataRes(responseJson);
            break;
          case this.getDistrictDataApiCallId:
            this.getDistrictDataRes(responseJson);
            break;
          case this.getTalukaDataApiCallId:
            this.getTalukaDataRes(responseJson);
            break;
          case this.getVillageDataApiCallId:
            this.getVillageDataRes(responseJson);
            break;
          // USER TABLE FiLTER ENDS
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleCloseCropModalAdmin = () => {
    this.setState({ cropImageModal: false })
  }

  handleSubmitAdminProgfileImage = (cropperRef: any) => {
    let croppedAdminImage = cropperRef.current?.cropper.getCroppedCanvas();
    croppedAdminImage.toBlob((blob: any) => {
      const file = new File([blob], "AdminProfile.png", { type: 'image/png' });
      this.setState({ croppedImage: file, cropImageModal: false }, () => {
        this.updateAdminAvatar(file);
      })
    });
  }

  handleAdminProfileImageUpload = (event: any) => {
    event.persist();
    const selectedFile = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.setState(prev => {
        return { adminProfileImage: { icon: reader.result as string }, cropImageModal: true }
      });
    };
    reader.readAsDataURL(selectedFile);
    if (event.currentTarget) {
      event.currentTarget.value = '';
    }
  }

  onProfileImageUpload = (event: any) => {
    event.currentTarget.files.length && this.handleAdminProfileImageUpload(event)
  }

  updateAdminAvatar = async (avatar: any) => {
    this.setState({ updateAdminLoading: true })
    const adminId = window.location.pathname.split("/")[2];

    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${adminId}?role_admin=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();

    reqFormdata.append("data[attributes][avatar]", avatar);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // RESPONSE FUNCTION STARTS
  getAdminByIdApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      if (responseJson.data) {
        this.setState({ currentAdminLoading: false });
        this.setState({
          currentAdminData: responseJson.data.attributes,
          currentAdminID: responseJson.data,
        });
      } else {
        window.location.replace("/users");
        this.setState({ currentAdminLoading: false });
        this.setState({
          currentAdminData: {
            first_name: "",
            last_name: "",
            dob: null,
          },
          currentAdminID: ""
        });
      }
    }
  };
  getFEListApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      if (responseJson.data) {
        const isFilterNotApplied: boolean = this.checkFilterError()
        if (isFilterNotApplied) {
          this.setState({
            hideBadge: true
          })
        } else {
          this.setState({
            hideBadge: false
          })
        }
        this.setState({ FEListDataLoading: false });
        this.setState({
          FEListData: responseJson.data,
          totalSearchedCount: Number(responseJson?.meta?.total_searched_count),
          totalFECount: responseJson?.meta?.fe_count,
          totalFarmerCount: responseJson?.meta?.farmers_count,
          totalAreaCotton: responseJson?.meta?.total_crop_area_of_cotton
        }, () => {
          this.handleCloseTableFilterPopup()
        });
      } else {
        this.setState({ FEListDataLoading: false });
        this.setState({ FEListData: [] });
      }
    }
  };
  deleteFEUserApiCallIdResp = (responseJson: any) => {
    if (responseJson?.account_user) {
      this.handleCloseDeletePopup()
      this.fetchFEListData()
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.DELETE_FE
      })
      this.setState({ deleteFELoading: false })
    } else if (responseJson?.data?.base) {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: responseJson?.data?.base[0]
      })
      this.handleCloseDeletePopup()
      this.setState({ deleteFELoading: false })
    } else {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
      })
      this.handleCloseDeletePopup()
      this.setState({ deleteFELoading: false })
    }
  }

  addAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.handleCloseAddUserModal()
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_ADMIN
      })
      this.setState({ addAdminLoading: false })
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: responseJson.errors[0],
        });
        this.setState({ addAdminLoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addAdminLoading: false })
      }
    }
  };
  updateAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      if (responseJson.data) {
        this.handleCloseAddUserModal()
        const adminId = window.location.pathname.split("/")[2];
        this.fetchAdminByIdData(adminId);
        this.setState({
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_ADMIN
        })
        this.setState({ updateAdminLoading: false })
      }
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: responseJson.errors[0],
        });
        this.setState({ updateAdminLoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateAdminLoading: false })
      }
    }
  };
  addSuperAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.handleCloseAddUserModal()
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_SA
      })
      this.setState({ addSALoading: false })
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: responseJson.errors[0],
        });
        this.setState({ addSALoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addSALoading: false })
      }
    }
  };
  deleteAdminUserApiCallIdResp = (responseJson: any) => {
    if (responseJson?.account_user) {
      this.handleCloseDeletePopup()
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.DELETE_ADMIN
      })
      this.setState({ deleteAdminLoading: false })
      window.location.replace("/users")
    } else if (responseJson?.data?.base) {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: responseJson?.data?.base[0],
      });
      this.handleCloseDeletePopup()
      this.setState({ deleteAdminLoading: false })
    } else {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
      })
      this.handleCloseDeletePopup()
      this.setState({ deleteAdminLoading: false })
    }
  };
  // FE FORM API RESP
  getMobileDeviceModelFEApiCallIdResp = (respJson: any) => {
    if (respJson?.length) {
      this.setState({ getMobileDeviceModelFEData: respJson });
    } else {
      this.setState({ getMobileDeviceModelFEData: [] });
    }
  };
  getAllVillageListApiCallIdResp = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({ AllVillageListData: respJson });
    } else {
      this.setState({ AllVillageListData: [] });
    }
  };
  addFEApiCallIdResp = (respJson: any) => {
    if (respJson !== null && !respJson.errors?.length) {
      this.handleCloseAddUserModal()
      this.fetchFEListData();
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FE
      })
      this.setState({ addFELoading: false })
    } else {
      if (respJson?.errors) {
        let newMsg = "";
        let errorMsg: string = respJson.errors[0];
        const rmvTxt = "Accounts villages village";
        if (respJson?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: newMsg,
        });
        this.setState({ addFELoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addFELoading: false })
      }
    }
  }
  updateFEApiCallIdResp = (responsJson: any) => {
    if (responsJson !== null && !responsJson.errors?.length) {
      if (responsJson.data) {
        this.handleCloseAddUserModal()
        this.fetchFEListData();
        this.setState({
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_FE
        })
        this.setState({ updateFELoading: false })
      }
    } else {
      if (responsJson?.errors) {
        let newMsg = "";
        let errorMsg: string = responsJson.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responsJson?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: newMsg,
        });
        this.setState({ updateFELoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateFELoading: false })
      }
    }
  };

  // FARMER FORM API RESP
  setFarmerFormResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getFarmerFormStateApiCallId:
        this.getFarmerFormStateApiCallIdResp(responseJson);
        break;
      case this.getFarmerFormDistrictApiCallId:
        this.getFarmerFormDistrictApiCallIdResp(responseJson);
        break;
      case this.getFarmerFormTalukaApiCallId:
        this.getFarmerFormTalukaApiCallIdResp(responseJson);
        break;
      case this.getFarmerFormVillageApiCallId:
        this.getFarmerFormVillageApiCallIdResp(responseJson);
        break;
      case this.getEducationFarmerApiCallId:
        this.getEducationFarmerApiCallIdResp(responseJson);
        break;
      case this.getOwnerListFarmerApiCallId:
        this.getOwnerListFarmerApiCallIdResp(responseJson);
        break;
      case this.getDistanceInApiCallId:
        this.getDistanceInApiCallIdResp(responseJson);
        break;
      case this.getUnitOfMeasureFarmerApiCallId:
        this.getUnitOfMeasureFarmerApiCallIdResp(responseJson);
        break;
      case this.getLandTypeFarmerApiCallId:
        this.getLandTypeFarmerApiCallIdResp(responseJson);
        break;
      case this.getSoilTextureFarmerApiCallId:
        this.getSoilTextureFarmerApiCallIdResp(responseJson);
        break;
      case this.getTypeIrrigationListingApiCallId:
        this.getTypeIrrigationListingApiCallIdResp(responseJson);
        break;
      case this.getSourceOfIrrigationFarmerApiCallId:
        this.getSourceOfIrrigationFarmerApiCallIdResp(responseJson);
        break;
      case this.addFarmerUserApiCallId:
        this.addFarmerUserApiCallIdResp(responseJson);
        break;
      case this.getFElistFarmerApiCallId:
        this.getFElistFarmerApiCallIdResp(responseJson);
        break;
    }
  }

  getFarmerFormStateApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getFarmerStateData: responseJson });
    } else {
      this.setState({ getFarmerStateData: [] });
    }
  };
  getFarmerFormDistrictApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getFarmerDistrictData: responseJson });
    } else {
      this.setState({ getFarmerDistrictData: [] });
    }
  };
  getFarmerFormTalukaApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getFarmerTalukaData: responseJson });
    } else {
      this.setState({ getFarmerTalukaData: [] });
    }
  };
  getFarmerFormVillageApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getFarmerVillageData: responseJson });
    } else {
      this.setState({ getFarmerVillageData: [] });
    }
  };
  getEducationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getEducationFarmerData: responseJson });
    } else {
      this.setState({ getEducationFarmerData: [] });
    }
  };
  getUnitOfMeasureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getUnitOfMeasureFarmerData: responseJson });
    } else {
      this.setState({ getUnitOfMeasureFarmerData: [] });
    }
  };
  getOwnerListFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getOwnerListFarmerData: responseJson });
    } else {
      this.setState({ getOwnerListFarmerData: [] });
    }
  };
  getSoilTextureFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getSoilTextureFarmerData: responseJson });
    } else {
      this.setState({ getSoilTextureFarmerData: [] });
    }
  };
  getDistanceInApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ DistanceInData: responseJson });
    } else {
      this.setState({ DistanceInData: [] });
    }
  };
  getSourceOfIrrigationFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getSourceOfIrrigationData: responseJson });
    } else {
      this.setState({ getSourceOfIrrigationData: [] });
    }
  };
  getLandTypeFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getLandTypeFarmerData: responseJson });
    } else {
      this.setState({ getLandTypeFarmerData: [] });
    }
  };
  getFElistFarmerApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ FElistData: responseJson });
    } else {
      this.setState({ FElistData: [] });
    }
  };
  getTypeIrrigationListingApiCallIdResp = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({ getTypeIrrigationListingData: responseJson });
    } else {
      this.setState({ getTypeIrrigationListingData: [] });
    }
  };

  addFarmerUserApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null && !responseJson.errors?.length) {
      this.handleCloseAddUserModal()
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FARMER
      })
      this.setState({ addFarmerLoading: false })
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: responseJson.errors[0],
        });
        this.setState({ addFarmerLoading: false })
      } else {
        this.setState({
          errorPopupOpen: true,
          errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addFarmerLoading: false })
      }
    }
  }

  // USER TABLE FILTER STARTS
  getStateDataRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialStates: responseJson,
        filteredStates: responseJson
      })
    } else {
      this.setState({
        initialStates: [],
        filteredStates: []
      })
    }

  }
  getDistrictDataRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initalDistricts: responseJson,
        filteredDistricts: responseJson,
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      })
    } else {
      this.setState({
        initalDistricts: [],
        filteredDistricts: [],
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      })
    }
  }
  getTalukaDataRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialTalukas: responseJson,
        filteredTalukas: responseJson,
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      })
    } else {
      this.setState({
        initialTalukas: [],
        filteredTalukas: [],
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      })
    }
  }
  getVillageDataRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialVillages: responseJson,
        filteredVillages: responseJson,
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      })
    } else {
      this.setState({
        initialVillages: [],
        filteredVillages: [],
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      })
    }
  }
  // USER TABLE FILTER STARTS
  setVillageFilterAPIResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getVillageFilterVillageData:
        this.getVillageFilterVillageDataRes(responseJson);
        break;
      case this.getVillageFilterTalukaData:
        this.getVillageFilterTalukaDataRes(responseJson);
        break;
      case this.getVillageFilterDistrictData:
        this.getVillageFilterDistrictDataRes(responseJson);
        break;
      case this.getVillageFilterStateData:
        this.getVillageFilterStateDataRes(responseJson);
        break;
    }
  };
  // ASSIGN-VILLAGE FILTER RESPONSE FUNCTION STARTS

  getVillageFilterVillageDataRes = (respJsonData: any[]) => {
    if (respJsonData) {
      let villageList: any[] = [];

      if (respJsonData?.length > 0) {
        if (this.state.selectedLocationArr.length > 0) {
          villageList = respJsonData.filter((el) => {
            return !this.state.selectedLocationArr.find((element) => {
              return element.id === el.id;
            });
          });
        } else {
          villageList = respJsonData;
        }
        this.setState({
          initialVillagesVF: villageList,
          filteredVillagesVF: villageList,
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      } else {
        this.setState({
          initialVillagesVF: [],
          filteredVillagesVF: [],
          isDisabledVF: {
            ...this.state.isDisabledVF,
            village: false,
          },
        });
      }
    }
  };
  getVillageFilterTalukaDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initialTalukasVF: respJson,
        filteredTalukasVF: respJson,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    } else {
      this.setState({
        initialTalukasVF: [],
        filteredTalukasVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: false,
        },
      });
    }
  };
  getVillageFilterDistrictDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initalDistrictsVF: respJson,
        filteredDistrictsVF: respJson,
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    } else {
      this.setState({
        initalDistrictsVF: [],
        filteredDistrictsVF: [],
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: false,
        },
      });
    }
  };
  getVillageFilterStateDataRes = (respJson: any[]) => {
    if (respJson?.length) {
      this.setState({
        initialStatesVF: respJson,
        filteredStatesVF: respJson,
      });
    } else {
      this.setState({
        initialStatesVF: [],
        filteredStatesVF: [],
      });
    }
  };
  // ASSIGN-VILLAGE FILTER RESPONSE FUNCTION ENDS

  // RESPONSE FUNCTION ENDS

  // HANDLE FUNCTIONS STARTS
  setUserAdminRole = () => {
    if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.ADMIN) {
      this.setState({ isLoggedUserAdmin: true });
    } else if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.SUPER_ADMIN) {
      this.setState({ isLoggedUserAdmin: false });
    } else {
      this.setState({ isLoggedUserAdmin: true });
    }
  };

  // HANDLE POPUPS FUNCTIONS


  handleClickFEDelete = (data: any) => {
    this.setState({
      deleteFEUserData: data,
      openFEDeletePopup: true,
    });
  };

  handleCloseDeletePopup = () => {
    this.setState({
      openAdminDeletePopup: false,
      openFEDeletePopup: false,
      deleteFEUserData: null
    })
  };
  handleCloseErrorPopup = () => {
    this.setState({ errorPopupOpen: false, errorPopupMsg: "" });
  }
  debouncedSearchTable = debounce(() => this.fetchFEListData(), 500);

  // ADD-USER MODAL FUNCTIONS STARTS
  handleOpenAddUserModal = () => {
    this.setState({
      userModal: true,
      openEditUserModal: false,
      seletedUserTypeAddUser: "Farmers",
      // CROP-MAPPING
      OpenFarmMapping: false,
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates:[],
    })
  }
  handleCloseAddUserModal = () => {
    this.setState({
      // CROP-MAPPING
      OpenFarmMapping: false,
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates:[],
      userModal: false,
    })
    this.resetFarmerFormValues()
    this.resetAdminFormValues()
    this.resetFEFormValues()
  }

  resetAdminFormValues = () => {
    this.setState({
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        dateOfBirthAdmin: null,
        usernameAdmin: "",
        passwordAdmin: "",
        confirmPasswordAdmin: "",
      },
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
    })
  }
  resetFarmerFormValues = () => {
    this.setState({
      farmerInitialValues: {
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",

        farmnameFarmer: "",
        farmSurveyNumberFarmer: "",
        ownershipFarmer: "",

        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        stateFarmer: "",
        genderFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        distanceInFarmer: "",
        motorInHpFarmer: "",
        depthOfPumpFarmer: "",
        soilTextureFarmer: "",
        landTypeFarmer: "",
        sourceOfIrrigationFarmer: "",
        typeOfIrrigationFarmer: "",
        totalFarmAreaFarmer: "",
        unitOfMeasureFarmer: "",
        horizontalDistanceFarmer: "",

      },
      userLandTypeFarmer: null,
      selectedFEformData: null,
      farmImageFile: null,
      farmImageURL: null,
    })
  }
  resetFEFormValues = () => {
    this.setState({
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        usernameFE: "",
        emailAddressFE: "",
        mobileDeviceModelFE: "",
      },
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      selectedLocationArr: [],

    })
  }
  getAddUserModalTitleTxt = () => {
    return this.state.openEditUserModal === true ? "Edit User" : "Add New User";
  };
  updateSeletedUserTypeAddUser = (userRole: string) => {
    this.setState({ seletedUserTypeAddUser: userRole });
  };

  editModalStatus = () => {
    return this.state.openEditUserModal === true;
  };
  getHelperText = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value];
  };


  getErrorMessageBoolean = (touched: any, errors: any, value: string) => {
    return touched[value] && Boolean(errors[value]);
  };
  getSubmitFormBtnTxt = () => {
    const updateBtnTxt = this.state.updateFELoading || this.state.updateAdminLoading ? "Updating..." : "Update User"
    const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
    return (this.state.openEditUserModal === true ? updateBtnTxt : addBtnTxt)
  };
  handleCloseSuccessModal = () => {
    this.setState({
      successModalOpen: false,
      successModalMsg: ""
    })
  }
  // FE FORM PROPS
  showTableFilterModal = () => {
    this.setState({ openVillageFilter: true });
  }
  anchorCurrentTarget = (event: any) => {
    this.setState({
      anchorElVillageFilter: event.currentTarget,
    });
  };
  handleSubmitFEUser = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFieldExecutiveUser(values)
      : this.addFieldExecutiveUser(values);
  };

  assignVillagePlaceholder = () => {
    return this.state.selectedLocationArr.length === 0
      ? "Search by village name"
      : "";
  };
  onFeLocationsChange = (event: any, values: any) => {
    this.setState({
      selectedLocationArr: values,
    });
  };
  handleVillageDelete = (event: any, village: any) => {
    const BEvillageObj: any = this.state.defaultAssignedVillages.find(
      (item: any) => String(item.attributes.village_id) === String(village.id)
    );
    if (BEvillageObj) {
      this.setState({
        deletedVillagesList: [
          ...this.state.deletedVillagesList,
          {
            id: BEvillageObj?.id,
            _destroy: true,
            village_id: BEvillageObj?.attributes.village_id,
          },
        ],
      });
    }
    const { selectedLocationArr } = this.state;
    const updatedOptions = selectedLocationArr?.filter(
      (option: any) => option !== village
    );

    this.setState({ selectedLocationArr: updatedOptions });
  };
  handleClickFeEdit = (data: any) => {
    let filteredArr: any = [];
    this.state.AllVillageListData.forEach((each: any) => {
      if (data.attributes.accounts_villages.length) {
        data.attributes.accounts_villages.forEach((item: any) => {
          if (String(each.id) === String(item.attributes.village_id)) {
            filteredArr.push(each);
          }
        });
      }
    });

    this.setState({
      selectedLocationArr: filteredArr,
      defaultAssignedVillages: data.attributes?.accounts_villages,
    });
    const feDoB = moment(data.attributes?.date_of_birth) || null;
    const mobileDevice = data.attributes?.mobile_type_id[0].id || "";
    this.setState({
      openEditUserModal: true,
      seletedUserTypeAddUser: "FieldExecutive",
      feInitialValues: {
        firstnameFE: data.attributes?.first_name,
        lastnameFE: data.attributes?.last_name,
        mobileNumberFE: data.attributes?.phone_number,
        locationFE: data.attributes?.location,
        dateOfBirthFE: feDoB,
        emailAddressFE: data.attributes?.email,
        usernameFE: data.attributes?.user_name,
        mobileDeviceModelFE: mobileDevice,
      },
      userModal: true,
      feRowData: data,
    });
  };

  addFieldExecutiveUser = (feBody: any) => {
    this.setState({ addFELoading: true })
    const dobFe = moment(feBody.dateOfBirthFE).format("DD/MM/YYYY");

    let feLocationsArr: any[] = [];
    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        feLocationsArr.push({ village_id: each.id });
      });
    } else {
      feLocationsArr = [];
    }
    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
      "content-type": configJSON.jsonContentType,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFEApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_fe=${true}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feBody.firstnameFE,
          last_name: feBody.lastnameFE,
          phone_number: feBody.mobileNumberFE,
          location: feBody.locationFE,
          accounts_villages_attributes: feLocationsArr,
          date_of_birth: dobFe,
          email: feBody.emailAddressFE,
          user_name: feBody.usernameFE,
          mobile_type_id: feBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feBody.mobileNumberFE,
        },
      }
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }
  updateFieldExecutiveUser = (feNewBody: any) => {
    this.setState({ updateFELoading: true })
    const feId = this.state.feRowData.id;
    const dobFe = moment(feNewBody.dateOfBirthFE).format("DD/MM/YYYY");
    const updatedDeleteArray = this.state.deletedVillagesList?.map((item) => {
      for (const element of this.state.selectedLocationArr) {
        if (element.id === item.village_id) {
          item._destroy = false;
        }
      }
      return item;
    });
    let feNewLocationsArr: any[] = [];
    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        const isAssignedVillageIsNew: boolean = !this.state.defaultAssignedVillages?.some(
          (item) => item.attributes.village_id === each.id
        )
        if (isAssignedVillageIsNew) {
          feNewLocationsArr.push({ village_id: each.id });
        }
      });
    } else {
      feNewLocationsArr = [];
    }

    const finalUpdatedVillageList = feNewLocationsArr.concat(
      updatedDeleteArray
    );
    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
      "content-type": configJSON.jsonContentType,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFEApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${feId}?role_fe=${true}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feNewBody.firstnameFE,
          last_name: feNewBody.lastnameFE,
          phone_number: feNewBody.mobileNumberFE,
          location: feNewBody.locationFE,
          accounts_villages_attributes: finalUpdatedVillageList,
          date_of_birth: dobFe,
          email: feNewBody.emailAddressFE,
          user_name: feNewBody.usernameFE,
          mobile_type_id: feNewBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feNewBody.mobileNumberFE,
        },
      },
      assign_farmer_ids: [],
    };
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  // ADMIN FORM PROPS
  handleEditAdmin = () => {
    const { currentAdminData: data } = this.state
    this.setState({
      openEditUserModal: true,
      seletedUserTypeAddUser: "Admin",
      adminInitialValues: {
        firstnameAdmin: data?.first_name,
        lastnameAdmin: data?.last_name,
        mobileNumberAdmin: data?.phone_number,
        emailAddressAdmin: data?.email,
        usernameAdmin: data?.user_name,
        dateOfBirthAdmin: data?.dob ? moment(data?.dob) : null,
        passwordAdmin: "",
        confirmPasswordAdmin: "",
      },
      userModal: true
    })
  }
  getPasswordType = () => {
    return this.state.showEyeIconPassword ? "password" : "text";
  };
  getConfirmPasswordType = () => {
    return this.state.showEyeIconConfirmPassword ? "password" : "text";
  };
  getshowEyeIcon = () => {
    return this.setState({
      showEyeIconPassword: !this.state.showEyeIconPassword,
    });
  };
  showEyeIconConfirm = () => {
    return this.setState({
      showEyeIconConfirmPassword: !this.state.showEyeIconConfirmPassword,
    });
  };
  handleAdminValidationSchema = (): any => {
    return this.state.openEditUserModal
      ? SchemaAdminFormEDIT
      : SchemaAdminFormADD;
  }
  handleSubmitAdmin = (values: any) => {
    if (this.state.seletedUserTypeAddUser === "Admin") {
      this.state.openEditUserModal
        ? this.updateAdmin(values)
        : this.addAdmin(values);

    } else {
      this.addSuperAdmin(values);
    }
  };
  addAdmin = (adminBody: any) => {
    this.setState({ addAdminLoading: true })
    const dob = moment(adminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdminApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_admin=${true}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: adminBody.firstnameAdmin,
          last_name: adminBody.lastnameAdmin,
          email: adminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + adminBody.mobileNumberAdmin,
          user_name: adminBody.usernameAdmin,
          password: adminBody.passwordAdmin,
          password_confirmation: adminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
  updateAdmin = (adminNewBody: any) => {
    this.setState({ updateAdminLoading: true })
    const dob = moment(adminNewBody.dateOfBirthAdmin).format("DD/MM/YYYY");
    const adminId = this.state.currentAdminID.id;

    if (adminId) {
      const header = {
        "content-type": configJSON.jsonContentType,
        Accept: configJSON.getTableAPiContentType,
        token: this.state.TokenValue,
      };
      const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAdminApiCallId = requestMsg.messageId;

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putAPiMethod
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTableAPiEndPoint}/${adminId}?role_admin=true`
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );

      const requestBody = {
        data: {
          attributes: {
            first_name: adminNewBody.firstnameAdmin,
            last_name: adminNewBody.lastnameAdmin,
            email: adminNewBody.emailAddressAdmin,
            dob: dob,
            phone_number: adminNewBody.mobileNumberAdmin,
            full_phone_number: "91" + adminNewBody.mobileNumberAdmin,
            user_name: adminNewBody.usernameAdmin,
            password: adminNewBody.passwordAdmin,
            password_confirmation: adminNewBody.confirmPasswordAdmin,
          },
        },
      };
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
      runEngine.sendMessage(requestMsg.id, requestMsg);
    } else {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
      });
    }
  }
  deleteAdmin = () => {
    this.setState({ deleteAdminLoading: true })
    let userId = this.state.currentAdminID?.id;
    let endpoint = `${configJSON.deleteUserApiEndPoint}/${userId}?role_admin=${true}`;
    if (userId) {
      const requestMessageAPI = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );


      this.deleteAdminUserApiCallId = requestMessageAPI.messageId;
      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );

      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    } else {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
      })
      return false;
    }
  }
  addSuperAdmin = (superAdminBody: any) => {
    this.setState({ addSALoading: true })
    const dob = moment(superAdminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSuperAdminApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_super_admin=${true}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: superAdminBody.firstnameAdmin,
          last_name: superAdminBody.lastnameAdmin,
          email: superAdminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + superAdminBody.mobileNumberAdmin,
          user_name: superAdminBody.usernameAdmin,
          password: superAdminBody.passwordAdmin,
          password_confirmation: superAdminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  // FARMER FORM PROPS
  handleSubmitFarmer = (values: any) => {
    return this.addFarmer(values);
  };

  getDistrictFarmerValue = (values: any) => {
    return this.state.getFarmerDistrictData?.length ? values?.districtFarmer : "";
  };
  getVillageFarmerValue = (values: any) => {
    return this.state.getFarmerVillageData?.length ? values?.villageFarmer : "";
  };
  getTalukaFarmerValue = (values: any) => {
    return this.state.getFarmerTalukaData?.length ? values?.talukaFarmer : "";
  };

  disableDistrictFarmerValue = () => {
    return !(this.state.getFarmerStateData.length && this.state.getFarmerDistrictData.length)
  };
  disableTalukaFarmerValue = () => {
    return !(this.state.getFarmerDistrictData.length && this.state.getFarmerTalukaData.length)
  };

  disableVillageFarmerValue = () => {
    return !(this.state.getFarmerTalukaData.length && this.state.getFarmerVillageData.length)
  };
  async handleImageUpload(event: any) {
    const selectedFile = event.target.files[0];
    const urlImage = await getBase64(selectedFile);
    this.setState({ farmImageURL: urlImage, farmImageFile: selectedFile });
  }
  checkLandType = (id: string | number) => {
    if (id && this.state.getLandTypeFarmerData.length) {
      const matchedObj = this.state.getLandTypeFarmerData?.find(
        (ech: any) => String(ech.id) === String(id)
      );
      this.setState({ userLandTypeFarmer: matchedObj });
    } else {
      this.setState({ userLandTypeFarmer: null });
    }
  }
  handleAssignFEchange = (event: any, value: any) => {
    this.setState({ selectedFEformData: value });
  };
  assignFEPlaceholder = () => {
    return this.state.selectedFEformData
      ? ""
      : "Search by field executive name";
  };
  isLandtypeIrrigation = () => {
    return this.state.userLandTypeFarmer?.label === "Irrigation"
  };
  // API CALLS
  fetchFarmerFormStates = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      getFarmerDistrictData: [],
      getFarmerTalukaData: [],
      getFarmerVillageData: [],
    });

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerFormStateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchFarmerFormDistrict = (stateId: string | number) => {
    if (!stateId || stateId === "") {
      this.setState({
        getFarmerDistrictData: [],
        getFarmerTalukaData: [],
        getFarmerVillageData: [],
      });
      return false;
    }
    this.setState({
      getFarmerDistrictData: [],
      getFarmerTalukaData: [],
      getFarmerVillageData: [],
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerFormDistrictApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictAPiEndPoint}?state_id=${stateId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchFarmerFormTaluka = (districtId: string | number) => {
    if (!districtId || districtId === "") {
      this.setState({ getFarmerTalukaData: [], getFarmerVillageData: [] });
      return false;
    }
    this.setState({
      getFarmerTalukaData: [],
      getFarmerVillageData: [],
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerFormTalukaApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaAPiEndPoint}?district_id=${districtId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchFarmerFormVillage = (villageId: string | number) => {
    if (!villageId) {
      this.setState({ getFarmerVillageData: [] });
      return false;
    }
    this.setState({
      getFarmerVillageData: [],
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerFormVillageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}?taluka_id=${villageId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchEducationFarmer = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getEducationFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationFarmerAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchUnitFarmAreaUrlData = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getUnitOfMeasureFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UnitFarmAreaUrl
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchOwnerListFarmer = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getOwnerListFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOwnerListFarmerAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchDistnaceInData = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getDistanceInApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDistanceInAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchLandTypeFarmer = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getLandTypeFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandTypeFarmerAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchSoilTextureFarmer = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getSoilTextureFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoilTextureFarmerAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchSourceOfIrrigationFarmer = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getSourceOfIrrigationFarmerApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSourceOfIrrigationFarmerAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchTypeIrrigation = () => {
    const requestAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getTypeIrrigationListingApiCallId = requestAPIMessage.messageId;
    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTypeIrrigationListingAPiEndPoint
    );

    requestAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestAPIMessage.id, requestAPIMessage);
  };
  fetchFElist = () => {
    const requestMessageAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFElistFarmerApiCallId = requestMessageAPI.messageId;
    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFEListAPiEndPoint
    );

    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
  };
  addFarmer = (farmerBody: any) => {
    this.setState({ addFarmerLoading: true })
    const dobFarmer = moment(farmerBody.dateOfBirthFarmer).format("DD/MM/YYYY");
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;

    const header = {
      token: this.state.TokenValue,
    };

    const requestMessageAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFarmerUserApiCallId = requestMessageAPI.messageId;

    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_farmer=${true}`
    );

    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let FormdataObj = new FormData();
    FormdataObj.append("data[attributes][role_id]", "2");
    FormdataObj.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    FormdataObj.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    FormdataObj.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    FormdataObj.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    FormdataObj.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    FormdataObj.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    FormdataObj.append("data[attributes][date_of_birth]", dobFarmer);
    FormdataObj.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    FormdataObj.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    FormdataObj.append("data[attributes][state_id]", farmerBody.stateFarmer);
    FormdataObj.append("data[attributes][gender]", farmerBody.genderFarmer);
    FormdataObj.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    FormdataObj.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    FormdataObj.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    if (this.state.farmImageFile) {
      FormdataObj.append(
        "data[attributes][land_details_attributes[0]][farm_image]",
        this.state.farmImageFile
      );
    }
    // farm_mapping_image
    if (this.state.latlngHash) 
    {
      FormdataObj.append(
        "data[attributes][land_details_attributes[0]][latitude_longitude]",
        this.state.latlngHash
      );
    }
    if (this.state.farmMappingFile) 
    {
      FormdataObj.append( "data[attributes][land_details_attributes[0]][farm_mapping_image]",
        this.state.farmMappingFile
      );
    }    
    FormdataObj.append(
      "data[attributes][land_details_attributes[0]][name]",
      farmerBody.farmnameFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][survey_number]",
      farmerBody.farmSurveyNumberFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][ownership_of_land_id]",
      farmerBody.ownershipFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][farm_area]",
      farmerBody.totalFarmAreaFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
      farmerBody.totalFarmAreaFarmer
    ); //extra
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][unit_farm_area_id]",
      farmerBody.unitOfMeasureFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][horizontal_distance]",
      farmerBody.horizontalDistanceFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][distance_in_id]",
      farmerBody.distanceInFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][motor_horse_power]",
      farmerBody.motorInHpFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][pump_depth]",
      farmerBody.depthOfPumpFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][land_type_id]",
      farmerBody.landTypeFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][source_irrigation_id]",
      farmerBody.sourceOfIrrigationFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][type_irrigation_id]",
      farmerBody.typeOfIrrigationFarmer
    );
    FormdataObj.append(
      "data[attributes][land_details_attributes][0][soil_texture_id]",
      farmerBody.soilTextureFarmer
    ); //extra
    if (this.state.selectedFEformData) {
      FormdataObj.append("data[attributes][assigned_fe_id]", FEid);
    }

    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      FormdataObj
    );
    runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
  };

  // TABLE FILTER FUNCTIONS STARTS
  handleChangeAtoZ = () => {
    this.setState({
      sortAscending: !this.state.sortAscending,
      sortDescending: false
    });
  }
  handleChangeZtoA = () => {
    this.setState({
      sortDescending: !this.state.sortDescending,
      sortAscending: false
    });
  }
  handleTalukaSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValue: e?.target?.value });
    const TalukafilteredArray = query
      ? this.state.initialTalukas.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukas;
    this.setState({
      filteredTalukas: TalukafilteredArray,
    });
  }
  handleVillageSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValue: e?.target?.value });
    const VillagefilteredArray = query
      ? this.state.initialVillages.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillages;
    this.setState({
      filteredVillages: VillagefilteredArray,
    });
  }
  handleDistrictSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValue: e?.target?.value });
    const DistrictfilteredArray = query
      ? this.state.initalDistricts.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistricts;
    this.setState({
      filteredDistricts: DistrictfilteredArray,
    });
  }
  handleStateSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValue: e?.target?.value });
    const StatefilteredArray = query
      ? this.state.initialStates.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStates;
    this.setState({
      filteredStates: StatefilteredArray,
    });
  }
  handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
    });
  };
  handleVillageFilterClear = () => {
    this.setState({
      openTableFilterPopup: false,
      anchorEl: null,
      sortDescending: false,
      sortAscending: false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      expanded: false,
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      savedSortAscending: false,
      savedSortDescending: false,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      filterHasError: false
    }, () => {
      this.fetchFEListData()
      this.getDistricts();
      this.getTalukas();
      this.getVillages();
    })
  };
  handleCloseTableFilterPopup = () => {
    this.setState({
      openTableFilterPopup: false,
      expanded: false,
      selectedState: this.state.savedState,
      selectedDistrict: this.state.savedDistrict,
      selectedTaluka: this.state.savedTaluka,
      selectedVillage: this.state.savedVillage,
      sortAscending: this.state.savedSortAscending,
      sortDescending: this.state.savedSortDescending,
      isDisabled: this.state.savedIsDisabled,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filterHasError: false
    })
  }
  handleSubmitFilter = () => {
    const isError = this.checkFilterError()
    if (!isError) {
      this.setState({
        expanded: false,
        savedState: this.state.selectedState,
        savedDistrict: this.state.selectedDistrict,
        savedTaluka: this.state.selectedTaluka,
        savedVillage: this.state.selectedVillage,
        savedIsDisabled: this.state.isDisabled,
        savedSortAscending: this.state.sortAscending,
        savedSortDescending: this.state.sortDescending,
      }, () => {
        this.fetchFEListData()
      });
    } else {
      return false
    }
  }
  handleChangeField = (key1: any, key2: any, key3: any, key4: any, key5?: any) => {
    const isDisabledInitialValues = {
      state: true,
      district: true,
      taluka: true,
      village: true,
    }
    this.setState({
      isDisabled: {
        ...isDisabledInitialValues,
        ...(key1 && { [key1]: false }),
        ...(key2 && { [key2]: false }),
        ...(key3 && { [key3]: false }),
        ...(key4 && { [key4]: false }),
      }
    })

  }
  handleChangeStateField = (stateData: any) => {
    this.setState({
      selectedState: stateData,
      expanded: false,
    }, () => {
      this.getDistricts()
    });
    this.handleChangeField("", "state", "district", "", "")
  }
  handleChangeDistrictField = (districtData: any) => {
    this.setState({
      selectedDistrict: districtData,
      expanded: false,
    }, () => {
      this.getTalukas()
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", "district", "taluka", "", "")

  }
  handleChangeTalukaField = (talukaData: any) => {
    this.setState({
      selectedTaluka: talukaData,
      expanded: false,
    }, () => {
      this.getVillages()
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", "taluka", "village", "")
  }
  handleChangeVillageField = (villageData: any) => {
    this.setState({
      selectedVillage: villageData,
      expanded: false,
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", "village", "fe")
  }
  opacityStateCalculator = () => {
    if (this.state.selectedState.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityDistrictCalculator = () => {
    if (this.state.selectedDistrict.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityTalukaCalculator = () => {
    if (this.state.selectedTaluka.name === "Select Taluka") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityVillageCalculator = () => {
    if (this.state.selectedVillage.name === "Select Village") {
      return 0.48
    }
    else {
      return 1
    }
  }
  checkFilterError = () => {
    if (
      this.state.selectedState.id !== "" || this.state.selectedDistrict.id !== "" ||
      this.state.selectedTaluka.id !== "" || this.state.selectedVillage.id !== "" ||
      this.state.sortAscending || this.state.sortDescending
    ) {
      this.setState({
        filterHasError: false
      })
      return false;
    } else {
      this.setState({
        filterHasError: true
      })
      return true;
    }
  }
  // TABLE FILTER FUNCTIONS ENDS
  // ASSIGN VILLAGE FILTER FUNCTION STARTS
  handleVillageFilterClearVF = () => {
    this.setState({
      expandedVF: false,
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: this.state.initialStatesVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredVillagesVF: this.state.initialVillagesVF,
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    });
  }
  closeVillageFilterVF = () => {
    this.setState({
      openVillageFilter: false,
      expandedVF: false,
      selectedStateVF: {
        name: "Select State",
        id: "",
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      isDisabledVF: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianErrorVF: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    });
  }
  handleChangePanelVF = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expandedVF: newExpanded ? panel : false,
      stateInputValueVF: "",
      districtInputValueVF: "",
      talukaInputValueVF: "",
      villageInputValueVF: "",
      filteredStatesVF: this.state.initialStatesVF,
      filteredDistrictsVF: this.state.initalDistrictsVF,
      filteredTalukasVF: this.state.initialTalukasVF,
      filteredVillagesVF: this.state.initialVillagesVF,
    });
  };

  handleVillageSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialVillagesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillagesVF;
    this.setState({
      filteredVillagesVF: filteredArray,
    });
  }
  handleTalukaSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialTalukasVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukasVF;
    this.setState({
      filteredTalukasVF: filteredArray,
    });
  }
  handleDistrictSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initalDistrictsVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistrictsVF;
    this.setState({
      filteredDistrictsVF: filteredArray,
    });
  }
  handleStateSearchVF = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValueVF: e?.target?.value });
    const filteredArray = query
      ? this.state.initialStatesVF.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStatesVF;
    this.setState({
      filteredStatesVF: filteredArray,
    });
  }
  handleChangeVillageFieldVF = (villageData: any) => {
    this.setState(
      {
        selectedVillageVF: villageData,
        expandedVF: false,
      },
      () => {
        this.checkAccordianError();
      }
    );
  }
  handleChangeTalukaFieldVF = (talukaData: any) => {
    this.setState(
      {
        selectedTalukaVF: talukaData,
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      },
      () => {
        this.checkAccordianError();
        this.getVillagesVF();
      }
    );
  }
  handleChangeDistrictFieldVF = (districtData: any) => {
    this.setState(
      {
        selectedDistrictVF: districtData,
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      },
      () => {
        this.checkAccordianError();
        this.getTalukasVF();
      }
    );
  }
  handleChangeStateFieldVF = (stateData: any) => {
    this.setState(
      {
        selectedStateVF: stateData,
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
        expandedVF: false,
      },
      () => {
        this.checkAccordianError();
        this.getDistrictsVF();
      }
    );
  }
  handleSubmitAccordionVF = () => {
    const result: boolean = this.checkAccordianError();
    if (result === true) {
      this.setState({
        expandedVF: false,
      });
    } else {
      const FilterObj = this.state.AllVillageListData.find(
        (item: any) => item.id === this.state.selectedVillageVF.id
      );
      let newVillageValue = [...this.state.selectedLocationArr];
      newVillageValue.push(FilterObj);
      this.setState(
        {
          selectedLocationArr: newVillageValue,
          openVillageFilter: false,
        },
        () => {
          this.closeVillageFilterVF();
        }
      );
    }
  }
  checkAccordianError = () => {
    let isStateEmpty = false;
    let isDistrictEmpty = false;
    let isTalukaEmpty = false;
    let isVillageEmpty = false;

    if (
      this.state.selectedStateVF?.id === "" || this.state.selectedDistrictVF?.id === "" ||
      this.state.selectedTalukaVF?.id === "" || this.state.selectedVillageVF?.id === ""
    ) {
      if (this.state.selectedStateVF?.id === "") {
        isStateEmpty = true;
      }
      if (this.state.selectedDistrictVF?.id === "") {
        isDistrictEmpty = true;
      }
      if (this.state.selectedTalukaVF?.id === "") {
        isTalukaEmpty = true;
      }
      if (this.state.selectedVillageVF?.id === "") {
        isVillageEmpty = true;
      }
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateEmpty,
          district: isDistrictEmpty,
          taluka: isTalukaEmpty,
          village: isVillageEmpty,
        },
      });
      return true;
    } else {
      this.setState({
        isAccordianErrorVF: {
          ...this.state.isAccordianErrorVF,
          state: isStateEmpty,
          district: isDistrictEmpty,
          taluka: isTalukaEmpty,
          village: isVillageEmpty,
        },
      });
      return false;
    }
  }
  opacityVillageCalculatorVF = () => {
    if (this.state.selectedVillageVF.name === "Select Village") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityTalukaCalculatorVF = () => {
    if (this.state.selectedTalukaVF.name === "Select Taluka") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityDistrictCalculatorVF = () => {
    if (this.state.selectedDistrictVF.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityStateCalculatorVF = () => {
    if (this.state.selectedStateVF.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }
  // ASSIGN VILLAGE FILTER FUNCTION ENDS

  // HANDLE FARM-MAPPING FUNCTION
  onSaveFarmMapping = (latlngHash: any, file: any, url: string, savedCoordinates: any[]) => {    
    this.setState({ latlngHash: latlngHash, OpenFarmMapping: false, farmMappingURL: url, farmMappingFile: file, tempCoordinates: savedCoordinates })
  }
  closeFarmMappingModal = () => { this.setState({ OpenFarmMapping: false }) }

  getCurrentLocation = () => {
    const success = (pos: any) => {
      this.setState({ userLocation: {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }
      }, () => {
        localStorage.setItem('userLocationFarmMapping', JSON.stringify(this.state.userLocation))
      })
    }

    const error = (error: any) => {
      this.setState({ userLocation: null
      })
    }

    if(navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    }
    
  }

  // HANDLE FUNCTIONS ENDS

  async componentDidMount(): Promise<void> {
    this.setUserAdminRole();

    const adminId = window.location.pathname.split("/")[2];
    this.fetchAdminByIdData(adminId);
    this.fetchFEListData();

    // FE
    this.fetchMobileDeviceModelFE()
    this.fetchAllVillageList()

    // FARMER
    this.fetchFarmerFormStates()
    this.fetchEducationFarmer()
    this.fetchUnitFarmAreaUrlData();
    this.fetchOwnerListFarmer();
    this.fetchDistnaceInData();
    this.fetchLandTypeFarmer();
    this.fetchSoilTextureFarmer();
    this.fetchSourceOfIrrigationFarmer();
    this.fetchTypeIrrigation();
    this.fetchFElist();

    // USER TABLE FILTER STARTS
    this.getStates();
    this.getDistricts();
    this.getTalukas();
    this.getVillages();
    // USER TABLE FILTER ENDS

    this.getStatesVF()
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    const newAdminId = window.location.pathname.split("/")[2];
    if (
      prevState.FEPageSize !== this.state.FEPageSize ||
      prevState.FETableCurrentPage !== this.state.FETableCurrentPage
    ) {
      this.fetchFEListData();
      this.fetchAdminByIdData(newAdminId);
    }
  }

  // API CALL FUNCTIONS STARTS

  fetchAdminByIdData = (adminId: string) => {
    this.setState({
      currentAdminData: { first_name: "", last_name: "", dob: null },
      currentAdminID: "",
      currentAdminLoading: true,
    });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}/${adminId}?role_admin=true`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getAdminByIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchFEListData = () => {
    this.setState({ FEListData: [], FEListDataLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.FEPageSize) * Number(this.state.FETableCurrentPage + 1)}&page=${1}&search=${this.state.searchFEbyNameValue}&state_id=${this.state.selectedState.id}&district_id=${this.state.selectedDistrict.id}&taluka_id=${this.state.selectedTaluka.id}&village_id=${this.state.selectedVillage.id}&list_of_fe=${true}`;
    if (this.state.sortAscending) {
      endpoint = endpoint + `&sort_by_a_to_z=${true}`
    }
    else if (this.state.sortDescending) {
      endpoint = endpoint + `&sort_by_z_to_a=${true}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    this.getFEListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteFE = () => {
    this.setState({ deleteFELoading: true })
    const userId = this.state.deleteFEUserData?.id
    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      this.deleteFEUserApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteUserApiEndPoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({
        errorPopupOpen: true,
        errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
      })
      return false;
    }
  }
  fetchAllVillageList = () => {
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getAllVillageListApiCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
  fetchMobileDeviceModelFE = () => {
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getMobileDeviceModelFEApiCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMobileDeviceModelFEAPiEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };
  // USER TABLE FILTER STARTS
  getVillages() {
    let params = this.state.selectedTaluka?.id
    this.setState({
      isDisabled: {
        ...this.state.isDisabled,
        village: true,
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsgAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageDataApiCallId = getValidationsMsgAPI.messageId;

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageList}?taluka_id=${params}`
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsgAPI.id, getValidationsMsgAPI);
  }
  getTalukas() {
    let params = this.state.selectedDistrict?.id
    this.setState({
      isDisabled: {
        ...this.state.isDisabled,
        taluka: true,
        village: true,
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsgAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaDataApiCallId = getValidationsMsgAPI.messageId;

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaList}?district_id=${params}`
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsgAPI.id, getValidationsMsgAPI);
  }
  getDistricts() {
    let params = this.state.selectedState?.id
    this.setState({

      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        district: true,
        village: true,
        taluka: true,
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsgAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistrictDataApiCallId = getValidationsMsgAPI.messageId;
    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictList}?state_id=${params}`
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsgAPI.id, getValidationsMsgAPI);
  }
  getStates() {
    this.setState({
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        district: true,
        village: true,
        taluka: true,
      },
    });
    const headers = {
      token: this.state.TokenValue,
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsgAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateDataApiCallId = getValidationsMsgAPI.messageId;

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsgAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsgAPI.id, getValidationsMsgAPI);
  }
  // USER TABLE FILTER ENDS

  // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS
  getStatesVF = () => {
    this.setState({
      isDisabledVF: {
        ...this.state.isDisabledVF,
        district: true,
        village: true,
        taluka: true,
      },
      selectedDistrictVF: {
        name: "Select District",
        id: "",
      },
      selectedTalukaVF: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageVF: {
        name: "Select Village",
        id: "",
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue,
    };

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageFilterStateData = reqMsg.messageId;

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
  }
  getDistrictsVF = () => {
    let params = this.state.selectedStateVF?.id;
    if (this.state.selectedStateVF?.id) {
      this.setState({
        selectedDistrictVF: {
          name: "Select District",
          id: "",
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        isDisabledVF: {
          ...this.state.isDisabledVF,
          district: true,
          village: true,
          taluka: true,
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
      });

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const reqMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterDistrictData = reqMsg.messageId;
      reqMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getDistrictList}?state_id=${params}`
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqMsg.id, reqMsg);
    } else {
      return false;
    }
  }
  getTalukasVF = () => {
    let params = this.state.selectedDistrictVF?.id;
    if (this.state.selectedDistrictVF?.id) {
      this.setState({
        isDisabledVF: {
          ...this.state.isDisabledVF,
          taluka: true,
          village: true,
        },
        selectedTalukaVF: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
      });
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const reqMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterTalukaData = reqMsg.messageId;

      reqMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTalukaList}?district_id=${params}`
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqMsg.id, reqMsg);
    } else {
      return false;
    }
  }
  getVillagesVF = () => {
    let params = this.state.selectedTalukaVF?.id;
    if (this.state.selectedTalukaVF?.id) {
      this.setState({
        isDisabledVF: {
          ...this.state.isDisabledVF,
          village: true,
        },
        selectedVillageVF: {
          name: "Select Village",
          id: "",
        },
      });
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const reqMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterVillageData = reqMsg.messageId;

      reqMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVillageList}?taluka_id=${params}`
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(reqMsg.id, reqMsg);
    } else {
      return false;
    }
  }
  // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS
  // API CALL FUNCTIONS ENDS

  // Customizable Area End
}
