import React from 'react'
import {
    Box,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    Popover,
    Divider,
    MenuItem,
    TextField
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FilterCloseIconSVG } from '../../blocks/AdminConsole/src/assets';
import SearchIcon from "@material-ui/icons/Search";
import { configJSON } from '../../blocks/AdminConsole/src/AdminConsoleController';
interface AssignVillageFilterProps {
    isVillageFilterOpen: boolean;
    anchorElVillageFilter: any;
    closeVillageFilter: () => void;

    isAccordianError: any;
    isDisabled: any;
    expanded: string | null;
    handleChangePanelState: (event: any, newExpanded: any) => void;
    handleChangePanelDistrict: (event: any, newExpanded: any) => void;
    handleChangePanelTaluka: (event: any, newExpanded: any) => void;
    handleChangePanelVillage: (event: any, newExpanded: any) => void;

    selectedState: any;
    stateInputValue: any;
    filteredStates: any[];
    handleStateSearch: (e: any) => void;
    handleChangeStateField: (stateData: any) => void;

    selectedDistrict: any;
    districtInputValue: any;
    handleDistrictSearch: (e: any) => void;
    filteredDistricts: any[];
    handleChangeDistrictField: (districtData: any) => void;

    selectedTaluka: any;
    talukaInputValue: any;
    handleTalukaSearch: (e: any) => void;
    filteredTalukas: any[];
    handleChangeTalukaField: (talukaData: any) => void;

    selectedVillage: any;
    villageInputValue: any;
    handleVillageSearch: (e: any) => void;
    filteredVillages: any[];
    handleChangeVillageField: (villageData: any) => void;

    handleSubmitAccordion: () => void;
    handleVillageFilterClear: () => void;

    opacityStateCalculator: () => 1 | 0.48
    opacityDistrictCalculator: () => 1 | 0.48
    opacityTalukaCalculator: () => 1 | 0.48
    opacityVillageCalculator: () => 1 | 0.48

    getDistrictsVF?:() =>void;
    getTalukasVF?:() =>void;
    getVillagesVF?:() =>void;
}

function AssignVillageFilter(props: AssignVillageFilterProps) {
    const {
        isVillageFilterOpen,
        anchorElVillageFilter,
        closeVillageFilter,
        isAccordianError,
        isDisabled,
        expanded,
        handleChangePanelState,
        handleChangePanelDistrict,
        handleChangePanelTaluka,
        handleChangePanelVillage,

        selectedState,
        stateInputValue,
        handleStateSearch,
        filteredStates,
        handleChangeStateField,

        selectedDistrict,
        districtInputValue,
        handleDistrictSearch,
        filteredDistricts,
        handleChangeDistrictField,

        selectedTaluka,
        talukaInputValue,
        handleTalukaSearch,
        filteredTalukas,
        handleChangeTalukaField,

        selectedVillage,
        villageInputValue,
        handleVillageSearch,
        filteredVillages,
        handleChangeVillageField,

        handleSubmitAccordion,
        handleVillageFilterClear,

        opacityStateCalculator,
        opacityDistrictCalculator,
        opacityTalukaCalculator,
        opacityVillageCalculator,

        getDistrictsVF,
        getTalukasVF,
        getVillagesVF,
    } = props;

    return (
        <>
            <Popover
                className="filterPopover filterPopover-farm-diaries"
                open={isVillageFilterOpen}
                anchorEl={anchorElVillageFilter}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={closeVillageFilter}
            >
                <Box
                    style={{
                        padding: "16px",
                        overflow: "inherit !important",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1C2324",
                                fontWeight: 500,
                            }}
                        >
                            Filter
                        </Typography>
                        <IconButton
                            aria-label="close"
                            data-testid="VillagefilterModalCloseBtnTestid"
                            className="close_btn"
                            style={{ padding: "0px" }}
                            onClick={closeVillageFilter}
                        >
                            <img src={FilterCloseIconSVG} alt='close' />
                        </IconButton>
                    </Box>
                    <Box style={{ marginTop: "12px" }}>
                        <Grid container style={{ display: "grid", gridGap: "20px" }}>
                            {/* STATE */}
                            <Grid item container md={12} lg={12}>
                                {/* STATE LABEL */}
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography
                                            style={{
                                                transform: "translateY(4px)",
                                                display: "flex",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                            }}
                                        >
                                            State
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* STATE DROPDOWN */}
                                <Grid
                                    item
                                    xs={9}
                                    className="accordion-grid"
                                    style={{
                                        position: "relative",
                                        zIndex: 4,
                                    }}
                                >
                                    <Accordion
                                        style={{
                                            position: "absolute",
                                            backgroundColor: "#FCFAF7",
                                            border: `${isAccordianError?.state
                                                ? "1px solid #B3261E"
                                                : "1px solid #F0EBE0"
                                                }`,
                                            borderRadius: "8px",
                                            width: "100%",
                                        }}
                                        disabled={isDisabled.state}
                                        expanded={expanded === "panel1"}
                                        onChange={handleChangePanelState}
                                    >
                                        <AccordionSummary
                                            className="AccordionSummaryWrapper"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                minHeight: "28px",
                                                height: "28px",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                    opacity: opacityStateCalculator()
                                                }}
                                            >
                                                {selectedState.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <Box
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Divider
                                                style={{
                                                    width: "80%",
                                                    height: "1px",
                                                    background: "#F0EBE0",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Box>
                                        <AccordionDetails className="accordionDetails accordionDetailsPadding">
                                            <Box
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <TextField
                                                    placeholder="Search"
                                                    className="state-field-class customDDtextfield"
                                                    data-testid="stateTextFieldTestid"
                                                    variant="outlined"
                                                    value={stateInputValue}
                                                    autoComplete="off"
                                                    id="searchDDField"
                                                    style={{ marginBottom: "7px" }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        opacity: "0.32",
                                                                        color: "#1C2324",
                                                                        width: "14px",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(event: any) => handleStateSearch(event)}
                                                />
                                                <Box
                                                    style={{ maxHeight: "55px", overflowY: "scroll" }}
                                                >
                                                    {filteredStates.length > 0 ? filteredStates?.map(
                                                        (stateData: any, index: number) => (
                                                            <MenuItem
                                                                key={stateData.name}
                                                                value={stateData.name}
                                                                className="state-menu-items"
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                    background: "transparent",
                                                                    opacity: `${String(selectedState.id) === String(stateData.id) ? 1 : 0.48}`
                                                                }}
                                                                data-testid={`filteredStatesTestid-${index}`}
                                                                onClick={() => {
                                                                    handleChangeStateField(stateData);
                                                                }}
                                                            >
                                                                {stateData.name}
                                                            </MenuItem>
                                                        )
                                                    ) : (
                                                        <MenuItem
                                                            className="state-menu-items"
                                                            style={{
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                lineHeight: "18px",
                                                                padding: "0px",
                                                                marginBottom: "2px",
                                                                color: "#1C2324",
                                                                background: "transparent",
                                                                opacity: 0.48
                                                            }}
                                                        >
                                                            {'- No data Found'}
                                                        </MenuItem>
                                                    )}
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    {getRedText(isAccordianError?.state, configJSON.ERROR_MESSAGES.state)}
                                </Grid>
                            </Grid>
                            {/* DISTRICT */}
                            <Grid
                                item
                                container
                                md={12}
                                lg={12}
                                style={{ marginTop: "6px" }}
                            >
                                {/* DISTRICT LABEL */}
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography
                                            style={{
                                                transform: "translateY(4px)",
                                                display: "flex",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                            }}
                                        >
                                            District
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* DISTRICT DROPDOWN */}
                                <Grid
                                    item
                                    xs={9}
                                    className="accordion-grid"
                                    style={{
                                        position: "relative",
                                        zIndex: 3,
                                    }}
                                >
                                    <Accordion
                                        style={{
                                            position: "absolute",
                                            backgroundColor: "#FCFAF7",
                                            border: `${isAccordianError?.district
                                                ? "1px solid #B3261E"
                                                : "1px solid #F0EBE0"
                                                }`,
                                            borderRadius: "8px",
                                            width: "100%",
                                        }}
                                        disabled={isDisabled.district}
                                        expanded={expanded === "panel2"}
                                        onChange={handleChangePanelDistrict}
                                    >
                                        <AccordionSummary
                                            className="AccordionSummaryWrapper"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                minHeight: "28px",
                                                height: "28px",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                    opacity: opacityDistrictCalculator()
                                                }}
                                            >
                                                {selectedDistrict.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <Box
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Divider
                                                style={{
                                                    width: "80%",
                                                    height: "1px",
                                                    background: "#F0EBE0",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Box>
                                        <AccordionDetails className="accordionDetails accordionDetailsPadding">
                                            <Box
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <TextField
                                                    placeholder="Search"
                                                    className="district-field-class customDDtextfield"
                                                    data-testid="districtTextFieldTestid"
                                                    variant="outlined"
                                                    value={districtInputValue}
                                                    autoComplete="off"
                                                    id="searchDDField"
                                                    style={{ marginBottom: "7px" }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        opacity: "0.32",
                                                                        color: "#1C2324",
                                                                        width: "14px",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(event: any) => handleDistrictSearch(event)}
                                                />
                                                <Box
                                                    style={{ maxHeight: "55px", overflowY: "scroll" }}
                                                >
                                                    {filteredDistricts.length > 0 ? filteredDistricts?.map(
                                                        (districtData: any, index: number) => (
                                                            <MenuItem
                                                                key={districtData.name}
                                                                value={districtData.name}
                                                                className="district-menu-items"
                                                                data-testid={`filteredDistrictsTestid-${index}`}
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                    background: "transparent",
                                                                    opacity: `${String(selectedDistrict.id) === String(districtData.id) ? 1 : 0.48}`
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeDistrictField(districtData);
                                                                }}
                                                            >
                                                                {districtData.name}
                                                            </MenuItem>
                                                        )
                                                    ) : (
                                                        <MenuItem
                                                            className="state-menu-items"
                                                            style={{
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                lineHeight: "18px",
                                                                padding: "0px",
                                                                marginBottom: "2px",
                                                                color: "#1C2324",
                                                                background: "transparent",
                                                                opacity: 0.48
                                                            }}
                                                        >
                                                            {'- No data Found'}
                                                        </MenuItem>
                                                    )}
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    {getRedText(isAccordianError?.district, configJSON.ERROR_MESSAGES.district)}
                                </Grid>
                            </Grid>
                            {/* TALUKA */}
                            <Grid
                                item
                                container
                                md={12}
                                lg={12}
                                style={{ marginTop: "6px" }}
                            >
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography
                                            style={{
                                                transform: "translateY(4px)",
                                                display: "flex",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Taluka
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    className="accordion-grid"
                                    xs={9}
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                    }}
                                >
                                    <Accordion
                                        style={{
                                            position: "absolute",
                                            backgroundColor: "#FCFAF7",
                                            border: `${isAccordianError?.taluka
                                                ? "1px solid #B3261E"
                                                : "1px solid #F0EBE0"
                                                }`,
                                            borderRadius: "8px",
                                            width: "100%",
                                        }}
                                        disabled={isDisabled.taluka}
                                        expanded={expanded === "panel3"}
                                        onChange={handleChangePanelTaluka}
                                    >
                                        <AccordionSummary
                                            className="AccordionSummaryWrapper"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                minHeight: "28px",
                                                height: "28px",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                    opacity: opacityTalukaCalculator(),
                                                }}
                                            >
                                                {selectedTaluka.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <Box
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Divider
                                                style={{
                                                    width: "80%",
                                                    height: "1px",
                                                    background: "#F0EBE0",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Box>
                                        <AccordionDetails className="accordionDetails accordionDetailsPadding">
                                            <Box
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <TextField
                                                    placeholder="Search"
                                                    className="taluka-field-class customDDtextfield"
                                                    data-testid="talukaTextFieldTestid"
                                                    variant="outlined"
                                                    value={talukaInputValue}
                                                    autoComplete="off"
                                                    id="searchDDField"
                                                    style={{ marginBottom: "7px" }}
                                                    onChange={(event: any) => handleTalukaSearch(event)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                style={{ background: "#ffffff" }}
                                                            >
                                                                <SearchIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        opacity: "0.32",
                                                                        color: "#1C2324",
                                                                        width: "14px",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <Box
                                                    style={{ maxHeight: "55px", overflowY: "scroll" }}
                                                >
                                                    {filteredTalukas.length > 0 ? filteredTalukas?.map(
                                                        (talukaData: any, index: number) => (
                                                            <MenuItem
                                                                key={talukaData.name}
                                                                value={talukaData.name}
                                                                className="taluka-menu-items"
                                                                data-testid={`filteredTalukasTestId-${index}`}
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                    background: "transparent",
                                                                    opacity: `${String(selectedTaluka.id) === String(talukaData.id) ? 1 : 0.48}`
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeTalukaField(talukaData);
                                                                }}
                                                            >
                                                                {talukaData.name}
                                                            </MenuItem>
                                                        )
                                                    ) : (
                                                        <MenuItem
                                                            className="state-menu-items"
                                                            style={{
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                lineHeight: "18px",
                                                                padding: "0px",
                                                                marginBottom: "2px",
                                                                color: "#1C2324",
                                                                background: "transparent",
                                                                opacity: 0.48
                                                            }}
                                                        >
                                                            {'- No data Found'}
                                                        </MenuItem>
                                                    )}
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    {getRedText(isAccordianError?.taluka, configJSON.ERROR_MESSAGES.taluka)}
                                </Grid>
                            </Grid>
                            {/* VILLAGE */}
                            <Grid
                                item
                                container
                                md={12}
                                lg={12}
                                style={{ marginTop: "6px" }}
                            >
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography
                                            style={{
                                                transform: "translateY(4px)",
                                                display: "flex",
                                                fontSize: "14px",
                                                lineHeight: "22px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Village
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    className="accordion-grid"
                                    style={{
                                        position: "relative",
                                        zIndex: 1,
                                    }}
                                >
                                    <Accordion
                                        style={{
                                            position: "absolute",
                                            backgroundColor: "#FCFAF7",
                                            border: `${isAccordianError?.village
                                                ? "1px solid #B3261E"
                                                : "1px solid #F0EBE0"
                                                }`,
                                            borderRadius: "8px",
                                            width: "100%",
                                        }}
                                        disabled={isDisabled.village}
                                        expanded={expanded === "panel4"}
                                        onChange={handleChangePanelVillage}
                                    >
                                        <AccordionSummary
                                            className="AccordionSummaryWrapper"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                minHeight: "28px",
                                                height: "28px",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                    opacity: opacityVillageCalculator(),
                                                }}
                                            >
                                                {selectedVillage.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <Box
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <Divider
                                                style={{
                                                    width: "80%",
                                                    height: "1px",
                                                    background: "#F0EBE0",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Box>
                                        <AccordionDetails className="accordionDetails accordionDetailsPadding">
                                            <Box
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <TextField
                                                    placeholder="Search"
                                                    className="village-field-class customDDtextfield"
                                                    data-testid="villageTextFieldTestid"
                                                    id="searchDDField"
                                                    value={villageInputValue}
                                                    autoComplete="off"
                                                    variant="outlined"
                                                    style={{ marginBottom: "7px" }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                style={{ background: "#ffffff" }}
                                                            >
                                                                <SearchIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        opacity: "0.32",
                                                                        color: "#1C2324",
                                                                        width: "14px",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(event: any) => handleVillageSearch(event)}
                                                />
                                                <Box
                                                    style={{ maxHeight: "55px", overflowY: "scroll" }}
                                                >
                                                    {filteredVillages.length > 0 ? filteredVillages?.map(
                                                        (villageData: any, index: number) => (
                                                            <MenuItem
                                                                key={villageData.name}
                                                                value={"villageData.name"}
                                                                className="village-menu-items"
                                                                data-testid={`filteredVillagesTestid-${index}`}
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                    background: "transparent",
                                                                    opacity: `${String(selectedVillage.id) === String(villageData.id) ? 1 : 0.48}`
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeVillageField(villageData);
                                                                }}
                                                            >
                                                                {villageData.name}
                                                            </MenuItem>
                                                        )
                                                    ) : (
                                                        <MenuItem
                                                            className="state-menu-items"
                                                            style={{
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                lineHeight: "18px",
                                                                padding: "0px",
                                                                marginBottom: "2px",
                                                                color: "#1C2324",
                                                                background: "transparent",
                                                                opacity: 0.48
                                                            }}
                                                        >
                                                            {'- No data Found'}
                                                        </MenuItem>
                                                    )}
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    {getRedText(isAccordianError?.village, configJSON.ERROR_MESSAGES.village)}
                                </Grid>
                            </Grid>
                            {/* SAVE AND CLEAR BUTTOS */}
                            <Grid
                                item
                                container
                                md={12}
                                lg={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexFlow: "row",
                                    marginTop: "10px",
                                }}
                            >
                                <Button
                                    style={filterStyle.saveBtn}
                                    className="submit_accordion_btn"
                                    variant="contained"
                                    onClick={handleSubmitAccordion}
                                    data-testid="VillageFilterSubmitBtnTestid"
                                >
                                    Save
                                </Button>
                                <Button
                                    style={filterStyle.clearBtn}
                                    variant="outlined"
                                    data-testid="VillageFilterClearBtnTestid"
                                    onClick={handleVillageFilterClear}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Popover>
        </>

    )
}

export default AssignVillageFilter
export const getRedText = (isError: boolean, Message: string): JSX.Element => {
    return (
        <>
            {isError ? (
                <div>
                    <Typography
                        className="text-danger"
                        style={{
                            fontSize: "0.75rem",
                            position: "absolute",
                            bottom: "-25px",
                            zIndex: -1,
                        }}
                    >
                        {Message}
                    </Typography>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
const filterStyle = {
    saveBtn: {
        width: "111px",
        height: "32px",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        textTransform: "uppercase",
        background: "#DF5200",
        padding: "5px 38px",
        borderRadius: "16px",
        color: "#FFFFFF",
    } as React.CSSProperties,
    clearBtn: {
        width: "111px",
        height: "32px",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        textTransform: "uppercase",
        border: "1px solid #DF5200",
        borderRadius: "16px",
        background: "#FFFFF",
        padding: "5px 34px",
        marginLeft: "8px",
        color: "#1C2324",
    } as React.CSSProperties
}