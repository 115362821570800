import React from "react";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ListItem,
  ListItemIcon,
  Avatar,
  Button,
  Box,
  InputBase 
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import "./LinkShare.web.css";
import { Search } from '@material-ui/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@material-ui/core/CircularProgress';


export const Loader = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading)
    return <Box sx={{ display: 'flex', justifyContent: "center" }}>
      <CircularProgress
        style={{ color: "#df5200" }}
      />
    </Box>
  return <></>
}

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import LinkShareController, {
  Props,
  configJSON,
} from "./LinkShareController";
import CustomModal from "../../../components/src/customModal.web";

export default class LinkShare extends LinkShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   renderChatList = () => {  
    return this.state.chatList?.map((ele: any, idx: number) => {
      const name = ele.name?.split(" ")
      let avatarName = ""
      if (name?.length) {
        avatarName = name[0].charAt(0).toUpperCase()
        if (name.length >= 2) {
          avatarName += name[1].charAt(0).toUpperCase()
        }
      }
      return (
        <ListItem
          button
          key={ele.id}
          data-test-id="btnShowChat"
          className={'user-box'}
          style={{width:"99%",paddingLeft:'30px'}}>
          <ListItemIcon
            data-testid={`user-image`}
            className="user-image"
          >
            {
              ele.logo
                ?
                <Avatar src={ele.logo} alt="" id="user_profile_image" />
                :
                <Avatar
                  data-testid='user-symbol'
                  className="user-symbol"
                  style={{height:'40px',width:'40px', fontSize:'14px'}}            
                >
                  {avatarName}
                </Avatar>
            }
          </ListItemIcon>
  
          <div className="chatside-user">
            <div className="name">
              <h5>{ele.name}</h5>          
            </div>   
            <div>
              <Button style={!this.state.isSent[idx] ? webStyles.sendbtn : webStyles.sendbtn2 } data-testid="send-btn-id" disabled={this.state.isSent[idx]} onClick={(e)=>{
                e.preventDefault()  
                e.currentTarget.disabled = true;  
                this.handleSendButtonClick(idx)          
                this.handleSendShareLink(ele.id,this.props.linkShare,this.props.messageHeading)
              }} >
                {!this.state.isSent[idx] ?'send': 'sent'}
              </Button>
              </div>      
          </div>
        </ListItem >
      );
    })
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.props.isShare}
          maxWidth={"md"}
          PaperProps={{
            style: { borderRadius: 12, width: "620px" },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding:"24px"
            }}
          >
            <div>
              <DialogTitle className="modalDialogTitles">
                <Typography variant="subtitle1">
                  {"Share in Chat"}
                </Typography>
              </DialogTitle>
            </div>
            <div style={{ color: '#1C2324' }}>
              <IconButton
                aria-label="close"
                data-testid="modalCloseBtnTestid"
                onClick={this.props.handleShareLink}
                style={{padding:0}}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <DialogContent dividers className="sharelinkModalBodyWrapper">
            <div className="search-chat" style={{ justifyContent: "center" }}>
              <Box style={webStyles.searchAppBar} className="link-share-search-input">
                <Box sx={webStyles.searchIconContainer}>
                  <Search style={webStyles.searchIcon} fontSize="medium" />
                </Box>
                <InputBase
                  inputProps={{ "aria-label": "search" }}
                  style={webStyles.searchInputNative}
                  data-testid="search"
                  placeholder="Search Chat"
                  defaultValue={this.state.searchText}
                  onChange={this.filterList}
                />
              </Box>
            </div>          
            <div id="scrollableDiv" style={{ overflowY: this.state.isLoading ? "hidden" : "auto", maxHeight: 336,marginBottom:'20px' }}>
             <Loader isLoading={this.state.isLoading} />
              <InfiniteScroll
                dataLength={this.state.chatList?.length}
                className="sidebar-chat"
                style={{                
                  boxShadow: "none",
                  width: "100%",
                  paddingRight: "5px"
                }}
                scrollableTarget="scrollableDiv"
                next={this.userChatListRenderer}
                hasMore={true}
                loader={<></>}>
               { this.renderChatList()}
              </InfiniteScroll>
             {this.state.chatList.length === 0 &&
              <ListItem className={'user-box'} style={{display:'flex',justifyContent:'center'}}>
                  {"No Records found !"}
              </ListItem>}
            </div>
          </DialogContent>
        </Dialog>
         {/* SUCCESS-ALERT-BOX START */}
         <CustomModal
            open={this.state.openSucessModal}
            title={"Success !"}
            description={" Link sent successfully "}          
            onClose={this.handleClose}
          />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  searchAppBar: {
    background: "#F0EBE0",
    border: "1px solid #F0EBE0",
    fontSize: "1rem",
    borderRadius: "8px",
    display: "flex",
    width: "556px",
    minHeight: "40px",
  },
  searchIconContainer: {
    padding: "0rem 0.9rem",
    paddingInlineEnd: "0.5rem",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.32,
    marginTop: '2px',
  },
  searchIcon: {
    color: "#1C2324",
    opacity: 0.32
  },
  searchInputNative: {
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    padding: 0,
    lineHeight: "1.5rem",
    width: "100%",
    marginBottom: '5px',
    opacity: 0.32
  },
  sendbtn: {
    display: 'flex',
    width: '51px',
    lineHeight: '28px',
    fontSize: '12px',
    color: '#ffff',
    background: "#DF5200",
    borderRadius: "25px",
    height: "28px",
  },
  sendbtn2: {
    display: 'flex',
    width: '51px',
    lineHeight: '28px',
    fontSize: '12px',
    color: 'rgb(28, 35, 36)',
    background: "rgb(228, 221, 201)",
    borderRadius: "25px",
    height: "28px",
  },
};
// Customizable Area End
