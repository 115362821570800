Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
exports.confirmOtpBtn = "Confirm OTP";
exports.otpScreenTitle = "Verify";
exports.resendOtp = "RESEND CODE";
exports.userMailId = "abhishek.shah@materra.tech"
exports.otpScreenSubtitle = "Please enter verification code sent to"

exports.apiEmailLoginOtpEndpoint = "admin/email_otp_confirmations";
exports.apiResendOtpEndpoint = "admin/resend_otp";
