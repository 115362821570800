//@ts-nocheck
import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export const configJSON = require("../config");

interface IProps {
  handleMapClick: () => void
}
const MapContainer = ({ handleGetDestinationList, handleMapClick, location, google }: any) => {
  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={configJSON.apiMapKey} // Replace with your API key
        selectProps={{
          onChange: handleGetDestinationList,
        }}
        placeholder="Enter Destination"
        minLength={2}
        debounce={300}
        autocompletionRequest={{
          types: ['(regions)'],
          // Restrict to a specific country if needed
        }}
        google={google}
      />
      <Map
        className="location-map"
        google={google}
        zoom={14}
        style={{ cursor: "ponter" }}
        onClick={handleMapClick}
        center={{ lat: location.latitude, lng: location.longitude }}
        initialCenter={{ lat: location.latitude, lng: location.longitude }}
      >
        <Marker
          position={{ lat: location.latitude, lng: location.longitude }}
          className="pickup-marker"

        />


      </Map>
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: configJSON.apiMapKey, // Replace with your API key
  libraries: ['places']
})(MapContainer);

