import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  InputAdornment,
  Radio,
  Tooltip,
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon, DateRange, WatchLater, Share, KeyboardArrowDown } from "@material-ui/icons";

// Customizable Area Start
import {
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CustomModal from "../../../components/src/customModal.web";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

import { styles, theme } from "./SurveysStyle.web";




// Customizable Area End

import SurveysDetailsController, { Props, configJSON } from "./SurveysDetailsController";
import Loader from "../../../components/src/surveys/loader.web";
import LinkShare from "../../LinkShare/src/LinkShare.web";


export class SurveysDetails extends SurveysDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  questionSection = (question: any, questionIdx: number) => {
    const { classes } = this.props

    let field = (<TextField
      id="outlined-multiline-static"
      multiline
      rows={4}
      defaultValue="User will write answer here...."
      variant="outlined"
      disabled
      className={classes.textArea}
    />)

    if (question?.attributes.question_type?.includes("Date")) {
      field = (
        <TextField
          defaultValue="DD/MM/YYYY"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><DateRange /></InputAdornment>,
          }}
          variant="outlined"
        />
      )
    }

    else if (question?.attributes.question_type?.includes("Time")) {
      field = (
        <TextField
          defaultValue="Time"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><WatchLater /></InputAdornment>,
          }}
          variant="outlined"
        />
      )
    }
    else if (question?.attributes.question_type?.includes("Dropdown")) {

      field = (
        <TextField
          defaultValue="Choose option"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><KeyboardArrowDown /></InputAdornment>,
          }}
          fullWidth
          variant="outlined"
        />
      )
    } else if (question?.attributes.question_type?.includes("Check")) {
      field = <div className={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel control={<Checkbox />} label={<span className={`${classes.betweenCardHeading} ${classes.option}`}>{data.attributes.value}</span>} key={`checkBox-${question.id}-${index}`} disabled />)}</div>
    }
    else if (question?.attributes.question_type?.includes("Radio")) {
      field = <div className={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel value={data.attributes.value} control={<Radio />} label={<span className={`${classes.betweenCardHeading} ${classes.option}`}>{data.attributes.value}</span>} key={`radio-${question.id}-${index}`} disabled />)}</div>
    }



    return (
      <Box className={`${classes.boxWraperForServeyInput} ${classes.questionCard}`} key={`question-${question.id}-${questionIdx}`}>
        <div>
          <div className={classes.bottomBorder}>
            <Tooltip title={question.attributes.question || ""}>
              <Typography className={classes.question} >
                {question.attributes.question}
              </Typography>
            </Tooltip>
          </div>
          {field}
        </div>
      </Box>
    )
  }

  // Customizable Area End

  render() {
    const { navigation, id, classes } = this.props;
    const { survey, deleteId, responseUser, accordionActive } = this.state

    if (!survey) {
      return (<Loader />)
    }

    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <CustomModal
            open={!!deleteId}
            onClose={() => this.toggleDelete(null)}
            confirmHandler={() => this.deleteSurveyAPI()}
            title="Delete Survey"
            description={configJSON.DELETE_SURVEY_DESCRIPTION}
            confirmTitle="Delete"
          />
          <Box>
            <div className={classes.between}>
              <div>
                <Typography className={classes.surveyName}>
                  {survey.attributes.name} <span className={classes.dateText}>{moment(survey.attributes.created_at).format("DD/MM/YYYY")}</span>
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.headerSubtitle}
                >
                  <span className={classes.pathFirstPart} id="surveySection" onClick={() => navigation.goBack()} >Surveys</span>
                  {` > ${survey.attributes.name}`}
                </Typography>
              </div>
              <div className={classes.rightHeader}>
                <FormControlLabel
                  value="start"
                  control={<Switch size="medium" checked={!survey.attributes.active}
                    id="switch-btn"
                    className={survey.attributes.active ? classes.switchBtn : classes.disableSwitchBtn}
                    onChange={() => this.changeSurveysActionState(survey.id, !survey.attributes.active)}
                  />}
                  className={classes.formControlLabel}
                  label={survey.attributes.active ? "DISABLE" : "ENABLE"}
                  labelPlacement="start"
                />
                <Button variant="contained" id="deleteBtn" className={classes.deleteBtnLarge} onClick={() => this.toggleDelete(survey.id)}>
                  Delete
                </Button>
              </div>
            </div>
            <Grid container spacing={3} className={classes.mt24}>
              <Grid item xs={12} sm={6} md={7}>
                {
                  survey.attributes.questions.data.map((question, index) => this.questionSection(question, index))
                }
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <Box className={classes.responseCard}>
                  <div className={`${classes.between} ${classes.padding10}`} >
                    <Typography className={classes.responseTitleKey} >
                      RESPONSES
                    </Typography>
                    <Typography className={classes.mainColor}>
                      {responseUser?.length || 0}
                    </Typography>
                  </div>
                  <div id="scrollableDiv" style={{ overflowY: "auto", maxHeight: "300px", marginBottom:"3.75rem"}}  >
                  <InfiniteScroll
                        dataLength={Array.isArray(responseUser) ? responseUser?.length : 0}
                        scrollableTarget="scrollableDiv"
                        next={() => this.responsesUser()}
                        hasMore={this.state.hasNext}
                        loader={<></>}
                    >
                  {Array.isArray(responseUser) && responseUser?.map((data, index) =>
                    <Accordion className={classes.responseAccordion} key={`user-response-${data.id}-${index}`} expanded={accordionActive === index} onChange={() => this.setState(({accordionActive}) => ({accordionActive: accordionActive === index ? null : index}))} >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div className={`${classes.betweenCard} ${classes.w100}`}>
                          <Typography className={classes.headingAccordion}>{data.attributes.account_id}</Typography>
                          <span className={classes.dateText}>{moment(data?.attributes.created_at).format("DD/MM/YYYY")}</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.detailSection}>
                        {Object.keys(data.attributes.response).map((item, index) => <div key={`question-${index}`} >
                          <Typography className={classes.questionAccordion}>
                            {item}
                          </Typography>
                          {typeof data.attributes.response[item] === "string" ? (
                            <Typography className={classes.answer}>
                              {data.attributes.response[item]}
                            </Typography>
                          ) : (
                            (data.attributes.response[item] as string[])?.map((items, index) => (
                              <Typography className={classes.answer} key={`checkbox-${index}`}>
                                {items}
                              </Typography>
                            ))
                          )}
                        </div>
                        )
                        }
                        <div className={classes.between} >
                          <Button className={classes.shareBtn} id="shareBtn" startIcon={<Share />} onClick={()=> this.handleShareLink(data)} > Share in Chat </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>)}
                    </InfiniteScroll>
                    </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(SurveysDetails);
// Customizable Area End
