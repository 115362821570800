// Customizable Area Start
import React from "react";

import {
    Box,
    Typography,
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Share } from "@material-ui/icons";

const StyledTypography = makeStyles(() => ({
    root: {},
    label: {},
    body1: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1c2324",
        marginBottom: "4px",
    },
    body2: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2324",
        marginBottom: "4px",
    },
    subtitle1: {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
    },
    subtitle2: {
        color: "#1C2324",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
    }
}));

const StyledAccordionSummary = makeStyles(() => ({
    root: {
        padding: "0px 24px !important",
    },
}));

const StyledAccordionDetails = makeStyles(() => (
    {
        root: {
            padding: "0px 16px 20px !important",
        },
    }
));


const displayUserBasedSurveys = (props: any) => {

    const typographyClasses = StyledTypography();
    const accordionSummaryClasses = StyledAccordionSummary();
    const accordionDetailsClasses = StyledAccordionDetails();

    const userSurveyEntries = props.data?.length && props.data.map((elem: any) => {

        return (
            <Accordion
                expanded={props.isExpanded === elem.id}
                onChange={props.handleAccordionChange(elem.id)}
                className="user-survey-accordion accordionSeparator userSurveyContainer"
                key={elem.id}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: accordionSummaryClasses.root,
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: "calc(100% - 10px)" }}>
                        <Typography
                            variant="subtitle1"
                            classes={{
                                body1: typographyClasses.body1,
                                body2: typographyClasses.body2,
                                subtitle1: typographyClasses.subtitle1
                            }}
                        >
                            {elem?.attributes?.survey_name}
                        </Typography>
                        <Typography variant="subtitle2" classes={{
                            body1: typographyClasses.body1,
                            body2: typographyClasses.body2,
                            subtitle1: typographyClasses.subtitle1,
                            subtitle2: typographyClasses.subtitle2
                        }} style={{ color: "#8a9394", fontSize: "12px" }}>
                            {moment(elem.attributes?.created_at).format('DD/MM/YYYY')}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails classes={{
                    root: accordionDetailsClasses.root,
                }}>
                    <Grid xs={12} container>
                        {
                            Object.keys(elem?.attributes?.response)?.map((item, index) =>
                                <Grid xs={12} item>
                                    <div key={`question-${index}`} >
                                        <Box py={2} px={4} sx={surveyStyles.question}>{item}</Box>
                                        {typeof elem?.attributes?.response[item] === "string" ? (
                                            <Box py={2} px={4} my={2} sx={surveyStyles.answer}>
                                                {elem.attributes.response[item]}
                                            </Box>
                                        ) : (
                                            (elem?.attributes?.response[item] as string[])?.map((el, index) => (
                                                <Box py={2} px={4} my={2} key={`checkbox-${index}`} sx={surveyStyles.answer}>
                                                    {el}
                                                </Box>
                                            ))
                                        )}
                                    </div>
                                </Grid>
                            )
                        }
                        
                        <Button style={{ color: '#006A77' }} startIcon={<Share />} onClick={(e) => {
                            e.preventDefault()
                            props.handleShareLink(elem)
                        }}> Share in Chat </Button>

                    </Grid>

                </AccordionDetails>
            </Accordion>
        );
    });
    return userSurveyEntries;
};

export const UserBasedSurveys = (props: any) => {

    const userSurveyEntries: any = displayUserBasedSurveys(props);

    return (
        <>
            <Box
                className="leftBlock noBorderBottom accordionSeparator userSurveyContainer"
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box className="sectionHeading">Surveys</Box>
                <Typography noWrap className="totalUserSurvey">
                    {userSurveyEntries?.length || '-'}
                </Typography>
            </Box>

            <Box className="userSurveyGrid">
                {userSurveyEntries}
            </Box>
        </>
    );

}

const surveyStyles = {
    question: {
        background: "#F0EBE0",
        padding: "10px",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        borderRadius: "6px",
        color: "#1C2324",
        wordBreak: "break-word",
    },
    answer: {
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        wordBreak: "break-word",
    }
}