import React, { createRef, useState } from 'react'

import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    CircularProgress
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Close } from '@material-ui/icons'


interface ImageCropEditorProps {
    open: boolean,
    onClose: () => void,
    handleUploadImage?: any;
    title: string;
    src: string;
    isUploading?: boolean;
    buttonText?:string;
}

const ImageCropEditor = (props: ImageCropEditorProps) => {
    const { open, onClose, handleUploadImage, title, src, isUploading,buttonText="Upload" } = props
    const classes = useStyles();
    const cropperRef = createRef<ReactCropperElement>();
    const [zoomValue,setZoomValue]=useState<any>()

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"sm"}
        >
            <div
                className={classes.dialogHeader}
            >
                <DialogTitle>
                    <Typography variant="subtitle1" className={classes.dialogTitle}>
                        {title}
                    </Typography>
                </DialogTitle>
                <div style={{ marginRight: 20, color: '#1C2324' }}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers style={{ padding: "1.5rem" }}>
                <Cropper
                    ref={cropperRef}
                    style={{ height: 400, width: "100%" }}
                    zoomTo={zoomValue}
                    preview=".img-preview"
                    src={src}
                    viewMode={1}
                    minCropBoxHeight={50}
                    minCropBoxWidth={50}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    aspectRatio={1}
                    checkOrientation={false}
                    guides={true}
                    onInitialized={(instance:any) => {
                        setZoomValue(instance);
                      }}
                />
                <StyeledButton disabled={isUploading} onClick={() => handleUploadImage(cropperRef)} >
                    {isUploading ? <CircularProgress size={28} style={{ color: "#fff" }} /> : buttonText}
                </StyeledButton>
            </DialogContent>
        </Dialog>
    )
}

const StyeledButton = styled(Button)({
    display: 'flex',
    width: "100%",
    paddingBlock: "0.875rem",
    fontSize: '1.125rem',
    fontFamily: "IBM Plex Sans",
    color: '#fff',
    background: "#DF5200",
    borderRadius: "28px",
    marginTop: "16px",
    "&:hover": {
        background: "#DF5200",
    },
    '@media (max-width:600px)': {
        padding: "0.675rem 1rem",
        '& .MuiTypography-root': {
            fontSize: '14px !important',
        }
    },
})

const useStyles = makeStyles(() => ({
    dialogHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    dialogTitle: {
        fontFamily: "IBM Plex Sans",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "36px",
        color: "#1C2324"
    },
    addbtn: {
        display: 'flex',
        width: "100%",
        paddingBlock: "0.875rem",
        fontSize: '1.125rem',
        fontFamily: "IBM Plex Sans",
        color: '#fff',
        background: "#DF5200",
        borderRadius: "28px",
        marginTop: "16px"
    },
}))

export default ImageCropEditor;