import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, Button} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    loginbg,
    cofarmlogo,
    imgb4f504af0c068b9e61a863261a16f237e444c685,
} from "./assets";
import "../../email-account-login/src/EmailAccountLogin.css";
import CustomModal from "../../../components/src/customModal.web";
import * as moment from "moment";
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";

export default class EmailLoginOTP extends OTPInputAuthController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        if(!localStorage.getItem("otp_token")){
            this.props.navigation.navigate("EmailAccountLoginBlock");
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <CustomModal 
                    open={!!this.state.errorPopup} 
                    onClose={() => this.toggleErrorPopup(null)}
                    title={this.state.typeModal}
                    description={this.state.errorPopup}
                    />
                <Grid container >
                    <Grid item md={6} lg={6} sm={12} xs={12}>
                        <Box sx={webStyle.farmImgBox}>
                            <img
                                src={cofarmlogo}
                                style={webStyle.cofarmImg}
                            />
                        </Box>
                        <Box style={webStyle.backBox}>
                                <Box onClick={this.goBackLogin} 
                                    data-testId={"goBackIcon"}
                                >
                                <img src={imgb4f504af0c068b9e61a863261a16f237e444c685} 
                                    style={webStyle.backImg}
                                    
                                />
                                </Box>
                               
                                <Typography style={webStyle.title}>
                                    {this.state.otpScreenTitle}
                                </Typography>
                        </Box>
                        <Box style={webStyle.verifyBox}>
                            <Box style={webStyle.otpInfoBox}>
                                <Typography style={webStyle.subtitle}>
                                    {this.state.otpScreenSubtitle}
                                </Typography>
                                <Typography style={webStyle.mailText}>
                                    {this.state.userMailId}
                                </Typography>
                    
                                <Box style={webStyle.otpSection}>
                                    {
                                        this.state.otpBoxes.map((val, index) => {
                                            return (
                                                <input type="text"
                                                    key={index}
                                                    maxLength={1}
                                                    value={val}
                                                    onChange={(e) => this.handleChangeOtp(e.target, index)}
                                                    onKeyDown={(e) => this.otpKeyDown(e, index)}
                                                    style={webStyle.otpBox}
                                                    data-testId={`otpBox-${index}`}
                                                />
                                            )
                                        })
                                    }

                                </Box>
                                <Typography style={webStyle.timerText}>
                                    Code is valid for <span style={webStyle.timerCount}>{moment.utc(moment.duration(this.state.otpTimer, 'seconds').asMilliseconds()).format('mm:ss')}</span> seconds.
                                </Typography>
                                <Typography style={webStyle.resendText}>
                                    Didn’t get code? {" "}
                                    <span style={webStyle.resendLink} onClick={this.resendLoginOtp}
                                    data-testId={"resendOtpLink"}
                                    >
                                        {this.state.resendOtp}
                                        </span>
                                </Typography>
                                <Box style={webStyle.BtnBox}>
                                    <Button
                                        data-testId={"BtnConfirmOTP"}
                                        variant="contained"
                                        fullWidth
                                        onClick={this.verifyConfirmOtp}
                                        style={webStyle.confirmBtn}
                                    >
                                        {this.state.confirmOtpBtn}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} lg={6} sm={12} xs={12}>
                        <img src={loginbg}
                            style={webStyle.loginImg}
                        />
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: 'IBM Plex Sans',
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    }
});

const webStyle: any = {
    farmImgBox: {
        padding: "3.3vw 0 0 5vw", 
    },
    loginImg: {
        width: '100%',
        height: '100%',
    },
    cofarmImg:{
        width:'11vw', 
        height:'2.5vw'
    },
    verifyBox: {
        padding: "-4vw 6vw 0 4.8vw",
    },
    backImg:{
        height:'24px',
        cursor:'pointer'
    },
    backBox:{
        padding: "8vw 0 0 5vw",
        display:'flex',
        alignItems:'center',
    },
    otpInfoBox:{
        padding:'0 9vw 0 11vw',
    },
    title: {
        fontSize: "2.8vw",
        marginLeft: "3.5vw",
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    subtitle: {
        color: "#1C2324",
        opacity: "0.64",
        fontSize: "1.1vw",
        fontWeight: 400,
        marginTop:'1rem',
    },
    otpBox:{
        width: '3.3vw', 
        height: '3.8vw',
        margin: '20px 2vw 20px 0',
        background: '#FCFAF7',
        border: '1px solid #F0EBE0',
        borderRadius: '.8vw',
        textAlign: 'center'
    },
    mailText: {
        color: "#1C2324",
        opacity: "0.64",
        fontSize: "1.1vw",
        fontWeight: 500,
    },
    timerText: {
        color: "#1C2324",
        fontSize: "1.1vw",
        fontWeight: 400,
    },
    timerCount: {
        color: "#006A77",
        fontWeight: 500,
    },
    resendText: {
        color: "#1C2324",
        fontSize: "1.1vw",
        fontWeight: 400,
        lineHeight: '1.6vw',
        padding:'2rem 0',
    },
    otpSection:{
        padding:'1vw 0 0 -2vw',
    },
    resendLink: {
        color: "#006A77",
        fontWeight: 500,
        cursor:"pointer",
    },
    BtnBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 2rem 0 0",
        marginTop: "1rem",
    },
    confirmBtn: {
        background: "#DF5200",
        borderRadius: "32px",
        fontFamily: "IBM Plex Sans",
        fontWeight: 700,
        fontSize: "1.2vw",
        lineHeight: "2vw",
        color: "#fff",
        boxShadow: "none",
        height:'3.8vw'
    },
};
// Customizable Area End
