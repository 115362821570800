import { createTheme } from "@material-ui/core/styles";

// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "11.5px",
    },
    subtitle2: {
      fontSize: "12px",
      color: "#8E8E8E",
    },
  },
});
// Customizable Area End

// Customizable Area Start
export const styles = () => ({
  headerTitle: {
    fontWeight: 550,
    paddingBottom: 10,
  },
  headerSubtitle: {
    fontFamily: 'IBM Plex Sans',
    color: "#006A77",
    fontSize: "14px",
    fontStyle: "italic",
    paddingBottom: 10,
  },
  pathFirstPart: {
    opacity: 0.6,
    color: "#046c79",
    "&:hover":{
      textDecorationLine: 'none'
    }
  },
  listSectionLeftSide: {
    marginTop: "18px",
    background: "#F8F6F1",
    width: "100%",
    borderRadius: "12px",
    height: "513px",
  },
  listSection: {
    marginTop: "18px",
    background: "#F8F6F1",
    width: "100%",
    borderRadius: "12px",
    '& text.recharts-text.recharts-pie-label-text':{
      fill: 'black',
      fontFamily: "IBM Plex Sans",
    },
    "& path.recharts-curve.recharts-pie-label-line ":{
      stroke: "#1C2324",
    }
  },
  listSectionScroll: {
    height: "570px",
    overflow: "auto",
  },
  listHeader: {
    padding: "15px 20px",
    fontSize: "18px",
    fontWeight: 500,
  },
  btnDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1px 25px",
    paddingTop: "12px",
    cursor: "pointer",
    "&:hover": {
      background: "#eee9dd",
    },
  },
  responseText: {
    color: "#006A77",
    fontWeight: 600,
    marginTop: "2px",
    fontSize: "12px",
  },
  dateText: {
    "text-align": "end",
    color: "#8a9394",
    fontSize: "12px",
  },
  deleteBtn: {
    color: "#B3261E",
    width: 0,
    "& svg": {
      fontSize: "22px",
    },
  },
  scrollSection: {
    overflowY: "auto" as const,
    height: "454px" as string,
  },
  w100: {
    width: "100%",
  },
  Survey: {
    backgroundColor: "#F8F6F1",
    padding: "10px 20px",
    borderRadius: "10px 10px 0 0",
    borderBottom: ' 4px solid #f7f2ea',
  },
  TopHeader: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
  },
  heading: {
    fontSize: "18px",
    fontWeight: 500,
    display:' -webkit-box',
    overflow: 'hidden',
    maxWidth: '245px',
    textOverflow: 'ellipsis',
   boxOrient: 'vertical' as const,
    lineClamp: 1 ,
    whiteSpace: 'break-spaces'  as const,
    lineBreak: "anywhere" as const,
  },

  question: {
    backgroundColor: "#F0EBE0",
    padding: "10px 20px",
    marginTop: "20px",
    wordWrap: "break-word" as const,
  },
  description: {
    backgroundColor: "#F8F6F1",
    margin: "10px 20px",
    borderBottom: "1px solid #E4DDC9",
    paddingBottom: "15px",
    wordBreak: "break-word" as const
  },
  loader: {
    "text-align": "center",
    marginTop: "10px",
  },
  alignEnd: {
    "text-align": "end",
  },
  activeSurvey: {
    background: "#eee9dd",
  },
  chartContainer:{
    width: "100%", height: 300,
    '& tspan':{
      fontFamily: "IBM Plex Sans",
    }
  },
  noResult: {
    padding: '12px',
    "text-align": 'center'
  }
});
// Customizable Area End
