import React from 'react'
import { Box, InputBase, Chip, Avatar } from "@material-ui/core";
import { Search, Close } from '@material-ui/icons';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IChat } from "../ChatController";
import TextField from "@material-ui/core/TextField";
interface SearchFieldd {
    isAddGroup: boolean,
    handleParticipant: (selectedParticipant: IChat[]) => void,
    chatList: IChat[],
    searchText: string,
    filterList: (e: any) => void,
    selectedParticipant: any[],
    onInputChange: (e: any, value: string) => void,
    disableOptions?:any
}
export const SearchBar = ({ params, participantLength }: { params: any, participantLength: number }) => {
    return (<TextField className='search-text' {...params} placeholder={!participantLength ? " Type Contact Name" : ""} />)
}

export const CustomTag = (props: any) => {
    const { label } = props;
    const [firstName, lastName] = label.split(' ');
    const initials = `${firstName[0]}${lastName[0]}`;
    return (
        <Chip
        style={webStyles.customChip}
            {...props}
            avatar={
                props.option?.logo ? <Avatar style={{width:"30px",height:"30px"}}  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7xKqBraewH2pFcgcR395hZ9uzw2ttXyN5v2Ay7DA&s"} />
                    :
                <Avatar className='autocompleteAvatart' style={{ textTransform: "uppercase" ,width:"30px",height:"30px",marginLeft:"2px",color:"#fff" }}>
                       {initials}
                </Avatar>   
            }
            deleteIcon={<Close style={webStyles.closeIcon} />}
        />
    );
};
export const SearchField = (props: SearchFieldd) => {
    const {disableOptions=[]} =props
    if (!props.isAddGroup) {
        return (<Box style={webStyles.searchAppBar}>
            <Box sx={webStyles.searchIconContainer}>
                <Search style={webStyles.searchIcon} fontSize="medium" />
            </Box>
            <InputBase
                inputProps={{ "aria-label": "search" }}
                style={webStyles.searchInputNative}
                data-testid="search"
                placeholder="Search..."
                defaultValue={props.searchText}
                onChange={props.filterList}
            />
        </Box>)
    }
    else {
        return (
            <Box style={{ width: "100%" }} className='groupSelection'>
                <Autocomplete
                    data-test-id="Autocomplete"
                    openOnFocus
                    multiple
                    id="add-multiple-tags"
                    options={props.chatList}
                    getOptionLabel={(option: any) => option?.name}
                    getOptionSelected={(option, value) => option?.name === value?.name}
                    getOptionDisabled={(option) =>
                        disableOptions.includes(Number(option?.id))
                      }
                    renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                            <CustomTag
                                key={option.id}
                                label={option.name}
                                option={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params: any) => (
                        <SearchBar params={params}  participantLength={props.selectedParticipant.length} />
                    )}
                    className="participant-auto"
                    value={props.selectedParticipant}
                    onChange={(event, value, reason) => {
                        props.handleParticipant(value)
                    }}
                    onInputChange={props.onInputChange}
                />
            </Box>
        )
    }
}

const webStyles = {
    searchAppBar: {
        background: "#FCFAF7",
        border: "1px solid #F0EBE0",
        fontSize: "1rem",
        borderRadius: "28px",
        display: "flex",
        width: "100%",
        minHeight: "48px"
    },
    searchIconContainer: {
        padding: "0rem 0.9rem",
        paddingInlineEnd: "0.5rem",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    searchIcon: {
        color: "#1C2324",
        opacity: 0.48
    },
    searchInputNative: {
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        padding: 0,
        lineHeight: "1.5rem",
        width: "100%"
    },
    closeIcon:{
        fontSize:"16px !important"
    },
    customChip:{
        backgroundColor: "#F0EBE0",
        fontSize: "16px",
        fontWeight: 400,
        color:"#1C2324"
    }
};