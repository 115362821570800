import React from "react";
import { IOfflineBrowsingContext } from "./OfflineBrowsingProviderController";

// Customizable Area Start
// Customizable Area End

const OfflineBrowsingContext: React.Context<IOfflineBrowsingContext> =
  React.createContext<IOfflineBrowsingContext>({
    // Customizable Area Start
    isConnected: true,
    offlineQueue: [],
    offlineState: {},
    sendNetworkSafeApiRequest: (
      uniqueApiCallId: string,
      method: string,
      endpoint: string,
      headers?: any,
      body?: any
    ) => {},
    setNetworkSafeState: () => {},
    getNetworkSafeState: (key: string) => Promise.resolve(),
    // Customizable Area End
  });

export default OfflineBrowsingContext;