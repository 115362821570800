// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import WeatherIntegration from '../../blocks/WeatherIntegration/src/WeatherIntegration';
import VideoLibrary from '../../blocks/VideoLibrary/src/VideoLibrary';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import OfflineBrowsing from '../../blocks/OfflineBrowsing/src/OfflineBrowsing';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Maps from '../../blocks/maps/src/Maps';
import Download from '../../blocks/Download/src/Download';
import ChatBackuprestore from '../../blocks/ChatBackuprestore/src/ChatBackuprestore';
import AdminConsole2 from '../../blocks/AdminConsole2/src/AdminConsole2';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Videos from '../../blocks/videos/src/Videos';
import BudgetingForecasting from '../../blocks/BudgetingForecasting/src/BudgetingForecasting';
import Geofence from '../../blocks/geofence/src/Geofence';
import GroupChat from '../../blocks/GroupChat/src/GroupChat';
import OfflineWork from '../../blocks/OfflineWork/src/OfflineWork';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ExpenseTracking from '../../blocks/ExpenseTracking/src/ExpenseTracking';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import MapSettings from '../../blocks/MapSettings/src/MapSettings';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import InventoryManagement2 from '../../blocks/InventoryManagement2/src/InventoryManagement2';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ProjectTaskTracking from '../../blocks/ProjectTaskTracking/src/ProjectTaskTracking';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import DownloadableProducts2 from '../../blocks/DownloadableProducts2/src/DownloadableProducts2';
import LanguageSupport from '../../blocks/LanguageSupport/src/LanguageSupport';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Location from '../../blocks/location/src/Location';
import Surveys from '../../blocks/Surveys/src/Surveys';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import Chat9 from '../../blocks/Chat9/src/Chat9';
import LiveChatSummary2 from '../../blocks/LiveChatSummary2/src/LiveChatSummary2';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import VideoEmbeddingYoutube from '../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import CfDbSetup from '../../blocks/CfDbSetup/src/CfDbSetup';
import TaskGroups from '../../blocks/TaskGroups/src/TaskGroups';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import LiveChat2 from '../../blocks/LiveChat2/src/LiveChat2';
import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import TaskList from '../../blocks/TaskList/src/TaskList';
import CallRecording2 from '../../blocks/CallRecording2/src/CallRecording2';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import CameraAccess from '../../blocks/cameraaccess/src/CameraAccess';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import Groups from '../../blocks/Groups/src/Groups';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import Sorting from '../../blocks/sorting/src/Sorting';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Search from '../../blocks/search/src/Search';
import Chat from '../../blocks/Chat9/src/Chat.web';
import ChatView from '../../blocks/Chat9/src/ChatView.web';
import Tasks from '../../blocks/tasks/src/Tasks';
import Task from '../../blocks/tasks/src/Task';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Settings from '../../blocks/Settings/src/Settings';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
// import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import LandingPageAdmin from '../../blocks/dashboard/src/LandingPageAdmin.web';
import EmailLoginOTP from '../../blocks/otp-input-confirmation/src/EmailLoginOTP.web';
import AdminConsoleLandingPage from '../../blocks/AdminConsole/src/AdminConsoleLandingPage.web';
import AdminConsoleProfileScreen from '../../blocks/AdminConsole/src/AdminConsoleProfileScreen.web';
import AdminConsoleUserFarmDiary from '../../blocks/AdminConsole/src/AdminConsoleUserFarmDiary.web';
import AdminConsoleCalendarScreen from '../../blocks/AdminConsole/src/AdminConsoleCalendarScreen.web';
import AdminConsoleAdminUserScreen from '../../blocks/AdminConsole/src/AdminConsoleAdminUserScreen.web';
import AdminConsoleFarmerUserScreen from '../../blocks/AdminConsole/src/AdminConsoleFarmerUserScreen.web';
import AdminConsoleFEUserScreen from '../../blocks/AdminConsole/src/AdminConsoleFEUserScreen.web';
import ViewSuperAdmin from '../../blocks/AdminConsole/src/ViewSuperAdmin.web';
import AdminConsoleUserSurvey from "../../blocks/AdminConsole/src/AdminConsoleUserSurvey.web";
import NewsAndEvents from "../../blocks/AdminConsole2/src/NewsAndEvents.web";
import Dropdown from "../../blocks/AdminConsole/src/DropDown/Dropdown.web"
import SurveysScreen from "../../blocks/Surveys/src/Surveys.web";
import SurveysDetails from "../../blocks/Surveys/src/SurveysDetails.web";


const routeMap = {
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  // PhotoLibrary:{
  //  component:PhotoLibrary,
  // path:"/PhotoLibrary"},

  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Chat: {
    component: Chat,
    path: "/Chat",
    exact: true,
    roles:'Private'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },

  CallRecording2: {
    component: CallRecording2,
    path: '/CallRecording2'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },
  Groups: {
    component: Groups,
    path: '/Groups'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/users',
    roles: 'Private'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Search: {
    component: Search,
    path: '/Search'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  WeatherIntegration: {
    component: WeatherIntegration,
    path: '/WeatherIntegration'
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: '/OfflineBrowsing'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  // OTPInputAuth:{
  //  component:OTPInputAuth,
  // path:"/OTPInputAuth"},
  EmailLoginOTP: {
    component: EmailLoginOTP,
    path: '/EmailLoginOTP'
    // roles:'Public'
  },
  ChatBackuprestore: {
    component: ChatBackuprestore,
    path: '/ChatBackuprestore'
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: '/AdminConsole2'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  BudgetingForecasting: {
    component: BudgetingForecasting,
    path: '/BudgetingForecasting'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  GroupChat: {
    component: GroupChat,
    path: '/GroupChat'
  },
  OfflineWork: {
    component: OfflineWork,
    path: '/OfflineWork'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ExpenseTracking: {
    component: ExpenseTracking,
    path: '/ExpenseTracking'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/notifications',
    exact: true,
    roles: 'Private'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  MapSettings: {
    component: MapSettings,
    path: '/MapSettings'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  InventoryManagement2: {
    component: InventoryManagement2,
    path: '/InventoryManagement2'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ProjectTaskTracking: {
    component: ProjectTaskTracking,
    path: '/ProjectTaskTracking'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  DownloadableProducts2: {
    component: DownloadableProducts2,
    path: '/DownloadableProducts2'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  Chat9: {
    component: Chat9,
    path: "/Chat9",
    
  },
  LiveChatSummary2: {
    component: LiveChatSummary2,
    path: '/LiveChatSummary2'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  CfDbSetup: {
    component: CfDbSetup,
    path: '/CfDbSetup'
  },
  TaskGroups: {
    component: TaskGroups,
    path: '/TaskGroups'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
    // roles:'Public'
  },
  LiveChat2: {
    component: LiveChat2,
    path: '/LiveChat2'
  },
  VideoManagement: {
    component: VideoManagement,
    path: "/materrapedia",
    exact: true,
    roles:'Private'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },

  Home: {
    component: LandingPageAdmin,
    path: '/',
    exact: true
    // roles:'Public'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AdminConsoleLandingPage: {
    component: AdminConsoleLandingPage,
    path: '/home',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleProfileScreen: {
    component: AdminConsoleProfileScreen,
    path: '/profile',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleAdminUserScreen: {
    component: AdminConsoleAdminUserScreen,
    path: '/view-admin-profile/:id',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleFarmerUserScreen: {
    component: AdminConsoleFarmerUserScreen,
    path: '/view-farmer-profile/:id',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleFEUserScreen: {
    component: AdminConsoleFEUserScreen,
    path: '/view-fe-profile/:id',
    exact: true,
    roles: 'Private'
  },
  ViewSuperAdmin:{
    component: ViewSuperAdmin,
    path: '/view-super-admin-profile/:id',
    exact: true,
    roles:'Private'
  },
  AdminConsoleUserFarmDiary: {
    component: AdminConsoleUserFarmDiary,
    path: '/users-farm-diary',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleCalendarScreen: {
    component: AdminConsoleCalendarScreen,
    path: '/calendar',
    exact: true,
    roles: 'Private'
  },
  AdminConsoleUserSurvey: {
    component: AdminConsoleUserSurvey,
    path: '/users-farm-surveys',
    exact: true,
    roles: 'Private'
  },
  NewsAndEvents: {
    component: NewsAndEvents,
    path: '/news-and-events',
    exact: true,
    roles:'Private'
  },
  Dropdowns: {
    component: Dropdown,
    path: '/app-dropdowns',
    exact: true,
    roles:'Private'
  },
  SurveysScreen:{
    component: SurveysScreen,
    path: '/surveys-section',
    exact: true,
    roles:'Private'
  },
  SurveysDetails:{
    component: SurveysDetails,
    path: '/surveys-details/:id',
    exact: true,
    roles:'Private'
  }
};

const firebaseConfig={
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
}

const firebaseAPI = firebase.initializeApp(firebaseConfig);

export const messaging=firebase.messaging(firebaseAPI)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function(registration) {
        console.log('firebase Service Worker registered with scope:', registration.scope);
      })
      .catch(function(error) {
        console.error(' firebase Service Worker registration failed:', error);
      });
  });
}
class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
