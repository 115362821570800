import React, { useState} from "react";
import { Box, Typography, Tooltip} from "@material-ui/core";
import {
    ExpandMore,
    ExpandLess,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import LanguageTabs from "./LanguageTabs.web";


interface AccordionSectionPropsType {
    loadingIndicator:boolean
    handleErrorClear: any
    getSpecificList: any
    errorType: string;
    error: string;
    handleSelectData: any;
    data: any;
    expandTab: boolean;
    handleAccordion: (id: number, keyName: string) => void;
    id: number;
    handleInputChange: any;
    handleAddInput: any;
    handleRemoveInput: any;
    section1InputsEnglish: string[];
    resetSelectionInput: any;
    section2InputsHindi: string[];
    section3InputsGujrati: string[];
    stateList: any[];
    districtList: any[];
    talukaList: any[];
    villageList: any[];
    specificDropDownData: any[]
}



const SubDropdownAccordionSection = (props: AccordionSectionPropsType) => {
    const { loadingIndicator,handleErrorClear, getSpecificList, errorType, error, specificDropDownData, resetSelectionInput, stateList, districtList, talukaList, villageList, handleInputChange, handleAddInput, handleRemoveInput, section1InputsEnglish, section2InputsHindi, section3InputsGujrati, id, data, expandTab, handleAccordion, handleSelectData } = props;
    const classes = useStyles(expandTab);
    const keyName: any = Object.keys(data)[0]
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Box className={classes.root} key={id} >
                <Box className={classes.boxContainer}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent event propagation
                        setLoading(true)
                        handleAccordion(id, keyName);
                    }}>
                    <Box className={classes.between} >
                        <Tooltip title={""}>
                            <Typography
                                className={classes.fontStyles}
                            >

                                {keyName}
                            </Typography>
                        </Tooltip>
                        {expandTab ? (
                            <ExpandLess
                                className={classes.expandBtn}
                                id="expandMore"

                            />
                        ) : (
                            <ExpandMore
                                className={classes.expandBtn}
                                id="expandLess"

                            />
                        )}
                    </Box>
                </Box>
                {expandTab &&
                    <LanguageTabs
                        loading={loading}
                        loadingIndicator={loadingIndicator}
                        setLoading={setLoading}
                        handleErrorClear={handleErrorClear}
                        getSpecificList={getSpecificList}
                        error={error}
                        errorType={errorType}
                        specificDropDownData={specificDropDownData}
                        handleSelectData={handleSelectData}
                        handleInputChange={handleInputChange}
                        handleAddInput={handleAddInput}
                        handleRemoveInput={handleRemoveInput}
                        section1InputsEnglish={section1InputsEnglish}
                        resetSelectionInput={resetSelectionInput}
                        section2InputsHindi={section2InputsHindi}
                        section3InputsGujrati={section3InputsGujrati}
                        stateList={stateList}
                        districtList={districtList}
                        talukaList={talukaList}
                        villageList={villageList}
                        keyName={keyName}
                    />
                }

            </Box>
        </>
    );
};

//style
const useStyles = makeStyles({
    "@media (min-width: 1100px) and (max-width: 1199px)": {
        tabName: {
            fontSize: "10px !important"
        },

    },
    "@media (min-width: 1200px)": {
        tabName: {
            fontSize: "12px !important"
        },

    },
    "@media (min-width: 960px) and (max-width: 1099px)": {
        tabName: {
            fontSize: "7px !important"
        },
        fontStyles: {
            fontSize: '14px !important',
        },
    },
    select_formcontrol: {
        width: "86%",
        alignSelf: "center"
    },
    formcontrol_container: {
        display: "flex",
        flexDirection: "column",
        width: "88%"
    },
    upload_container: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    opcity_48: {
        color: "#1C2324 !important",
    },
    individualList: {
        gap: "1.2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    individualButton: {
        borderRadius: "28px",
        fontWeight: 700,
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        background: "#DF5200",
        fontSize: "18px",
        color: "#fff",
        padding: "14px 32px",
        width: "100%",
        height: "40px",
        marginTop: "7%"
    },
    individualWholeList: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    modalTextField: {
        width: "100%",
        borderRadius: "0.75rem",
        paddingBlock: "1rem",
        paddingInline: "1.2rem",
        marginTop: "8px",
        fontSize: "1rem",
        resize: "none",
        backgroundColor: '#FCFAF7',
        border: "1px solid #F0EBE0",
        '::placeholder': {
            color: "red",
            fontWeight: 300
        },
    },
    customTab: {
        minWidth: 50,
    },
    tabName: {
        fontSize: "0.875rem"
    },
    selectedTab: {
        color: '#DF5200', // Set the text color for the selected tab
    },
    customIndicator: {
        backgroundColor: '#DF5200', // Set the indicator color here
    },
    "@media (max-width: 767px)": {
        root: {
            padding: "1rem 0.6rem !important",
        },

    },
    root: {
        display: "flex",
        padding: "1rem 0.8rem",
        background: "#F8F6F1",
        borderRadius: "12px",
        marginBottom: ".5rem",
        position: "relative",
        transition: "all 2s",
        alignItems: "center",
        flexDirection: (isOpen) => (isOpen ? "column" : "row"),
    },
    rootSub: {
        display: "flex",
        borderRadius: "12px",
        marginBottom: ".2rem",
        position: "relative",
        transition: "all 2s",
        width: "100%",
        flexDirection: (isOpen) => (isOpen ? "column" : "row"),
    },
    subDropdownTitle: {
        color: "#1C2324",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22px"
    },
    subDropdownSubTitle: {
        color: "#006A77",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "18px"
    },
    subIconContainer: {
        height: "20px",
        width: "20px"
    },
    container: {
        flexGrow: (isOpen) => (isOpen ? 0 : 0.1),
    },
    boxContainer: {
        width: "100%",
        padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
    },
    boxContainerSub: {
        width: "98%",
        padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
    },
    img: {
        borderRadius: "6px",
        transition: "all 0.2s",
        width: (isOpen) => (isOpen ? "100%" : "82px"),
        height: (isOpen) => (isOpen ? "180px" : "82px"),
    },
    between: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: 'center'
    },
    fontStyles: {
        fontFamily: "IBM Plex Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#1C2324",
        margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    },
    dateContainer: {
        color: "#006A77",
        fontSize: "14px",
        padding: ".4rem 0",
    },
    dateIcon: {
        color: "#006A77",
        fontSize: "14px",
        marginRight: "5px",
    },
    collapseCardLoc: {
        fontSize: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        width: "60px",
        height: '20px',
    },
    font15: {
        fontSize: "15px",
    },
    writerSection: {
        color: '#108B88',
    },
    btnContainer: {
        display: "flex",
        flexGrow: 0.1,
        justifyContent: "space-between",
        alignItem: "center",
        gap: "8px",
    },
    shareBtn: {
        color: "#006A77",
        fontSize: "18px",
    },
    editBtn: { color: "#EB8F06", fontSize: "18px" },
    deleteBtn: { color: "#B3261E", fontSize: "18px" },
    expandBtn: {
        position: "absolute",
        right: "10px",
    },
    expandSubBtn: {

        fontSize: "14px",
    },
    dividerAccordion: {
        marginBottom: "4px",
        marginTop: "10px",
        height: "2px",
        background: "#F0EBE0",
        width: "102%",
    },
    expandSubParent: {
        position: "absolute",
        right: "10px",
        width: "25px",
        height: "25px",
        backgroundColor: "#E4DDC9",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    location: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: "rgba(28, 35, 36, 0.8)",
        width: '100%',
        marginBottom: '8px',
    },
    boxIconsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    },
    boxIcons: {
        display: "flex",
        alignItems: "center",
    },
    fontSmall: {
        fontSize: "13px",
        color: '#006A77',
    },
    locationTextOverflow: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "13px",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        width: "60px",
        height: '20px',
        color: '#1C2324',
    },
    whiteColor: {
        color: "#fff",
    },
    ellipsis: {
        overflow: "hidden",
        lineClamp: 1,
        boxOrient: "vertical",
        maxWidth: "80%",
        display: "-webkit-inline-box",
    },
    btnSection: {
        display: "flex",
        float: "right",
        gap: '10px',
        paddingTop: '7px',
        marginRight: '10px',
    },
    locationExpand: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    font18: {
        fontSize: "14px",
    },
    fw500: {
        fontWeight: 500,
    },
    dateStyleClose: {
        color: "#1C2324",
        fontWeight: 450,
        fontSize: "12px",
    },
    ellipsisNews: {
        fontWeight: 400,
        fontSize: "13px",
        // height: "64px",
        overflow: "hidden",
        maxWidth: "80%",
        textOverflow: "ellipsis",
        boxOrient: "vertical",
        lineClamp: 3,
        color: "#1c2324c7"
    },

});


export default SubDropdownAccordionSection;
