import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
// Customizable Area Start
import moment from "moment";
import { debounce, getBase64, SchemaAdminFormADD, SchemaAdminFormEDIT } from "../../../components/src/helper";
const userLocation = localStorage.getItem('userLocationFarmMapping');
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    TokenValue: null | string;
    loggedInUserRole: string | null;
    isLoggedUserAdmin: boolean;
    // SuperAdmin States
    superAdminId: string;
    currentSuperAdminLoading: boolean;
    currentSuperAdminData: any;
    currentSuperAdminID: any;
    setAdminRole: string;

    // Admin Table States
    adminTableDataLoading: boolean;
    adminTableData: any[];
    adminTablePageSize: number;
    adminTableCurrentPage: number;
    searchAdminInputValue: string;
    adminRowData: any;


    // Delete Popup States
    openDeletePopup: boolean;
    deleteAdminUserData: any;
    openSuperAdminDeletePopup: boolean;
    // Error Popup States
    errorPopupOpen: boolean;
    errorPopupMsg: string;

    // View States
    totalAdminsCount: any;
    totalFECount: any;
    totalSearchedCount: any;

    // Add User Modal
    addUserModal: boolean;
    editUserModal: boolean;
    seletedUserTypeForm: string;

    // farmer form
    farmerInitialValues: any;
    farmImageFile: any;
    farmImageURL: any;
    userLandTypeFarmer: any;
    selectedFEformData: any;
    getEducationFarmerData: any[];
    getStateData: any[];
    getDistrictData: any[];
    getTalukaData: any[];
    getVillageData: any[];
    getOwnerListFarmerData: any[];
    getUnitOfMeasureFarmerData: any[];
    DistanceInData: any[];
    getLandTypeFarmerData: any[];
    getSoilTextureFarmerData: any[];
    getSourceOfIrrigationData: any[];
    getTypeIrrigationListingData: any[];
    FElistData: any[];
    // fe form
    feInitialValues: any;
    selectedLocationArr: any[];
    getMobileDeviceModelFEData: any[];
    AllVillageListData: any[];


    // admin form
    adminInitialValues: any;
    showEyeIconPassword: boolean;
    showEyeIconConfirmPassword: boolean;
    successAddAdmin: any;
    successUpdateAdmin: any;
    successAddSuperAdmin: any;
    successUpdateSuperAdmin: any;

    // VILLAGE FILTER INTERFACE STARTS
    isVillageFilterOpen: boolean;
    anchorElVillageFilter: any;
    expanded: any;
    initialStates: any[];
    initalDistricts: any[];
    initialTalukas: any[];
    initialVillages: any[];
    selectedState: any;
    selectedDistrict: any;
    selectedTaluka: any;
    selectedVillage: any;
    stateInputValue: string;
    districtInputValue: string;
    talukaInputValue: string;
    villageInputValue: string;
    filteredStates: any[];
    filteredDistricts: any[];
    filteredTalukas: any[];
    filteredVillages: any[];
    isDisabled: any;
    isAccordianError: any;
    deletedVillagesList: any[];
    defaultAssignedVillages: any[];
    // VILLAGE FILTER INTERFACE ENDS
    successModalOpen: boolean;
    successModalMsg: string;
    // LOADING BTN INTERFACE
    addFarmerLoading: boolean;
    addFELoading: boolean;
    addAdminLoading: boolean;
    addSALoading: boolean;
    updateAdminLoading: boolean;
    updateSALoading: boolean;
    deleteAdminLoading: boolean;
    deleteSALoading: boolean;
    cropImageModal: boolean;
    croppedImage: any;
    superAdminProfileImage: { icon: string };
    // CROP-MAPPING
    OpenFarmMapping: boolean;
    latlngHash: any;
    farmMappingURL: any;
    coordinates: any[];
    markerDefault: any;
    farmMappingFile: any;
    tempCoordinates: any[];
    userLocation: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class ViewSuperAdminController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getSuperAdminByIdApiCallId: string = "";
    getAdminsDataApiCallId: string = "";
    deleteAdminUserDataApiCallId: string = "";
    addAdminApiCallId: string = "";
    updateAdminApiCallId: string = "";
    addSuperAdminApiCallId: string = "";
    updateSuperAdminApiCallId: string = "";
    deleteSuperAdminApiCallId: string = "";
    addFarmerApiCallId: string = "";
    getEducationFarmerApiCallId: string = "";
    getStateApiCallId: string = "";
    getDistrictApiCallId: string = "";
    getTalukaApiCallId: string = "";
    getVillageApiCallId: string = "";
    getOwnerListFarmerApiCallId: string = "";
    getUnitOfMeasureFarmerApiCallId: string = "";
    getDistanceInApiCallId: string = "";
    getSoilTextureFarmerApiCallId: string = "";
    getLandTypeFarmerApiCallId: string = "";
    getSourceOfIrrigationFarmerApiCallId: string = "";
    getTypeIrrigationListingApiCallId: string = "";
    getFElistApiCallId: string = "";
    getMobileDeviceModelFEApiCallId: string = "";
    getAllVillageListApiCallId: string = "";
    addFEApiCallId: string = "";

    // ASSIGN VILLAGE FILTER STARTS
    getVillageFilterVillageData: string = "";
    getVillageFilterTalukaData: string = "";
    getVillageFilterDistrictData: string = "";
    getVillageFilterStateData: string = "";
    // ASSIGN VILLAGE FILTER ENDS
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            TokenValue: localStorage.getItem("tokenn"),
            loggedInUserRole: localStorage.getItem("user_role"),
            isLoggedUserAdmin: false,
            // SuperAdmin States
            superAdminId: "",
            currentSuperAdminLoading: false,
            currentSuperAdminData: {
                first_name: "",
                last_name: "",
                dob: null,
            },
            currentSuperAdminID: "",
            setAdminRole: "super_admin",
            // Admin Table States
            adminTableDataLoading: true,
            adminTableData: [],
            adminTablePageSize: 15,
            adminTableCurrentPage: 0,
            searchAdminInputValue: "",
            adminRowData: null,


            // Delete Popup States
            openDeletePopup: false,
            deleteAdminUserData: null,
            openSuperAdminDeletePopup: false,
            // Error Popup States
            errorPopupOpen: false,
            errorPopupMsg: "",

            // View States
            totalAdminsCount: 0,
            totalFECount: 0,
            totalSearchedCount: 0,
            // Add User Modal
            addUserModal: false,
            editUserModal: false,
            seletedUserTypeForm: "Farmer",

            // farmer form
            farmerInitialValues: {
                typeOfIrrigationFarmer: "",
                sourceOfIrrigationFarmer: "",
                landTypeFarmer: "",
                soilTextureFarmer: "",
                depthOfPumpFarmer: "",
                motorInHpFarmer: "",
                distanceInFarmer: "",
                horizontalDistanceFarmer: "",
                unitOfMeasureFarmer: "",
                totalFarmAreaFarmer: "",
                ownershipFarmer: "",
                farmSurveyNumberFarmer: "",
                farmnameFarmer: "",
                villageFarmer: "",
                talukaFarmer: "",
                districtFarmer: "",
                genderFarmer: "",
                stateFarmer: "",
                educationFarmer: "",
                totalFamilyMemberFarmer: "",
                dateOfBirthFarmer: null,
                adharcardNumberFarmer: "",
                mobileNumberFarmer: "",
                surnameFarmer: "",
                middlenameFarmer: "",
                firstnameFarmer: "",
            },
            farmImageFile: null,
            farmImageURL: null,
            userLandTypeFarmer: null,
            selectedFEformData: null,
            getEducationFarmerData: [],
            getStateData: [],
            getDistrictData: [],
            getTalukaData: [],
            getVillageData: [],
            getOwnerListFarmerData: [],
            getUnitOfMeasureFarmerData: [],
            DistanceInData: [],
            getLandTypeFarmerData: [],
            getSoilTextureFarmerData: [],
            getSourceOfIrrigationData: [],
            getTypeIrrigationListingData: [],
            FElistData: [],
            // fe form
            feInitialValues: {
                firstnameFE: "",
                lastnameFE: "",
                mobileNumberFE: "",
                locationFE: "",
                dateOfBirthFE: null,
                emailAddressFE: "",
                usernameFE: "",
                mobileDeviceModelFE: "",
            },
            selectedLocationArr: [],

            getMobileDeviceModelFEData: [],
            AllVillageListData: [],

            // admin form
            adminInitialValues: {
                firstnameAdmin: "",
                lastnameAdmin: "",
                mobileNumberAdmin: "",
                emailAddressAdmin: "",
                usernameAdmin: "",
                dateOfBirthAdmin: null,
                passwordAdmin: null,
                confirmPasswordAdmin: null,
            },
            showEyeIconPassword: true,
            showEyeIconConfirmPassword: true,
            successAddAdmin: null,
            successUpdateAdmin: null,
            successAddSuperAdmin: null,
            successUpdateSuperAdmin: null,

            isVillageFilterOpen: false,
            anchorElVillageFilter: null,
            // Village Filter states Starts
            expanded: false,
            initialStates: [],
            initalDistricts: [],
            initialTalukas: [],
            initialVillages: [],
            selectedState: {
                name: "Select State",
                id: "",
            },
            selectedDistrict: {
                name: "Select District",
                id: "",
            },
            selectedTaluka: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillage: {
                name: "Select Village",
                id: "",
            },
            stateInputValue: "",
            districtInputValue: "",
            talukaInputValue: "",
            villageInputValue: "",
            filteredStates: [],
            filteredDistricts: [],
            filteredTalukas: [],
            filteredVillages: [],
            isAccordianError: {
                state: false,
                district: false,
                taluka: false,
                village: false,
            },
            isDisabled: {
                state: false,
                district: true,
                taluka: true,
                village: true,
            },
            deletedVillagesList: [],
            defaultAssignedVillages: [],
            // Village Filter states Ends
            successModalOpen: false,
            successModalMsg: "",
            // LOADING BTN INTERFACE
            addFarmerLoading: false,
            addFELoading: false,
            addAdminLoading: false,
            addSALoading: false,
            updateAdminLoading: false,
            updateSALoading: false,
            deleteAdminLoading: false,
            deleteSALoading: false,
            cropImageModal: false,
            croppedImage: "",
            superAdminProfileImage: { icon: "" },
            // CROP-MAPPING
            OpenFarmMapping: false,
            latlngHash: null,
            farmMappingURL: null,
            coordinates: [],
            markerDefault: null,
            farmMappingFile: null,
            tempCoordinates: [],
            userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                this.setFilterAPIResponses(apiRequestCallId, responseJson);
                switch (apiRequestCallId) {
                    case this.getSuperAdminByIdApiCallId:
                        this.getSuperAdminByIdApiCallIdResp(responseJson);
                        break;
                    case this.getAdminsDataApiCallId:
                        this.getAdminsDataApiCallIdResp(responseJson);
                        break;
                    case this.deleteAdminUserDataApiCallId:
                        this.deleteAdminUserDataApiCallIdResp(responseJson)
                        break;
                    case this.addAdminApiCallId:
                        this.addAdminApiCallIdResp(responseJson);
                        break;
                    case this.updateAdminApiCallId:
                        this.updateAdminApiCallIdResp(responseJson);
                        break;
                    case this.addSuperAdminApiCallId:
                        this.addSuperAdminApiCallIdResp(responseJson);
                        break;
                    case this.updateSuperAdminApiCallId:
                        this.updateSuperAdminApiCallIdResp(responseJson);
                        break;
                    case this.deleteSuperAdminApiCallId:
                        this.deleteSuperAdminApiCallIdResp(responseJson);
                        break;
                    case this.addFarmerApiCallId:
                        this.addFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getEducationFarmerApiCallId:
                        this.getEducationFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getStateApiCallId:
                        this.getStateApiCallIdResp(responseJson);
                        break;
                    case this.getDistrictApiCallId:
                        this.getDistrictApiCallIdResp(responseJson);
                        break;
                    case this.getTalukaApiCallId:
                        this.getTalukaApiCallIdResp(responseJson);
                        break;
                    case this.getVillageApiCallId:
                        this.getVillageApiCallIdResp(responseJson);
                        break;
                    case this.getOwnerListFarmerApiCallId:
                        this.getOwnerListFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getUnitOfMeasureFarmerApiCallId:
                        this.getUnitOfMeasureFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getDistanceInApiCallId:
                        this.getDistanceInApiCallIdResp(responseJson);
                        break;
                    case this.getSoilTextureFarmerApiCallId:
                        this.getSoilTextureFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getLandTypeFarmerApiCallId:
                        this.getLandTypeFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getSourceOfIrrigationFarmerApiCallId:
                        this.getSourceOfIrrigationFarmerApiCallIdResp(responseJson);
                        break;
                    case this.getTypeIrrigationListingApiCallId:
                        this.getTypeIrrigationListingApiCallIdResp(responseJson);
                        break;
                    case this.getFElistApiCallId:
                        this.getFElistApiCallIdResp(responseJson);
                        break;
                    case this.getAllVillageListApiCallId:
                        this.getAllVillageListApiCallIdResp(responseJson);
                        break;
                    case this.getMobileDeviceModelFEApiCallId:
                        this.getMobileDeviceModelFEApiCallIdResp(responseJson);
                        break;
                    case this.addFEApiCallId:
                        this.addFEApiCallIdResp(responseJson);
                        break;

                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleCloseCropModalSuperAdmin = () => {
        this.setState({ cropImageModal: false })
    }

    handleSubmitSAProgfileImage = (cropperRef: any) => {
        let croppedSAImage = cropperRef.current?.cropper.getCroppedCanvas();
        croppedSAImage.toBlob((blob: any) => {
          const file = new File([blob], "SAProfile.png", { type: 'image/png' });
          this.setState({ croppedImage: file, cropImageModal: false }, () => {
            this.updateSuperAdminAvatar(file);
          })
        });
      }

    handleSuperAdminProfileImageUpload = async (event: any) => {
        event.persist();
        const selectedFile = event.currentTarget.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            this.setState(prev => {
                return { superAdminProfileImage: { icon: reader.result as string }, cropImageModal: true }
            });
        };
        reader.readAsDataURL(selectedFile);
        if (event.currentTarget) {
            event.currentTarget.value = '';
        }
    }

    onProfileImageUpload = (event: any) => {
        event.currentTarget.files.length && this.handleSuperAdminProfileImageUpload(event)
    }

    updateSuperAdminAvatar = async (avatar: any) => {
        this.setState({ currentSuperAdminLoading: true })
        const superAdminId = window.location.pathname.split("/")[2];

        const header = {
            token: this.state.TokenValue,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateSuperAdminApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}/${superAdminId}?role_super_admin=true`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        let reqFormdata = new FormData();

        reqFormdata.append("data[attributes][avatar]", avatar);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            reqFormdata
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    setFilterAPIResponses = (apiRequestCallId: any, responseJson: any) => {
        switch (apiRequestCallId) {
            case this.getVillageFilterVillageData:
                this.getVillageFilterVillageDataRes(responseJson);
                break;
            case this.getVillageFilterTalukaData:
                this.getVillageFilterTalukaDataRes(responseJson);
                break;
            case this.getVillageFilterDistrictData:
                this.getVillageFilterDistrictDataRes(responseJson);
                break;
            case this.getVillageFilterStateData:
                this.getVillageFilterStateDataRes(responseJson);
                break;
        }
    };

    getSuperAdminByIdApiCallIdResp = (responseJson: any) => {
        if (responseJson && Boolean(!responseJson?.errors?.length)) {
            this.setState({
                currentSuperAdminLoading: false,
                currentSuperAdminData: responseJson.data?.attributes,
                currentSuperAdminID: responseJson.data,
                totalAdminsCount: responseJson?.meta?.total_number_of_admins,
                totalFECount: responseJson?.meta?.total_number_of_field_executives,
            });
        } else {
            window.location.replace("/users");
            this.setState({ currentSuperAdminLoading: false });
            this.setState({
                currentSuperAdminData: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    dob: null,
                    phone_number: "",
                    user_name: ""
                },
                currentSuperAdminID: "",

            });
        }

    };
    getAdminsDataApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null) {
            if (responseJson.data) {
                this.setState({ adminTableDataLoading: false });
                this.setState({
                    adminTableData: responseJson.data,
                    totalSearchedCount: responseJson?.meta?.total_searched_count,
                });
            } else {
                this.setState({ adminTableDataLoading: false });
                this.setState({ adminTableData: [] });
            }
        }
    };

    deleteAdminUserDataApiCallIdResp = (responseJson: any) => {
        if (responseJson?.account_user) {
            this.handleDeletePopupClose()
            this.fetchAdminTableData()
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.DELETE_ADMIN,
            })
            this.setState({ deleteAdminLoading: false });
        } else if (responseJson?.data?.base) {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: responseJson?.data?.base[0],
            });
            this.setState({ deleteAdminLoading: false });
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
            });
            this.setState({ deleteAdminLoading: false });
        }
    }
    addAdminApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            this.setState({ successAddAdmin: responseJson?.data, addUserModal: false, editUserModal: false });
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.ADD_ADMIN,
            })
            this.setState({ addAdminLoading: false })
        } else if (responseJson?.errors) {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: responseJson.errors[0],
            });
            this.setState({ addAdminLoading: false })
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
            });
            this.setState({ addAdminLoading: false })
        }

    };
    updateAdminApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            if (responseJson.data) {
                this.setState({ successUpdateAdmin: responseJson.data });
                this.handleAddUserModalClose()
                this.setState({
                    successModalOpen: true,
                    successModalMsg: configJSON.SUCCESSMSG.UPDATE_ADMIN,
                })
                this.setState({ updateAdminLoading: false })
            }
        } else if (responseJson?.errors) {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: responseJson.errors[0],
            });
            this.setState({ updateAdminLoading: false })
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
            });
            this.setState({ updateAdminLoading: false })

        }

    };

    addSuperAdminApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            this.setState({
                successAddSuperAdmin: responseJson.data,
            });
            this.handleAddUserModalClose()
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.ADD_SA,
            })
            this.setState({ addSALoading: false })
        } else {
            if (responseJson?.errors) {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: responseJson.errors[0],
                });
                this.setState({ addSALoading: false })
            } else {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
                });
                this.setState({ addSALoading: false })
            }
        }
    };
    updateSuperAdminApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            if (responseJson.data) {
                this.setState({
                    successUpdateSuperAdmin: responseJson.data,
                });
                this.handleAddUserModalClose()
                this.setState({
                    successModalOpen: true,
                    successModalMsg: configJSON.SUCCESSMSG.UPDATE_SA,
                })
                this.setState({ updateSALoading: false })
            }
        } else {
            if (responseJson?.errors) {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: responseJson.errors[0],
                });
                this.setState({ updateSALoading: false })
            } else {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
                });
                this.setState({ updateSALoading: false })
            }
        }
    };
    deleteSuperAdminApiCallIdResp = (responseJson: any) => {
        if (responseJson?.account_user) {
            this.setState({ deleteSALoading: false });
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.DELETE_SA,
            })
            this.handleDeletePopupClose()
            window.location.replace("/users")
        } else if (responseJson?.data?.base) {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: responseJson?.data?.base[0],
            });
            this.setState({ deleteSALoading: false });
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
            });
            this.setState({ deleteSALoading: false });
        }
    };

    addFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            this.handleAddUserModalClose()
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.ADD_FARMER,
            })
            this.setState({ addFarmerLoading: false })
        } else {
            if (responseJson?.errors) {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: responseJson.errors[0],
                });
                this.setState({ addFarmerLoading: false })
            } else {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
                });
                this.setState({ addFarmerLoading: false })
            }
        }
    };
    getEducationFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getEducationFarmerData: responseJson });
        } else {
            this.setState({ getEducationFarmerData: [] });
        }
    };
    getStateApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                getStateData: responseJson,
            });
        } else {
            this.setState({ getStateData: [] });
        }
    };
    getDistrictApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getDistrictData: responseJson });
        } else {
            this.setState({ getDistrictData: [] });
        }
    };
    getTalukaApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getTalukaData: responseJson });
        } else {
            this.setState({ getTalukaData: [] });
        }
    };
    getVillageApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getVillageData: responseJson });
        } else {
            this.setState({ getVillageData: [] });
        }
    };
    getOwnerListFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getOwnerListFarmerData: responseJson });
        } else {
            this.setState({ getOwnerListFarmerData: [] });
        }
    };
    getUnitOfMeasureFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getUnitOfMeasureFarmerData: responseJson });
        } else {
            this.setState({ getUnitOfMeasureFarmerData: [] });
        }
    };
    getDistanceInApiCallIdResp = (responseJson: any[]) => {
        if (responseJson?.length) {
            this.setState({ DistanceInData: responseJson });
        } else {
            this.setState({ DistanceInData: [] });
        }
    };
    getSoilTextureFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getSoilTextureFarmerData: responseJson });
        } else {
            this.setState({ getSoilTextureFarmerData: [] });
        }
    };
    getLandTypeFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getLandTypeFarmerData: responseJson });
        } else {
            this.setState({ getLandTypeFarmerData: [] });
        }
    };
    getSourceOfIrrigationFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getSourceOfIrrigationData: responseJson });
        } else {
            this.setState({ getSourceOfIrrigationData: [] });
        }
    };
    getTypeIrrigationListingApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getTypeIrrigationListingData: responseJson });
        } else {
            this.setState({ getTypeIrrigationListingData: [] });
        }
    };
    getFElistApiCallIdResp = (responseJson: any[]) => {
        if (responseJson?.length) {
            this.setState({ FElistData: responseJson });
        } else {
            this.setState({ FElistData: [] });
        }
    };

    getMobileDeviceModelFEApiCallIdResp = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({ getMobileDeviceModelFEData: responseJson });
        } else {
            this.setState({ getMobileDeviceModelFEData: [] });
        }
    };
    getAllVillageListApiCallIdResp = (responseJson: any[]) => {
        if (responseJson?.length) {
            this.setState({ AllVillageListData: responseJson });
        } else {
            this.setState({ AllVillageListData: [] });
        }
    };
    addFEApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && !responseJson.errors?.length) {
            this.handleAddUserModalClose()
            this.setState({
                successModalOpen: true,
                successModalMsg: configJSON.SUCCESSMSG.ADD_FE,
            })
            this.setState({ addFELoading: false })
        } else {
            if (responseJson?.errors) {
                let newMsg = "";
                let errorMsg: string = responseJson.errors[0];
                const rmvTxt = "Accounts villages village";
                if (responseJson?.errors[0].includes(rmvTxt)) {
                    newMsg = errorMsg.replace(rmvTxt, "");
                } else {
                    newMsg = errorMsg;
                }
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: newMsg,
                });
                this.setState({ addFELoading: false })
            } else {
                this.setState({
                    errorPopupOpen: true,
                    errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
                });
                this.setState({ addFELoading: false })
            }
        }
    };

    // ASSIGN-VILLAGE FILTER RESPONSE FUNCTION STARTS

    getVillageFilterVillageDataRes = (respJson: any[]) => {
        if (respJson) {
            let villageList: any[] = [];

            if (respJson?.length > 0) {
                if (this.state.selectedLocationArr.length > 0) {
                    villageList = respJson.filter((el) => {
                        return !this.state.selectedLocationArr.find((element) => {
                            return element.id === el.id;
                        });
                    });
                } else {
                    villageList = respJson;
                }
                this.setState({
                    initialVillages: villageList,
                    filteredVillages: villageList,
                    isDisabled: {
                        ...this.state.isDisabled,
                        village: false,
                    },
                });
            } else {
                this.setState({
                    initialVillages: [],
                    filteredVillages: [],
                    isDisabled: {
                        ...this.state.isDisabled,
                        village: false,
                    },
                });
            }
        }
    };
    getVillageFilterTalukaDataRes = (respJson: any[]) => {
        if (respJson?.length) {
            this.setState({
                initialTalukas: respJson,
                filteredTalukas: respJson,
                isDisabled: {
                    ...this.state.isDisabled,
                    taluka: false,
                },
            });
        } else {
            this.setState({
                initialTalukas: [],
                filteredTalukas: [],
                isDisabled: {
                    ...this.state.isDisabled,
                    taluka: false,
                },
            });
        }
    };
    getVillageFilterDistrictDataRes = (respJson: any[]) => {
        if (respJson?.length) {
            this.setState({
                initalDistricts: respJson,
                filteredDistricts: respJson,
                isDisabled: {
                    ...this.state.isDisabled,
                    district: false,
                },
            });
        } else {
            this.setState({
                initalDistricts: [],
                filteredDistricts: [],
                isDisabled: {
                    ...this.state.isDisabled,
                    district: false,
                },
            });
        }
    };
    getVillageFilterStateDataRes = (respJson: any[]) => {
        if (respJson?.length) {
            this.setState({
                initialStates: respJson,
                filteredStates: respJson,
            });
        } else {
            this.setState({
                initialStates: [],
                filteredStates: [],
            });
        }
    };
    // ASSIGN-VILLAGE FILTER RESPONSE FUNCTION ENDS
    // HANDLE FARM-MAPPING FUNCTION
    onSaveFarmMapping = (latlngHash: any,
        file: any,
        url: string,
        savedCoordinates: any[]) => {
        this.setState({
            latlngHash: latlngHash,
            OpenFarmMapping: false, farmMappingURL: url,
            farmMappingFile: file, tempCoordinates: savedCoordinates
        })
    }
    closeFarmMappingModal = () => { 
        this.setState({ OpenFarmMapping: false }) 
    }

    getCurrentLocation = () => {
        const success = (pos: any) => {
            this.setState({
                
                userLocation: {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                }

            }, () => {
                localStorage.setItem('userLocationFarmMapping', JSON.stringify(this.state.userLocation))
            })
        }
        const error = (error: any) => {
            this.setState({ userLocation: null })
        }
        if (navigator && navigator.geolocation) 
        {
            navigator.geolocation.getCurrentPosition(success, error);
        }
    }

    async componentDidMount(): Promise<void> {
        const superAdminId = window.location.pathname.split("/")[2];
        this.setUserAdminRole();
        this.fetchSuperAdminById(superAdminId);
        this.fetchAdminTableData();
        // farmer form
        this.fetchEducationFarmer()
        this.fetchStates();
        this.fetchUnitFarmAreaUrlData();
        this.fetchOwnerListFarmer();
        this.fetchDistnaceInData();
        this.fetchLandTypeFarmer();
        this.fetchSoilTextureFarmer();
        this.fetchSourceOfIrrigationFarmer();
        this.fetchTypeIrrigation();
        this.fetchFElist();
        // fe form
        this.fetchMobileDeviceModelFE();
        this.fetchAllVillageList()
        this.getStates();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        const newSuperAdminId = window.location.pathname.split("/")[2];

        if (prevState.successAddAdmin !== this.state.successAddAdmin ||
            prevState.successUpdateAdmin !== this.state.successUpdateAdmin ||
            prevState.adminTablePageSize !== this.state.adminTablePageSize ||
            prevState.adminTableCurrentPage !== this.state.adminTableCurrentPage
        ) {
            this.fetchAdminTableData();
        }
        if (prevState.successUpdateSuperAdmin !== this.state.successUpdateSuperAdmin) {
            this.fetchSuperAdminById(newSuperAdminId);
        }
    }

    // HANDLE FUNCTIONS STARTS
    setUserAdminRole = () => {
        if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.ADMIN) {
            this.setState({ isLoggedUserAdmin: true });
        } else if (atob(String(this.state.loggedInUserRole)) === configJSON.USERROLES.SUPER_ADMIN) {
            this.setState({ isLoggedUserAdmin: false });
        } else {
            this.setState({ isLoggedUserAdmin: true });
        }
    };
    debouncedSearchTable = debounce(() => this.fetchAdminTableData(), 500);

    getErrorBoolean = (touched: any, errors: any, value: string) => {
        return touched[value] && Boolean(errors[value]);
    };
    getHelperText = (touched: any, errors: any, value: string) => {
        return touched[value] && errors[value];
    };
    getPasswordType = () => {
        return this.state.showEyeIconPassword ? "password" : "text";
    };
    getConfirmPasswordType = () => {
        return this.state.showEyeIconConfirmPassword ? "password" : "text";
    };
    getSubmitFormBtnTxt = () => {
        const updateBtnTxt = this.state.updateAdminLoading || this.state.updateSALoading ? "Updating..." : "Update User"
        const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
        return (this.state.editUserModal === true ? updateBtnTxt : addBtnTxt)
    };
    getAddUserModalTitleTxt = () => {
        return this.state.editUserModal === true ? "Edit User" : "Add New User";
    };
    handleAddUserModalOpen = () => {
        this.setState({
            addUserModal: true,
            editUserModal: false,
            // CROP-MAPPING
            OpenFarmMapping: false,
            latlngHash: null,
            farmMappingURL: null,
            coordinates: [],
            markerDefault: null,
            farmMappingFile: null,
            tempCoordinates: [],
        })
    }

    handleAddUserModalClose = () => {
        this.setState({
            addUserModal: false,
            seletedUserTypeForm: "Farmer",
            // CROP-MAPPING
            OpenFarmMapping: false,
            latlngHash: null,
            farmMappingURL: null,
            coordinates: [],
            markerDefault: null,
            farmMappingFile: null,
            tempCoordinates: [],
        })
        this.resetFarmerFormValues()
        this.resetFEFormValues()
        this.resetAdminFormValues()
    }

    resetFarmerFormValues = () => {
        this.setState({
            farmerInitialValues: {

                mobileNumberFarmer: "",
                adharcardNumberFarmer: "",
                dateOfBirthFarmer: null,
                totalFamilyMemberFarmer: "",
                educationFarmer: "",
                stateFarmer: "",
                genderFarmer: "",
                districtFarmer: "",
                talukaFarmer: "",
                villageFarmer: "",
                farmnameFarmer: "",
                farmSurveyNumberFarmer: "",
                ownershipFarmer: "",

                firstnameFarmer: "",
                middlenameFarmer: "",
                surnameFarmer: "",

                totalFarmAreaFarmer: "",
                unitOfMeasureFarmer: "",
                horizontalDistanceFarmer: "",
                distanceInFarmer: "",
                motorInHpFarmer: "",
                depthOfPumpFarmer: "",
                soilTextureFarmer: "",
                landTypeFarmer: "",
                sourceOfIrrigationFarmer: "",
                typeOfIrrigationFarmer: "",
            },
            userLandTypeFarmer: null,
            selectedFEformData: null,
            farmImageFile: null,
            farmImageURL: null,
        })
    }
    resetFEFormValues = () => {
        this.setState({
            feInitialValues: {
                firstnameFE: "",
                lastnameFE: "",
                mobileNumberFE: "",
                locationFE: "",
                dateOfBirthFE: null,
                emailAddressFE: "",
                usernameFE: "",
                mobileDeviceModelFE: "",
            },
            selectedLocationArr: [],
        })
    }

    resetAdminFormValues = () => {
        this.setState({
            adminInitialValues: {
                firstnameAdmin: "",
                lastnameAdmin: "",
                mobileNumberAdmin: "",
                emailAddressAdmin: "",
                usernameAdmin: "",
                dateOfBirthAdmin: null,
                passwordAdmin: "",
                confirmPasswordAdmin: "",
            },
        })
    }

    handleEditSuperAdmin = () => {
        const { currentSuperAdminData: data } = this.state
        this.setState({
            editUserModal: true,
            seletedUserTypeForm: "SuperAdmin",
            adminInitialValues: {
                firstnameAdmin: data.first_name,
                lastnameAdmin: data.last_name,
                mobileNumberAdmin: data.phone_number,
                emailAddressAdmin: data.email,
                usernameAdmin: data.user_name,
                dateOfBirthAdmin: moment(data.dob),
            },
            addUserModal: true
        })
    }

    isEditUserModalOpen = () => {
        return this.state.editUserModal === true ? true : false;
    };

    handleAdminEditClick = (data: any) => {
        this.setState({
            seletedUserTypeForm: "Admin",
            adminRowData: data,
            adminInitialValues: {
                firstnameAdmin: data.first_name,
                lastnameAdmin: data.last_name,
                mobileNumberAdmin: data.phone_number,
                emailAddressAdmin: data.email,
                usernameAdmin: data.user_name,
                dateOfBirthAdmin: moment(data.dob),
            },
            editUserModal: true,
            addUserModal: true,
        });
    };
    handleAdminDelete = (data: any) => {
        this.setState({ deleteAdminUserData: data, openDeletePopup: true });
    };

    handleDeletePopupClose = () => {
        this.setState({
            openDeletePopup: false, deleteAdminUserData: null,
            openSuperAdminDeletePopup: false
        })
    }
    handleErrorPopupClose = () => {
        this.setState({ errorPopupOpen: false, errorPopupMsg: "" });
    }
    // Admin-SA Form Functions
    setAdminSchema = () => {
        return this.state.editUserModal ? SchemaAdminFormEDIT : SchemaAdminFormADD;
    };
    handleSubmitAdmin = (values: any) => {
        if (this.state.seletedUserTypeForm === "SuperAdmin") {
            this.state.editUserModal
                ? this.updateSuperAdmin(values)
                : this.addSuperAdmin(values);
        } else {
            this.state.editUserModal
                ? this.updateAdmin(values)
                : this.addAdmin(values);
        }
    };
    // Farmer Form Fucntions
    isLandtypeIrrigation = () => {
        return this.state.userLandTypeFarmer?.label === "Irrigation" ? true : false;
    };
    assignFEPlaceholder = () => {
        return this.state.selectedFEformData
            ? ""
            : "Search by field executive name";
    };
    disableDistrictFarmerValue = () => {
        return this.state.getStateData.length && this.state.getDistrictData.length
            ? false
            : true;
    };
    disableTalukaFarmerValue = () => {
        return this.state.getDistrictData.length && this.state.getTalukaData.length
            ? false
            : true;
    };
    disableVillageFarmerValue = () => {
        return this.state.getTalukaData.length && this.state.getVillageData.length
            ? false
            : true;
    };
    getVillageFarmerValue = (values: any) => {
        return this.state.getVillageData?.length ? values?.villageFarmer : "";
    };
    getTalukaFarmerValue = (values: any) => {
        return this.state.getTalukaData?.length ? values?.talukaFarmer : "";
    };
    getDistrictFarmerValue = (values: any) => {
        return this.state.getDistrictData?.length ? values?.districtFarmer : "";
    };

    handleAssignFEchange = (event: any, value: any) => {
        this.setState({ selectedFEformData: value });
    };
    checkLandType(id: any) {
        if (id && this.state.getLandTypeFarmerData.length) {
            const matchedObj = this.state.getLandTypeFarmerData.find(
                (ech: any) => ech.id === Number(id)
            );
            this.setState({ userLandTypeFarmer: matchedObj });
        } else {
            this.setState({ userLandTypeFarmer: null });
        }
    }
    async handleImageUpload(event: any) {
        const selectedFile = event.target.files[0];
        const urlImage = await getBase64(selectedFile);
        this.setState({ farmImageURL: urlImage, farmImageFile: selectedFile });
    }
    // FE HANDLE FUNCTIONS
    onFeLocationsChange = (event: any, values: any) => {
        this.setState({
            selectedLocationArr: values,
        });
    };
    assignVillagePlaceholder = () => {
        return this.state.selectedLocationArr.length === 0
            ? "Search by village name"
            : "";
    };


    // ASSIGN VILLAGE FILTER FUNCTION STARTS
    handleVillageFilterClear = () => {
        this.setState({
            expanded: false,
            selectedState: {
                name: "Select State",
                id: "",
            },
            selectedDistrict: {
                name: "Select District",
                id: "",
            },
            selectedTaluka: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillage: {
                name: "Select Village",
                id: "",
            },
            stateInputValue: "",
            districtInputValue: "",
            talukaInputValue: "",
            villageInputValue: "",
            filteredStates: this.state.initialStates,
            filteredDistricts: this.state.initalDistricts,
            filteredTalukas: this.state.initialTalukas,
            filteredVillages: this.state.initialVillages,
            isDisabled: {
                state: false,
                district: true,
                taluka: true,
                village: true,
            },
            isAccordianError: {
                state: false,
                district: false,
                taluka: false,
                village: false,
            },
        });
    }
    closeVillageFilter = () => {
        this.setState({
            isVillageFilterOpen: false,
            expanded: false,
            selectedState: {
                name: "Select State",
                id: "",
            },
            selectedDistrict: {
                name: "Select District",
                id: "",
            },
            selectedTaluka: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillage: {
                name: "Select Village",
                id: "",
            },
            stateInputValue: "",
            districtInputValue: "",
            talukaInputValue: "",
            villageInputValue: "",
            isDisabled: {
                state: false,
                district: true,
                taluka: true,
                village: true,
            },
            isAccordianError: {
                state: false,
                district: false,
                taluka: false,
                village: false,
            },
        });
    }
    handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
        this.setState({
            expanded: newExpanded ? panel : false,
            stateInputValue: "",
            districtInputValue: "",
            talukaInputValue: "",
            villageInputValue: "",
            filteredStates: this.state.initialStates,
            filteredDistricts: this.state.initalDistricts,
            filteredTalukas: this.state.initialTalukas,
            filteredVillages: this.state.initialVillages,
        });
    };
    handleVillageDelete = (event: any, village: any) => {
        const BEvillageObject: any = this.state.defaultAssignedVillages.find(
            (item: any) => String(item.attributes.village_id) === String(village.id)
        );
        if (BEvillageObject) {
            this.setState({
                deletedVillagesList: [
                    ...this.state.deletedVillagesList,
                    {
                        id: BEvillageObject?.id,
                        _destroy: true,
                    },
                ],
            });
        }
        const { selectedLocationArr } = this.state;
        const updatedOptions = selectedLocationArr.filter(
            (option: any) => option !== village
        );

        this.setState({ selectedLocationArr: updatedOptions });
    };
    handleVillageSearch = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ villageInputValue: e?.target?.value });
        const filteredArray = query
            ? this.state.initialVillages.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialVillages;
        this.setState({
            filteredVillages: filteredArray,
        });
    }
    handleTalukaSearch = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ talukaInputValue: e?.target?.value });
        const filteredArray = query
            ? this.state.initialTalukas.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialTalukas;
        this.setState({
            filteredTalukas: filteredArray,
        });
    }
    handleDistrictSearch = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ districtInputValue: e?.target?.value });
        const filteredArray = query
            ? this.state.initalDistricts.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initalDistricts;
        this.setState({
            filteredDistricts: filteredArray,
        });
    }
    handleStateSearch = (e: any) => {
        const query = e?.target?.value?.toLowerCase() || "";
        this.setState({ stateInputValue: e?.target?.value });
        const filteredArray = query
            ? this.state.initialStates.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialStates;
        this.setState({
            filteredStates: filteredArray,
        });
    }
    handleChangeVillageField = (villageData: any) => {
        this.setState(
            {
                selectedVillage: villageData,
                expanded: false,
            },
            () => {
                this.checkAccordianError();
            }
        );
    }
    handleChangeTalukaField = (talukaData: any) => {
        this.setState(
            {
                selectedTaluka: talukaData,
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
                expanded: false,
            },
            () => {
                this.checkAccordianError();
                this.getVillages();
            }
        );
    }
    handleChangeDistrictField = (districtData: any) => {
        this.setState(
            {
                selectedDistrict: districtData,
                selectedTaluka: {
                    name: "Select Taluka",
                    id: "",
                },
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
                expanded: false,
            },
            () => {
                this.checkAccordianError();
                this.getTalukas();
            }
        );
    }
    handleChangeStateField = (stateData: any) => {
        this.setState(
            {
                selectedState: stateData,
                selectedDistrict: {
                    name: "Select District",
                    id: "",
                },
                selectedTaluka: {
                    name: "Select Taluka",
                    id: "",
                },
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
                expanded: false,
            },
            () => {
                this.checkAccordianError();
                this.getDistricts();
            }
        );
    }
    handleSubmitAccordion = () => {
        const result: boolean = this.checkAccordianError();
        if (result === true) {
            this.setState({
                expanded: false,
            });
        } else {
            const FilterObj = this.state.AllVillageListData.find(
                (item: any) => item.id === this.state.selectedVillage.id
            );
            let newVillageValue = [...this.state.selectedLocationArr];
            newVillageValue.push(FilterObj);
            this.setState(
                {
                    selectedLocationArr: newVillageValue,
                    isVillageFilterOpen: false,
                },
                () => {
                    this.closeVillageFilter();
                }
            );
        }
    }
    checkAccordianError = () => {
        let isStateEmpty = false;
        let isDistrictEmpty = false;
        let isTalukaEmpty = false;
        let isVillageEmpty = false;

        if (
            this.state.selectedState.id === "" || this.state.selectedDistrict.id === "" ||
            this.state.selectedTaluka.id === "" || this.state.selectedVillage.id === ""
        ) {
            if (this.state.selectedState.id === "") {
                isStateEmpty = true;
            }
            if (this.state.selectedDistrict.id === "") {
                isDistrictEmpty = true;
            }
            if (this.state.selectedTaluka.id === "") {
                isTalukaEmpty = true;
            }
            if (this.state.selectedVillage.id === "") {
                isVillageEmpty = true;
            }
            this.setState({
                isAccordianError: {
                    ...this.state.isAccordianError,
                    state: isStateEmpty,
                    district: isDistrictEmpty,
                    taluka: isTalukaEmpty,
                    village: isVillageEmpty,
                },
            });
            return true;
        } else {
            this.setState({
                isAccordianError: {
                    ...this.state.isAccordianError,
                    state: isStateEmpty,
                    district: isDistrictEmpty,
                    taluka: isTalukaEmpty,
                    village: isVillageEmpty,
                },
            });
            return false;
        }
    }
    opacityVillageCalculator = () => {
        if (this.state.selectedVillage.name === "Select Village") {
            return 0.48
        }
        else {
            return 1
        }
    }
    opacityTalukaCalculator = () => {
        if (this.state.selectedTaluka.name === "Select Taluka") {
            return 0.48
        }
        else {
            return 1
        }
    }
    opacityDistrictCalculator = () => {
        if (this.state.selectedDistrict.name === "Select District") {
            return 0.48
        }
        else {
            return 1
        }
    }
    opacityStateCalculator = () => {
        if (this.state.selectedState.name === "Select State") {
            return 0.48
        }
        else {
            return 1
        }
    }
    // ASSIGN VILLAGE FILTER FUNCTION ENDS

    handleCloseSuccessModal = () => {
        this.setState({
            successModalOpen: false,
            successModalMsg: ""
        })
    }

    // HANDLE FUNCTIONS ENDS

    // API CALL FUNCTIONS STARTS
    fetchSuperAdminById = (superAdminId: string) => {
        this.setState({
            currentSuperAdminData: { first_name: "", last_name: "", dob: null },
            currentSuperAdminLoading: true,
            currentSuperAdminID: ""

        });

        let endpoint: string = `${configJSON.getTableAPiEndPoint
            }/${superAdminId}?role_super_admin=${true}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getSuperAdminByIdApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getTableAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    fetchAdminTableData = () => {
        this.setState({ adminTableData: [], adminTableDataLoading: true });

        let adminTableEndpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.adminTablePageSize) * Number(this.state.adminTableCurrentPage + 1)}&page=${1}&search=${this.state.searchAdminInputValue}&list_of_admins=${true}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAdminsDataApiCallId = requestMessage.messageId;
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            adminTableEndpoint
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    addAdmin = (adminBody: any) => {
        this.setState({ addAdminLoading: true })
        const dob = moment(adminBody.dateOfBirthAdmin).format("DD/MM/YYYY");
        const header = {
            "content-type": configJSON.jsonContentType,
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST_METHOD_TYPE
        );

        this.addAdminApiCallId = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}?add_admin=${true}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        const requestBody = {
            data: {
                attributes: {
                    first_name: adminBody.firstnameAdmin,
                    last_name: adminBody.lastnameAdmin,
                    full_phone_number: "91" + adminBody.mobileNumberAdmin,
                    email: adminBody.emailAddressAdmin,
                    dob: dob,
                    user_name: adminBody.usernameAdmin,
                    password: adminBody.passwordAdmin,
                    password_confirmation: adminBody.confirmPasswordAdmin,
                },
            },
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateAdmin = (adminNewBody: any) => {
        this.setState({ updateAdminLoading: true })

        const adminId = this.state.adminRowData.id;
        const dob = moment(adminNewBody.dateOfBirthAdmin).format("DD/MM/YYYY");

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateAdminApiCallId = requestMessageAPI.messageId;

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPiMethod
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}/${adminId}?role_admin=true`
        );

        const header = {
            "content-type": configJSON.jsonContentType,
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        const requestBody = {
            data: {
                attributes: {
                    first_name: adminNewBody.firstnameAdmin,
                    last_name: adminNewBody.lastnameAdmin,
                    email: adminNewBody.emailAddressAdmin,
                    dob: dob,
                    phone_number: adminNewBody.mobileNumberAdmin,
                    full_phone_number: "91" + adminNewBody.mobileNumberAdmin,
                    user_name: adminNewBody.usernameAdmin,
                    password: adminNewBody.passwordAdmin,
                    password_confirmation: adminNewBody.confirmPasswordAdmin,
                },
            },
        };
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );
        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    handleDeleteUser = () => {
        this.setState({ deleteAdminLoading: true });
        let userId = this.state.deleteAdminUserData?.id;

        let deleteAdminEndpoint = `${configJSON.deleteUserApiEndPoint}/${userId}?role_admin=${true}`;

        if (userId) {
            const requestMessageAPI = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.deleteAdminUserDataApiCallId = requestMessageAPI.messageId;

            const header = {
                Accept: configJSON.jsonContentType,
                token: this.state.TokenValue,
            };
            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                deleteAdminEndpoint
            );

            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.DELETE_METHOD_TYPE
            );

            runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
            });
            this.setState({ deleteAdminLoading: false });

            return false;
        }
    };

    addSuperAdmin = (superAdminBody: any) => {
        this.setState({ addSALoading: true })

        const dob = moment(superAdminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

        const header = {
            "content-type": configJSON.jsonContentType,
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addSuperAdminApiCallId = requestMessageAPI.messageId;

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.POST_METHOD_TYPE
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}?add_super_admin=${true}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        const requestBody = {
            data: {
                attributes: {
                    first_name: superAdminBody.firstnameAdmin,
                    last_name: superAdminBody.lastnameAdmin,
                    email: superAdminBody.emailAddressAdmin,
                    dob: dob,
                    full_phone_number: "91" + superAdminBody.mobileNumberAdmin,
                    user_name: superAdminBody.usernameAdmin,
                    password: superAdminBody.passwordAdmin,
                    password_confirmation: superAdminBody.confirmPasswordAdmin,
                },
            },
        };

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    updateSuperAdmin = (superAdminNewBody: any) => {
        this.setState({ updateSALoading: true })

        const dob = moment(superAdminNewBody.dateOfBirthAdmin).format("DD/MM/YYYY");
        const superAdminId = this.state.currentSuperAdminID?.id;

        const header = {
            "content-type": configJSON.jsonContentType,
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateSuperAdminApiCallId = requestMessageAPI.messageId;

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPiMethod
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}/${superAdminId}?role_super_admin=${true}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        const requestBody = {
            data: {
                attributes: {
                    role: "super_admin",
                    first_name: superAdminNewBody.firstnameAdmin,
                    last_name: superAdminNewBody.lastnameAdmin,
                    email: superAdminNewBody.emailAddressAdmin,
                    dob: dob,
                    phone_number: superAdminNewBody.mobileNumberAdmin,
                    full_phone_number: "91" + superAdminNewBody.mobileNumberAdmin,
                    user_name: superAdminNewBody.usernameAdmin,
                    password: superAdminNewBody?.passwordAdmin,
                    password_confirmation: superAdminNewBody?.confirmPasswordAdmin,
                },
            },
        };
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );
        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    deleteSuperAdmin = () => {
        this.setState({ deleteSALoading: true });
        let userId = this.state.currentSuperAdminID?.id;
        let endpoint = `${configJSON.deleteUserApiEndPoint}/${userId}?role_super_admin=${true}`;

        if (userId) {
            const requestMessageAPI = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            const header = {
                Accept: configJSON.jsonContentType,
                token: this.state.TokenValue,
            };
            this.deleteSuperAdminApiCallId = requestMessageAPI.messageId;
            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                endpoint
            );

            requestMessageAPI.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.DELETE_METHOD_TYPE
            );

            runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
        } else {
            this.setState({
                errorPopupOpen: true,
                errorPopupMsg: configJSON.AdminConsoleDefaults.ErrorDefault
            });
            this.setState({ deleteSALoading: false });
            return false;
        }


    }

    addFarmer = (farmerBody: any) => {
        this.setState({ addFarmerLoading: true })

        const dobFarmer = moment(farmerBody.dateOfBirthFarmer).format("DD/MM/YYYY");
        const FEid = this.state.selectedFEformData
            ? this.state.selectedFEformData.id
            : null;

        const header = {
            token: this.state.TokenValue,
        };

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addFarmerApiCallId = requestMessageAPI.messageId;

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}?add_farmer=${true}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        let reqFormdataObj = new FormData();
        reqFormdataObj.append("data[attributes][role_id]", "2");
        reqFormdataObj.append(
            "data[attributes][first_name]",
            farmerBody.firstnameFarmer
        );
        reqFormdataObj.append(
            "data[attributes][middle_name]",
            farmerBody.middlenameFarmer
        );
        reqFormdataObj.append("data[attributes][last_name]", farmerBody.surnameFarmer);
        reqFormdataObj.append(
            "data[attributes][phone_number]",
            farmerBody.mobileNumberFarmer
        );
        reqFormdataObj.append(
            "data[attributes][full_phone_number]",
            "+91" + farmerBody.mobileNumberFarmer
        );
        reqFormdataObj.append(
            "data[attributes][aadhaar_number]",
            farmerBody.adharcardNumberFarmer
        );
        reqFormdataObj.append("data[attributes][date_of_birth]", dobFarmer);
        reqFormdataObj.append(
            "data[attributes][total_family_members]",
            farmerBody.totalFamilyMemberFarmer
        );
        reqFormdataObj.append(
            "data[attributes][highest_education_id]",
            farmerBody.educationFarmer
        );
        reqFormdataObj.append("data[attributes][state_id]", farmerBody.stateFarmer);
        reqFormdataObj.append("data[attributes][gender]", farmerBody.genderFarmer);
        reqFormdataObj.append(
            "data[attributes][district_id]",
            farmerBody.districtFarmer
        );
        reqFormdataObj.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
        reqFormdataObj.append(
            "data[attributes][village_id]",
            farmerBody.villageFarmer
        );
        if (this.state.farmImageFile) {
            reqFormdataObj.append(
                "data[attributes][land_details_attributes[0]][farm_image]",
                this.state.farmImageFile
            );
        }
        // farm_mapping_image
        if (this.state.farmMappingFile) {
            reqFormdataObj.append(
                "data[attributes][land_details_attributes[0]][farm_mapping_image]",
                this.state.farmMappingFile
            );
        }
        if (this.state.latlngHash) {
            reqFormdataObj.append(
                "data[attributes][land_details_attributes[0]][latitude_longitude]",
                this.state.latlngHash
            );
        }
        reqFormdataObj.append(
            "data[attributes][land_details_attributes[0]][name]",
            farmerBody.farmnameFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][survey_number]",
            farmerBody.farmSurveyNumberFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][ownership_of_land_id]",
            farmerBody.ownershipFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][farm_area]",
            farmerBody.totalFarmAreaFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][farm_area_for_cotton]",
            farmerBody.totalFarmAreaFarmer
        ); //extra
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][unit_farm_area_id]",
            farmerBody.unitOfMeasureFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][horizontal_distance]",
            farmerBody.horizontalDistanceFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][distance_in_id]",
            farmerBody.distanceInFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][motor_horse_power]",
            farmerBody.motorInHpFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][pump_depth]",
            farmerBody.depthOfPumpFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][land_type_id]",
            farmerBody.landTypeFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][source_irrigation_id]",
            farmerBody.sourceOfIrrigationFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][type_irrigation_id]",
            farmerBody.typeOfIrrigationFarmer
        );
        reqFormdataObj.append(
            "data[attributes][land_details_attributes][0][soil_texture_id]",
            farmerBody.soilTextureFarmer
        ); //extra
        if (this.state.selectedFEformData) {
            reqFormdataObj.append("data[attributes][assigned_fe_id]", FEid);
        }

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            reqFormdataObj
        );
        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchEducationFarmer = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getEducationFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getEducationFarmerAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchOwnerListFarmer = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getOwnerListFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getOwnerListFarmerAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchStates = () => {
        this.setState({
            getDistrictData: [],
            getTalukaData: [],
            getVillageData: [],
        });
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getStateApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getStateAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getTableAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchDistrict = (stateId: string | number) => {
        if (!stateId || stateId === "") {
            this.setState({
                getDistrictData: [],
                getTalukaData: [],
                getVillageData: [],
            });
            return false;
        }
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getDistrictApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDistrictAPiEndPoint}?state_id=${stateId}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getTableAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchTaluka = (districtId: string | number) => {
        if (!districtId || districtId === "") {
            this.setState({ getTalukaData: [], getVillageData: [] });
            return false;
        }
        this.setState({ getTalukaData: [], getVillageData: [] });

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getTalukaApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTalukaAPiEndPoint}?district_id=${districtId}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchVillage = (villageId: string | number) => {
        if (!villageId) {
            this.setState({ getVillageData: [] });
            return false;
        }
        this.setState({ getVillageData: [] });

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getVillageApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getVillageAPiEndPoint}?taluka_id=${villageId}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchUnitFarmAreaUrlData = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUnitOfMeasureFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.UnitFarmAreaUrl
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchDistnaceInData = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getDistanceInApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDistanceInAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchLandTypeFarmer = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getLandTypeFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getLandTypeFarmerAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchSoilTextureFarmer = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getSoilTextureFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSoilTextureFarmerAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchSourceOfIrrigationFarmer = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getSourceOfIrrigationFarmerApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSourceOfIrrigationFarmerAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchTypeIrrigation = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getTypeIrrigationListingApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTypeIrrigationListingAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchFElist = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getFElistApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getFEListAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };

    fetchMobileDeviceModelFE = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };
        this.getMobileDeviceModelFEApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMobileDeviceModelFEAPiEndPoint
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };
    fetchAllVillageList = () => {
        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getAllVillageListApiCallId = requestMessageAPI.messageId;
        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getVillageAPiEndPoint}`
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };

    addFieldExecutive = (feBody: any) => {
        this.setState({ addFELoading: true })

        const dobFe = moment(feBody.dateOfBirthFE).format("DD/MM/YYYY");

        let feLocationsArrTemp: any[] = [];
        if (this.state.selectedLocationArr.length) {
            this.state.selectedLocationArr.forEach((each: any) => {
                feLocationsArrTemp.push({ village_id: each.id });
            });
        } else {
            feLocationsArrTemp = [];
        }

        const requestMessageAPI = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addFEApiCallId = requestMessageAPI.messageId;

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod
        );

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTableAPiEndPoint}?add_fe=${true}`
        );
        const header = {
            "content-type": configJSON.jsonContentType,
            Accept: configJSON.jsonContentType,
            token: this.state.TokenValue,
        };

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        const requestBody = {
            data: {
                attributes: {
                    role_id: 1,
                    first_name: feBody.firstnameFE,
                    last_name: feBody.lastnameFE,
                    phone_number: feBody.mobileNumberFE,
                    location: feBody.locationFE,
                    accounts_villages_attributes: feLocationsArrTemp,
                    date_of_birth: dobFe,
                    email: feBody.emailAddressFE,
                    user_name: feBody.usernameFE,
                    mobile_type_id: feBody.mobileDeviceModelFE,
                    total_family_members: 0,
                    full_phone_number: "+91" + feBody.mobileNumberFE,
                },
            },
        };

        requestMessageAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );

        runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    };

    // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS
    getStates() {
        this.setState({
            isDisabled: {
                ...this.state.isDisabled,
                district: true,
                village: true,
                taluka: true,
            },
            selectedDistrict: {
                name: "Select District",
                id: "",
            },
            selectedTaluka: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillage: {
                name: "Select Village",
                id: "",
            },
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVillageFilterStateData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getStateList}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getDistricts() {
        let params = this.state.selectedState.id;
        if (this.state.selectedState.id) {
            this.setState({
                isDisabled: {
                    ...this.state.isDisabled,
                    district: true,
                    village: true,
                    taluka: true,
                },
                selectedDistrict: {
                    name: "Select District",
                    id: "",
                },
                selectedTaluka: {
                    name: "Select Taluka",
                    id: "",
                },
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
            });

            const headers = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.TokenValue,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getVillageFilterDistrictData = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getDistrictList}?state_id=${params}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            return false;
        }
    }
    getTalukas() {
        let params = this.state.selectedDistrict.id;
        if (this.state.selectedDistrict.id) {
            this.setState({
                isDisabled: {
                    ...this.state.isDisabled,
                    taluka: true,
                    village: true,
                },
                selectedTaluka: {
                    name: "Select Taluka",
                    id: "",
                },
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
            });
            const headers = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.TokenValue,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getVillageFilterTalukaData = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getTalukaList}?district_id=${params}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            return false;
        }
    }
    getVillages() {
        let params = this.state.selectedTaluka.id;
        if (this.state.selectedTaluka.id) {
            this.setState({
                isDisabled: {
                    ...this.state.isDisabled,
                    village: true,
                },
                selectedVillage: {
                    name: "Select Village",
                    id: "",
                },
            });
            const headers = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.TokenValue,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getVillageFilterVillageData = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getVillageList}?taluka_id=${params}`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            return false;
        }
    }
    // ASSIGNE VILLAGE FILTER API CALL FUNCTIONS STARTS

    // API CALL FUNCTIONS ENDS

    // Customizable Area End
}
