// Customizable Area Start
import React from "react";
export const configJSON = require("./config");

import {
  Box,
  Typography,
  Tooltip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Share } from "@material-ui/icons";

import {
  gapFillingImg,
  harvestImg,
  irrigationImg,
  nutrientManagementImg,
  pestManagementImg,
  preSowingImg,
  sellImg,
  sowingImg,
  weedManagementImg,
} from "../../AdminConsole/src/assets";

import UserFarmDiaryController, { Props } from "./UserFarmDiaryController";
import LinkShare from "../../LinkShare/src/LinkShare.web";


const farmDiaryCategoryMappingIcons: any = {
  pre_sowing: preSowingImg,
  sowing: sowingImg,
  gap_filling: gapFillingImg,
  weed_management: weedManagementImg,
  nutrient: nutrientManagementImg,
  pest_management: pestManagementImg,
  irrigation: irrigationImg,
  harvest: harvestImg,
  sell: sellImg
}

// Customizable Area End

const StyledTypography = withStyles({
  root: {},
  label: {},
  body1: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1c2324",
    marginBottom: "4px",
  },
  body2: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    marginBottom: "4px",
  },
  subtitle1: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
})(Typography);

const StyledAccordionSummary = withStyles({
  root: {
    padding: "0px 24px !important",
  },
})(AccordionSummary);

const StyledAccordionDetails = withStyles({
  root: {
    padding: "0px 16px 20px !important",
  },
})(AccordionDetails);


export default class UserFarmDiary extends UserFarmDiaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  changeElemType = (elem: any) => {
    if (elem.type.includes('pest_management')) {     
      return 'pest_management'
    }
    return elem.type
  }

  displayFarmDiary = () => {
    const farmDiaryEntries = this.props.data.map((elem: any) => {
      const originalType = elem.type;      
      const elemType = this.changeElemType(elem);
      const farmDiaryCategoryItems =
        configJSON.farmDiaryCategoryMapping[elemType];

      let categoryItems = [];

      for (let key in farmDiaryCategoryItems) {
        const valueFormatter = farmDiaryCategoryItems[key].formatter;
        let attributeVal = elem.attributes[key];

        if (attributeVal && typeof attributeVal == "object") {
          const attributeValObj = valueFormatter(elem, key);
          attributeVal = attributeValObj?.name || attributeValObj?.label
        }

        if (attributeVal && farmDiaryCategoryItems[key].type === "DATE") {
          attributeVal = moment(attributeVal).format("DD/MM/YYYY");
        }
        if (this.props.landType === 'Rainfed' && elem.type === 'irrigation') {
          return null;
        }
        if (attributeVal) {
          categoryItems.push(
            <Grid container xs={12} style={{ padding: "0", marginBottom: "8px" }}>
              <Grid xs={7} item style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={farmDiaryCategoryItems[key].label}>
                  <StyledTypography
                    noWrap
                  >
                    {farmDiaryCategoryItems[key].label}:
                  </StyledTypography>
                </Tooltip>
              </Grid>
              <Grid xs={5} item style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={attributeVal}>
                  <StyledTypography
                    variant="body2"
                    noWrap
                  >
                    {attributeVal}
                  </StyledTypography>
                </Tooltip>
              </Grid>
            </Grid>
          );
        }
      }

      const middleIndexCategoryItems = Math.ceil(categoryItems.length / 2);

      const firstHalfCategoryItems = categoryItems.splice(
        0,
        middleIndexCategoryItems
      );
      const secondHalfCategoryItems = categoryItems.splice(
        -middleIndexCategoryItems
      );

      return (
        <Accordion
          expanded={this.state.isExpanded === `${elem.id}-${originalType}`}
          onChange={this.handleAccordionChange(`${elem.id}-${originalType}`)}
          className="farm-diary-accordion accordionSeparator farmDiaryContainer"
          key={`${elem.id}-${originalType}`}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Box display="flex" alignItems="center">
              <Box mr={3}>
                <img src={farmDiaryCategoryMappingIcons[elemType]} alt={elemType} />
              </Box>
              <StyledTypography
                variant="subtitle1"
              >
                {configJSON.farmDiaryScreenDefaults[elemType]}
              </StyledTypography>
            </Box>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Grid xs={12} container>
              <Grid xs={6} item>
                {firstHalfCategoryItems}
              </Grid>
              <Grid xs={6} item>
                {secondHalfCategoryItems}
              </Grid>
              <Grid xs={12}>
            <Grid xs={6} item>
            <Button style={{color:'#006A77'}} startIcon={<Share />} onClick={()=> this.handleShareLink(elem)} > Share in Chat </Button>
            </Grid>
            </Grid>
            </Grid>
            
          </StyledAccordionDetails>
        </Accordion>
      );
    });
    return farmDiaryEntries.filter(Boolean);
  };
  // Customizable Area End

  render() {

    // Customizable Area Start
    const farmDiaryEntries: any = this.displayFarmDiary();

    return (
      <>
        <Box
          className="leftBlock noBorderBottom accordionSeparator farmDiaryContainer"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box className="sectionHeading">Farm Diary Entries</Box>
          <Typography noWrap className="totalFarmDiaries">
            {farmDiaryEntries?.length || '-'}
          </Typography>
        </Box>

        <Box className="farmDiaryGrid">
          {farmDiaryEntries}
        </Box>
        {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}
      </>
      // Customizable Area End
    );
  }
}

