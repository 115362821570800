import React, { ReactElement } from "react";

// Customizable Area Start
import {
    Box,
} from "@material-ui/core";
import moment from "moment";
import { TMessageList } from "../ChatViewController";
import ReactPlayer from "react-player";
import ModalImage from "react-modal-image";
import { handleLinkShare, renderMessageContent } from "../../../../components/src/AdminConsoleHelper";
import { OnProgressProps } from "react-player/base";
import { documentSVG, messageDeliveredSVG, messageSeenSVG, messageSentSVG } from "../assets";

// Customizable Area End
export type MyComponentProps = {
    item: TMessageList;
    search: string;
    highLightMessage: ({ message, search }: { message: string, search: string }) => ReactElement;
    user: Record<"first_name" | "last_name" | "email" | "initials" | "id", string>,
    scrollService: (int: number) => void;
    int: number,
    handleShareLinkPopup: (linkType?: string,linkId?:any,accountId?:any,type?:any)=> void
    handleProgress: (state: OnProgressProps) => void
    is_mark_read?:boolean;
    is_mark_delivered?:boolean
};

export type Message_Meta_Data = {
    email: string;
    video: string;
    url: string;
    created_at: string;
}

function MessageComponent({ item, search, highLightMessage, user, scrollService, int,handleShareLinkPopup, handleProgress ,is_mark_read,is_mark_delivered}: MyComponentProps) {
    const message = <Box style={{ display: "flex",flexDirection:'column' }}>
        {item?.attributes?.message.includes(search) && !!search ? highLightMessage({
             message: item.attributes.message, search 
             }) : <span id={"message_identifier"}>
                    { renderMessageContent(item?.attributes?.message, handleShareLinkPopup) }
                </span>}
        {item?.attributes?.link_share && 
            <a style={{ lineHeight:'22px',
                        textDecorationLine:'underline',
                        color:'#006A77',marginTop:'4px',
                        cursor:'grabbing' 
                    }} 
                onClick={()=>{
                        handleLinkShare(item, handleShareLinkPopup)
                    }}> { item?.attributes?.link_share }
            </a>} 
    </Box>;

    const role: Record<string, string> = {
        "1": "Field Executive",
        "2": "",
        "super_admin": "Super Admin",
        "admin": "Admin"
    }

    const message_user_profile: any = {};
    if (item.attributes.account && item.attributes.account_id) {
        message_user_profile["role_id"] = item.attributes.account.role_id;
        message_user_profile["role"] = role[`${item.attributes.account.role_id}`];
        message_user_profile['full_name'] = `${item.attributes.account.first_name}  ${item.attributes.account.last_name}`;
        message_user_profile['id'] = item.attributes.account.id;
        message_user_profile['isSender'] = item.attributes.account.id === +user.id;
    } else if (item.attributes.admin_user) {
        message_user_profile["role_id"] = item.attributes.admin_user.role_id;
        message_user_profile["role"] = role[`${item.attributes.admin_user.role}`];
        message_user_profile['id'] = item.attributes.admin_user.id;
        message_user_profile['full_name'] = `${item.attributes.admin_user.first_name}  ${item.attributes.admin_user.last_name}`
        message_user_profile['isSender'] = item.attributes.admin_user.id === +user.id;
    }

    const message_meta_data: Message_Meta_Data = {
        email: item.attributes?.admin_user?.email as string,
        url: item?.attributes?.attachments?.url,
        video: item?.attributes?.attachment_file_type,
        created_at: item?.attributes?.created_at,
    }

    const MessageComponentHeader = <h6>
        {message_user_profile['isSender'] ? "You" : `${message_user_profile["role"] && message_user_profile["role"] + " -"} ${message_user_profile["full_name"]}`}
    </h6>

    const MediaMessageElement = (message_meta_data: Message_Meta_Data) => {
        return <Box style={{maxHeight: '400px', maxWidth: '300px'}}>
        {message_meta_data.video === 'video' &&
             <div className='player-wrapper'>
             <ReactPlayer
                className='react-player fixed-bottom'
                url= {message_meta_data.url}
                width='100%'
                height='226px'
                style={{ 
                    borderRadius: '8px',
                    border: '2px solid #DFE8C4' 
                }}
                controls = {true}
                preload = "auto"
                onProgress={handleProgress}
             />
         </div>}
        {message_meta_data.video === 'audio' ? (
            <audio controls src={message_meta_data.url} />
        ) : null}
        {message_meta_data.video === 'image' ? (
            <ModalImage
            small={message_meta_data.url}
            large={message_meta_data.url}
            hideZoom={true}
            className="chat-image"
          />
        ) : null}
        {
            message_meta_data.video === 'application' && <a href={message_meta_data.url} target="_blank"><img className="doc-icon" src={documentSVG} alt="" /></a> 
        }
        </Box>
    }

    const renderMessageStatus=(item:any)=>{
        if(is_mark_read){
            return <img alt="message seen" src={messageSeenSVG} />
        }else if(is_mark_delivered){
            return <img alt="message delivered" src={messageDeliveredSVG} />
        }else{
            return <img alt="message sent" src={messageSentSVG} />
        }
    }
  
    const Sender = <Box className={`content-show sender`}>
        <Box>
            {MessageComponentHeader}
            <span style={{
                display: "flex",
                flexDirection: 'column-reverse',
            }}>
                {message}
                {message_meta_data.url && MediaMessageElement(message_meta_data)}
            </span>
        </Box>
        <Box className="time-msg">
            <span style={{textTransform: "lowercase",display:"flex",alignItems:"center",gap:"5px",justifyContent:"end"}}>{moment(message_meta_data['created_at']).format('LT')} {renderMessageStatus(item)}  </span>
        </Box>
    </Box>

    const Receiver = <Box className={`content-show receiver`}>
        <Box>
            {MessageComponentHeader}
            <span style={{
                display: "flex",
                flexDirection: 'column-reverse',
            }}>
                {message}
                {message_meta_data.url && MediaMessageElement(message_meta_data)}
            </span>
        </Box>

        <Box className="time-msg">
            <span>{moment(message_meta_data['created_at']).format('LT')}</span>
        </Box>
    </Box>

    const resolveMessage = () => {
        if (message_user_profile['isSender']) {
            return Sender;
        }

        return Receiver;
    }

    return (
        <li key={item.id} onClick={() => scrollService(int)} data-testid="message_component_list_item" >
            {resolveMessage()}
        </li>
    );
}

export default React.memo(MessageComponent);
