import React, { useEffect } from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import {
  ExpandMore,
  ExpandLess,
  ArrowForwardIos
} from "@material-ui/icons";
import { RegistrationDropdownIcon, CalenderDropdownIcon, FarmdiaryDropdownIcon, NotificationDropdownIcon, } from "../../blocks/AdminConsole/src/assets";

import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import { Help } from "../../blocks/dashboard/src/assets";



type SubDropdownType = {
  name: string,
  dropdownCounts: number,
}
interface AccordionSectionPropsType {
  handleSelectData: any;
  data: any;
  selectedData: any;
  expandTab: boolean;
  handleAccordion: (id: number) => void;
  id: number
}
interface SubComponentPropsType {
  handleSelectData: any;
  data: SubDropdownType;
  expandTab: boolean;
  selectedData: any;
  id: number
}
const SubDropdownAccordionSection = ({ selectedData, id, data, expandTab, handleSelectData }: SubComponentPropsType) => {
  const classes = useStyles();

  return (
    <Box className={classes.rootSub} key={id} style={{ border: selectedData.name === data.name ? "1px solid #DF5200" : "" }}>
      <Box className={classes.boxContainerSub}>
        <Box className={classes.between}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <Typography
              className={classes.subDropdownTitle}
            >
              {data.name}
            </Typography>
            <Typography
              className={classes.subDropdownSubTitle}
            >

              {data.dropdownCounts} dropdowns
            </Typography>
          </div>
          <div className={classes.expandSubParent}>
            <ArrowForwardIos
              className={classes.expandSubBtn}
              id="expandMore"
              onClick={() => handleSelectData(data)}

            />
          </div>
        </Box>
      </Box>
    </Box>
  );
}

const DropdownAccordionSection = (props: AccordionSectionPropsType) => {
  const { id, data, expandTab, handleAccordion, handleSelectData, selectedData } = props;
  const classes = useStyles(expandTab);
  const keys = Object.keys(data)

  const value: any = Object.values(data)
  let subHeadingsData: { dropdownCounts?: any; name?: any; dropdownData?: any }[] = [];
  let currentSubHeading: { dropdownCounts?: any; name?: any; dropdownData?: any } | null = null;

  value[0]?.forEach((item: any) => {
    if (item.hasOwnProperty('Sub_Heading')) {
      currentSubHeading = { name: item.Sub_Heading, dropdownCounts: null, dropdownData: [] };
      subHeadingsData.push(currentSubHeading);
    } else if (currentSubHeading && item.hasOwnProperty('dropdowns counts')) {
      currentSubHeading.dropdownCounts = item['dropdowns counts'];
    } else if (Array.isArray(item) && currentSubHeading) {
      const dropdownName = item[0]['dropdown name'];
      const dropdownItems = item[1];
      currentSubHeading.dropdownData.push({ [dropdownName]: dropdownItems });
    }
  });
  const getImage = () => {
    let image: any = ""

    if (keys[0] === "Register") {
      image = RegistrationDropdownIcon
    } else if (keys[0] === "Calender") {
      image = CalenderDropdownIcon

    } else if (keys[0] === "Farm Dairy") {
      image = FarmdiaryDropdownIcon

    } else if (keys[0] === "Materrapedia") {
      image = Help
    } else {
      image = NotificationDropdownIcon

    }
    return image
  }
  const getMargin = () => {
    let margin: any = "0 7px 0 0"

    if (keys[0] === "Farm Dairy") {
      margin = "0 10px 0 2px"

    } else if (keys[0] === "Materrapedia") {
      margin = "10px 19px 10px 11px"

    }
    return margin
  }
  const getName = () => {
    let name: any = keys[0]

    if (keys[0] === "Register") {
      name = "Registration"
    } else if (keys[0] === "Calender") {
      name = "Calendar"

    } else if (keys[0] === "Farm Dairy") {
      name = "Farm diary"

    } else {
      name = keys[0]
    }
    return name
  }
  return (
    <>
      {keys && keys[0] !== "Calender" &&
        <Box className={classes.root} key={id}>
          <Box className={classes.boxContainer}>
            <Box className={classes.between}>
              <img
                src={getImage()}
                style={{ margin: getMargin() }}
              />
              <Tooltip title={keys && keys[0]}>
                <Typography
                  className={classes.fontStyles}
                >

                  {getName()}
                </Typography>
              </Tooltip>
              {expandTab ? (
                <ExpandLess
                  className={classes.expandBtn}
                  id="expandMore"
                  onClick={() => handleAccordion(id)}

                />
              ) : (
                <ExpandMore
                  className={classes.expandBtn}
                  id="expandLess"
                  onClick={() => handleAccordion(id)}

                />
              )}
            </Box>

            {expandTab && <Divider className={classes.dividerAccordion} />}
          </Box>
          {expandTab && subHeadingsData.map((item: any, index: any) => {
            return (
              <div >
                <SubDropdownAccordionSection selectedData={selectedData} handleSelectData={handleSelectData} id={index} expandTab={expandTab} data={item} />
              </div>
            )
          })
          }
        </Box>
      }

    </>
  );
};

//style
const useStyles = makeStyles({
  "@media (min-width: 960px) and (max-width: 1099px)": {
    fontStyles: {
      fontSize:"14px !important",
    },
    subDropdownTitle:{
      fontSize:"12px !important"
    }
  },
  root: {
    display: "flex",
    padding: ".5rem",
    background: "#F8F6F1",
    borderRadius: "12px",
    marginBottom: ".75rem",
    position: "relative",
    transition: "all 2s",
    flexDirection: (isOpen) => (isOpen ? "column" : "row"),
  },
  rootSub: {
    display: "flex",
    padding: ".5rem",
    background: "#F0EBE0",
    borderRadius: "12px",
    marginBottom: ".5rem",
    position: "relative",
    transition: "all 2s",
    flexDirection: (isOpen) => (isOpen ? "column" : "row"),
  },
  subDropdownTitle: {
    color: "#1C2324",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px"
  },
  subDropdownSubTitle: {
    color: "#006A77",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px"
  },
  subIconContainer: {
    height: "20px",
    width: "20px"
  },
  container: {
    flexGrow: (isOpen) => (isOpen ? 0 : 0.1),
  },
  boxContainer: {
    width: "100%",
    padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
  },
  boxContainerSub: {
    width: "98%",
    padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
  },
  img: {
    borderRadius: "6px",
    transition: "all 0.2s",
    width: (isOpen) => (isOpen ? "100%" : "82px"),
    height: (isOpen) => (isOpen ? "180px" : "82px"),
  },
  between: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'center'
  },
  fontStyles: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2324",
    margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    textTransform: "uppercase",
  },

  dateContainer: {
    color: "#006A77",
    fontSize: "14px",
    padding: ".4rem 0",
  },
  dateIcon: {
    color: "#006A77",
    fontSize: "14px",
    marginRight: "5px",
  },
  collapseCardLoc: {
    fontSize: "15px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    width: "60px",
    height: '20px',
  },
  font15: {
    fontSize: "15px",
  },
  writerSection: {
    color: '#108B88',
  },
  btnContainer: {
    display: "flex",
    flexGrow: 0.1,
    justifyContent: "space-between",
    alignItem: "center",
    gap: "8px",
  },
  shareBtn: {
    color: "#006A77",
    fontSize: "18px",
  },
  editBtn: { color: "#EB8F06", fontSize: "18px" },
  deleteBtn: { color: "#B3261E", fontSize: "18px" },
  expandBtn: {
    position: "absolute",
    right: "10px",
  },
  expandSubBtn: {

    fontSize: "14px",
  },
  dividerAccordion: {
    marginBottom: "4px",
    marginTop: "10px",
    height: "2px",
    background: "#F0EBE0",
    width: "102%",
  },
  expandSubParent: {
    position: "absolute",
    right: "10px",
    width: "25px",
    height: "25px",
    backgroundColor: "#E4DDC9",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  location: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(28, 35, 36, 0.8)",
    width: '100%',
    marginBottom: '8px',
  },
  boxIconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
  },
  boxIcons: {
    display: "flex",
    alignItems: "center",
  },
  fontSmall: {
    fontSize: "13px",
    color: '#006A77',
  },
  locationTextOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "13px",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    width: "60px",
    height: '20px',
    color: '#1C2324',
  },
  whiteColor: {
    color: "#fff",
  },
  ellipsis: {
    overflow: "hidden",
    lineClamp: 1,
    boxOrient: "vertical",
    maxWidth: "80%",
    display: "-webkit-inline-box",
  },
  btnSection: {
    display: "flex",
    float: "right",
    gap: '10px',
    paddingTop: '7px',
    marginRight: '10px',
  },
  locationExpand: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  font18: {
    fontSize: "14px",
  },
  fw500: {
    fontWeight: 500,
  },
  dateStyleClose: {
    color: "#1C2324",
    fontWeight: 450,
    fontSize: "12px",
  },
  ellipsisNews: {
    fontWeight: 400,
    fontSize: "13px",
    // height: "64px",
    overflow: "hidden",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    lineClamp: 3,
    color: "#1c2324c7"
  }
});

export default DropdownAccordionSection;
