import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Grid,
  Tooltip
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import moment from "moment";
import { configJSON } from "../../blocks/AdminConsole2/src/UserFarmDiary.web";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop: '10px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px",
      textTransform: 'uppercase',
      fontStyle: "normal"
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px",
      textTransform: 'capitalize'
    },
    body1: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop: '5px',
      paddingBottom: '10px'
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});

const StyledTypography = withStyles({
  root: {},
  label: {},
  body1: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1c2324",
    marginBottom: "4px",
  },
  body2: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    marginBottom: "4px",
  },
  subtitle1: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
})(Typography);

interface FarmDiaryContent {
  handleShareLinkPopup: (linkType?: string, linkId?: any, accountId?: any, type?: any) => void;
  isLinkPopup: boolean;
  farmObj: any,
  farmType: string
}

const FarmDiaryChatContent = (props: FarmDiaryContent) => {
  const useStyles = makeStyles((theme) => ({
    modalContent: {
      width: '480px',
      "@media (max-width: 550px)": {
        width: "calc(100% - 44px)",
      },
    },
  }))
  
  const classes = useStyles();
  const { handleShareLinkPopup, isLinkPopup, farmObj, farmType } = props

  const changeElemType = (farmType: any) => {
    if(farmType){
      if (farmType.includes('pest_management')) {
        return 'pest_management'
      }
      return farmType
    }   
  }

  const farmDiaryType = changeElemType(farmType);

  const displayFarmDiary = () => {
    if(farmType){    
    const elemType = changeElemType(farmType);
    const farmDiaryCategoryItems = configJSON.farmDiaryCategoryMappingChatPopup[elemType];
    let categoryItems = [];
    for (let key in farmDiaryCategoryItems) {
      const valueFormatter = farmDiaryCategoryItems[key].formatter;
      let attributeVal = farmObj[key];
      if (attributeVal && typeof attributeVal == "object") {
        const attributeValObj = valueFormatter(farmObj, key);
        attributeVal = attributeValObj.name || attributeValObj.label
      }
      if (attributeVal && farmDiaryCategoryItems[key].type === "DATE") {
        attributeVal = moment(attributeVal).format("DD/MM/YYYY");
      }
      if (farmType === 'irrigation') {
        return null;
      }
      if (attributeVal) {
        categoryItems.push(
          <Grid container xs={12} style={{ padding: "0", marginBottom: "8px" }}>
            <Grid xs={7} item style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={farmDiaryCategoryItems[key].label}>
                <StyledTypography noWrap>
                  {farmDiaryCategoryItems[key].label}:
                </StyledTypography>
              </Tooltip>
            </Grid>
            <Grid xs={5} item style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={attributeVal}>
                <StyledTypography variant="body2" noWrap >
                  {attributeVal}
                </StyledTypography>
              </Tooltip>
            </Grid>
          </Grid>
        );
      }
    }

    const middleIndexCategoryItems = Math.ceil(categoryItems.length / 2);
    const firstHalfCategoryItems = categoryItems.splice(0,middleIndexCategoryItems);
    const secondHalfCategoryItems = categoryItems.splice(-middleIndexCategoryItems);
    return (
      <Grid xs={12} container>
        <Grid xs={6} item>
          {firstHalfCategoryItems}
        </Grid>
        <Grid xs={6} item>
          {secondHalfCategoryItems}
        </Grid>
      </Grid>
    );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <DialogTitle className="modalDialogTitle">
              <Typography variant="subtitle1">
                {"Farm Diary"}
              </Typography>
            </DialogTitle>
          </div>
          <div style={{ marginRight: 20, color: '#1C2324' }}>
            <IconButton
              aria-label="close"
              data-testid="modalCloseBtnTestid"
              onClick={(e) => {
                const linkType = 'farmdairy'
                handleShareLinkPopup(linkType)
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <DialogContent dividers className="modalBodyWrapper">
          <Box className={classes.modalContent} style={{ borderRadius: '12px', boxSizing: 'border-box', marginLeft: '22px', marginBottom: '15px',background:'#F0EBE0' }}>
          <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
           <Typography variant="subtitle2"> 
            {farmDiaryType && configJSON.farmDiaryScreenDefaults[farmDiaryType]}
            </Typography>
           </div>  
            <Box style={{ paddingBottom: '10px', marginLeft: '10px', marginRight: '10px' }}>
              {displayFarmDiary}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start
// Customizable Area End

export default FarmDiaryChatContent;