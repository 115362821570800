export const bellIcon = require("../assets/bell.png");
export const circleIcon = require("../assets/circle.png");
export const crossIcon = require("../assets/cross.png");
// export const bellIcon = require("../../global_assets/bell.png");
// export const circleIcon = require("../assets/circle.png");
// export const crossIcon = require("../assets/cross.png");
export const img4224fe4a50285de9324ed4093827830b32a20f9a = require("../../global_assets/4224fe4a50285de9324ed4093827830b32a20f9a.png");
export const img3a3819e8ff74c051c05abba030d2b5f42f6c6a49 = require("../../global_assets/3a3819e8ff74c051c05abba030d2b5f42f6c6a49.png");
export const imgeca50a720f0a9d58669b69ce160fbfb73a0def5d = require("../../global_assets/eca50a720f0a9d58669b69ce160fbfb73a0def5d.png");
export const imgf814aa5c414f2550af6f51e00f6f5710b88ba20c = require("../assets/f814aa5c414f2550af6f51e00f6f5710b88ba20c.png");
export const img3677cb0551e24bb50ef517510b9f03e9d2e83886 = require("../assets/3677cb0551e24bb50ef517510b9f03e9d2e83886.png");
export const img56099666609ccdd1735f7fb84d3293c86cd4f745 = require("../assets/56099666609ccdd1735f7fb84d3293c86cd4f745.png");
